import React from 'react';
import styled from 'styled-components';

import { Message } from '@pro4all/graphql';
import { Tag } from '@pro4all/shared/ui/tag';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { tryDecodeString } from '@pro4all/shared/utils';

export const AttachmentsTooltip = ({
  attachments,
}: {
  attachments: Message['attachments'];
}) => {
  const count = (attachments || []).length;

  return (
    <Container>
      {count > 0 && (
        <Tooltip
          placement="bottom"
          title={(attachments || [])
            ?.map((a) => tryDecodeString(a.fileName))
            .join(', ')}
        >
          <Tag
            color="default"
            name={count.toString()}
            startIcon="attachment"
            variant="outlined"
          />
        </Tooltip>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 8px;
`;
