import React from 'react';
import { useTranslation } from 'react-i18next';

import { NavLink } from '@pro4all/shared/ui/nav-link';

export const LogoutLink: React.FC = () => {
  const { t } = useTranslation();

  return (
    <NavLink
      aria-label={t('Log out')}
      data-testid="logout"
      icon="logout"
      label={t('Log out')}
      to="/logout"
    />
  );
};
