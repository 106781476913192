import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Project, useDeleteProjectsMutation } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Dialog } from '@pro4all/shared/ui/dialog';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';

export const ConfirmProjectDeleteDialog: React.FC = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { enqueueSnackbar } = useSnackbar();

  const deleteProjectParam = searchParams.get('action') === 'deleteProject';
  const projectId = searchParams.get('id') || '';
  const projectName = decodeURIComponent(searchParams.get('name'));
  const open = Boolean(deleteProjectParam && projectId);

  const [deleteProjects] = useDeleteProjectsMutation();

  const { deleteItems } = useOptimisticResponseContext<Project>();

  const onClose = () => {
    if (deleteProjectParam) {
      searchParams.clear();
    }
  };

  const onConfirm = async () => {
    try {
      await deleteProjects({ variables: { projectIds: [projectId] } });
      deleteItems([projectId]);
      const message = (
        <ItemChangedMessage
          description={MessageAction.Delete}
          entityName={projectName}
          entityTypeTranslation={EntityTypeTranslation.Project}
        />
      );
      enqueueSnackbar(message);
      onClose();
    } catch (e) {
      onClose();
      enqueueSnackbar(t('Something went wrong'));
    }
  };

  return (
    <Dialog
      confirmLabel={t('Delete project')}
      iconName="warning"
      onClose={onClose}
      onConfirm={onConfirm}
      open={open}
      title={t('Delete project')}
    >
      <Trans
        components={{ strong: <strong /> }}
        defaults="You are about to delete <strong>{{projectName}}</strong>. All content within your project will be lost"
        i18nKey="confirmDeleteProject"
        values={{ projectName }}
      />
    </Dialog>
  );
};
