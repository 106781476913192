import { useTranslation } from 'react-i18next';

import { Integration } from '@pro4all/graphql';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { useTableContextMenuRowContext } from '@pro4all/shared/ui/table';

export function useIntegrationsActions() {
  const { hasAppPermission } = useAppPermissions();
  const { searchParams } = useRouting();
  const { contextMenuRow } = useTableContextMenuRowContext<Integration>();
  const { id } = contextMenuRow || {};

  const { t } = useTranslation();

  const mainActions: ActionProps[] = [
    {
      ariaLabel: t('Add integration'),
      dataTestId: 'add-integration',
      disabled: !hasAppPermission('OrganizationUpdate'),
      key: 'add-integration',
      label: t('Add integration'),
      onClick: () => searchParams.set('action', 'addIntegration'),
      startIcon: 'add',
    },
  ];

  const altActions: ActionProps[] = [
    {
      ariaLabel: t('Delete'),
      dataTestId: 'delete-integration',
      disabled: !hasAppPermission('OrganizationUpdate'),
      key: 'delete-integration',
      label: t('Delete'),
      onClick: () => searchParams.set({ action: 'deleteIntegration', id }),
      startIcon: 'delete',
    },
  ];

  return { altActions, mainActions };
}
