import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';

import { useAddUsersToProjectsMutation, User } from '@pro4all/graphql';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikForm } from '@pro4all/shared/ui/formik';
import { InfoBlock } from '@pro4all/shared/ui/info-block';
import { useTableCheck } from '@pro4all/shared/ui/table';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { FormikProjectSelect } from '../../form-elements/FormikProjectSelect';
import { useAddGroupsUsersToProjectFormConfig } from '../../form-elements/useAddGroupsUsersToProjectFormConfig';

interface Props {
  onClose: () => void;
  userToAdd?: User;
}

export const AddUsersToProjectForm: React.FC<Props> = ({
  onClose,
  userToAdd,
}) => {
  const { checkedRows, uncheckAllRows } = useTableCheck<User>();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [addUsersToProjects] = useAddUsersToProjectsMutation();

  const { validationSchema } = useAddGroupsUsersToProjectFormConfig();

  return (
    <Formik
      initialValues={{
        projects: [],
      }}
      onSubmit={async (values) => {
        try {
          await addUsersToProjects({
            variables: {
              projectIds: values.projects.map((project) => project.id),
              userIds: userToAdd
                ? [userToAdd.id]
                : checkedRows.map((user) => user.id),
            },
          });

          enqueueSnackbar(t('Selected users added to selected projects'));
          onClose();
          uncheckAllRows();
        } catch (e) {
          enqueueSnackbar(
            `${t('Something went wrong')}. ${t('Please try again')}.`
          );
        }
      }}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting }) => (
        <FormikForm>
          <FormWrapper>
            <InfoBlock
              paragraph={`${t(
                'Choose one or more projects to add the selected users to'
              )}. ${t('Users are added as with the member role')}.`}
            />
            <FormikProjectSelect />
          </FormWrapper>

          <FormFooter
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            onClose={onClose}
            pb={3}
            pt={2}
            px={3}
            sticky
            submitLabel="Connect"
          />
        </FormikForm>
      )}
    </Formik>
  );
};
