import React from 'react';
import isEqual from 'react-fast-compare';

import { Folder, FolderPermission } from '@pro4all/graphql';
import { Checkbox } from '@pro4all/shared/ui/inputs';

import { PermissionToggle } from './context/types';
import { InheritedPermission } from './InheritedPermissionsIcon';
import { PermissionCellContainer } from './PermissionCellContainer';

export interface PermissionFolderCellProps {
  checked: boolean;
  disabled: boolean;
  folder: Folder;
  inheritedFromGroups: string[];
  permission: FolderPermission;
  showFinalPermissions: boolean;
  togglePermission: ({ folderId, permission }: PermissionToggle) => void;
}

const PermissionFolderCellComponent = ({
  checked,
  disabled,
  folder,
  inheritedFromGroups,
  permission,
  showFinalPermissions,
  togglePermission,
}: PermissionFolderCellProps) => {
  const checkedFinal = checked || inheritedFromGroups.length > 0;
  return (
    <>
      <PermissionCellContainer placement="bottom" title="">
        {showFinalPermissions ? (
          <Checkbox checked={checkedFinal || false} disabled />
        ) : (
          <Checkbox
            checked={checked || false}
            disabled={disabled}
            onChange={async () => {
              togglePermission({ folderId: folder.id, permission });
            }}
          />
        )}
      </PermissionCellContainer>
      {showFinalPermissions ? null : (
        <InheritedPermission
          folder={folder}
          groups={inheritedFromGroups}
          permission={permission}
        />
      )}
    </>
  );
};

const skipUpdate = (
  prevProps: PermissionFolderCellProps,
  nextProps: PermissionFolderCellProps
) =>
  prevProps.checked === nextProps.checked &&
  prevProps.disabled === nextProps.disabled &&
  prevProps.showFinalPermissions === nextProps.showFinalPermissions &&
  isEqual(
    prevProps.inheritedFromGroups.sort(),
    nextProps.inheritedFromGroups.sort()
  );

export const PermissionFolderCell = React.memo(
  PermissionFolderCellComponent,
  skipUpdate
);
