import { Box } from '@mui/system';

import { Task } from '@pro4all/graphql';
import { Calendar } from '@pro4all/shared/ui/calendar';
import { Loader } from '@pro4all/shared/ui/loader';

import { useGetCalendarEvents } from './useGetCalendarEvents';

export const TaskCalendar: React.FC<{
  loading: boolean;
  onSelectTask: (taskId: string) => void;
  tasks: Task[];
}> = ({ loading, tasks, onSelectTask }) => {
  const calendarEvents = useGetCalendarEvents(tasks);

  return (
    <>
      {loading && <Loader />}
      <Box p={1} sx={{ height: '100%' }}>
        <Calendar
          events={calendarEvents || []}
          onSelect={(event) => {
            onSelectTask(event.resource.id);
          }}
        ></Calendar>
      </Box>
    </>
  );
};
