import {
  FilterColumnIdProps,
  FilterColumnProps,
  useFilterContext,
} from '@pro4all/shared/ui/filtering';
import { BaseRow } from '@pro4all/shared/ui/table';

import { getColumnKey } from './helpers';

export function useOnReset<Row extends BaseRow, SubProp>({
  metaDataHeaderId = '',
  onColumnResizeCallback,
  propertyId,
  subPropertyId,
}: Pick<FilterColumnProps, 'metaDataHeaderId' | 'onColumnResizeCallback'> &
  Pick<FilterColumnIdProps<Row, SubProp>, 'propertyId' | 'subPropertyId'>) {
  const { removeFilter } = useFilterContext<Row>();

  const onReset = () => {
    const { keyPropertyId, keySubPropertyId } = getColumnKey<Row, SubProp>({
      metaDataHeaderId,
      propertyId,
      subPropertyId,
    });
    removeFilter({
      onColumnResizeCallback,
      propertyId: keyPropertyId,
      subPropertyId: keySubPropertyId,
    });
  };

  return onReset;
}
