import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  BatchQualityControlInstancesIncludeDocument,
  QualityControlInstance,
  useAddSnagToFormLinkMutation,
} from '@pro4all/graphql';
import { getBatchFormLinkedSnagsIncludeVariables } from '@pro4all/quality-control/data-access';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button, CopyTextButton } from '@pro4all/shared/ui/buttons';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { Card } from '@pro4all/shared/ui/general';
import { useShowMessages } from '@pro4all/shared/ui/messages';
import { useSearchInput } from '@pro4all/shared/ui/search-input';
import { Timestamp } from '@pro4all/shared/ui/timestamp';

interface LinkSnagProps {
  fieldId: string;
  linkedSnagsIds: string[];
  onClose: () => void;
  refetchQualityInstance: () => void;
  snagIntancesList: QualityControlInstance[];
  taskError?: ApolloError;
}

export const LinkSnagDialog = ({
  onClose,
  fieldId,
  refetchQualityInstance,
  taskError,
  linkedSnagsIds,
  snagIntancesList,
}: LinkSnagProps) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { t } = useTranslation();

  const { searchParams } = useRouting();
  const { enqueueSnackbar } = useSnackbar();

  const [addSnagToFormLink] = useAddSnagToFormLinkMutation();
  const { showSingleError } = useShowMessages();

  const { query, searchInput } = useSearchInput();

  const formInstanceId = searchParams.get('id');

  if (taskError) {
    enqueueSnackbar(t('Could not fetch snags.'));
    return null;
  }

  const filteredSnagInstances = snagIntancesList.filter(({ name }) =>
    name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
  );

  const LinkExistingSnagInstance = async (id: string) => {
    setIsButtonDisabled(true);
    try {
      const { data } = await addSnagToFormLink({
        variables: {
          fieldId,
          formInstanceId,
          snagInstanceId: id,
        },
      });
      if (data.addSnagToFormLink?.errors) {
        onClose();
        enqueueSnackbar(t('Something went wrong. Please try again.'));
      } else {
        const variables = getBatchFormLinkedSnagsIncludeVariables({
          ids: linkedSnagsIds,
        });
        const currentLinkedSnags =
          (await client?.readQuery({
            query: BatchQualityControlInstancesIncludeDocument,
            variables,
          })?.qualityControlInstances) ?? [];

        await client.writeQuery({
          data: {
            qualityControlInstances: [
              ...currentLinkedSnags,
              snagIntancesList.find((snag) => snag.id === id),
            ],
          },
          query: BatchQualityControlInstancesIncludeDocument,
          variables,
        });
        refetchQualityInstance();
        onClose();
        enqueueSnackbar(t('Snag is succesfully linked.'));
      }
    } catch (e) {
      showSingleError(e);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  return (
    <FloatingModal
      maxWidth="md"
      onClose={() => onClose()}
      open
      title={t('Link an existing Snag')}
    >
      <FloatingModal.Header iconName="link">
        {t('Link an existing Snag')}
      </FloatingModal.Header>
      <ContentWrapper>
        {searchInput}
        {filteredSnagInstances.map(
          ({ id, name, reference, createdAt, createdBy, visualContext }) => (
            <Card
              disabledButtonBorder
              iconName="snag"
              key={id}
              menuItems={[
                {
                  label: `Link Snag`,
                  startIcon: 'link',
                },
              ]}
              meta={[
                `${createdBy?.displayName ? createdBy?.displayName : ''}`,
                <Timestamp date={createdAt} format="lll" />,
                `${visualContext?.name}`,
              ]}
              onClick={() => !isButtonDisabled && LinkExistingSnagInstance(id)}
              titleWithElement={
                <Box display="flex" gap="5px">
                  <Box display="flex">
                    #
                    <CopyTextButton
                      textName={t('Reference')}
                      textToCopy={reference}
                    />
                  </Box>
                  <Box>{name}</Box>
                </Box>
              }
            />
          )
        )}
      </ContentWrapper>
      <FloatingModal.Footer>
        <Button color="inherit" onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
      </FloatingModal.Footer>
    </FloatingModal>
  );
};

const ContentWrapper = styled(Box)`
  && {
    height: 500px;
  }
`;
