import React, { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Routes } from '@pro4all/shared/config';
import { Box } from '@pro4all/shared/mui-wrappers';
import { PermissionUserOption } from '@pro4all/shared/types';
import { Column, Main } from '@pro4all/shared/ui/layout';
import { useDiscardModalContext } from '@pro4all/shared/ui/messages';

import { FolderTreePermissions } from '../components/folder-permissions/FolderTreePermissions';
import { PersonOrGroupSelect } from '../components/folder-permissions/PersonOrGroupSelect';
import { OrganizationAccessTabs } from '../components/tabs/OrganizationAccessTabs';

import { useAccessMembers } from './useAccessMembers';

export const OrganizationAccess = () => {
  const [selectedOption, setSelectedOption] = useState<PermissionUserOption>();
  const members = useAccessMembers({ includeOrganization: true });
  const { isDirty } = useDiscardModalContext();

  return (
    <>
      <Box sx={{ maxWidth: '600px', pt: 1, px: 2 }}>
        <PersonOrGroupSelect
          disabled={isDirty}
          members={members}
          onChange={(e) => setSelectedOption(e)}
          value={selectedOption}
        />
      </Box>
      <Box pr={2}>
        <OrganizationAccessTabs />
      </Box>

      <Main>
        <Column>
          <Route exact path={Routes.access}>
            <Redirect to={Routes.baseAccessDocuments} />
          </Route>
          <Route path={Routes.accessDocuments}>
            <FolderTreePermissions selectedOption={selectedOption} />
          </Route>
        </Column>
      </Main>
    </>
  );
};
