import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { useGenericContext } from '@pro4all/shared/providers';
import { Switch } from '@pro4all/shared/ui/inputs';

import { getThisFolderPermission } from './context/helpers/getThisFolderPermission';
import { usePermissionsFolderMatrixContext } from './context/PermissionsFolderMatrixProvider';
import { useToggleBreakInheritance } from './hooks/useToggleBreakInheritance';

export interface BreakInheritanceProps {
  folderId: string;
  userId: string;
}

export const BreakInheritanceForFolderSwitch = ({
  folderId,
  userId,
}: BreakInheritanceProps) => {
  const {
    state: { displayPermissions },
  } = usePermissionsFolderMatrixContext();

  const {
    state: { showFinalPermissions },
  } = useGenericContext();

  const handleToggleBreakInheritance = useToggleBreakInheritance(folderId);

  const currentFolder = getThisFolderPermission(folderId, displayPermissions);

  const { userId: loggedInUserId } = AuthService.getProfile();

  return showFinalPermissions ? null : (
    <Switch
      checked={currentFolder?.breakInheritance || false}
      disabled={loggedInUserId === userId}
      onChange={handleToggleBreakInheritance}
    />
  );
};
