import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  QualityControlInstance,
  QualityControlInstanceType,
} from '@pro4all/graphql';
import { CopyTextButton } from '@pro4all/shared/ui/buttons';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { Timestamp } from '@pro4all/shared/ui/timestamp';

export const useResultProperties = (
  result: QualityControlInstance,
  type: QualityControlInstanceType,
  percentageTotal?: string
) => {
  const { t } = useTranslation();
  const { createdAt, createdBy, reference } = result ?? {};

  return [
    {
      label: t('Reference'),
      value: (
        <CopyTextButton textName={t('Reference')} textToCopy={reference} />
      ),
    },
    {
      label: t('Created by'),
      value: createdBy?.displayName ? (
        <UserTag user={createdBy} />
      ) : (
        t('Unknown')
      ),
    },
    {
      label: t('Created on'),
      value: <Timestamp date={createdAt} format="lll" />,
    },
    {
      label: t('Progress'),
      value: percentageTotal,
    },
  ];
};
