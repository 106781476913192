import React from 'react';
import styled from 'styled-components';

import { FieldDefinition, User, ValueTypeName } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { RenderOptionType } from '@pro4all/shared/types';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';
import { getFormattedDate, Timestamp } from '@pro4all/shared/ui/timestamp';

import { useFieldConfig } from '../configs/useFieldConfig';
import { FieldTypeIcon } from '../field-types/FieldTypeIcon';

const StyledBox = styled(Box)`
  && {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
  }
`;

export const useColumnsFields = () => {
  const fieldConfig = useFieldConfig();
  const columnsFields: ColumnProps<FieldDefinition>[] = [
    {
      defaultSort: true,
      filterable: true,
      headerComponent: (
        <FilterHeader<FieldDefinition, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Text}
          label="Name" // i18n
          minWidth={100}
          propertyId="name"
          pullFromLocalStorage
        />
      ),
      key: 'name',
      render: ({ name, type, __typename }) => (
        <StyledBox>
          <FieldTypeIcon
            type={
              // If user switches from Templates to FieldDefinitions the data provider needs a sec to overwrite the old items list.
              __typename === 'FieldDefinition' ? type : ValueTypeName.Text
            }
          />
          <MiddleEllipsis text={name}></MiddleEllipsis>
        </StyledBox>
      ),
      width: 200,
    },
    {
      filterable: true,
      getValue: ({ type, __typename }) =>
        // If user switches from Templates to FieldDefinitions the data provider needs a sec to overwrite the old items list.
        __typename === 'FieldDefinition'
          ? fieldConfig[type].label
          : fieldConfig[ValueTypeName.Text].label,
      headerComponent: (
        <FilterHeader<FieldDefinition, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Select}
          label="Type" // i18n
          minWidth={100}
          propertyId="type"
          translateOptions
        />
      ),
      key: 'type',
      width: 200,
    },
    {
      filterable: true,
      getValue: (fieldDefinition: FieldDefinition) =>
        fieldDefinition.createdAt
          ? getFormattedDate(fieldDefinition.createdAt).label
          : '',
      headerComponent: (
        <FilterHeader<FieldDefinition, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="Created on" // i18n
          minWidth={120}
          propertyId="createdAt"
        />
      ),
      key: 'createdAt',
      render: ({ createdAt }) => <Timestamp date={createdAt} />,
      width: 200,
    },
    {
      filterable: true,
      getValue: ({ createdBy }) => createdBy?.displayName,
      headerComponent: (
        <FilterHeader<FieldDefinition, User>
          defaultWidth={300}
          filterType={FilterHeaderType.Select}
          label="Created by" // i18n
          minWidth={120}
          propertyId="createdBy"
          renderOption={RenderOptionType.UserCard}
          subPropertyId="displayName"
        />
      ),
      key: 'createdBy.displayName',
      render: ({ createdBy }: FieldDefinition) => <UserTag user={createdBy} />,
      width: 300,
    },
  ];
  return columnsFields;
};
