import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  Integration,
  IntegrationType,
  useAddIntegrationMutation,
} from '@pro4all/graphql';
import { getAuthUserDisplayName } from '@pro4all/shared/identity';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';
import { useShowMessages } from '@pro4all/shared/ui/messages';

import { getIntegrationCustomTypeInput } from './getIntegrationCustomTypeInput';
import { FormFields } from './types';

export const useSubmit = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [addIntegration] = useAddIntegrationMutation();
  const { showSingleError } = useShowMessages();
  const { userId } = AuthService.getProfile();
  const userName = getAuthUserDisplayName();

  const { addItems } = useOptimisticResponseContext<Integration>();

  return async (values: FormFields) => {
    const { application } = values;

    const integrationCustomTypeInput = getIntegrationCustomTypeInput({
      integrationType: application?.id,
      valueType: values,
    });

    const applicationName =
      application?.id === IntegrationType.Integration12Build
        ? '12Build'
        : 'Snagstream'; //TODO

    const integrationName = `${applicationName} (${values.name})`;
    try {
      const response = await addIntegration({
        variables: {
          application: {
            integrationType: application?.id,
            name: integrationName,
          },
          customIntegrationProps: integrationCustomTypeInput,
        },
      });

      addItems([
        {
          createdAt: new Date().toISOString(),
          createdBy: { displayName: userName, id: userId },
          id: response?.data?.addIntegration || '',
          integrationName: integrationName,
          integrationType: application.id,
        },
      ]);

      onClose();

      enqueueSnackbar(
        t('Succesfully integrated Prostream with {{application}}', {
          application: applicationName,
        })
      );
    } catch (e) {
      showSingleError(e);
      onClose();
    }
  };
};
