import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StorageKeys } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { Button } from '@pro4all/shared/ui/buttons';
import { Dialog } from '@pro4all/shared/ui/dialog';
import { Text } from '@pro4all/shared/ui/typography';

type Props = { updateAndPublishLabel: string; updateLabel: string };

export const usePublishTemplatesModal = ({
  updateLabel,
  updateAndPublishLabel,
}: Props) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [updateCallback, setUpdateCallback] = useState<() => void>(null);

  const {
    setLocalStorageItem: setPublishTemplatesAfterFieldUpdateLocalStorage,
  } = useLocalStorage<boolean>({
    key: StorageKeys.PUBLISH_TEMPLATES_AFTER_FIELD_UPDATE,
  });

  const update = () => {
    setPublishTemplatesAfterFieldUpdateLocalStorage(false);
    updateCallback();
    setShowModal(false);
  };

  const updateAndPublish = () => {
    setPublishTemplatesAfterFieldUpdateLocalStorage(true);
    updateCallback();
    setShowModal(false);
  };

  const publishTemplatesModal = (
    <Dialog
      additionalActions={
        <Button
          data-testid="update-without-publishing-button"
          onClick={update}
          type="button"
          variant="outlined"
        >
          {t(updateLabel)}
        </Button>
      }
      confirmLabel={t(updateAndPublishLabel)}
      iconName="warning"
      name="routerPrompt"
      onClose={() => setShowModal(false)}
      onConfirm={updateAndPublish}
      open={showModal}
      title={t('Publish related templates')}
    >
      <Text>
        {t(
          'Do you want the changes in this field to be published in all related templates?'
        )}
      </Text>
    </Dialog>
  );

  return { publishTemplatesModal, setShowModal, setUpdateCallback };
};
