import { v4 as uuid } from 'uuid';

import { Option } from '@pro4all/shared/types';

import { isValidEmail } from './emailValidation';

export function splitEmailAddress(
  value: string,
  prevValue: (Option & { email?: string })[], // TODO: Why did we introduce `RecipientField` type and not just use `Option` instead? Feels like a burden.
  callback: (values: Option[]) => void
) {
  const regexPattern = /[\s,;]+/;

  // Generic regex to find e-mailadresses in the value.
  const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;

  // Return true if address already exists in field
  const findDuplicate = (value: string) =>
    prevValue.find(
      (input) =>
        input.inputValue === value ||
        input.label === value ||
        input.email === value // Specifically for messaging because there the type `RecipientField` is used that contains `email`.
    );

  // Remove duplicates from pasted text
  const toUniqueArray = (addresses: string[]) => [...new Set(addresses)];

  // Extract all emails, even if they are wrapped in quotes or other characters
  const extractEmails = (text: string): string[] => {
    const matches = text.match(emailPattern);
    return matches || [];
  };

  // Process value
  const extractedEmails = extractEmails(value);
  const rawEmails = value
    .split(regexPattern)
    .filter(Boolean)
    .filter((chunk) => isValidEmail(chunk));

  const allEmails = [...extractedEmails, ...rawEmails];
  const uniqueValues = toUniqueArray(allEmails).filter(
    (value) => !findDuplicate(value)
  );

  const nextValue: Option[] = uniqueValues.map((value) => ({
    id: uuid(),
    inputValue: value,
    label: value,
  }));

  callback([...prevValue, ...nextValue]);
}
