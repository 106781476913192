import React from 'react';
import styled from 'styled-components';

import { FormControlLabel } from '@pro4all/shared/mui-wrappers';
import { Checkbox } from '@pro4all/shared/ui/inputs';

import { useGenerateLinkContext } from '../GenerateLinkContext';

import { useSendLinkByEmailFormConfig } from './useSendLinkByEmailFormConfig';

export const CopyToSelfCheckbox: React.FC = () => {
  const { copyToSelf, setCopyToSelf } = useGenerateLinkContext();
  const { getField } = useSendLinkByEmailFormConfig();
  const sendCopyField = getField('sendCopy');

  return (
    <StyledFormControlLabel
      control={
        <Checkbox
          checked={copyToSelf}
          onChange={() => setCopyToSelf(!copyToSelf)}
        />
      }
      id={sendCopyField.name}
      label={sendCopyField.label}
    />
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;
