import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Box } from '@pro4all/shared/mui-wrappers';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikForm, FormikRadioGroup } from '@pro4all/shared/ui/formik';
import { FormikInput } from '@pro4all/shared/ui/formik';
import { Text } from '@pro4all/shared/ui/typography';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useInitialValues } from './useInitialValues';
import { useProjectSettingsFormConfig } from './useProjectSettingsFormConfig';
import { useSubmit } from './useSubmit';

export const ProjectSettingsForm = ({
  showCancel = true,
}: {
  showCancel?: boolean;
}) => {
  const { t } = useTranslation();
  const { getField, validationSchema } = useProjectSettingsFormConfig();
  const uploadFileNamingField = getField(
    'version_name_instead_of_document_name_on_upload'
  );
  const requiredMetaDataField = getField('metadata_required');
  const approveActionLabel = getField('approve_action_label');
  const rejectActionLabel = getField('reject_action_label');
  const finalizeActionLabel = getField('finalize_action_label');

  const onSubmit = useSubmit();

  const { initialValues, helperValues } = useInitialValues();
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting }) => (
        <FormikForm>
          <Box sx={{ px: 1 }}>
            <FormWrapper noPadding>
              <Text variant="h4">{t('Documents')}</Text>
              <FormikRadioGroup
                label={uploadFileNamingField?.displayName}
                name={uploadFileNamingField?.name}
                options={uploadFileNamingField?.options || []}
              />
              <FormikRadioGroup
                label={requiredMetaDataField?.displayName}
                name={requiredMetaDataField?.name}
                options={requiredMetaDataField?.options || []}
              />
              <Text color="inherit" variant="h5">
                {t('Customizing action naming convention')}
              </Text>
              <FormikInput
                helperText={helperValues.approve_action_label_helper}
                label={approveActionLabel?.displayName}
                name={approveActionLabel?.name}
              />
              <FormikInput
                helperText={helperValues.reject_action_label_helper}
                label={rejectActionLabel?.displayName}
                name={rejectActionLabel?.name}
              />
              <FormikInput
                helperText={helperValues.finalize_action_label_helper}
                label={finalizeActionLabel?.displayName}
                name={finalizeActionLabel?.name}
              />
            </FormWrapper>

            <FormFooter
              disableSubmit={isSubmitDisabled({
                dirty,
                errors,
                isSubmitting,
              })}
              pb={3}
              pt={2}
              px={3}
              showCancel={showCancel}
              sticky
            />
          </Box>
        </FormikForm>
      )}
    </Formik>
  );
};
