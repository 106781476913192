import {
  LinkError,
  SharedDocsTable,
  useSharedDocsContext,
} from '@pro4all/documents/ui/share';
import { DocumentVersion } from '@pro4all/graphql';
import { Column, Main } from '@pro4all/shared/ui/layout';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

export const SharedDocuments = () => {
  const { error } = useSharedDocsContext();
  return error ? (
    <LinkError />
  ) : (
    <Main>
      <Column flex={1}>
        <OptimisticResponseProvider<DocumentVersion>>
          <SharedDocsTable />
        </OptimisticResponseProvider>
      </Column>
    </Main>
  );
};
