import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CircularProgress } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { TextFieldProps } from '@pro4all/shared/ui/inputs';

import { useFetchSignature } from '../hooks/useFetchSignature';
import { useUploadSignature } from '../hooks/useUploadSignature';
import * as Styled from '../styles/Signature.styles';

import { SignatureDrawing } from './SignatureDrawing';

interface Props extends TextFieldProps {
  setValue: (name: string, value: string) => void;
}

export const Signature = ({ name, setValue, value }: Props) => {
  // prop 'name' is the name of the form input which equals the input id.

  const { t } = useTranslation();
  const [displaySignatureDrawing, setDisplaySignatureDrawing] = useState(false);
  const { loading, signature } = useFetchSignature(value as string);
  const { currentValue, handleChange } = useUploadSignature({
    fieldId: name,
    setValue,
  });

  return (
    <>
      <Styled.SignatureCanvas>
        {loading ? (
          <CircularProgress />
        ) : (
          <Styled.SignatureImage
            alt=""
            src={currentValue === null ? signature : currentValue}
          />
        )}
      </Styled.SignatureCanvas>

      <Styled.ButtonWrapper>
        {(signature || currentValue) && (
          <Button
            aria-label={t('Remove signature')}
            color="inherit"
            data-testid="remove-signature"
            onClick={() => handleChange('')}
            startIcon="removeCircle"
          >
            {t('Remove signature')}
          </Button>
        )}

        <Button
          aria-label={t('Sign')}
          data-testid="remove-signature"
          onClick={() => setDisplaySignatureDrawing(true)}
          startIcon="signature"
        >
          {t('Sign')}
        </Button>
      </Styled.ButtonWrapper>
      <SignatureDrawing
        onClose={() => setDisplaySignatureDrawing(false)}
        onSign={(signature: string) => handleChange(signature)}
        open={displaySignatureDrawing}
      />
    </>
  );
};
