import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Alert, Box } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { FormikInput } from '@pro4all/shared/ui/formik';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { Buttons, Form, StepHeader } from './FormComponents';
import { FormikCaptcha } from './FormikCaptcha';
import { PasswordStrengthMeter } from './PasswordStrengthMeter';
import { useStep2Config } from './useStep2Config';

export const initialValues = {
  confirmPassword: '',
  email: '',
  password: '',
  recaptcha: '',
};

export type Values = typeof initialValues;

const CAPTCHA_KEY =
  process.env.NX_CAPTCHA_KEY || '6Ld0OXEaAAAAAKr-vaRLms80eZ_iqJzLt4HSxLV4';
interface Props {
  goBack: () => void;
  onSubmit: (values: Values) => Promise<void>;
  values: Values;
}

export const Step2: React.FC<Props> = ({ goBack, onSubmit, values }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  const { getField, validationSchema } = useStep2Config();
  const emailField = getField('email');
  const passwordField = getField('password');
  const confirmPasswordField = getField('confirmPassword');
  const recaptchaField = getField('recaptcha');

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      onSubmit={async (values) => {
        setError(false);
        try {
          await onSubmit(values);
        } catch (e) {
          setError(true);
        }
      }}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting, values }) => (
        <Form>
          <StepHeader step={1} />
          <FormWrapper noPadding>
            <FormikInput
              autoFocus
              label={emailField.label}
              name={emailField.name}
            />
            <FormikInput
              label={passwordField.label}
              name={passwordField.name}
              type="password"
            />
            <PasswordStrengthMeter value={values.password} />
            <FormikInput
              label={confirmPasswordField.label}
              name={confirmPasswordField.name}
              type="password"
            />
            <FormikCaptcha name={recaptchaField.name} sitekey={CAPTCHA_KEY} />
          </FormWrapper>
          {error && (
            <Box mb={4}>
              <Alert severity="error">
                {t('Something went wrong. Please try again.')}
              </Alert>
            </Box>
          )}
          <Buttons>
            <Button onClick={goBack}>{t('Previous')}</Button>
            <Button
              color="primary"
              disabled={isSubmitDisabled({
                dirty,
                errors,
                isSubmitting,
              })}
              type="submit"
            >
              {t('Register')}
            </Button>
          </Buttons>
        </Form>
      )}
    </Formik>
  );
};
