import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Group, Member } from '@pro4all/graphql';
import { Box, ListSubheader } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { ActionProps } from '@pro4all/shared/types';
import { Portal } from '@pro4all/shared/ui/general';
import {
  GroupCard,
  useGetUsersAction,
  UserCard,
} from '@pro4all/shared/ui/identity-card';
import { Loader } from '@pro4all/shared/ui/loader';

import { SuggestionWrap } from './EditorStyles';

interface Props {
  contentRef: React.RefObject<HTMLDivElement>;
  members?: Member[];
  onSelect: () => void;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  show: boolean;
}

const SelectableBox: React.FC<{
  children: React.ReactNode;
  id: string;
  index: number;
  onSelect: () => void;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}> = ({ index, onSelect, setIndex, id, children }) => (
  <Box
    key={id}
    onMouseDown={(event) => {
      // In case we clicked on an action icon in the Card, the callback of that action must be triggered and NOT this `onSelect` callback.
      if (!(event.target as HTMLElement).closest('.CardActions')) {
        event.preventDefault();
        onSelect();
      }
    }}
    onMouseEnter={() => setIndex(index)}
    sx={{
      '&:hover': {
        backgroundColor: customColors['grey400'],
      },
      p: 2,
    }}
  >
    {children}
  </Box>
);

export const Suggestions: React.FC<Props> = ({
  contentRef,
  members,
  onSelect,
  setIndex,
  show,
}) => {
  const { t } = useTranslation();
  const { getShowUsersAction } = useGetUsersAction();

  const { users, groups } = useMemo(() => {
    const users: React.ReactNode[] = [];
    const groups: React.ReactNode[] = [];
    let userHeaderPrinted = false;
    let groupHeaderPrinted = false;

    members?.forEach((member, index) => {
      const { __typename, displayName, id } = member;
      if (__typename === 'Group') {
        const memberTyped = member as Group;
        const { totalUsers } = memberTyped;
        if (!groupHeaderPrinted) {
          groups.push(
            <ListSubheader key="group-header">{t('Groups')}</ListSubheader>
          );
          groupHeaderPrinted = true;
        }
        const cardIcons: ActionProps[] = [
          getShowUsersAction({ id, totalUsers: totalUsers || 0 }),
        ];

        groups.push(
          <SelectableBox
            id={id}
            index={index}
            onSelect={onSelect}
            setIndex={setIndex}
          >
            <GroupCard
              // cardIcons={cardIcons} TODO: re-implement this after BE has finished endpoints to fetch all direct and nested users for a group.
              group={{
                displayName,
                id,
                totalUsers,
              }}
            />
          </SelectableBox>
        );
      } else if (__typename === 'User') {
        if (!userHeaderPrinted) {
          users.push(
            <ListSubheader key="user-header">{t('Users')}</ListSubheader>
          );
          userHeaderPrinted = true;
        }
        users.push(
          <SelectableBox
            id={id}
            index={index}
            onSelect={onSelect}
            setIndex={setIndex}
          >
            <UserCard userId={id} />
          </SelectableBox>
        );
      }
    });

    return { groups, users };
  }, [getShowUsersAction, onSelect, members, setIndex, t]);

  if (!show) return null;

  return (
    <Portal>
      <SuggestionWrap ref={contentRef}>
        {!members ? (
          <Loader />
        ) : (
          <>
            {users}
            {groups}
          </>
        )}
      </SuggestionWrap>
    </Portal>
  );
};
