import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  GroupFolderPermissionTable,
  UserFolderPermissionTable,
} from '@pro4all/documents/ui/folders';
import { Box, useTheme } from '@pro4all/shared/mui-wrappers';
import { useGenericContext } from '@pro4all/shared/providers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Tab, Tabs } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { Column, Main } from '@pro4all/shared/ui/layout';
import { VisibilityToggle } from '@pro4all/shared/ui/visibility-toggle';

import * as Styled from './FolderPermissionsEditor.styles';

enum TabValues {
  Groups = 'Groups',
  Users = 'Users',
}

export const FolderPermissionsEditor: React.FC = () => {
  const { params, searchParams } = useRouting();
  const { t } = useTranslation();
  const theme = useTheme();
  const { projectId, path } = params;

  const isGroupsTab = searchParams.is('tab', TabValues.Groups);
  const currentTab = isGroupsTab ? TabValues.Groups : TabValues.Users;

  const {
    state: { showFinalPermissions },
    toggleShowFinalPermissions,
  } = useGenericContext();

  return (
    <Main>
      <Column>
        <Box p={2}>
          <Styled.BackButton
            color="inherit"
            onClick={() => searchParams.clear()}
            startIcon="arrowBack"
            variant="text"
          >
            {t('Back')}
          </Styled.BackButton>
          <Box alignItems="center" display="flex" pt={1} px={1}>
            <Icon htmlColor={theme.palette.warning.main} iconName="folder" />
            <Styled.FolderName variant="subtitle1">{path}</Styled.FolderName>
          </Box>
        </Box>
        <Styled.TabsWrapper>
          <Tabs
            onChange={(_, value) => {
              searchParams.set({ tab: value });
            }}
            value={currentTab}
          >
            <Tab
              data-testid="folder-permissions-members-tab"
              label={projectId ? t('Members') : t('Users')}
              value={TabValues.Users}
            />
            <Tab
              data-testid="folder-permissions-project-groups-tab"
              label={projectId ? t('Project groups') : t('Groups')}
              value={TabValues.Groups}
            />
          </Tabs>
          <VisibilityToggle
            label="Show final permissions"
            toggle={toggleShowFinalPermissions}
            toggleValue={showFinalPermissions}
          />
        </Styled.TabsWrapper>
        {isGroupsTab ? (
          <GroupFolderPermissionTable />
        ) : (
          <UserFolderPermissionTable />
        )}
      </Column>
    </Main>
  );
};
