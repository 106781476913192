import { useTranslation } from 'react-i18next';

import { Dialog } from '@pro4all/shared/ui/dialog';
import { Text } from '@pro4all/shared/ui/typography';

type ConfirmationModalProps = {
  cancel: () => void;
  confirm: () => void;
  showModal: boolean | undefined;
};

export const VersionTableDropConfirmationDialog = ({
  showModal,
  confirm,
  cancel,
}: ConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    showModal && (
      <Dialog
        buttonVariant="outlined"
        closeLabel={t('Cancel')}
        confirmLabel={t('Yes, new version')}
        iconName="reportProblemOutlined"
        onClose={cancel}
        onConfirm={confirm}
        open={showModal}
        title={t('Move file')}
        titleVariant="h3"
      >
        <Text display="inline">
          {t(
            'Do you want to move this file to a new version of the selected one?'
          )}
        </Text>
      </Dialog>
    )
  );
};
