import React from 'react';
import { useTranslation } from 'react-i18next';

import { Group, User } from '@pro4all/graphql';
import { RenderOptionType } from '@pro4all/shared/types';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { Chip } from '@pro4all/shared/ui/general';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';
import { getFormattedDate, Timestamp } from '@pro4all/shared/ui/timestamp';

export const useGroupColumns = () => {
  const { t } = useTranslation();

  const columns: ColumnProps<Group>[] = [
    {
      defaultSort: true,
      filterable: true,
      headerComponent: (
        <FilterHeader<Group, {}>
          defaultWidth={300}
          filterType={FilterHeaderType.Text}
          label="Name" // i18n
          minWidth={100}
          propertyId="displayName"
          pullFromLocalStorage
        />
      ),
      key: 'displayName',
      render: ({ displayName }: Group) => (
        <MiddleEllipsis endLength={9} text={displayName} />
      ),
      width: 300,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<Group, {}>
          defaultWidth={120}
          filterType={FilterHeaderType.Number}
          label="Members" // i18n
          minWidth={100}
          propertyId="membersCount"
        />
      ),
      key: 'membersCount',
      render: ({ membersCount }: Group) => (
        <Chip icon="personOutline" label={membersCount}></Chip>
      ),
      width: 120,
    },
    {
      filterable: true,
      getValue: (group: Group) => group?.subgroupIds?.length || 0,
      headerComponent: (
        <FilterHeader<Group, {}>
          defaultWidth={120}
          filterType={FilterHeaderType.Number}
          getCustomValueCallback={(subgroupIds) => subgroupIds?.length || 0}
          label="Subgroups" // i18n
          minWidth={100}
          propertyId="subgroupIds"
        />
      ),
      key: 'subgroupIds',
      render: ({ subgroupIds }: Group) => (
        <Chip icon="group" label={subgroupIds?.length || 0}></Chip>
      ),
      width: 120,
    },
    {
      filterable: true,
      getValue: (group: Group) =>
        group.createdAt ? getFormattedDate(group.createdAt).label : '',
      headerComponent: (
        <FilterHeader<Group, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="Created on" // i18n
          minWidth={120}
          propertyId="createdAt"
        />
      ),
      key: 'createdAt',
      render: ({ createdAt }) => <Timestamp date={createdAt} />,
      width: 200,
    },
    {
      filterable: true,
      getValue: ({ createdBy }) => createdBy?.displayName,
      headerComponent: (
        <FilterHeader<Group, User>
          defaultWidth={300}
          filterType={FilterHeaderType.Select}
          label="Created by" // i18n
          minWidth={120}
          propertyId="createdBy"
          renderOption={RenderOptionType.UserCard}
          subPropertyId="displayName"
        />
      ),
      key: 'createdBy.displayName',
      render: ({ createdBy }) => <UserTag user={createdBy} />,
      width: 300,
    },
  ];
  return columns;
};
