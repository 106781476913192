import { useTranslation } from 'react-i18next';
import parsePhoneNumberFromString, {
  isValidNumber,
  ParsedNumber,
  parseNumber,
} from 'libphonenumber-js';
import * as Yup from 'yup';

import { Option } from '@pro4all/shared/types';
import { areAllValidEmails } from '@pro4all/shared/utils/email';

import { FormFieldConfig } from './useValidation';
import { useWarnings } from './useWarnings';

export const useStandardValidations = () => {
  const { t } = useTranslation();

  const {
    greaterWarning,
    lessWarning,
    requiredWarning,
    minCharWarning,
    maxCharWarning,
  } = useWarnings();

  const checkDate = (item: FormFieldConfig) => {
    let schema = Yup.date().nullable().typeError(t('Invalid date'));

    const required = item?.required;
    if (required) schema = schema.required(requiredWarning(item));

    return schema;
  };

  const checkNumber = (item: FormFieldConfig) => {
    const max = item?.valueType?.maxValue;
    const min = item?.valueType?.minValue;
    const required = item?.required;

    let schema = Yup.number().nullable();

    if (min !== null && min !== undefined)
      schema = schema.min(min, greaterWarning(item));

    if (max !== null && max !== undefined)
      schema = schema.max(max, lessWarning(item));

    if (required) schema = schema.required(requiredWarning(item));

    return schema;
  };

  const checkSelect = (item: FormFieldConfig) => {
    let schema = Yup.object().nullable();

    const required = item?.required;
    if (required) schema = schema.required(requiredWarning(item));

    return schema;
  };

  const checkMultiSelect = (item: FormFieldConfig) => {
    let schema = Yup.array().nullable();

    if (item.email) {
      schema = schema.test({
        message: t('Enter valid email addresses'),
        name: 'validEmails',
        test: (value) =>
          areAllValidEmails((value as Option[]).map(({ label }) => label)),
      });
    }

    const required = item?.required;
    if (required) schema = schema.required(requiredWarning(item));

    return schema;
  };

  const checkString = (item: FormFieldConfig) => {
    if (item.email) {
      return Yup.string()
        .email(t('This is not a valid email address')) // Unfortunately we have to extract email check, else it causes a bug.
        .required(item.required ? requiredWarning(item) : undefined);
    }

    let schema = Yup.string()
      .notOneOf(item.notOneOf || [], t('Invalid value'))
      .nullable();
    const matches = item?.matches;
    const max = item?.max;
    const min = item?.min;
    const required = item?.required;

    if (matches)
      schema = schema.matches(matches, t('Value contains invalid characters'));

    if (max) schema = schema.max(max, maxCharWarning(item));
    if (min) schema = schema.min(min, minCharWarning(item));
    if (required) schema = schema.required(requiredWarning(item));

    if (item.phoneNumber) {
      schema = schema.test(
        'phoneNumber',
        t(
          'A phone number must start with the country code, followed by your number. The total length should be between 11 to 15 digits.'
        ),
        (value) => {
          // Allow empty phone number
          if (!value) {
            return true;
          }

          // Check if the phone number contains any letters
          if (/[a-zA-Z]/.test(value as string)) {
            return false;
          }

          const phoneNumberObject = parsePhoneNumberFromString(value as string);
          if (phoneNumberObject) {
            const phoneNumberParsed = parseNumber(
              value as string,
              phoneNumberObject.country
            );

            return phoneNumberParsed
              ? isValidNumber(phoneNumberParsed as ParsedNumber)
              : false;
          }
          return false;
        }
      );
    }

    return schema;
  };

  return {
    checkDate,
    checkMultiSelect,
    checkNumber,
    checkSelect,
    checkString,
  };
};
