import React, { useRef } from 'react';
import dayjs from 'dayjs';
import { Formik } from 'formik';

import { ApiKey } from '@pro4all/graphql';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikDate, FormikForm, FormikInput } from '@pro4all/shared/ui/formik';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { getInitialValues } from './getInitialValues';
import { useApiKeysFormConfig } from './useApiKeysFormConfig';
import { useSubmitApiKey } from './useSubmitApiKey';

interface Props {
  apiKey: ApiKey | null;
  onClose: () => void;
}

export const ApiKeyForm = ({ apiKey, onClose }: Props) => {
  const inputRef = useRef(null);

  const submitApiKey = useSubmitApiKey(apiKey);
  const initialValues = getInitialValues(apiKey);

  const { getField, validationSchema } = useApiKeysFormConfig();
  const descriptionField = getField('description');
  const expiresAtField = getField('expiresAt');

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values) => {
        submitApiKey({
          onClose,
          values,
        });
      }}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting, dirty }) => (
        <FormikForm>
          <FormWrapper>
            <FormikInput
              autoFocus
              disabled={Boolean(apiKey)}
              inputRef={inputRef}
              label={descriptionField?.label}
              name={descriptionField?.name}
            />
            <FormikDate
              label={expiresAtField?.label}
              minDate={dayjs().add(1, 'day')}
              name={expiresAtField?.name || ''}
            />
          </FormWrapper>
          <FormFooter
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            onClose={onClose}
            pb={3}
            pt={2}
            px={3}
            sticky
          />
        </FormikForm>
      )}
    </Formik>
  );
};
