import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { SchemaExtender } from '@pro4all/shared/forms';
import { FormikCheckbox, FormikInput } from '@pro4all/shared/ui/formik';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { CustomType } from './Types';
import { useFieldDefinitionFormConfig } from './useFieldDefinitionFormConfig';

const MAX_NUMBER = 2147483647;

export const CustomNumberForm = ({ values: { rangeEnabled } }: CustomType) => {
  const { getField } = useFieldDefinitionFormConfig();
  const rangeEnabledField = getField('rangeEnabled');
  const minValueField = getField('minValue');
  const maxValueField = getField('maxValue');

  return (
    <>
      <FormikCheckbox
        label={rangeEnabledField.label}
        name={rangeEnabledField.name}
      />
      {rangeEnabled && (
        <FormWrapper noPadding>
          <FormikInput
            label={minValueField.label}
            name={minValueField.name}
            type="number"
          />
          <FormikInput
            label={maxValueField.label}
            name={maxValueField.name}
            type="number"
          />
        </FormWrapper>
      )}
    </>
  );
};

export const useCustomNumberValidationSchema = (): Record<
  string,
  SchemaExtender
> => {
  const { t } = useTranslation();

  const maxValue = () =>
    Yup.string()
      .when(['rangeEnabled', 'type'], {
        is: (rangeEnabled, type) =>
          type?.id === 'Number' && rangeEnabled === 'true',
        then: Yup.string().required(
          t("'{{name}}' is required", { name: t('To') })
        ),
      })
      .test(
        'maxLessThenMaxNumber',
        t("'{{larger}}' must be less than '{{smaller}}'", {
          larger: t('To'),
          smaller: `${MAX_NUMBER + 1}`,
        }),
        function (value: string) {
          return (
            !value ||
            !this.parent.rangeEnabled ||
            this.parent.type?.id !== 'Number' ||
            parseInt(this.parent.maxValue) < MAX_NUMBER + 1
          );
        }
      )
      .test(
        'maxGreaterThanMin',
        t("'{{larger}}' must be greater than '{{smaller}}'", {
          larger: t('To'),
          smaller: t('From'),
        }),
        function (value: string) {
          return (
            !value ||
            !this.parent.minValue ||
            !this.parent.rangeEnabled ||
            this.parent.type?.id !== 'Number' ||
            parseInt(this.parent.maxValue) > parseInt(this.parent.minValue)
          );
        }
      )
      .test('noDecimals', t('Cannot contain decimals'), (value) => {
        if (!value?.length) {
          return true;
        }

        return !value?.includes('.');
      });

  const minValue = () =>
    Yup.string()
      .when(['rangeEnabled', 'type'], {
        is: (rangeEnabled, type) =>
          type?.id === 'Number' && rangeEnabled === 'true',
        then: Yup.string().required(
          t("'{{name}}' is required", { name: t('From') })
        ),
      })
      .test(
        'minLessThenMaxNumber',
        t("'{{larger}}' must be less than '{{smaller}}'", {
          larger: t('From'),
          smaller: MAX_NUMBER,
        }),
        function (value: string) {
          return (
            !value ||
            !this.parent.rangeEnabled ||
            this.parent.type?.id !== 'Number' ||
            parseInt(this.parent.minValue) < MAX_NUMBER
          );
        }
      )
      .test(
        'minLessThanMax',
        t("'{{smaller}}' must be less than '{{larger}}'", {
          larger: t('To'),
          smaller: t('From'),
        }),
        function (value) {
          return (
            !this.parent.maxValue ||
            !value ||
            !this.parent.rangeEnabled ||
            this.parent.type?.id !== 'Number' ||
            parseInt(this.parent.maxValue) > parseInt(this.parent.minValue)
          );
        }
      )
      .test('noDecimals', t('Cannot contain decimals'), (value) => {
        if (!value?.length) {
          return true;
        }

        return !value?.includes('.');
      });

  return {
    maxValue,
    minValue,
  };
};
