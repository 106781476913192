import React, { SyntheticEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ReferenceType } from '@pro4all/graphql';
import {
  Option,
  RenderCustomInputProps,
  RenderCustomOptionProps,
} from '@pro4all/shared/types';
import {
  _MultiSelect as MultiSelect,
  isOption,
} from '@pro4all/shared/ui/inputs';
import { isDefined } from '@pro4all/shared/utils';
import { isValidEmail, pasteHandlerEmails } from '@pro4all/shared/utils/email';

import { MessageFormFields, RecipientField } from '../types';

import { InputWrap, StyledLabel } from './Header.styles';

export const UserSelect: React.FC<{
  displayErrorMessage: (email: string) => void;
  handleDebounceDraftSave: () => void;
  label: string;
  name: string;
  noOptionsText?: string;
  options: Option[];
  renderCustomInput?: (props: RenderCustomInputProps) => React.ReactNode;
  renderCustomOption?: (props: RenderCustomOptionProps) => React.ReactNode;
}> = ({
  displayErrorMessage,
  label,
  name,
  noOptionsText,
  options,
  renderCustomInput,
  renderCustomOption,
  handleDebounceDraftSave,
}) => {
  const { control, getValues } = useFormContext<MessageFormFields>();
  const toOption = (value: RecipientField) => {
    const isOption = options.find((options) => options.id === value.id);
    if (isOption) return isOption;
    return {
      id: value.id,
      inputValue: value.email,
      label: value.email,
      type: ReferenceType.Email,
    };
  };

  const findOptionByEmail = (email: string) =>
    options.find((option) => option.inputValue === email);

  const values: RecipientField[] = getValues(name);

  const multiSelectValue: Option[] = values?.length
    ? values.map(toOption).filter(isDefined)
    : [];

  const getEmailOption = (email: string) => {
    const matchingEmail = findOptionByEmail(email);
    if (matchingEmail) {
      return {
        email: matchingEmail.inputValue,
        id: matchingEmail.id,
        type: matchingEmail.type,
      };
    }

    return {
      email,
      id: email,
      type: ReferenceType.Email,
    };
  };

  const updateValue = (values: (Option & { email?: string })[]) => {
    // TODO: Why did we introduce `RecipientField` type and not just use `Option` instead? Feels like a burden.
    const remappedEmails = values.map((value) => {
      const { email, id, label, type } = value;
      return {
        email: email || label,
        id,
        type: type || ReferenceType.Email,
      };
    });
    const normalizedEmails = remappedEmails.map((email) =>
      getEmailOption(email.email)
    );

    control.setValue(name, normalizedEmails);
    control.trigger();
  };

  const onChangeHandler = (
    e: SyntheticEvent<Element, Event>,
    values: (string | Option)[]
  ) => {
    control.setValue(
      name,
      values
        .map((value) => {
          if (isOption(value)) {
            return {
              email: value.inputValue,
              id: value.id,
              type: value.type,
            };
          } else {
            try {
              // Test email and display error modal if its not a valid email
              if (!isValidEmail(value)) displayErrorMessage(value);
            } catch (e) {
              // Fallback if there is an issue with regex pattern
              displayErrorMessage(value);
            }
            return getEmailOption(value);
          }
        })
        .filter(isDefined)
    );
    control.trigger();
  };

  return (
    <InputWrap>
      <StyledLabel>{label}</StyledLabel>
      <Controller
        control={control}
        name={name}
        render={(field) => (
          <MultiSelect
            autoCompleteProps={{
              getOptionLabel: (option) => {
                if (isOption(option)) return option.label;
                return option;
              },
              isOptionEqualToValue: (option, value) => {
                let selectedOption: string;
                let selectedValue: string;
                if (isOption(option)) {
                  selectedOption = option.id;
                } else {
                  selectedOption = option;
                }

                if (isOption(value)) {
                  selectedValue = value.id;
                } else {
                  selectedValue = value;
                }
                return selectedOption === selectedValue;
              },
              onChange: (e, values) => {
                onChangeHandler(e, values);
                handleDebounceDraftSave();
              },
              options: options,
              value: multiSelectValue,
            }}
            color="default"
            inputRef={field.ref}
            noOptionsText={noOptionsText}
            onPaste={(event) =>
              pasteHandlerEmails({
                currentValues: control.getValues(name),
                event,
                updateValuesCallback: updateValue,
              })
            }
            renderCustomInput={renderCustomInput}
            renderCustomOption={renderCustomOption}
          />
        )}
      />
    </InputWrap>
  );
};
