import React from 'react';
import { useTranslation } from 'react-i18next';

import { Switch } from '@pro4all/shared/ui/inputs';
import { NativeCheckbox } from '@pro4all/shared/ui/inputs';

import { useTableCheck } from '../hooks/useTableCheck';
import { OnToggleAllRowsType } from '../types';

type Props = {
  onToggleAllRows?: OnToggleAllRowsType;
  type?: string;
};
export const ToggleCheckAll = ({ type, onToggleAllRows }: Props) => {
  const { t } = useTranslation();
  const {
    allRowsAreChecked,
    anyRowsAreChecked,
    toggleCheckAllRows,
    noRowsAreChecked,
  } = useTableCheck();

  const ariaLabel = !anyRowsAreChecked
    ? t('Select all rows')
    : t('Deselect all rows');

  const Component = type === 'selectedSwitch' ? Switch : NativeCheckbox;
  const customProps =
    type === 'selectedSwitch'
      ? {}
      : {
          interminate: !allRowsAreChecked && anyRowsAreChecked,
        };

  return (
    <Component
      aria-label={ariaLabel}
      checked={allRowsAreChecked}
      onChange={() => {
        toggleCheckAllRows();
        onToggleAllRows?.({
          allRowsAreChecked,
          anyRowsAreChecked,
          noRowsAreChecked,
        });
      }}
      {...customProps}
    />
  );
};
