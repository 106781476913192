import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import { ActionProps } from '@pro4all/shared/types';
import { Button } from '@pro4all/shared/ui/buttons';
import { DataViewContextToggle } from '@pro4all/shared/ui/data-view';
import {
  PredefinedFilter,
  PredefinedFilterSelect,
  useFilterContext,
} from '@pro4all/shared/ui/filtering';
import { ActionBar } from '@pro4all/shared/ui/action-bar';
import { useTableCheck } from '@pro4all/shared/ui/table';

import { useExportActions } from './export-table/useExportActions';
import * as Styled from './TableActionBar.styles';

interface Props {
  altActions?: ActionProps[];
  dataTestid?: string;
  dataViewToggle?: boolean;
  mainActions?: ActionProps[];
  predefinedFilters?: PredefinedFilter[];
  search?: boolean;
  showExportButton?: boolean;
  showTableActions?: boolean;
}

// useTableCheck activates the checkboxes in the context. This way we only activate them when needed.
const TableActionBarWithChecks: React.FC<Props> = ({
  altActions = [],
  mainActions = [],

  search = false,
  showTableActions = true,
}) => {
  const { anyRowsAreChecked } = useTableCheck();
  return (
    <ActionBar
      altActions={altActions}
      mainActions={mainActions}
      search={search}
      showAltBar={anyRowsAreChecked}
      showTableActions={showTableActions}
    />
  );
};

export const TableActionBar: React.FC<Props> = ({
  altActions = [],
  dataViewToggle = false,
  mainActions = [],
  dataTestid = '',
  predefinedFilters,
  search = false,
  showExportButton = true,
  showTableActions = true,
}) => {
  const { filters, removeAllFilters } = useFilterContext() || {};
  const { t } = useTranslation();

  const predefinedFilterEnabled = useFeatureFlag('predefined-table-filters');

  const exportButton = useExportActions();

  // No actions, no checkedActions, no search and no dataviewToggle
  if (
    !mainActions?.length &&
    !altActions?.length &&
    !search &&
    !dataViewToggle
  ) {
    return null;
  }

  return (
    <Styled.Wrapper data-testid={dataTestid}>
      {altActions.length ? (
        // Checkboxes are automatically added to the table in case there are row related actions (altActions).
        <TableActionBarWithChecks
          altActions={altActions}
          mainActions={mainActions}
          search={search}
          showTableActions={showTableActions}
        />
      ) : (
        <ActionBar
          mainActions={mainActions}
          search={search}
          showTableActions={showTableActions}
        />
      )}
      <Styled.GenericTableActionsWrapper>
        {predefinedFilters && predefinedFilterEnabled && (
          <Box sx={{ minWidth: '150px' }}>
            <PredefinedFilterSelect
              dataTestId={`${dataTestid}-predefined-filter`}
              predefinedFilters={predefinedFilters}
            ></PredefinedFilterSelect>
          </Box>
        )}
        {filters?.length ? (
          <Button
            color="inherit"
            data-testid="reset-filters"
            onClick={removeAllFilters}
            startIcon="reset"
          >
            {t('Clear filters')}
          </Button>
        ) : null}
        {showExportButton ? exportButton : null}
        {dataViewToggle && <DataViewContextToggle />}
      </Styled.GenericTableActionsWrapper>
    </Styled.Wrapper>
  );
};
