import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  Answer,
  useSavedAnswersInstanceIncludeLazyQuery,
} from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useSavedAnswersContext } from '@pro4all/shared/providers/saved-answers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { Card } from '@pro4all/shared/ui/general';
import { Loader } from '@pro4all/shared/ui/loader';
import {
  BigMessageNoSavedAnswers,
  useDiscardModalContext,
} from '@pro4all/shared/ui/messages';
import { useSearchInput } from '@pro4all/shared/ui/search-input';
import { sortBy } from '@pro4all/shared/utils';

import { useGetSavedAnswersOnTemplateId } from './useGetSavedAnswersOnTemplateId';

export const DialogSelectSavedAnswers = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const applySavedAnswersContext = searchParams.is('applySavedAnswers', 'true');
  const { query, searchInput } = useSearchInput();
  const { setAnswersForApplying } = useSavedAnswersContext();
  const { savedAnswersInstances, showLoader } =
    useGetSavedAnswersOnTemplateId();
  const { setBypass } = useDiscardModalContext();
  const [getSavedAnswersInstances] = useSavedAnswersInstanceIncludeLazyQuery();

  const closeModal = () => {
    setBypass(false);
    setTimeout(() => {
      searchParams.delete('applySavedAnswers');
    }, 100); // Little delay to give the bypass state time to update.
  };

  const onSelect = async (id: string) => {
    const { data } = await getSavedAnswersInstances({
      fetchPolicy: 'cache-and-network',
      variables: {
        id,
        includeAnswers: true,
      },
    });
    const answers = (
      data ? data.savedAnswersInstance?.answers : []
    ) as Answer[];
    setAnswersForApplying(answers);
    if (data) closeModal();
  };

  const filteredSavedAnswers =
    !showLoader && applySavedAnswersContext
      ? savedAnswersInstances
          .sort(sortBy({ key: 'name' }))
          .filter(({ name }) =>
            name
              ? name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
              : true
          )
      : [];

  return (
    <FloatingModal
      data-testid="select-savedanswers-modal"
      maxWidth="md"
      onClose={closeModal}
      open={applySavedAnswersContext}
    >
      <FloatingModal.Header>{t('Apply saved answers')}</FloatingModal.Header>
      <ContentWrapper>
        {showLoader ? (
          <Loader />
        ) : filteredSavedAnswers.length ? (
          <>
            {searchInput}
            {filteredSavedAnswers.map((savedAnswers) => {
              const { id, name } = savedAnswers;
              return (
                <Card
                  disabledButtonBorder
                  iconName="bookmark"
                  key={id}
                  menuItems={[
                    {
                      startIcon: 'visibility',
                    },
                  ]}
                  onClick={() => {
                    onSelect(id);
                  }}
                  title={name || ''}
                />
              );
            })}
          </>
        ) : (
          <BigMessageNoSavedAnswers />
        )}
      </ContentWrapper>
    </FloatingModal>
  );
};

const ContentWrapper = styled(Box)`
  && {
    height: 500px;
  }
`;
