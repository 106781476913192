import styled from 'styled-components';

import { Button } from '@pro4all/shared/ui/buttons';
import { Footer } from '@pro4all/shared/ui/footer';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

export const Header = styled(Text)`
  && {
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 500;
  }
`;

export const SectionName = styled('span')`
  color: ${({ theme }) => theme.palette.primary.main};
  display: inline-block;
  font-weight: 500;
  padding-left: 4px;
  padding-right: 4px;
`;

export const StyledIcon = styled(Icon)<{ section: string }>`
  vertical-align: ${(props) => (props.section === 'section' ? '-8px' : '-6px')};
  padding-right: 2px;
`;

export const Main = styled.div`
  flex: 1;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: calc(24px + 60px);
  overflow-y: auto;
`;

export const StyledButton = styled(Button)`
  && {
    top: 10px;
  }
`;

export const StyledFooter = styled(Footer)`
  && {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 20px;
    padding-top: 2px;
    padding-left: 24px;
    padding-right: 24px;
  }
`;
