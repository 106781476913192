import { FieldDefinition } from '@pro4all/graphql';

export const useIsEmptySection = () => {
  const isEmptySection = (items: FieldDefinition[]): boolean =>
    // Check every item in the array.
    items.every((item) => {
      // If it is a Section, check the subFields recursively.
      if (item.type === 'Section' && item.valueType?.subFields) {
        return isEmptySection(item.valueType.subFields);
      }
      // Else check if the value is empty.
      return item.value === null || item.value === '';
    });
  return { isEmptySection };
};
