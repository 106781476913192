import React from 'react';
import styled from 'styled-components';

import { Avatar as MUIAvatar } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

type Size = 'small' | 'large';

export type RoundedImageProps = {
  name?: string;
  noOfCharacters?: number;
  size?: Size;
  src?: string;
};

export const getInitials = ({
  fullName,
  noOfCharacters = 2,
}: {
  fullName: string;
  noOfCharacters?: number;
}) => {
  if (!fullName) {
    return '-';
  }

  // Take only the part in front of the e-mailadres.
  const nameOnly = fullName.split(/[<(@]/)[0].trim();

  // Split the name in words.
  const names = nameOnly.split(/\s+/);

  const charactersToPrint =
    noOfCharacters > fullName.length ? fullName.length : noOfCharacters;

  if (names.length < charactersToPrint) {
    if (names[0].length >= charactersToPrint) {
      // Return the first two character of the word.
      return names[0].substring(0, charactersToPrint).toUpperCase();
    } else if (names.length > 0) {
      return names[0].charAt(0).toUpperCase();
    } else {
      return '-';
    }
  }

  const firstInitial = names[0].charAt(0).toUpperCase();
  const lastInitial = names[names.length - 1].charAt(0).toUpperCase();

  return firstInitial + lastInitial;
};

export const Avatar: React.FC<RoundedImageProps> = ({
  name,
  noOfCharacters = 2,
  size = 'small',
  src,
}) => (
  <StyledAvatar $size={size} src={src}>
    {getInitials({ fullName: name || '', noOfCharacters })}
  </StyledAvatar>
);

const StyledAvatar = styled(MUIAvatar)<{ $size: Size }>`
  && {
    background-color: ${customColors.grey500};
    color: ${customColors.white};
    font-size: ${({ $size }) => ($size === 'small' ? '10px' : '16px')};
    height: ${({ $size }) => ($size === 'small' ? 1.7 : 2.6)}rem;
    width: ${({ $size }) => ($size === 'small' ? 1.7 : 2.6)}rem;
  }
`;
