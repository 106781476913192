import React, { useCallback, useEffect, useMemo } from 'react';

import { ImageType, useReportLogosQuery } from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';
import { SearchableSelectProps } from '@pro4all/shared/ui/inputs';

import { ImageUpload } from '../../components';
import { useReportOptionsContext } from '../../ReportOptionsProvider';

export const LogoField: React.FC = () => {
  const {
    setLogo,
    setLogoOptions,
    state: {
      logoOptions,
      reportOptions: { logoId },
    },
  } = useReportOptionsContext();

  const { data: logoData } = useReportLogosQuery();

  useEffect(() => {
    if (logoData) {
      const queriedLogoOptions = logoData?.reportLogos?.map((logo) => ({
        id: logo.id,
        label: decodeURI(logo.name),
      }));

      const defaultLogoOption = {
        id: 'default',
        label: 'Prostream logo (default)',
      };

      const logoOptions: Option[] = queriedLogoOptions
        ? [defaultLogoOption, ...queriedLogoOptions]
        : [defaultLogoOption];

      setLogoOptions(logoOptions);
    }
  }, [logoData, setLogoOptions]);

  const selectLogoOption = useCallback(
    async ({ id }: Option) => {
      setLogo(id);
    },
    [setLogo]
  );

  const onChange = (value: SearchableSelectProps['value']) => {
    if (!value || typeof value === 'string') {
      selectLogoOption({ id: 'default', label: 'default' });
    } else {
      selectLogoOption({ id: value.id, label: value.label });
    }
  };

  const memoizedJSX = useMemo(
    () => (
      <ImageUpload
        imageType={ImageType.CompanyLogo}
        label="Company logo"
        name="companyLogo"
        onChange={onChange}
        options={logoOptions}
        selectedId={logoId}
      />
    ),
    [logoOptions, logoId]
  );

  return memoizedJSX;
};
