import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { List } from '@pro4all/shared/ui/list';

import { HEIGHT } from './types';

export const StyledActionBar = styled(Box)<{
  $increasedBottomSpacing: boolean;
  $increasedIconSpacing: boolean;
  $largeScreen: boolean;
}>`
  && {
    display: flex;
    min-height: ${HEIGHT}px;

    & > :not(:last-child) {
      margin-right: ${({ theme, $increasedIconSpacing }) =>
        theme.spacing($increasedIconSpacing ? 1.5 : 1)};
    }

    ul > li :not(:last-child) {
      margin-bottom: ${({ theme, $increasedBottomSpacing, $largeScreen }) =>
        `${$increasedBottomSpacing && $largeScreen ? theme.spacing(1.5) : 0}`};
    }
  }
`;

export const StyledList = styled(List)`
  gap: ${({ theme }) => theme.spacing(0.5)};
`;
