import React from 'react';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Loader } from '@pro4all/shared/ui/loader';
import { BigMessageNoCommentsInThread } from '@pro4all/shared/ui/messages';

import { CommentForm } from './forms';
import { useThreadContext } from './ThreadContext';

interface CommentListProps {
  projectId?: string;
}

// Generic component list for comments.
export const CommentsList: React.FC<CommentListProps> = ({ projectId }) => {
  const context = useThreadContext();
  if (!context) throw Error('Comments context not initialized.');
  const { comments, loading, setProjectId } = context;

  setProjectId(projectId);

  const showSpinner = loading || (comments && !comments.length);
  const noMessages = !comments || !comments.length;

  return (
    <>
      {showSpinner && (
        <Box display="flex" justifyContent="center">
          <Loader />
        </Box>
      )}
      {noMessages && <BigMessageNoCommentsInThread />}
      {comments?.map(({ id: messageId, ...comment }, key) => (
        <CommentForm
          key={key}
          messageId={messageId}
          projectId={projectId}
          {...comment}
        />
      ))}
    </>
  );
};
