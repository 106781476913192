import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { useLocalStorage } from '@pro4all/shared/hooks';
import {
  BaseRow,
  ColumnSizes,
  useOptimisticResponseContext,
  useTableContext,
} from '@pro4all/shared/ui/table';

import { COLUMN_ADJUST_SIZE } from './constants';
import {
  AdjustColumnWidthType,
  AdvancedFilterValue,
  ColumnWidthChangeType,
  FilterColumnProps,
  FilterContextValue,
  FilterType,
  FilterTypeLS,
} from './types';

const FilterContext = React.createContext(null);

export function useFilterContext<Row extends BaseRow>() {
  return useContext<FilterContextValue<Row>>(FilterContext);
}

export function FilterContextProvider<Row extends BaseRow>({
  children,
}: PropsWithChildren<{}>) {
  const {
    filterItems,
    setFilteredItems,
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<Row>();

  const [filters, setFilters] = useState<FilterType<Row>[]>();

  // For the document and version table we want to use the same id regarding resizing.
  // But a different id regarding column filtering and sorting. That's why the prop 'idFilteringSorting' was introduced.
  const { id, idFilteringSorting } = useTableContext<Row>();
  const tableId = idFilteringSorting || id;
  const { setLocalStorageItem: setLocalStorageItemFilters } = useLocalStorage<
    FilterTypeLS[]
  >({
    key: `prostream-column-filters-${tableId}`,
  });

  const {
    localStorageItem: columnSizesLs,
    setLocalStorageItem: setLocalStorageItemSizes,
  } = useLocalStorage<ColumnSizes>({
    key: `prostream-column-sizes-${id}`,
  });

  const adjustColumnWidth = useCallback(
    ({
      columnWidthChange,
      propertyId,
      subPropertyId,
    }: AdjustColumnWidthType) => {
      const key = subPropertyId ? `${propertyId}.${subPropertyId}` : propertyId;
      const sizeUpdated =
        columnWidthChange === ColumnWidthChangeType.Decrease
          ? columnSizesLs[key] - COLUMN_ADJUST_SIZE
          : columnSizesLs[key] + COLUMN_ADJUST_SIZE;
      setLocalStorageItemSizes({ ...columnSizesLs, [key]: sizeUpdated });
    },
    [columnSizesLs, setLocalStorageItemSizes]
  );

  const handleFilterChange = useCallback(
    ({
      filters,
      onColumnResizeCallback,
    }: { filters: FilterType<Row>[] } & Pick<
      FilterColumnProps,
      'onColumnResizeCallback'
    >) => {
      setFilters(filters);
      setLocalStorageItemFilters(filters.map(filterToLocalStorage));
      onColumnResizeCallback &&
        onColumnResizeCallback(filters.map(filterToLocalStorage));
    },
    [setLocalStorageItemFilters]
  );

  const toParsedValue = (filterValue: string | AdvancedFilterValue) =>
    typeof filterValue === 'object' ? filterValue : filterValue.toString();

  const getFilterValuesFromContext = useCallback(
    ({
      flattenOptions,
      propertyId,
      subPropertyId,
    }: Pick<
      FilterColumnProps,
      'flattenOptions' | 'propertyId' | 'subPropertyId'
    >) => {
      let match: FilterType<Row> | undefined;

      if (flattenOptions) {
        match = (filters || []).find(
          (filter) =>
            filter.propertyId.includes(propertyId) &&
            (!subPropertyId || subPropertyId.includes(filter.subPropertyId))
        );
      } else
        match = (filters || []).find(
          (filter) =>
            filter.propertyId === propertyId &&
            (!subPropertyId || filter.subPropertyId === subPropertyId)
        );

      return match?.filterValues.map(toParsedValue);
    },
    [filters]
  );

  const getFiltersOtherColumns = useCallback(
    ({
      filters,
      propertyId,
      subPropertyId,
    }: {
      filters: FilterType<Row>[];
    } & Pick<FilterColumnProps, 'propertyId' | 'subPropertyId'>) => {
      const filtersOtherColumns = subPropertyId
        ? filters.filter(
            (filter) =>
              !(
                filter.propertyId === propertyId &&
                filter.subPropertyId === subPropertyId
              )
          )
        : filters.filter((filter) => filter.propertyId !== propertyId);
      return filtersOtherColumns;
    },
    []
  );

  const getItemsSubset = useCallback(
    ({
      filters,
      items,
      propertyId,
      subPropertyId,
    }: {
      filters: FilterType<Row>[];
      items: Row[];
    } & Pick<FilterColumnProps, 'propertyId' | 'subPropertyId'>) =>
      items.filter((item) => {
        if (filters) {
          const filtersOtherColumns = getFiltersOtherColumns({
            filters,
            propertyId,
            subPropertyId,
          });

          // We have to check if this item is included in all other filters.
          let countIncluded = 0;
          filtersOtherColumns.forEach((filter) => {
            filter.itemsFiltered.forEach((itemOtherColumn) => {
              if (item.id === itemOtherColumn.id) {
                // Filter of another column, item is also included in that filter.
                countIncluded++;
              }
            });
          });
          return countIncluded === filtersOtherColumns.length;
        } else return true;
      }),
    [getFiltersOtherColumns]
  );

  const getItemsThatAreIncludedInAllFilters = useCallback(
    ({ filters }: { filters: FilterType<Row>[] }) =>
      itemsInitial.filter((item) => {
        // We have to check if this item is included in all passed in filters.
        let countIncluded = 0;
        filters.forEach((filter) => {
          filter.itemsFiltered.forEach((itemOtherColumn) => {
            if (item.id === itemOtherColumn.id) {
              countIncluded++;
            }
          });
        });
        return countIncluded === filters.length;
      }),
    [itemsInitial]
  );

  const filterToLocalStorage = ({
    filterType,
    filterValues,
    isMultiSelect,
    propertyId,
    subPropertyId,
    translateOptions,
  }: FilterTypeLS) => ({
    filterType,
    filterValues,
    isMultiSelect,
    propertyId,
    subPropertyId,
    translateOptions,
  });

  const removeFilter = useCallback(
    ({
      onColumnResizeCallback,
      propertyId,
      subPropertyId,
    }: Pick<
      FilterColumnProps,
      'onColumnResizeCallback' | 'propertyId' | 'subPropertyId'
    >) => {
      if (filters && filters.length) {
        const filtersOtherColumns = getFiltersOtherColumns({
          filters,
          propertyId,
          subPropertyId,
        });

        // We have to re-populate the table with a subset of the filters left.
        if (filters.length === 1) {
          // There will be no more filters left, so re-populate the table with all the items.
          const itemsReset = itemsInitial.length ? itemsInitial : items;
          filterItems(itemsReset);
        } else {
          setFilteredItems(
            getItemsSubset({
              filters: filtersOtherColumns,
              items: filtersOtherColumns[0].itemsFiltered,
              propertyId,
              subPropertyId,
            })
          );
        }

        handleFilterChange({
          filters: filtersOtherColumns,
          onColumnResizeCallback,
        });

        adjustColumnWidth({
          columnWidthChange: ColumnWidthChangeType.Decrease,
          propertyId,
          subPropertyId,
        });
      }
    },
    [
      adjustColumnWidth,
      filters,
      filterItems,
      getFiltersOtherColumns,
      getItemsSubset,
      handleFilterChange,
      items,
      itemsInitial,
      setFilteredItems,
    ]
  );

  const removeAllFilters = useCallback(() => {
    const itemsReset = itemsInitial.length ? itemsInitial : items;
    setFilteredItems(itemsReset);
    setFilters([]);
    setLocalStorageItemFilters([]);
  }, [items, itemsInitial, setFilteredItems, setLocalStorageItemFilters]);

  const applyFilters = useCallback(
    (filters: FilterType<Row>[]) => {
      removeAllFilters();
      setFilters(filters);
      setLocalStorageItemFilters(filters.map(filterToLocalStorage));
    },
    [removeAllFilters, setLocalStorageItemFilters]
  );

  const setSelectedOptions = useCallback(
    ({
      filterType,
      filterValues,
      isMultiSelect = false,
      itemsFiltered,
      onColumnResizeCallback,
      propertyId,
      subPropertyId,
      translateOptions,
    }: FilterType<Row>) => {
      if (filters && filters.length) {
        if (filterValues.length === 0) {
          // There is an active filter, but not that long anymore because the last filter values has just been removed.

          // In case there are no other columns with an active filter, just re-populate the table with itemsFiltered.
          if (filters.length === 1) {
            filterItems(itemsFiltered);
          }

          // In case there are other columns with an active filter, we have to re-populate the table with the subset of items from both filters.
          if (filters.length > 1) {
            const filtersOtherColumns = getFiltersOtherColumns({
              filters,
              propertyId,
              subPropertyId,
            });

            // Re-populate the table with a subset of items from all filters left.
            filterItems(
              getItemsSubset({
                filters,
                items: filtersOtherColumns[0].itemsFiltered,
                propertyId,
                subPropertyId,
              })
            );
          }

          // All filtered values for this column have been removed, so remove the filter.
          removeFilter({ onColumnResizeCallback, propertyId, subPropertyId });
        } else {
          // Currently there are is at least one active filter, add/overwrite the filter for this column.

          const updatedFilter: FilterType<Row> = {
            filterType,
            filterValues,
            isMultiSelect: isMultiSelect || false,
            itemsFiltered,
            propertyId,
            subPropertyId,
            translateOptions,
          };

          const isIncludedInFilters = Boolean(
            subPropertyId
              ? filters.find(
                  (filter) =>
                    filter.propertyId === propertyId &&
                    filter.subPropertyId === subPropertyId
                )
              : filters.find((filter) => filter.propertyId === propertyId)
          );

          if (isIncludedInFilters) {
            // Filter currently included, overwrite it.
            const filtersOtherColumns = getFiltersOtherColumns({
              filters,
              propertyId,
              subPropertyId,
            });
            const filtersUpdated = [...filtersOtherColumns, updatedFilter];
            handleFilterChange({
              filters: filtersUpdated,
              onColumnResizeCallback,
            });
          } else {
            // Filter currently included, add it.
            const filtersUpdated = [...filters, updatedFilter];
            handleFilterChange({
              filters: filtersUpdated,
              onColumnResizeCallback,
            });
            adjustColumnWidth({
              columnWidthChange: ColumnWidthChangeType.Increase,
              propertyId,
              subPropertyId,
            });
          }

          // Get a subset of items from all filters.
          filterItems(
            getItemsSubset({
              filters,
              items: itemsFiltered,
              propertyId,
              subPropertyId,
            })
          );
        }
      } else {
        // No filter yet, add the first filter.
        const filtersUpdated: FilterType<Row>[] = [
          {
            filterType,
            filterValues,
            isMultiSelect: isMultiSelect || false,
            itemsFiltered,
            propertyId,
            subPropertyId,
            translateOptions,
          },
        ];

        handleFilterChange({
          filters: filtersUpdated,
          onColumnResizeCallback,
        });
        adjustColumnWidth({
          columnWidthChange: ColumnWidthChangeType.Increase,
          propertyId,
          subPropertyId,
        });
        filterItems(itemsFiltered);
      }
    },
    [
      adjustColumnWidth,
      filters,
      filterItems,
      getFiltersOtherColumns,
      getItemsSubset,
      handleFilterChange,
      removeFilter,
    ]
  );

  const updateTableBasedOnCurrentFilters = useCallback(
    (filters: FilterType<Row>[]) => {
      if (filters && filters.length) {
        setFilters(filters);
        filterItems(
          getItemsSubset({
            filters,
            items: filters[0].itemsFiltered,
            propertyId: filters[0].propertyId,
            subPropertyId: filters[0].subPropertyId,
          })
        );
      }
    },
    [filterItems, getItemsSubset]
  );

  const filterContextValue = useMemo(
    () => ({
      applyFilters,
      filters,
      getFilterValuesFromContext,
      getItemsThatAreIncludedInAllFilters,
      removeAllFilters,
      removeFilter,
      setFilters,
      setSelectedOptions,
      updateTableBasedOnCurrentFilters,
    }),
    [
      applyFilters,
      filters,
      getFilterValuesFromContext,
      getItemsThatAreIncludedInAllFilters,
      removeAllFilters,
      removeFilter,
      setFilters,
      setSelectedOptions,
      updateTableBasedOnCurrentFilters,
    ]
  );

  return (
    <FilterContext.Provider value={filterContextValue}>
      {children}
    </FilterContext.Provider>
  );
}
