import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  HierarchicalList,
  HierarchicalListsIncludeDocument,
  useDeleteHierarchicalListsMutation,
} from '@pro4all/graphql';
import { TrackingEvent } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useTableCheck } from '@pro4all/shared/ui/table';
import { useAnalytics } from '@pro4all/shared/vendor';

export const useDeleteHierarchicalLists = ({
  includeOrgItems,
}: {
  includeOrgItems: boolean;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteHierarchicalLists] = useDeleteHierarchicalListsMutation();
  const { checkedRows, uncheckAllRows } = useTableCheck<HierarchicalList>();
  const { track } = useAnalytics();
  const {
    params: { projectId },
  } = useRouting();

  return async () => {
    const ids = checkedRows.map((row) => row.id);

    const variables = {
      includeCreatedAt: true,
      includeCreatedBy: true,
      includeOrgItems,
      includeScope: true,
      projectId,
    };

    try {
      track(TrackingEvent.HierarchicalListDeleted, {
        hierarchicalListsDeleted: ids.length,
      });
      await deleteHierarchicalLists({
        variables: { ids },
      });

      // Update the cache.
      const cachedHierarchicalLists = client?.readQuery({
        query: HierarchicalListsIncludeDocument,
        variables,
      }).hierarchicalLists as HierarchicalList[];
      client?.writeQuery({
        data: {
          hierarchicalLists: cachedHierarchicalLists.filter(
            (cachedHierarchicalList) => !ids.includes(cachedHierarchicalList.id)
          ),
        },
        query: HierarchicalListsIncludeDocument,
        variables,
      });

      enqueueSnackbar(t(`Selected hierarchical lists deleted`));
      uncheckAllRows();
    } catch (e) {
      enqueueSnackbar(
        `${t('Something went wrong')}. ${t('Please try again')}.`
      );
    }
  };
};
