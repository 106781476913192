import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentVersion } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/table';
import { Text } from '@pro4all/shared/ui/typography';

import { useSharedDocsContext } from '../shared-docs/SharedDocsContext';
import { DocumentsActionBar } from '../table/DocumentsActionBar';
import { useColumns } from '../table/useColumns';

export const SharedDocsTable: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useSharedDocsContext();

  const { nameColumn, pathColumn } = useColumns({});

  const documentVersions = useMemo(() => {
    const documents: DocumentVersion[] = [
      ...(data?.documentVersions ?? []),
      ...(data?.latestDocumentVersions ?? []),
    ];
    return documents || [];
  }, [data]);

  useSetItemsInLocalState<DocumentVersion>(documentVersions);

  const {
    state: { items },
  } = useOptimisticResponseContext<DocumentVersion>();

  const translation = data?.latestDocumentVersions?.length
    ? 'Shared documents will automatically update when there is a new version.'
    : 'Only the version at the time of link creation is shared with you.';

  return (
    <>
      <Box ml={3} mt={2}>
        <Text align="left" variant="subtitle2">
          {t(translation)}
        </Text>
      </Box>
      <TableContextProvider
        checkable={false}
        columns={[nameColumn, pathColumn]}
        id="table-shared-documents"
        items={items}
      >
        <FilterContextProvider<DocumentVersion>>
          <DocumentsActionBar />
          <Table<DocumentVersion> headerHeight={40} />
        </FilterContextProvider>
      </TableContextProvider>
    </>
  );
};
