import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormikSearchableSelect } from '@pro4all/shared/ui/formik';
import { InfoBlock } from '@pro4all/shared/ui/info-block';

import { useGetIntegrationTypeData } from '../form/useGetIntegrationTypeData';

import { useConnectionFormConfig } from './useConnectionFormConfig';
import { useGetConnection } from './useGetConnection';
import { useGetProjectOptions } from './useGetProjectOptions';

export const CustomBuild12 = () => {
  const { t } = useTranslation();
  const { getField } = useConnectionFormConfig();
  const projectField = getField('project');
  const { integrationId } = useGetIntegrationTypeData();
  const { dataConnection, loadingConnection } = useGetConnection();
  const { projectOptions } = useGetProjectOptions(integrationId);

  return !loadingConnection ? (
    <>
      <InfoBlock
        paragraph={`${t(
          "Select which project from '{{integrationName}}' you want to connect.",
          { integrationName: dataConnection?.connectionName }
        )}`}
      />
      <FormikSearchableSelect
        id={projectField?.name}
        label={projectField?.displayName}
        name={projectField?.name || ''}
        options={projectOptions}
        placeholder={t('Select project')}
      />
    </>
  ) : null;
};
