import styled from 'styled-components';

import { Collapsible as UICollapsible } from '@pro4all/shared/ui/collapsible';
import { List as UIList } from '@pro4all/shared/ui/list';

interface Props {
  $orientation: 'horizontal' | 'vertical';
}

export const Collapsible = styled(UICollapsible)`
  && {
    margin-bottom: 1rem;
  }
`;

export const Label = styled.strong<Props>`
  display: block;

  ${({ $orientation }) =>
    $orientation === 'horizontal'
      ? `flex: none;
         margin-right: 0.5rem;
         width: 130px;`
      : `margin-bottom: 0.5rem;
  `}
`;

export const List = styled(UIList)`
  position: relative;
`;

export const Item = styled(UIList.Item)<Props>`
  word-break: break-word;

  ${({ $orientation }) =>
    $orientation === 'horizontal' &&
    `
    display: flex;
  `}

  & + & {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }
`;
