import React from 'react';
import { useTranslation } from 'react-i18next';

import { getAnswer, getAnswerForVersion } from '@pro4all/documents/data-access';
import { FieldDefinition } from '@pro4all/graphql';
import { AnswerView } from '@pro4all/metadata/ui';
import { DocumentAndVersionType } from '@pro4all/shared/types';
import { DocumentVersionNumber, FileSize } from '@pro4all/shared/ui/general';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { Property, PropertyList } from '@pro4all/shared/ui/property-list';
import { Timestamp } from '@pro4all/shared/ui/timestamp';

type Props = {
  fieldDefinitions?: FieldDefinition[];
  isExpectedDoc?: boolean;
} & DocumentAndVersionType;

const MAX_META_DATA_PROPERTIES = 10;

enum StandardLabel {
  Approved = 'Approved',
  Build12 = 'Build12',
  Comments = 'Comments',
  CreatedBy = 'Created by',
  CreatedOn = 'Created on',
  Extension = 'Extension',
  Finalized = 'Finalized',
  Name = 'Name',
  Organization = 'Organization',
  Qr = 'QR',
  Size = 'Size',
  ThreeD = 'ThreeD',
  UpdatedBy = 'Updated by',
  UpdatedOn = 'Updated on',
  Version = 'Version',
}

export const GeneralProperties: React.FC<Props> = ({
  document,
  fieldDefinitions,
  version,
}) => {
  const { t } = useTranslation();

  if (!document && !version) return null;

  const { createdBy, updatedBy } = document || version || {};

  const currentVersion = version
    ? version
    : document?.versions?.find((version) => version?.id === document.versionId);

  const notUpdated = t('Not updated yet');
  const unknown = t('Unknown');
  const name = document ? document.name : version ? version.name : unknown;
  const organizationProp: Property = {
    label: t(StandardLabel.Organization),
    value: createdBy?.organization?.name || unknown,
  };

  const createdAt = document
    ? document.createdAt
    : version
    ? version.createdAt
    : unknown;
  const updatedAt = document
    ? document.updatedAt
    : version
    ? version.updatedAt
    : unknown;
  const versionNumber = document
    ? document.versionNumber
    : version
    ? version.versionNumber
    : unknown;

  const propsForUploadedDocument: Property[] =
    !document?.isExpected || version
      ? [
          organizationProp,
          {
            label: t(StandardLabel.Size),
            value: <FileSize bytes={currentVersion?.fileSize} />,
          },
          {
            label: t(StandardLabel.CreatedOn),
            value: <Timestamp date={createdAt} format="lll" />,
          },
          {
            label: t(StandardLabel.CreatedBy),
            value: createdBy?.displayName ? (
              <UserTag user={createdBy} />
            ) : (
              unknown
            ),
          },
          {
            label: t(StandardLabel.UpdatedOn),
            value: updatedAt ? (
              <Timestamp date={updatedAt} format="lll" />
            ) : (
              notUpdated
            ),
          },
          {
            label: t(StandardLabel.UpdatedBy),
            value: updatedBy?.displayName ? (
              <UserTag user={updatedBy} />
            ) : (
              notUpdated
            ),
          },
        ]
      : [organizationProp];

  const metaDataProperties: Property[] = document
    ? (fieldDefinitions ?? []).map((item) => ({
        label: item?.name || unknown,
        value: <AnswerView item={item} value={getAnswer(document, item)} />,
      }))
    : version
    ? (fieldDefinitions ?? []).map((item) => ({
        label: item?.name || unknown,
        value: (
          <AnswerView item={item} value={getAnswerForVersion(version, item)} />
        ),
      }))
    : [];

  const metaDataPropertiesWithoutStandardColumns = metaDataProperties.filter(
    (prop) => !Object.keys(StandardLabel).includes(prop.label)
  );

  const properties: Property[] = [
    {
      label: t(StandardLabel.Name),
      value: name,
    },
    {
      label: t(StandardLabel.Version),
      value: (
        <DocumentVersionNumber
          isExpected={Boolean(document ? document.isExpected : false)}
          variant="body1"
        >
          {versionNumber}
        </DocumentVersionNumber>
      ),
    },
    ...propsForUploadedDocument,
    ...metaDataPropertiesWithoutStandardColumns,
  ];

  return (
    <PropertyList
      items={properties}
      limit={MAX_META_DATA_PROPERTIES}
      mb={3}
      px={3}
    />
  );
};
