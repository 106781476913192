import React from 'react';

import {
  Group,
  Message,
  ReferenceKind,
  ReferenceType,
  User,
} from '@pro4all/graphql';
import { AvatarGroup, Box } from '@pro4all/shared/mui-wrappers';
import { Avatar } from '@pro4all/shared/ui/avatar';
import {
  GroupTag,
  IdentityAvatar,
  UserTag,
} from '@pro4all/shared/ui/identity-card';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

export const ReferenceTag = ({
  referenceKind,
  references,
}: {
  referenceKind: ReferenceKind;
  references: Message['references'];
}) => {
  const recipients = references.filter(
    (reference) => reference.referenceKind === referenceKind
  );
  if (!recipients.length) return null;

  // In case of one recipient, we show the entire tag.
  if (recipients.length === 1) {
    if (recipients[0].referenceType === ReferenceType.GroupSnapshot) {
      const { referenceData, referenceId } = recipients[0];
      const group: Group = {
        displayName: referenceData || '',
        id: referenceId || '1',
      };
      return <GroupTag group={group} />;
    } else {
      const { referenceData, referenceValue } = recipients[0];
      const user: User = {
        displayName: referenceData || '',
        id: referenceValue,
      };
      return <UserTag user={user} />;
    }
  }

  const MAX_AVATARS = 4;

  // Get the names of the extra names for the tooltip.
  const extraRecipients = recipients
    .slice(MAX_AVATARS)
    .map((recipient) => recipient.referenceData || '')
    .join(', ');

  // In case of multiple recipients, we show the first 4 avatars and a tooltip with the rest.
  return (
    <Box sx={{ ml: '1px' }}>
      <AvatarGroup>
        {recipients.slice(0, MAX_AVATARS).map((recipient) => {
          const { referenceData, referenceValue, referenceType } = recipient;
          return (
            <IdentityAvatar
              id={referenceValue}
              key={referenceValue}
              label={referenceData || ''}
              type={
                referenceType === ReferenceType.GroupSnapshot ? 'Group' : 'User'
              }
            />
          );
        })}
        {recipients.length > MAX_AVATARS && (
          <Tooltip placement="bottom" title={extraRecipients}>
            <Avatar
              name={`+${recipients.length - MAX_AVATARS}`}
              noOfCharacters={3}
            />
          </Tooltip>
        )}
      </AvatarGroup>
    </Box>
  );
};
