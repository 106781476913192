import { useRef } from 'react';
import { DropzoneState } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { Document } from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Position } from '@pro4all/shared/types';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikForm } from '@pro4all/shared/ui/formik';
import {
  useOptimisticResponseContext,
  useTableCheck,
} from '@pro4all/shared/ui/table';
import { Text } from '@pro4all/shared/ui/typography';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { getDownloadName } from '../share/getDownloadName';

export const PublishVersionForm = ({
  onClose,
  openFileInputPublish,
}: {
  onClose: () => void;
  openFileInputPublish: DropzoneState['open'];
}) => {
  const { t } = useTranslation();
  const { uncheckAllRows } = useTableCheck<Document>();
  const { searchParams } = useRouting();
  const documentId = useRef(searchParams.get('publishDocumentId'));
  const {
    state: { items },
  } = useOptimisticResponseContext<Document>();

  const document = items.find((item) => item.id === documentId.current);

  const selectFileToPublish = () => {
    searchParams.delete('action');

    openFileInputPublish();

    // Deselect all rows if applicable.
    const actionTriggeredFrom = window.sessionStorage.getItem(
      StorageKeys.ACTION_TRIGGERED_FROM
    );
    if (actionTriggeredFrom !== Position.Contextmenu) uncheckAllRows();
  };

  return (
    <Formik initialValues={{}} onSubmit={selectFileToPublish}>
      {() => (
        <FormikForm>
          <FormWrapper>
            <Text paragraph variant="body1">
              {t(
                'You are about to upload a locally edited file as a new version. Others can view and act on this again after successfully publishing this.'
              )}
            </Text>
            <Trans
              components={{ strong: <strong /> }}
              defaults="Only a document with the name <strong>{{documentName}}-{{versionId}}{{fileExtension}}</strong>. Can be uploaded as a new version."
              values={{
                documentName: getDownloadName(document),
                fileExtension: document?.extension
                  ? `.${document?.extension}`
                  : '',

                versionId: document?.versionId,
              }}
            />
          </FormWrapper>
          <FormFooter onClose={onClose} submitLabel={t('Publish')} />
        </FormikForm>
      )}
    </Formik>
  );
};
