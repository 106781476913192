import React, { useState } from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';

import { QcPermissionCategory } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import {
  ProjectAccessType,
  useProjectContext,
} from '@pro4all/projects/ui/context';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useGenericContext } from '@pro4all/shared/providers';
import { generateRoute } from '@pro4all/shared/routing-utils';
import { useRouting } from '@pro4all/shared/routing-utils';
import { PermissionUserOption } from '@pro4all/shared/types';
import { Column, Main } from '@pro4all/shared/ui/layout';
import {
  BigMessageNoQcPermissionsForGroups,
  useDiscardModalContext,
} from '@pro4all/shared/ui/messages';
import { VisibilityToggle } from '@pro4all/shared/ui/visibility-toggle';

import { FolderTreePermissions } from '../components/folder-permissions/FolderTreePermissions';
import { PersonOrGroupSelect } from '../components/folder-permissions/PersonOrGroupSelect';
import QCVisibilityToggle from '../components/qc-permissions/components/QCVisibilityToggle';
import PermissionsMatrix from '../components/qc-permissions/PermissionsMatrix';
import {
  getTaskTypePermissionCategories,
  qcPermissionCategories,
} from '../components/qc-permissions/utils';
import { ProjectAccessTabs } from '../components/tabs/ProjectAccessTabs';

import { useAccessMembers } from './useAccessMembers';

export const ProjectAccess = () => {
  const projectContext = useProjectContext();
  const permissionStatus = projectContext?.projectAccessLoadStatus?.status;
  const {
    state: { showFinalPermissions },
    toggleShowFinalPermissions,
  } = useGenericContext();

  const isDocumentAccessRoute = useRouteMatch(Routes.projectAccessDocuments);
  const isQcPermissionsRoute = useRouteMatch([
    Routes.projectAccessQualityControl,
    Routes.projectAccessTasks,
  ]);

  const isAdmin = permissionStatus === ProjectAccessType.ProjectUpdate;
  const { contextScopedOrganizationSubscriptionLevel } =
    useOrganizationContext();
  const { hasDmsLicense, hasQualityControlLicense } =
    useSubscriptionRestriction(contextScopedOrganizationSubscriptionLevel);
  const hasObjectsFeature = useFeatureFlag('objects');
  const hasQcFeature = useFeatureFlag('qualitycontrol');
  const qcGroupPermissions = useFeatureFlag('qc-group-permissions');

  const { isDirty } = useDiscardModalContext();

  const {
    params: { projectId },
  } = useRouting();

  const base = generateRoute('project', { params: { projectId } });

  const { userId } = useOrganizationContext();

  //TEMPORARILY not setting a non-admin user automatically as selected option until the explicit project member temporary solution is no longer needed.
  const [selectedOption, setSelectedOption] = useState<PermissionUserOption>();

  const members = useAccessMembers({
    includeExplicitProjectMember: true,
    includeOrganization: true,
  });

  const getPersonOrGroupSelectMembers = () =>
    members.filter((member) => isAdmin || member.id === userId);

  return (
    <>
      <Box sx={{ maxWidth: '600px', pt: 1, px: 2 }}>
        <PersonOrGroupSelect
          disabled={isDirty}
          members={getPersonOrGroupSelectMembers()}
          onChange={(e) => setSelectedOption(e)}
          value={selectedOption}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" pr={2}>
        <ProjectAccessTabs />
        {isDocumentAccessRoute && (
          <VisibilityToggle
            label="Show final permissions"
            toggle={toggleShowFinalPermissions}
            toggleValue={showFinalPermissions}
          />
        )}

        {isQcPermissionsRoute && selectedOption && qcGroupPermissions && (
          <QCVisibilityToggle />
        )}
      </Box>
      <Main>
        <Column>
          <Route exact path={`${base}${Routes.access}`}>
            <Redirect to={`${base}${Routes.baseAccessDocuments}`} />
          </Route>
          <Route path={Routes.projectAccessDocuments}>
            <FolderTreePermissions selectedOption={selectedOption} />
          </Route>
          <Route path={Routes.projectAccessQualityControl}>
            {selectedOption &&
            selectedOption.type === 'Group' &&
            !qcGroupPermissions ? (
              <BigMessageNoQcPermissionsForGroups />
            ) : (
              <PermissionsMatrix
                category={QcPermissionCategory.Qcs}
                isAdmin={isAdmin}
                permissionCategories={qcPermissionCategories}
                target={selectedOption}
              />
            )}
          </Route>
          <Route path={Routes.projectAccessTasks}>
            {selectedOption &&
            selectedOption.type === 'Group' &&
            !qcGroupPermissions ? (
              <BigMessageNoQcPermissionsForGroups />
            ) : (
              <PermissionsMatrix
                category={QcPermissionCategory.Procedure}
                isAdmin={isAdmin}
                permissionCategories={getTaskTypePermissionCategories({
                  hasDmsLicense,
                  hasObjectsFeature,
                  hasQcFeature,
                  hasQualityControlLicense,
                })}
                target={selectedOption}
              />
            )}
          </Route>
        </Column>
      </Main>
    </>
  );
};
