import { Section as SectionComponent } from './Section';
import { AltToggle, ContentWrapper, Header, Toggle } from './Section.styles';

export * from './Section';

export const Section = Object.assign(SectionComponent, {
  AltToggle,
  ContentWrapper,
  Header,
  Toggle,
});
