import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Box, Button } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { getFormattedDate } from '@pro4all/shared/ui/timestamp';

import { useSubscriptionAnalytics } from './hooks/useSubscriptionAnalytics';
import { useSubscriptionHelpers } from './hooks/useSubscriptionHelpers';

export const FeedbackSubscriptionExpires = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const { trackChooseLicenseClicked } = useSubscriptionAnalytics();

  const cancelCancellation = (
    <Button
      onClick={() => {
        trackChooseLicenseClicked();
        searchParams.set({ action: 'changeSubscription' });
      }}
    >
      {t('Choose license')}
    </Button>
  );

  const { getSubscriptionEndDate, isExpired, isTrial } =
    useSubscriptionHelpers();

  return getSubscriptionEndDate() && !isTrial() && !isExpired ? (
    <Box maxWidth="860px" mb={2}>
      <Alert action={cancelCancellation} severity="warning">
        {t(
          'License expires on {{date}}. Reactivate your license to continue using the powers of Prostream.',
          {
            date: getFormattedDate(getSubscriptionEndDate(), 'll').label,
          }
        )}
      </Alert>
    </Box>
  ) : null;
};
