import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormikSearchableMultiSelect } from '@pro4all/shared/ui/formik';

import { SelectType } from './Types';
import { useGroupFormConfig } from './useGroupFormConfig';

export const MemberSelect = ({
  loading,
  noOptionsText,
  options,
  renderCustomInput,
  renderCustomOption,
}: SelectType) => {
  const { t } = useTranslation();

  const { getField } = useGroupFormConfig();
  const membersField = getField('members');

  return (
    <FormikSearchableMultiSelect
      label={membersField.label}
      limitTags={10}
      loading={loading}
      name={membersField.name}
      noOptionsText={noOptionsText}
      options={options}
      placeholder={t('Add members')}
      renderCustomInput={renderCustomInput}
      renderCustomOption={renderCustomOption}
    />
  );
};
