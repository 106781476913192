import { useTranslation } from 'react-i18next';

import { QualityControlInstanceType, SearchQcInstance } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import {
  BarChart,
  ChartsWrapper,
  DonutChart,
  LoadingOverlay,
  ValueChart,
} from '@pro4all/shared/ui/charts';
import { Loader } from '@pro4all/shared/ui/loader';

import { useDonutOptionsQcResults } from './useDonutOptionsQcResults';
import { useTimeClusterOptionsQcResults } from './useTimeClusterOptionsQcResults';

export const SearchResultsCharts: React.FC<{
  instances: SearchQcInstance[];
  loading: boolean;
}> = ({ instances, loading }) => {
  const { t } = useTranslation();

  const donutOptions = useDonutOptionsQcResults();
  const timeClusterOptions = useTimeClusterOptionsQcResults();

  return (
    <>
      {loading && (
        <LoadingOverlay>
          <Loader />
        </LoadingOverlay>
      )}
      {instances && instances.length > 0 && (
        <Box p={2} sx={{ flexGrow: 'inherit' }}>
          <ChartsWrapper columns={3} rows={2}>
            <ValueChart
              afterValue={t(`${t('Snags')} | ${t('Forms')}`)}
              chartKey="qc-instances-count-top-left"
              columnSpan={1}
              rowSpan={1}
              title={`${t('Amount of results')}`}
              value={`${instances
                .filter(
                  (instance) =>
                    instance.type === QualityControlInstanceType.Snag ||
                    instance.type === QualityControlInstanceType.FreeSnag
                )
                .length.toString()} | ${instances
                .filter(
                  (instance) =>
                    instance.type === QualityControlInstanceType.Form
                )
                .length.toString()}`}
            ></ValueChart>
            <DonutChart
              chartKey="qc-instances-donut-top-left"
              columnSpan={1}
              data={instances}
              initialOptionId="state"
              options={donutOptions}
              rowSpan={1}
            ></DonutChart>
            <DonutChart
              chartKey="qc-instances-donut-top-right"
              columnSpan={1}
              data={instances}
              initialOptionId="visualContext"
              options={donutOptions}
              rowSpan={1}
            ></DonutChart>
            <BarChart
              chartKey="document-tasks-bar-bottom"
              columnSpan={2}
              data={instances}
              initialTimeClusterOptionId="completedAt"
              initialTimeOptionId="month"
              rowSpan={1}
              timeClusterOptions={timeClusterOptions}
            />
          </ChartsWrapper>
        </Box>
      )}
    </>
  );
};
