import dayjs from 'dayjs';

import { Task } from '@pro4all/graphql';
import { getFormattedDate } from '@pro4all/shared/ui/timestamp';

export const isOverdue = (task: Task) => {
  let late = false;
  let done = false;

  if (task.status) done = task.status === 'Done';
  if (task.endTime) {
    const { dayJsDate } = getFormattedDate(task.endTime);
    late = dayJsDate.isBefore(dayjs());
  }
  return late && !done;
};

export const isUpcoming = (task: Task) => {
  const today = dayjs();
  let upcoming = false;
  let done = false;

  if (task.status) done = task.status === 'Done';
  if (task.endTime) {
    const differenceInDays = Math.abs(today.diff(dayjs(task.endTime), 'day'));
    upcoming = differenceInDays <= 7;
  }
  return upcoming && !done;
};
