import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import {
  FieldDefinition,
  FormLinkedSnag,
  QualityControlInstance,
} from '@pro4all/graphql';
import { useQualityControlContext } from '@pro4all/quality-control/context';
import { PhotoContext } from '@pro4all/shared/contexts';
import { useValidation } from '@pro4all/shared/forms';
import { useAnswerEditContext } from '@pro4all/shared/qc-sources/answer-edit-context';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikForm } from '@pro4all/shared/ui/formik';
import { useDiscardModalContext } from '@pro4all/shared/ui/messages';
import { DiscardWrapper } from '@pro4all/shared/ui/messages';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { fieldDefinitionToFormFieldConfig } from './fieldDefinitionToFormFieldConfig';
import { SectionsAndFields } from './SectionsAndFields';
import { useDisableSubmit } from './useDisableSubmit';
import { useInitialValues } from './useInitialValues';
import { useSubmit } from './useSubmit';

interface Props {
  edit: boolean;
  items: FieldDefinition[];
  linkedSnags?: FormLinkedSnag[];
  onClose: () => void;
  onSuccessfulSubmit: () => void;
  refetchQualityInstance: () => void;
  result: QualityControlInstance;
}

export const ResultForm: React.FC<Props> = ({
  edit,
  items,
  linkedSnags,
  onClose,
  onSuccessfulSubmit,
  refetchQualityInstance,
  result,
}) => {
  const { t } = useTranslation();

  const { setIsValid } = useDiscardModalContext();

  const { getInitialValues } = useInitialValues();
  const initialValues = getInitialValues(items);

  const { searchParams } = useRouting();
  const routeApplySavedAnswers = searchParams.is('applySavedAnswers', 'true');
  const routeCreateSavedAnswers = searchParams.is('createSavedAnswers', 'true');

  const { updateMarkerObj, setUpdateMarkerObj } = useQualityControlContext();

  const onSubmit = useSubmit({
    initialValues,
    items,
    onSuccessfulSubmit,
    refetchQualityInstance,
    result,
    updateMarkerObj,
  });

  const disableSubmit = useDisableSubmit({ items });

  const validationSchema = useValidation({
    formFields: (items || []).map(fieldDefinitionToFormFieldConfig),
  });
  const { setLinkedTaskId } = useAnswerEditContext();
  const { resetPhotos } = useContext(PhotoContext);

  useEffect(() => {
    setLinkedTaskId(result?.task?.id ?? '');
  }, [result, setLinkedTaskId]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, helpers) => {
        helpers.resetForm({ values });
      }}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting, values, resetForm }) => {
        const noOfErrors = errors ? Object.keys(errors).length : 0;
        setIsValid(noOfErrors > 0 ? false : true); // To manage the `Save` button in the DiscardModal to be enabled or disabled.

        return (
          <DiscardWrapper
            dirty={
              !routeApplySavedAnswers && !routeCreateSavedAnswers && edit
                ? dirty
                : false
            }
            onSubmit={() => onSubmit({ values })}
            values={values}
          >
            <FormikForm>
              <FormWrapper noPadding>
                <SectionsAndFields
                  edit={edit}
                  formType={result?.type ?? ''}
                  items={items}
                  linkedSnags={linkedSnags}
                  onSubmit={(keepFormOpen?: boolean) =>
                    onSubmit({ keepFormOpen, values })
                  }
                  refetchQualityInstance={refetchQualityInstance}
                />
              </FormWrapper>
              {edit && (
                <FormFooter
                  cancelLabel={t('Cancel')}
                  disableSubmit={disableSubmit({
                    dirty,
                    errors,
                    formValues: values,
                    isSubmitting,
                  })}
                  onClose={() => {
                    onClose();
                    setUpdateMarkerObj({
                      instanceId: '',
                      page: result?.page ?? 1,
                      visualContextId: '',
                      x: 0,
                      y: 0,
                    });
                  }}
                  onReset={() => {
                    resetPhotos();
                  }}
                  onSubmit={() => {
                    onSubmit({ values });
                    resetForm({ values });
                  }}
                  onSubmitWithoutClose={() => {
                    onSubmit({ keepFormOpen: true, values });
                    resetForm({ values });
                  }}
                  pb={3}
                  showSubmitWithoutClose
                  sticky
                  submitLabel="Save and close"
                />
              )}
            </FormikForm>
          </DiscardWrapper>
        );
      }}
    </Formik>
  );
};
