import { QualityControlInstance } from '@pro4all/graphql';
import { FieldDefinition } from '@pro4all/graphql';
import { useTranslateTbq } from '@pro4all/shared/qc-meta';
import { TranslateInstancesProps } from '@pro4all/shared/types';

const translateInstances = (
  instances: QualityControlInstance[],
  translateTbq: (value: string) => string
): QualityControlInstance[] => {
  const processItem = (item: FieldDefinition): void => {
    if (item.type !== 'Section') {
      if (item.value) {
        item.value = translateTbq(item.value);
      }
    } else if (item?.valueType?.subFields) {
      item.valueType.subFields.forEach(processItem);
    }
  };

  instances.forEach((instance) => {
    if (instance.items) instance.items.forEach(processItem);
    instance.answers = instance.answers?.map((a) => ({
      ...a,
      value: translateTbq(a.value ?? ''),
    }));
  });

  return instances;
};

export const useTranslateInstances = ({
  instances,
}: TranslateInstancesProps) => {
  const { translateTbq } = useTranslateTbq();
  const finalInstances = translateInstances(instances, translateTbq);

  return { instances: finalInstances };
};
