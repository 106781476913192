import React from 'react';
import { Form, useFormikContext } from 'formik';
import styled from 'styled-components';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikInput } from '@pro4all/shared/ui/formik';

import * as Styled from './Keyword.styles';
import { KeywordFormValues } from './types';
import { useKeywordModalConfig } from './useKeywordModalConfig';

interface Props {
  onClose: () => void;
}

export const KeywordForm: React.FC<Props> = ({ onClose }) => {
  const { dirty, errors, isSubmitting } = useFormikContext<KeywordFormValues>();

  const { getField } = useKeywordModalConfig();
  // For now it is decided to visually hide the score. I leave the code commented out in case we want to show it in the future.
  // const scoreField = getField('score');
  const textField = getField('text');

  return (
    <StyledForm>
      <Styled.KeywordWrapper>
        <Styled.TextWrapper>
          <FormikInput autoFocus name={textField?.name} />
        </Styled.TextWrapper>
        {
          // For now it is decided to visually hide the score. I leave the code commented out in case we want to show it in the future.
          /* <Styled.ScoreWrapper>
          <FormikInput name={scoreField?.name} type="number" />
        </Styled.ScoreWrapper> */
        }
      </Styled.KeywordWrapper>

      <FormFooter
        disableSubmit={isSubmitDisabled({
          dirty,
          errors,
          isSubmitting,
        })}
        onClose={onClose}
        pt={2}
        sticky
      />
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;
