import {
  EmailActions,
  gqlType,
  Group,
  Member,
  useGroupsAndUsersIncludeQuery,
  User,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useRouting } from '@pro4all/shared/routing-utils';

import { MessageFormFields } from '../message-branch/types';

import { useOptimisticDraftMessageUpdate } from './apollo-cache/useOptimisticUpdateDraftMessage';
import {
  getAddMessageConfig,
  getUpdateMessageConfig,
} from './utils/getMessagesConfig';
import { getReferences } from './utils/getReferences';
import { referenceMapper } from './utils/referenceMapper';
import { useOptimisticAddMessage } from './apollo-cache';

/* Todo: add mention to reference once BE supports it */

// TODO: Allow type emails to go through here

export type SubmitArgs = {
  createdAt?: Date;
  draft?: boolean;
  onError: () => void;
  onSuccess: (id: string) => void;
  values: MessageFormFields;
};

interface Props {
  action?: EmailActions;
  messageId?: string;
  parentId?: string;
  threadId?: string;
}

export const useSubmitMessage = ({
  messageId = 'new',
  threadId,
  action,
  parentId: _parentId,
}: Props) => {
  const { userDisplayName, userEmail, userFirstName, userLastName, userId } =
    useOrganizationContext();

  const { params, searchParams } = useRouting();
  const { projectId } = params;
  const replyId = searchParams.get('replyId');

  const { data } = useGroupsAndUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      includeActive: true,
      includeEmail: true,
      includeIsAdmin: true,
      projectId,
    },
  });
  const members = (data?.groupsAndUsers as Member[]) || [];

  const users = members.filter(
    (member) => member && gqlType('User')(member)
  ) as User[];

  const groups = members.filter(
    (member) => member && gqlType('Group')(member)
  ) as Group[];

  const [addMessage] = useOptimisticAddMessage({ messageId });
  const [updateDraftMessage] = useOptimisticDraftMessageUpdate({ messageId });

  return async ({ onSuccess, onError, values, draft }: SubmitArgs) => {
    const {
      body: _body,
      subject: _subject,
      attachments,
      to,
      bcc,
      cc,
      // mentions,
    } = values;

    const { _To, _Cc, _Bcc, To, Cc, Bcc } = referenceMapper(to, cc, bcc);

    const references = getReferences(users, groups, { Bcc, Cc, To });

    // @TODO: When FE needs to process the mentions for BE we need to add it here
    // Get mentions from form values
    // const Mentions = mentions?.map((mention) => mention.email) || [];

    const body = _body || '';
    const subject = _subject || '';

    const user: User = {
      displayName: userDisplayName,
      email: userEmail,
      firstName: userFirstName,
      id: userId,
      isAdmin: false,
      lastName: userLastName,
      organization: null,
    };

    if (action === EmailActions.DraftEdit) {
      const updateMessageConfig = getUpdateMessageConfig({
        _Bcc,
        _Cc,
        _To,
        attachments: attachments || [],
        body,
        draft: Boolean(draft),
        messageId,
        parentId: _parentId,
        projectId: projectId || '',
        references,
        replyId,
        subject,
        threadId: threadId || '',
        user,
      });

      const res = await updateDraftMessage(updateMessageConfig);
      res?.data?.updateDraftMessage?.id ? onSuccess(messageId) : onError();
    } else {
      try {
        const addMessageConfig = getAddMessageConfig({
          _Bcc,
          _Cc,
          _To,
          attachments: attachments || [],
          body,
          draft: Boolean(draft),
          messageId,
          projectId: projectId || '',
          references,
          replyId,
          subject,
          threadId: threadId || '',
          user,
        });

        const res = await addMessage(addMessageConfig);
        res?.data?.addMessage?.id
          ? onSuccess(res.data.addMessage.id)
          : onError();
      } catch (e) {
        onError();
        console.error(e);
      }
    }
  };
};
