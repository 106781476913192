import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikForm, FormikInput } from '@pro4all/shared/ui/formik';
import { useDiscardModalContext } from '@pro4all/shared/ui/messages';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useSavedAnswersFormConfig } from './useSavedAnswersFormConfig';
import { useSubmit } from './useSubmit';

export const SavedAnswersForm = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const open = searchParams.get('createSavedAnswers') === 'true';

  const { getField, validationSchema } = useSavedAnswersFormConfig();
  const nameField = getField('name');
  const { setBypass } = useDiscardModalContext();

  const onClose = () => {
    setBypass(false);
    setTimeout(() => {
      searchParams.delete('createSavedAnswers');
    }, 100); // Little delay to give the bypass state time to update.
  };

  const onSubmit = useSubmit({ onClose });
  const initialValues = {
    name: '',
  };

  return (
    <FloatingModal
      data-testid="add-saved-answers"
      onClose={onClose}
      open={open}
    >
      <FloatingModal.Header>
        {t('Name your saved answers')}
      </FloatingModal.Header>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({ dirty, errors, isSubmitting }) => (
          <FormikForm>
            <FormWrapper noPadding>
              <FormikInput
                autoFocus
                label={nameField?.label}
                name={nameField?.name}
                type="text"
              />
            </FormWrapper>
            <FormFooter
              disableSubmit={isSubmitDisabled({
                dirty,
                errors,
                isSubmitting,
              })}
              onClose={onClose}
              pt={2}
              sticky
            />
          </FormikForm>
        )}
      </Formik>
    </FloatingModal>
  );
};
