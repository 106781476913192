import styled from 'styled-components';

import { Icon } from '@pro4all/shared/ui/icons';

// A container for tags when the input has a fixed height
export const TagsContainer = styled.div`
  width: auto;
  overflow-x: auto;

  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;
