import React from 'react';
import { Route } from 'react-router-dom';

import { HierarchicalList } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { HierarchicalLists } from './HierarchicalLists';

export const OrganizationHierarchicalLists = () => (
  <Route
    exact
    path={[Routes.metaDataHierarchicalLists]}
    render={() => (
      <OptimisticResponseProvider<HierarchicalList>>
        <HierarchicalLists
          pathHierarchicalLists={Routes.metaDataHierarchicalLists}
        />
      </OptimisticResponseProvider>
    )}
  />
);
