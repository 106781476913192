import React from 'react';

import { ProstreamCta } from '@pro4all/shared/assets-sharing';
import { Box } from '@pro4all/shared/mui-wrappers';
import { InfoTopBar } from '@pro4all/shared/ui/infopages';
import { MainLayout, PurpleBackground } from '@pro4all/shared/ui/layout';

import {
  PublicLinkErrorMessages,
  PubliclySharedMessagesContextProvider,
  PubliclySharedMessagesTable,
} from '../../publicly-shared-messages';

export const PubliclySharedMessages: React.FC = () => (
  <PubliclySharedMessagesContextProvider>
    <MainLayout>
      <PurpleBackground />
      <InfoTopBar />
      <Box display="flex" flex={1} p={3} pt={2}>
        <PublicLinkErrorMessages />
        <PubliclySharedMessagesTable />
      </Box>
      <ProstreamCta />
    </MainLayout>
  </PubliclySharedMessagesContextProvider>
);
