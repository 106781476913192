import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { Routes } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Dialog } from '@pro4all/shared/ui/dialog';
import { Text } from '@pro4all/shared/ui/typography';

interface ConfirmRevokeDialogProps {
  cancelRevoke: () => void;
  confirmRevoke: () => void;
}

export const ConfirmRevokeDialog: React.FC<ConfirmRevokeDialogProps> = ({
  cancelRevoke,
  confirmRevoke,
}) => {
  const { t } = useTranslation();

  const { searchParams } = useRouting();

  const revokingLink =
    Boolean(
      useRouteMatch({
        exact: true,
        path: Routes.revokePublicLink,
      })
    ) || searchParams.get('action') === 'revoke';

  return (
    <Dialog
      buttonVariant="outlined"
      confirmLabel="OK"
      iconName="warning"
      name="revokeDialog"
      onClose={cancelRevoke}
      onConfirm={confirmRevoke}
      open={revokingLink}
      title={t('Revoke link')}
    >
      <Text display="inline">
        {t(
          'Are you sure you want to revoke this link? It will no longer be accessible after you do.'
        )}
      </Text>
    </Dialog>
  );
};
