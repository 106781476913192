import { useCallback } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { DebouncedState } from 'use-debounce/lib/useDebouncedCallback';

import { useFeatureFlag } from '@pro4all/shared/feature-flags';

import { SubmitArgs } from '../../mutation-utils/useSubmitMessage';
import { MessageFormFields } from '../types';

interface UseDraftHandlersParams {
  bodyEdited: boolean;
  editDraft: ({
    onSuccess,
    onError,
    values,
    draft,
  }: SubmitArgs) => Promise<void>;
  form: UseFormMethods<MessageFormFields>;
  handleDraftError: () => void;
  handleDraftSuccess: (messageId: string) => void;
  isDraftEdit: boolean;
  isSubmitted: boolean;
  readOnly: boolean;
  submitDraft: ({
    onSuccess,
    onError,
    values,
    draft,
  }: SubmitArgs) => Promise<void>;
}

interface UseDraftHandlersReturn {
  debouncedEditDraft: DebouncedState<() => void>;
  handleDebounceDraftSave: () => void;
  handleDraftSaveManual: () => void;
}

export const useDraftHandlers = ({
  bodyEdited,
  readOnly,
  isSubmitted,
  isDraftEdit,
  editDraft,
  submitDraft,
  form,
  handleDraftSuccess,
  handleDraftError,
}: UseDraftHandlersParams): UseDraftHandlersReturn => {
  const { isDirty, isSubmitting } = form.formState;
  const hasDraftFeature = useFeatureFlag('draft-messages');

  const debouncedEditDraft = useDebouncedCallback(() => {
    if (!isSubmitting && !readOnly && hasDraftFeature && !isSubmitted) {
      editDraft({
        draft: true,
        onError: () => {
          // No message is shown to the user
          console.error('Failed to save draft');
        },
        onSuccess: () => {
          // No action required
        },
        values: form.getValues(),
      });
    }
  }, 5000);

  const handleDraftSaveManual = useCallback(() => {
    if (
      (isDirty || bodyEdited) &&
      !isSubmitting &&
      !readOnly &&
      hasDraftFeature &&
      !isSubmitted
    ) {
      debouncedEditDraft.cancel();

      isDraftEdit
        ? editDraft({
            draft: true,
            onError: handleDraftError,
            onSuccess: handleDraftSuccess,
            values: form.getValues(),
          })
        : submitDraft({
            draft: true,
            onError: handleDraftError,
            onSuccess: handleDraftSuccess,
            values: form.getValues(),
          });
    }
  }, [
    bodyEdited,
    isSubmitting,
    readOnly,
    hasDraftFeature,
    isSubmitted,
    isDraftEdit,
    editDraft,
    submitDraft,
    form,
    debouncedEditDraft,
    handleDraftSuccess,
    handleDraftError,
  ]);

  const handleDebounceDraftSave = useCallback(() => {
    if (!isSubmitting && !readOnly && hasDraftFeature && !isSubmitted) {
      isDraftEdit && debouncedEditDraft();
    }
  }, [
    bodyEdited,
    isSubmitting,
    readOnly,
    hasDraftFeature,
    isSubmitted,
    isDraftEdit,
    debouncedEditDraft,
  ]);

  return { debouncedEditDraft, handleDebounceDraftSave, handleDraftSaveManual };
};
