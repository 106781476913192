import { useBatchFetchResults } from '@pro4all/quality-control/data-access';

import { useTranslateInstances } from './useTranslateInstances';

export const useGetReportInstances = (ids: string[]) => {
  const { instances } = useBatchFetchResults({
    fetchPolicy: 'no-cache',
    variables: {
      ids,
      includeAnswers: true,
      includeCommentStatus: true,
      includeCreatedAt: true,
      includeCreatedBy: true,
      includeDeletedAt: true,
      includeIconName: true,
      includeIndicativeState: true,
      includeItems: true,
      includeLinkedFieldId: true,
      includeLinkedSnagInstances: true,
      includeLinkedSnags: true,
      includeName: true,
      includePage: true,
      includePath: true,
      includeProject: true,
      includeProjectId: true,
      includeReference: true,
      includeTask: true,
      includeTaskId: true,
      includeTemplateIconProps: true,
      includeTemplateId: true,
      includeTemplateVersion: true,
      includeTemplates: true,
      includeType: true,
      includeVisualContext: true,
      includeVisualContextId: true,
      includeX: true,
      includeY: true,
    },
  });

  return {
    instances: useTranslateInstances({ instances }).instances,
  };
};
