import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FacetGroup,
  FacetItem,
  FilterType,
  MetaDataInstance,
  SearchDocument,
  useGroupsAndUsersIncludeQuery,
  ValueTypeName,
} from '@pro4all/graphql';
import { mdFilterColumnTypes, useFilters } from '@pro4all/search/ui';
import { metadataColumnKey } from '@pro4all/shared/config';
import { NO_VALUE } from '@pro4all/shared/constants';
import { HierarchyPayload } from '@pro4all/shared/hierarchy-editor';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { Loader } from '@pro4all/shared/ui/loader';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';
import { Tag } from '@pro4all/shared/ui/tag';
import { getFormattedDate, Timestamp } from '@pro4all/shared/ui/timestamp';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import {
  isDefined,
  toFilterType,
  toValueTypeName,
} from '@pro4all/shared/utils';

type RenderAnswerProps<GenericColumnType> = {
  document: GenericColumnType;
  facetItem: FacetItem;
  type: FilterType;
};

const RenderAnswer = <GenericColumnType,>({
  document,
  facetItem,
  type,
}: RenderAnswerProps<GenericColumnType>): JSX.Element => {
  const { params } = useRouting();
  const { t } = useTranslation();

  // Since we are fetching from cache only, we dont need to worry about performance
  const { data, loading } = useGroupsAndUsersIncludeQuery({
    fetchPolicy: 'cache-first',
    variables: {
      includeActive: true,
      includeMembers: true,
      projectId: params.projectId,
    },
  });

  const answer = getAnswer({ document, facetItem, type });

  const projectMembers = useMemo(() => data?.groupsAndUsers || [], [data]);

  const getDisplayNameById = useCallback(
    (id: string): string =>
      projectMembers?.find?.((user) => user?.id === id)?.displayName || '',
    [projectMembers]
  );

  switch (facetItem?.mdField?.valueType) {
    case ValueTypeName.DateTime:
      return <Timestamp date={answer} format="lll" />;
    case ValueTypeName.UserSelection:
      return loading ? (
        <Loader />
      ) : (
        <MiddleEllipsis endLength={9} text={getDisplayNameById(answer)} />
      );
    case ValueTypeName.HierarchyList: {
      const hierarchyList: HierarchyPayload[] =
        JSON.parse(answer || '[]') || [];

      const tagList = hierarchyList.map((item) =>
        item?.breadcrumbs
          ?.map?.((breadcrumb, index, breadcrumbArr) => {
            const isLast = index === breadcrumbArr.length - 1;
            return (
              isLast && (
                <Tooltip title={item?.breadcrumbs?.join?.(' > ')}>
                  <Tag
                    key={index}
                    name={breadcrumb || ''}
                    sx={{ margin: '0 0.2rem' }}
                  />
                </Tooltip>
              )
            );
          })
          .filter(Boolean)
      );

      return <Box>{tagList}</Box>;
    }
    default:
      return <MiddleEllipsis endLength={9} text={t(answer)} />;
  }
};

export const useMDColumns = <GenericColumnType,>(
  mdFacetGroups?: FacetGroup[]
): ColumnProps<GenericColumnType>[] => {
  const { t } = useTranslation();
  const { currentFilters } = useFilters();

  const includedGroups = mdFacetGroups?.filter((group) =>
    mdFilterColumnTypes.includes(group.type)
  );

  const uniqueFacetIds = Array.from(
    new Set(
      includedGroups?.flatMap((group) =>
        group.items?.map((item) => item?.mdField?.id).filter(isDefined)
      )
    )
  );

  const allFacetItems = includedGroups
    ? includedGroups.flatMap((group) => group.items).filter(isDefined)
    : [];

  const matchingFacetItems = uniqueFacetIds
    .map((facetId) =>
      allFacetItems.find(
        (item) =>
          item.mdField?.id === facetId &&
          currentFilters &&
          currentFilters.find((filter) => filter.metaDataKey === facetId)
      )
    )
    .filter(isDefined);

  const getFilterType = (facetItem: FacetItem) => {
    switch (facetItem?.mdField?.valueType) {
      case ValueTypeName.DateTime:
        return FilterHeaderType.Date;
      case ValueTypeName.Number:
        return FilterHeaderType.Number;
      case ValueTypeName.Text:
        return FilterHeaderType.Text;
      default:
        return FilterHeaderType.Select;
    }
  };

  const columns: ColumnProps<GenericColumnType>[] = [...matchingFacetItems]
    .map((facetItem, index) => {
      if (!facetItem?.mdField?.valueType) return null;
      const itemId = facetItem?.mdField?.id;
      const key = `${metadataColumnKey}.${itemId}.${index.toString()}`;
      const type = toFilterType(
        facetItem?.mdField?.valueType?.toString() || ''
      );
      const columnIncluded = currentFilters?.some(
        (filter) => filter.type === type
      );

      return columnIncluded
        ? {
            filterable: false,
            getValue: (document: GenericColumnType) => {
              const answer = getAnswer({ document, facetItem, type });
              if (facetItem?.mdField?.valueType === ValueTypeName.Bool)
                return answer ? t(answer) : '';
              if (facetItem?.mdField?.valueType === ValueTypeName.DateTime)
                return answer ? getFormattedDate(answer).label : '';
              return answer;
            },
            headerComponent: (
              <FilterHeader<SearchDocument, MetaDataInstance>
                defaultWidth={200}
                filterType={getFilterType(facetItem)}
                getCustomValueCallback={(item: MetaDataInstance) =>
                  item?.questions?.find((question) => {
                    if (!question) return null;
                    const { id, displayName } = question;
                    const generatedId = `${id}_${displayName}`;
                    return generatedId === itemId;
                  })?.answer || NO_VALUE
                }
                label={facetItem?.name}
                minWidth={120}
                propertyId="metaDataInstance"
                showFilterIcon={false}
              />
            ),
            key: key,
            label: facetItem?.name,
            render: (document: GenericColumnType) => (
              <RenderAnswer<GenericColumnType>
                document={document}
                facetItem={facetItem}
                type={type}
              />
            ),
            width: 200,
          }
        : null;
    })
    .filter(isDefined);

  return columns;
};

const getAnswer = <GenericColumnType,>({
  document,
  facetItem,
  type,
}: RenderAnswerProps<GenericColumnType>) => {
  if (!facetItem?.mdField?.valueType) return '';

  const { name } = facetItem;

  const question = getQuestion<GenericColumnType>({
    doc: document as GenericColumnType & {
      metaDataInstance: MetaDataInstance;
    },
    name,
    type,
  });

  return question?.answer || '';
};

export const getQuestion = <GenericColumnType,>({
  doc,
  name,
  type,
}: {
  doc: GenericColumnType & { metaDataInstance: MetaDataInstance };
  name: string;
  type: FilterType;
}) =>
  doc?.metaDataInstance?.questions?.find?.(
    (question) =>
      question?.valueType === toValueTypeName(type) &&
      question.displayName === name
  );
