import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  MailStyle,
  OrganizationUsersIncludeDocument,
  User,
  useReInviteUsersMutation,
  UserState,
} from '@pro4all/graphql';
import { Application, useApplication } from '@pro4all/shared/hooks';
import { useShowMessages } from '@pro4all/shared/ui/messages';
import {
  useOptimisticResponseContext,
  useTableCheck,
  useTableContextMenuRowContext,
} from '@pro4all/shared/ui/table';

import { organizationUsersIncludeProps } from '../organization/getIncludeProps';

export const useResendEmail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const application = useApplication();
  const [reInviteUsers] = useReInviteUsersMutation();
  const { showSingleError } = useShowMessages();

  const { checkedRows, uncheckAllRows } = useTableCheck<User>();
  const { contextMenuRow } = useTableContextMenuRowContext<User>();

  const {
    state: { items },
  } = useOptimisticResponseContext<User>();

  const resend = async () => {
    try {
      const userIds = contextMenuRow
        ? [contextMenuRow.id]
        : checkedRows.map((user) => user.id);

      await reInviteUsers({
        variables: {
          requestIds: userIds,
          style:
            application === Application.pro4all
              ? MailStyle.Prostream
              : MailStyle.Tbq,
        },
      });

      const usersUpdated = items
        .filter((item) => userIds.includes(item.id))
        .map((item) => ({
          ...item,
          state: UserState.InvitationSent,
        }));

      const itemsRest = items.filter((item) => !userIds.includes(item.id));

      client.writeQuery({
        data: {
          organizationUsers: [...itemsRest, ...usersUpdated],
        },
        query: OrganizationUsersIncludeDocument,
        variables: {
          ...organizationUsersIncludeProps,
        },
      });

      uncheckAllRows();

      enqueueSnackbar(t('Invitations were sent again.'));
    } catch (e) {
      showSingleError(e);
    }
  };
  return resend;
};
