import type { Key } from 'react';

import { createUseState } from '@pro4all/shared/hooks';

import type { UseToggleProviderOptions } from '@pro4all/shared/ui/toggle';
import { useToggleProvider } from '@pro4all/shared/ui/toggle';

interface UseTableRowToggleArgs
  extends Pick<
    UseToggleProviderOptions<string>,
    'filter' | 'initial' | 'onToggle'
  > {
  key: Key;
}

export function useTableRowToggle({ key, ...rest }: UseTableRowToggleArgs) {
  const useState = createUseState<string[]>(key.toString(16));
  return useToggleProvider<string>({ ...rest, useState });
}
