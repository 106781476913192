import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, useFormikContext } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Alert } from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import { Backdrop } from '@pro4all/shared/ui/backdrop';
import { FormFooter } from '@pro4all/shared/ui/form';
import {
  FormikForm,
  FormikSearchableMultiSelect,
  FormikTextarea,
} from '@pro4all/shared/ui/formik';
import { InfoBlock } from '@pro4all/shared/ui/info-block';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';
import { useDelayedFormikUpdate } from '@pro4all/shared/utils';
import { pasteHandlerEmails } from '@pro4all/shared/utils/email';

import { InviteExternalsFormValues } from '../../user-management/components/types';
import { useSubmitExistingUsers } from '../utils/useSubmitExistingUsers';

import { useInviteExternalUsersFormConfig } from './useInviteExternalUsersFormConfig';

interface Props {
  onClose: () => void;
}

export const InviteExistingUsersForm: React.FC<Props> = ({ onClose }) => {
  const { validationSchema } = useInviteExternalUsersFormConfig();
  const [showBackdrop, setShowBackdrop] = useState(false);

  const onSubmit = useSubmitExistingUsers({ onClose, setShowBackdrop });

  return (
    <Formik
      initialValues={{
        admins: [],
        message: '',
        users: [],
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {() => (
        <InviteExistingUsersFormInputs
          onClose={onClose}
          showBackdrop={showBackdrop}
        />
      )}
    </Formik>
  );
};

export const InviteExistingUsersFormInputs = ({
  onClose,
  showBackdrop,
}: {
  onClose: () => void;
  showBackdrop: boolean;
}) => {
  const { t } = useTranslation();

  const { dirty, errors, isSubmitting } =
    useFormikContext<InviteExternalsFormValues>();

  const [inputField, setInputField] = useState<string>('');

  const { currentValue, setNextValue } =
    useDelayedFormikUpdate<InviteExternalsFormValues>(inputField);

  const { getField } = useInviteExternalUsersFormConfig();
  const usersField = getField('users');
  const adminsField = getField('admins');
  const messageField = getField('message');

  return (
    <>
      {showBackdrop && <Backdrop />}
      <FormikForm>
        <FormWrapper>
          <InfoBlock
            paragraph={t(
              'You are about to ask existing users in different Prostream organizations to leave theirs and join yours. None existing users will also be invited but as new users.'
            )}
          />
          <FormikSearchableMultiSelect
            autoFocus
            canAddNewOptions
            label={usersField.label}
            limitTags={5}
            name={usersField.name}
            onFocus={() => setInputField(usersField.name)}
            onPaste={(event) =>
              pasteHandlerEmails({
                currentValues: currentValue as unknown as Option[],
                event,
                updateValuesCallback: setNextValue,
              })
            }
            options={[]}
            placeholder={t('Enter email addresses')}
          />
          <FormikSearchableMultiSelect
            canAddNewOptions
            label={adminsField.label}
            limitTags={5}
            name={adminsField.name}
            onFocus={() => setInputField(adminsField.name)}
            onPaste={(event) =>
              pasteHandlerEmails({
                currentValues: currentValue as unknown as Option[],
                event,
                updateValuesCallback: setNextValue,
              })
            }
            options={[]}
            placeholder={t('Enter email addresses')}
          />
          <FormikTextarea
            id={messageField.name}
            label={messageField.label}
            name={messageField.name}
            rows={4}
          />
          <Alert severity="warning" variant="filled">
            {t(
              'Be aware that after sending the invitation, the invited users are able to join your organization. Be sure that you only invite users that you really want to grant access.'
            )}
          </Alert>
        </FormWrapper>

        <FormFooter
          disableSubmit={isSubmitDisabled({
            dirty,
            errors,
            isSubmitting,
          })}
          onClose={onClose}
          pb={3}
          pt={2}
          px={3}
          sticky
        />
      </FormikForm>
    </>
  );
};
