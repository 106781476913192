import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from '@pro4all/shared/ui/dialog';
import { Text } from '@pro4all/shared/ui/typography';

interface ConfirmationDialogProps {
  bodyKey: string;
  confirm: () => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  titleKey: string;
}

export const MessageConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  confirm,
  open,
  setOpen,
  titleKey,
  bodyKey,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      buttonVariant="outlined"
      iconName="warning"
      name="confirmationDialog"
      onClose={() => setOpen(false)}
      onConfirm={confirm}
      open={open}
      title={t(titleKey)}
    >
      <Text display="inline">{t(bodyKey)}</Text>
    </Dialog>
  );
};
