import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';

export const useGetUsersAction = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const getShowUsersAction = useCallback(
    ({ id, totalUsers }: { id: string; totalUsers: number }) => {
      const showUserAction: ActionProps = {
        ariaLabel: t('Show users'),
        dataTestId: 'show-users',
        disabled: !totalUsers,
        key: 'show-users',
        label: t('Show users'),
        onClick: (event) => {
          event.stopPropagation();
          searchParams.set({ showUsersFromGroup: id });
        },
        startIcon: 'info',
      };
      return showUserAction;
    },
    []
  );

  return { getShowUsersAction };
};
