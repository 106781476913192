import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentVersion } from '@pro4all/graphql';
import { useShareLinkParams } from '@pro4all/shared/assets-sharing';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { Column, Main } from '@pro4all/shared/ui/layout';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/table';
import { Text } from '@pro4all/shared/ui/typography';

import { PasswordPrompt } from '../feedback/PasswordPrompt';
import { DocumentsActionBar } from '../table/DocumentsActionBar';
import { useColumns } from '../table/useColumns';

import { usePubliclySharedDocsContext } from './PubliclySharedDocsContext';

export const PubliclySharedDocsTable: React.FC = () => {
  const { t } = useTranslation();
  const { passwordProtected } = useShareLinkParams();
  const { data, invalidPasswordError, notFoundError, savedPassword, loading } =
    usePubliclySharedDocsContext();

  const { searchParams } = useRouting();
  const revoked = searchParams.get('revoked');

  const { data: publicLinkData } = usePubliclySharedDocsContext();

  const showPasswordPrompt =
    (passwordProtected && !savedPassword && !notFoundError) ||
    invalidPasswordError;

  const documentVersions = useMemo(() => {
    const documents: DocumentVersion[] = [
      ...(data?.documentVersions ?? []),
      ...(data?.latestDocumentVersions ?? []),
    ];
    return (!showPasswordPrompt && documents) || [];
  }, [data, showPasswordPrompt]);

  useSetItemsInLocalState<DocumentVersion>(documentVersions);

  const {
    state: { items },
  } = useOptimisticResponseContext<DocumentVersion>();

  const { nameColumn, pathColumn } = useColumns({
    canNavigateToFolder: false,
  });

  const info = data?.latestDocumentVersions?.length
    ? 'Shared documents will automatically update when there is a new version.'
    : 'Only the version at the time of link creation is shared with you.';
  const translatedInfo = t(info);

  if ((!data && !passwordProtected) || revoked === 'true') return null;

  const showFolders = publicLinkData?.showFolders || false;

  return (
    <Main>
      <Column>
        {!showPasswordPrompt && !loading && (
          <Box ml={3} mt={2}>
            <Text align="left" variant="subtitle2">
              {translatedInfo}
            </Text>
          </Box>
        )}
        <TableContextProvider
          checkable={false}
          columns={showFolders ? [nameColumn, pathColumn] : [nameColumn]}
          id="table-publicly-shared-documents"
          items={items}
        >
          <FilterContextProvider<DocumentVersion>>
            {!showPasswordPrompt && !loading && (
              <>
                <DocumentsActionBar />
                <Table<DocumentVersion> headerHeight={40} />
              </>
            )}
            {showPasswordPrompt && <PasswordPrompt />}
          </FilterContextProvider>
        </TableContextProvider>
      </Column>
    </Main>
  );
};
