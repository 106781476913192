import { ApolloCache } from '@apollo/client';

import {
  Message,
  MessageBranch,
  MessageBranchDocument,
  MessageInboxDocument,
  Reference,
  SentOrInbox,
  UnreadCountDocument,
} from '@pro4all/graphql';

import { CountQueryType } from './useOptimisticMarkMessage';

interface OptimisticUpdateOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: ApolloCache<any>;
  data: Message;
  messageId: string;
  optimisticTables: SentOrInbox[];
  organizationId: string;
  projectId: string | undefined;
  replyId?: string | null;
  updateCounter?: boolean;
  userDisplayName: string;
}

export const handleOptimisticUpdate = ({
  cache,
  data,
  organizationId,
  projectId,
  userDisplayName,
  messageId,
  replyId,
  optimisticTables,
  updateCounter = false,
}: OptimisticUpdateOptions) => {
  optimisticTables.forEach((type) => {
    const inboxQuery: { messageInbox: Message[] } | null = cache.readQuery({
      query: MessageInboxDocument,
      variables: { organizationId, projectId, sentOrInbox: type },
    });

    const includeRefId = (ref: Reference): Reference => ({
      ...ref,
      referenceData: null,
      referenceId: null,
    });

    const references = data.references?.map(includeRefId) || [];

    const optimisticMessage: Message = {
      ...data,
      bcc: [],
      cc: [],
      createdAt: new Date().toISOString(),
      createdBy: {
        ...data.createdBy,
        isAdmin: data.createdBy.isAdmin || false,
        organization: data.createdBy.organization,
      },
      read: false,
      references,
      subject: data.subject || '',
      to: [],
    };

    const filteredMessages = inboxQuery?.messageInbox?.filter(
      (message) => message.id !== data.id
    );

    const newMessageInbox = [optimisticMessage, ...(filteredMessages || [])];

    cache.writeQuery({
      data: {
        messageInbox: newMessageInbox,
      },
      query: MessageInboxDocument,
      variables: { organizationId, projectId, sentOrInbox: type },
    });

    const existingBranch: { messageBranch: MessageBranch } | null =
      cache.readQuery({
        query: MessageBranchDocument,
        variables: { authorName: userDisplayName, messageId, projectId },
      });

    if (replyId) {
      const original: Message | null =
        inboxQuery?.messageInbox?.find((message) => message.id === replyId) ||
        null;

      const replyBranch: MessageBranch = {
        id: existingBranch?.messageBranch.id || 'new',
        main: optimisticMessage,
        original,
        previous: null,
        replyAll: null,
        replyId: null,
      };

      cache.writeQuery({
        data: { messageBranch: replyBranch },
        query: MessageBranchDocument,
        variables: { authorName: userDisplayName, messageId, projectId },
      });
    } else {
      const newMessageBranch: MessageBranch = {
        id: 'new',
        main: optimisticMessage,
        original: null,
        previous: null,
        replyAll: null,
        replyId: null,
      };

      cache.writeQuery({
        data: { messageBranch: newMessageBranch },
        query: MessageBranchDocument,
        variables: { authorName: userDisplayName, messageId, projectId },
      });
    }

    // Update sidebar draft count
    updateCounter && updateDraftCount({ cache, organizationId, projectId });
  });
};

const calculateDraftCountNumber = ({
  numberOfMessages,
  currentCount,
}: {
  currentCount: number;
  numberOfMessages: number;
}) => currentCount + numberOfMessages;

const updateDraftCount = (
  params: Pick<
    OptimisticUpdateOptions,
    'cache' | 'projectId' | 'organizationId'
  >
) => {
  const { cache, projectId, organizationId } = params;
  const countQueryVariables = {
    isOrganization: !projectId ? true : false,
  };
  const countQueryArray: {
    unreadCount: CountQueryType[];
  } | null = cache.readQuery({
    optimistic: true,
    query: UnreadCountDocument,
    variables: countQueryVariables,
  });

  const projectOrOrganizationId = projectId || organizationId;

  const countQuery = countQueryArray?.unreadCount?.find?.(
    (item) => item.id === projectOrOrganizationId
  );

  // Count the number of messages in draft

  const modifiedUnreadCount = countQuery
    ? countQueryArray?.unreadCount?.map?.((item) => {
        if (item.id !== projectOrOrganizationId) return item;
        return {
          all: item.all,
          draft: calculateDraftCountNumber({
            currentCount: countQuery.draft,
            numberOfMessages: 1,
          }),
          id: projectOrOrganizationId,
          inbox: item.inbox,
          sent: item.sent,
        };
      })
    : (countQueryArray?.unreadCount || []).concat({
        all: 0,
        draft: 1,
        id: projectOrOrganizationId,
        inbox: 0,
        sent: 0,
      });

  cache.writeQuery({
    data: {
      unreadCount: modifiedUnreadCount,
    },
    query: UnreadCountDocument,
    variables: countQueryVariables,
  });
};
