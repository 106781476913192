import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';
import { Lightbox } from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Inline from 'yet-another-react-lightbox/plugins/inline';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import { Action } from '@pro4all/shared/config';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { Option } from '@pro4all/shared/types';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';
import { useAnalytics } from '@pro4all/shared/vendor';

import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';

import { calculatePreviewDimensions } from './ImagePreview.helpers';
import * as Styled from './ImagePreview.styles';
import {
  CurrentSlide,
  ImagePreviewProps,
  PreviewDimensions,
} from './ImagePreview.types';
import { ImagePreviewViewModes } from './ImagePreviewViewOptions';
import { useLightBoxConfig } from './LightboxConfig';
import { SphereViewerStylesheet } from './SphereViewer.styles';

export const ImagePreview: React.FC<ImagePreviewProps> = ({
  open = false,
  onClose,
  totalPreviewPages = 0,
  previewImages = [],
  sphereModeEnabled = false,
  slidesLoaded = false,
  title = 'Preview',
}) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const [previewDimensions, setPreviewDimensions] = useState<PreviewDimensions>(
    calculatePreviewDimensions()
  );

  const isMobileScreen = useIsMobileScreen();

  const [currentSlide, setCurrentSlide] = useState<CurrentSlide>({ index: 0 });
  const [viewMode, setViewMode] = useState<string>('normal');

  const LIGHTBOX_CONFIG = useLightBoxConfig(viewMode);

  const imagePreviewViewModes = ImagePreviewViewModes.map((opt) => ({
    id: opt.id,
    label: t(opt.label),
  }));

  const getLightBoxPlugins = () => {
    let plugins = [Inline];
    if (viewMode === 'normal') {
      plugins = [...plugins, ...[Fullscreen, Zoom]];
      if (totalPreviewPages > 1) plugins = [...plugins, ...[Thumbnails]];
    }
    return plugins;
  };

  const handleViewModeChange = (value: Option) => {
    track(Action.DocumentPreviewModeChange, {
      viewMode: value.id,
    });
    setViewMode(value.id);
  };

  useEffect(() => {
    const handleResize = () => {
      setPreviewDimensions(calculatePreviewDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <style>{open ? LIGHTBOX_CONFIG.styles : ''}</style>
      <FloatingModal
        data-testid="preview-document"
        fullScreen
        maxWidth="md"
        onClose={onClose}
        open={open}
        sx={{
          borderRadius: isMobileScreen ? '0' : 'initial',
        }}
      >
        <FloatingModal.Header>
          <MiddleEllipsis
            endLength={8}
            text={
              `${title}` +
              ` ${
                totalPreviewPages > 1
                  ? [
                      '[',
                      currentSlide.index + 1,
                      '/',
                      totalPreviewPages,
                      ']',
                    ].join('')
                  : ''
              }`
            }
          />

          {sphereModeEnabled && (
            <Styled.ViewmodeSelect
              name="viewModeSelect"
              onChange={handleViewModeChange}
              options={imagePreviewViewModes}
              value={viewMode}
            />
          )}
        </FloatingModal.Header>
        <Styled.FloatingModalContainer>
          {!slidesLoaded && (
            <Styled.Loader
              value={100 * (previewImages.length / totalPreviewPages)}
              variant="determinate"
            />
          )}
          <Lightbox
            animation={LIGHTBOX_CONFIG.animation}
            carousel={LIGHTBOX_CONFIG.carousel}
            index={currentSlide.index}
            inline={{
              style: {
                aspectRatio: `${previewDimensions.width}/${previewDimensions.height}`,
                background: 'white',
                height: '100%',
                width: '100%',
              },
            }}
            on={{ view: setCurrentSlide }}
            plugins={getLightBoxPlugins()}
            render={{
              buttonNext: previewImages.length <= 1 ? () => null : undefined,
              buttonPrev: previewImages.length <= 1 ? () => null : undefined,
              slide:
                viewMode === 'normal'
                  ? undefined
                  : ({ slide }) => (
                      <ReactPhotoSphereViewer
                        container="div"
                        height="100%"
                        keyboard={false}
                        navbar={['zoomIn', 'zoomOut', 'fullscreen']}
                        requestHeaders={{
                          'sec-fetch-mode': 'no-cors',
                        }}
                        src={slide.src}
                        width="100%"
                        zoomSpeed={5}
                      ></ReactPhotoSphereViewer>
                    ),
            }}
            slides={previewImages}
            thumbnails={LIGHTBOX_CONFIG.thumbnails}
            zoom={LIGHTBOX_CONFIG.zoom}
          />
        </Styled.FloatingModalContainer>
        <style>{SphereViewerStylesheet}</style>
      </FloatingModal>
    </>
  );
};
