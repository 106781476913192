import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ValueTypeName } from '@pro4all/graphql';
import { getMultiValue } from '@pro4all/shared/qc-meta';
import { DisplayType, List } from '@pro4all/shared/ui/list';
import { Tag } from '@pro4all/shared/ui/tag';
import { Text } from '@pro4all/shared/ui/typography';

import type { AnswerComponent } from '../AnswerView';

export const Selection: AnswerComponent = ({
  children,
  item,
  tagHasMargin,
}) => {
  const { t } = useTranslation();

  if (!children) return null;

  // Boolean
  if (item.type === ValueTypeName.Bool) {
    return (
      <Text variant="body1">
        {children === 'true' || children === 'Yes'
          ? t('Yes')
          : children === 'false' || children === 'No'
          ? t('No')
          : ''}
      </Text>
    );
  }

  // Single select
  if (!item?.valueType?.multiSelect) {
    return <Tag key={children} name={children} />;
  }

  // Multi select
  if (item?.valueType?.multiSelect) {
    const multiValue = getMultiValue(children);
    return children ? (
      <ListNoFlex
        direction="horizontal"
        displayType={DisplayType.Counter}
        marginRight={5}
      >
        {multiValue.map((child) => (
          <TagWithSpacing $hasMargin={tagHasMargin} key={child} name={child} />
        ))}
      </ListNoFlex>
    ) : null;
  } else {
    // Should not be possible, but just in case.
    return null;
  }
};

const ListNoFlex = styled(List)`
  && {
    display: block;
  }
`;

const TagWithSpacing = styled(Tag)<{ $hasMargin: boolean }>`
  && {
    margin-top: ${({ $hasMargin }) => ($hasMargin ? '6px' : '0px')};
  }
`;
