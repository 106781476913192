import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useFormikContext } from 'formik';
import styled from 'styled-components';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Option } from '@pro4all/shared/types';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikSearchableMultiSelect } from '@pro4all/shared/ui/formik';
import { useDelayedFormikUpdate } from '@pro4all/shared/utils';
import { pasteHandlerEmails } from '@pro4all/shared/utils/email';

import { useGenerateLinkContext } from '../GenerateLinkContext';
import { ModalActionsContainer } from '../modal/styles';

import { CopyToSelfCheckbox } from './CopyToSelfCheckbox';
import { useSendLinkByEmailFormConfig } from './useSendLinkByEmailFormConfig';

export interface EmailFormValues {
  to: Option[];
}

export const SendLinkByEmailForm: React.FC = () => {
  const { t } = useTranslation();
  const { openShareModal } = useGenerateLinkContext();
  const { setNextValue } = useDelayedFormikUpdate<EmailFormValues>('to');

  const { dirty, errors, isSubmitting, values } =
    useFormikContext<EmailFormValues>();

  const { getField } = useSendLinkByEmailFormConfig();
  const toField = getField('to');

  return (
    <StyledForm>
      <FormikSearchableMultiSelect
        canAddNewOptions
        label={toField.label}
        name={toField.name}
        onPaste={(event) =>
          pasteHandlerEmails({
            currentValues: values.to,
            event,
            updateValuesCallback: setNextValue,
          })
        }
        options={[]}
      />
      <ModalActionsContainer>
        <CopyToSelfCheckbox />
        <FormFooter
          cancelLabel="Go Back"
          disableSubmit={
            isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            }) || !values?.to?.length
          }
          onClose={openShareModal} // Back to previous modal
          pt={2}
          submitLabel={t('Send and close')}
        />
      </ModalActionsContainer>
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
