import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { QualityControlInstance, ValueTypeName } from '@pro4all/graphql';
import { showField } from '@pro4all/metadata/ui/utils';
import {
  InstancePageSectionProps,
  TemplateOptions,
} from '@pro4all/shared/types';

import { applyBackground, applyColor } from '../Colors';

import { useIsEmptySection } from './helpers/useIsEmptySection';
import { FieldDescription } from './reportFields/FieldDescription';
import LinkedSnagsList from './reportFields/LinkedSnagsList';
import ReportDescriptionField from './reportFields/ReportDescriptionField';
import ReportHierarchyField from './reportFields/ReportHierarchyField';
import ReportMediaField from './reportFields/ReportMediaField';
import ReportSelectionField from './reportFields/ReportSelectionField';
import ReportSignatureField from './reportFields/ReportSignatureField';
import ReportStatusField from './reportFields/ReportStatusField';
import ReportStopwatchField from './reportFields/ReportStopWatchField';
import ReportUserSelectionField from './reportFields/ReportUserSelectionField';
import { Instance, Section, SectionBody, SectionHeader } from './elements';

type TypeName = 'Bool' | 'DateTime' | 'Number' | 'Signature' | 'Text';

const splitIntoParagraphs = (text: string) =>
  text?.split('\n').map((paragraph, i) => (
    <p key={i}>
      {paragraph.includes('.')
        ? paragraph
            ?.split('.')
            .filter(Boolean)
            .map((sentence, j) => <span key={j}>{sentence.trim()}. </span>)
        : paragraph}
    </p>
  ));

export const InstancePageSection = ({
  instanceId,
  items = [],
  level = 0,
  linkedSnagInstances,
  members,
  photoBlobs,
  reportConfigurationDummyTemplate,
  reportOptions,
  signatures,
  templateId,
  unnestedItems,
}: InstancePageSectionProps) => {
  const { t } = useTranslation();
  const { isEmptySection } = useIsEmptySection();

  const templateOptions = reportOptions?.templates?.[
    templateId ?? 'default'
  ] as TemplateOptions;
  const typeHandlers: { [key in TypeName]: (value: string) => string } = {
    Bool: (value) => (value === 'true' ? t('Yes') : t('No')),
    DateTime: (value) => dayjs(value).format('ll'),
    Number: (value) => value,
    Signature: () => t('Coming soon'),
    Text: (value) => value,
  };

  if (items.length === 0) {
    return null;
  }

  const filterSignatureUrl = (itemId: string) =>
    signatures &&
    (signatures.filter((s) => s.itemId === itemId)[0]
      ? signatures.filter((s) => s.itemId === itemId)[0].signatureUrl
      : '');

  const filterLinkedSnags = (
    itemId: string,
    linkedSnagInstances: QualityControlInstance[]
  ) =>
    linkedSnagInstances.filter(
      (linkedSnagInstance) => linkedSnagInstance.linkedFieldId === itemId
    );

  return (
    <Instance.ItemList
      compactLists={reportOptions.compactLists}
      inlineLists={reportOptions.inlineLists}
    >
      {items.map((item) => {
        const {
          id,
          displayName,
          indicative,
          value,
          type,
          valueType,
          displayDescription,
        } = item;

        if (templateOptions?.hiddenSectionsAndFields?.includes(id)) return null;

        const identifier = `${instanceId}_${id}`;

        const linkedSnags = filterLinkedSnags(item.id, linkedSnagInstances);
        const commonProps = {
          item,
          linkedSnags,
          templateOptions,
        };

        if (
          type === undefined ||
          type === null ||
          !showField({ field: item, items: unnestedItems })
        ) {
          return null;
        }

        if (
          type !== 'Section' &&
          (value === undefined || value === '' || value === null) && // false and 0 are valid values
          !reportOptions.showEmptyAnswers
        ) {
          return null;
        }

        // If the section contains no fields or fields without a value and `showEmptyAnswers` is not enabled then do not show the section.
        if (type === 'Section' && !reportOptions.showEmptyAnswers) {
          if (valueType?.subFields && isEmptySection(valueType.subFields))
            return null;
        }

        const formattedValue =
          type && value ? typeHandlers[type as TypeName]?.(value) : '-';

        if (type === 'Section') {
          return valueType?.subFields ? (
            <Section borderRight={!level} key={identifier}>
              <SectionHeader>{displayName}</SectionHeader>
              <SectionBody>
                <InstancePageSection
                  instanceId={instanceId}
                  items={valueType.subFields}
                  level={level + 1}
                  linkedSnagInstances={linkedSnagInstances}
                  members={members}
                  photoBlobs={photoBlobs}
                  reportConfigurationDummyTemplate={
                    reportConfigurationDummyTemplate
                  }
                  reportOptions={reportOptions}
                  signatures={signatures}
                  templateId={templateId}
                  unnestedItems={unnestedItems}
                />
              </SectionBody>
            </Section>
          ) : null;
        }

        if (value && type === ValueTypeName.Status && !indicative) {
          return (
            <ReportStatusField
              {...commonProps}
              background={applyBackground(
                valueType?.options?.find((option) => option.name === value)
                  ?.color || 'grey'
              )}
              color={applyColor(
                valueType?.options?.find((option) => option.name === value)
                  ?.color || 'grey'
              )}
              key={identifier}
            />
          );
        }

        if (value && type === ValueTypeName.Selection) {
          return (
            <ReportSelectionField
              {...commonProps}
              key={identifier}
              reportOptions={reportOptions}
              templateOptions={templateOptions}
            />
          );
        }

        if (value && type === ValueTypeName.Media) {
          return (
            <ReportMediaField
              {...commonProps}
              key={identifier}
              photoBlobs={photoBlobs}
              reportConfigurationDummyTemplate={
                reportConfigurationDummyTemplate
              }
              reportOptions={reportOptions}
              templateId={templateId ?? ''}
            />
          );
        }

        if (value && type === ValueTypeName.Signature) {
          return (
            <ReportSignatureField
              {...commonProps}
              key={identifier}
              reportConfigurationDummyTemplate={
                reportConfigurationDummyTemplate
              }
              signatureUrl={filterSignatureUrl(item.id) ?? ''}
              signatures={signatures}
            />
          );
        }

        if (value && type === ValueTypeName.HierarchyList) {
          return <ReportHierarchyField {...commonProps} key={identifier} />;
        }

        if (type === ValueTypeName.Description) {
          return <ReportDescriptionField {...commonProps} key={identifier} />;
        }

        if (type === ValueTypeName.TimeSpan) {
          return <ReportStopwatchField {...commonProps} key={identifier} />;
        }

        if (type === ValueTypeName.UserSelection) {
          return (
            <ReportUserSelectionField
              {...commonProps}
              key={identifier}
              members={members}
            />
          );
        }
        return indicative ? null : (
          <Instance.Item key={identifier}>
            <Instance.Label>{displayName}</Instance.Label>
            <Instance.Value>
              {splitIntoParagraphs(formattedValue)}
              {templateOptions?.showDescription && (
                <FieldDescription displayDescription={displayDescription} />
              )}
              {templateOptions?.showLinkedSnags && (
                <LinkedSnagsList linkedSnags={linkedSnags} />
              )}
            </Instance.Value>
          </Instance.Item>
        );
      })}
    </Instance.ItemList>
  );
};
