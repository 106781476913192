import React, { useEffect, useState } from 'react';

import { FilterType } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { delimiters, FilterBaseProps } from '@pro4all/shared/search-utils';
import { Option } from '@pro4all/shared/types';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { useIsQCSearchRoute } from '@pro4all/shared/ui/filtering';
import { Icon } from '@pro4all/shared/ui/icons';
import { TagList, TagProps } from '@pro4all/shared/ui/tag';
import { ConditionalTooltip } from '@pro4all/shared/ui/tooltip';
import { sortBy } from '@pro4all/shared/utils';

import { ResultMatch } from '../../results/';
import { FilterBreadcrumb } from '../FilterBreadcrumb';
import { FilterOptions } from '../FilterOptions';
import { History, useFilters } from '../utils/';

import { FilterQCEmptyState } from './FilterQCEmptyState';
import {
  InputWrap,
  StyledInputAdornment,
  StyledLi,
  StyledTextField,
  TagBg,
} from './Styles';

interface Props extends FilterBaseProps {
  freeSolo?: boolean;
  history?: History;
  initialOptions: Option[];
  loading?: boolean;
  match?: RegExp;
  maxNameLength?: number;
  options: Option[];
  order?: (a: Option, b: Option) => number;
  parseTagValue?: (optionId: string) => string;
  prefix?: string;
  reloadOnChange?: boolean;
  renderOption?: (props: {
    uiOption: {
      iconComponent?: JSX.Element;
      label: string;
      matchString: string;
      onClick?: () => void;
    };
  }) => JSX.Element;
  showInputFilter?: boolean;
  showOptionIcon?: boolean;
  showTagIcon?: boolean;
  showTooltip?: boolean;
  type: FilterType;
}

export const TagSelect: React.FC<Props> = ({
  freeSolo = false,
  history,
  initialOptions,
  loading,
  options,
  prefix = '',
  match = /^[a-zA-Z\d ]*$/,
  maxNameLength = 24,
  metaDataKey,
  order = sortBy({ key: 'label' }),
  parseTagValue = (optionId: string) => optionId,
  reloadOnChange,
  showTooltip,
  showInputFilter = true,
  showOptionIcon,
  showTagIcon,
  value,
  type,
  renderOption,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[] | null>(null);
  const [inputValue, setInputValue] = useState<string>('');

  const isQCRoute = useIsQCSearchRoute();

  const { setFilterValue, resetFilter } = useFilters();

  const matchString = inputValue.replace(/ /g, '\u00A0');

  const selectedIds = selectedOptions?.map(
    (selectedOption) => selectedOption.id
  );
  const availableOptions = loading
    ? []
    : options
        .filter((option) => !selectedIds || !selectedIds.includes(option.id))
        .sort(order);

  const filteredOptions = inputValue.length
    ? availableOptions.filter((option) =>
        option.label.toLowerCase().match(inputValue.toLowerCase())
      )
    : availableOptions;

  useEffect(() => {
    if (!selectedOptions && initialOptions.length)
      setSelectedOptions(initialOptions);
    if (!value) setSelectedOptions(null);
  }, [initialOptions, selectedOptions, value]);

  const removeTag = (id: string) => {
    const newOptions = selectedOptions?.filter((option) => option.id !== id);
    newOptions && setSelectedOptions([...newOptions]);
    const newOptionsSerialized =
      newOptions
        ?.map((selectedOption) =>
          parseTagValue(selectedOption.inputValue || '')
        )
        .join(delimiters.multiSelectOptions) || '';

    setFilterValue({
      metaDataKey,
      reload: reloadOnChange,
      type,
      value: newOptionsSerialized,
    });

    if (!newOptions?.length) {
      resetFilter(type, metaDataKey); // Force a refetch if the last tag was removed
    }
  };

  const toTag = ({ id, label, iconName }: Option): TagProps => ({
    color: 'default',
    id,
    name: `${prefix}${label}`,
    onDelete: () => removeTag(id),
    startIcon: showTagIcon ? iconName : undefined,
    variant: 'outlined',
  });

  const tags = selectedOptions ? selectedOptions.map(toTag) : [];

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (freeSolo && event.key === 'Enter' && inputValue) {
      const parsedValue = parseTagValue(inputValue);
      const newOption = { id: parsedValue, label: parsedValue };

      if (tags.some((tag) => tag.id === parsedValue)) return; // Skip tags that are already included
      history && history.set(parsedValue);
      onSelect(newOption);
      setInputValue('');
    }
    event.stopPropagation();
  };

  const onSelect = (option: Option) => {
    const parsedOption: Option = {
      ...option,
      label: parseTagValue(option.label),
    };
    const updatedOptions = selectedOptions
      ? [...selectedOptions, parsedOption]
      : [parsedOption];
    setSelectedOptions(updatedOptions);

    const selectionSerialized = updatedOptions
      ?.map((selectedOption) => selectedOption.inputValue || '')
      .join(delimiters.multiSelectOptions);

    selectionSerialized &&
      setFilterValue({
        metaDataKey,
        reload: reloadOnChange,
        type,
        value: selectionSerialized,
      });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.match(match)) setInputValue(event.target.value);
  };

  const startAdornment = (
    <>
      <StyledInputAdornment position="start">
        <Icon iconName="search" />
      </StyledInputAdornment>
      {prefix}
    </>
  );

  const endAdornment = (
    <StyledInputAdornment position="end">
      <IconButton
        color="default"
        disableBorder
        iconName="cancel"
        onClick={() => setInputValue('')}
      />
    </StyledInputAdornment>
  );

  const renderOptions = () =>
    filteredOptions.map(({ iconName, id, inputValue, label }, index) => (
      <ConditionalTooltip
        key={`option-${index}`}
        options={{
          placement: 'right',
          title: label,
        }}
        showTooltip={showTooltip}
      >
        {renderOption ? (
          renderOption({
            uiOption: {
              iconComponent:
                showOptionIcon && iconName ? (
                  <Icon iconName={iconName} />
                ) : undefined,
              label,
              matchString,
              onClick: () => onSelect({ iconName, id, inputValue, label }),
            },
          })
        ) : (
          <StyledLi
            key={id}
            onClick={() => onSelect({ iconName, id, inputValue, label })}
          >
            <ResultMatch
              IconComponent={
                showOptionIcon && iconName ? (
                  <Icon iconName={iconName} />
                ) : undefined
              }
              forcedWidth
              matchString={matchString}
              text={`${prefix}${label}`}
            />
          </StyledLi>
        )}
      </ConditionalTooltip>
    ));

  return (
    <Box display="flex" flex={1} flexDirection="column" overflow="auto">
      {showInputFilter && (
        <Box>
          <FilterBreadcrumb metaDataKey={metaDataKey} />
          <InputWrap>
            <StyledTextField
              InputProps={
                inputValue
                  ? {
                      endAdornment,
                      startAdornment,
                    }
                  : {
                      startAdornment,
                    }
              }
              name={`${type}-input`}
              onChange={onChange}
              onKeyDown={onKeyDown}
              value={inputValue}
            />
          </InputWrap>
        </Box>
      )}
      {tags.length > 0 && (
        <TagBg>
          <TagList enableTooltip maxNameLength={maxNameLength} tags={tags} />
        </TagBg>
      )}

      {Boolean(filteredOptions.length) && (
        <Box>
          <FilterOptions
            loading={loading}
            matchString={matchString}
            renderOptions={renderOptions}
          />
        </Box>
      )}
      {isQCRoute &&
        !loading &&
        filteredOptions.length === 0 &&
        tags.length === 0 && <FilterQCEmptyState />}
    </Box>
  );
};
