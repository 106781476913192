import { useTranslation } from 'react-i18next';

import { Task, Tbq, TbqModuleType, User } from '@pro4all/graphql';
import { NO_VALUE } from '@pro4all/shared/constants';
import { RenderOptionType } from '@pro4all/shared/types';
import { StatusChip } from '@pro4all/shared/ui/chips';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';
import { getFormattedDate, Timestamp } from '@pro4all/shared/ui/timestamp';
import { useTaskStatuses } from '@pro4all/workflow/ui/utils';

const getType = (task: Task) => {
  const { tbq } = task;
  return tbq?.tbqModuleType === TbqModuleType.Rie ? 'RI&E' : tbq?.tbqModuleType;
};

export const useTBQTaskColumns = () => {
  const { t } = useTranslation();
  const statuses = useTaskStatuses();

  const columns: ColumnProps<Task>[] = [
    {
      defaultSort: true,
      filterable: true,
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Text}
          label={t('Title')}
          minWidth={100}
          propertyId="name"
          pullFromLocalStorage
        />
      ),
      key: 'name',
      minWidth: 100,
      width: 200,
    },
    {
      filterable: true,
      getValue: (task: Task) => {
        const { assignment } = task;
        return assignment?.[0]?.displayName ? assignment[0].displayName : '';
      },
      headerComponent: (
        <FilterHeader<Task, User>
          customCallbackId="assignment.displayName"
          defaultWidth={200}
          filterType={FilterHeaderType.Select}
          getCustomValueCallback={(assignment: User[]) =>
            assignment?.[0]?.displayName ?? NO_VALUE
          }
          label={t('Responsible')}
          minWidth={120}
          propertyId="assignment"
          renderOption={RenderOptionType.UserCard}
          translateOptions
        />
      ),
      key: 'assignment.displayName',
      minWidth: 120,
      render: ({ assignment }) => <UserTag user={assignment?.[0]} />,
      width: 200,
    },
    {
      filterable: true,
      getValue: (task: Task) => getType(task),
      headerComponent: (
        <FilterHeader<Task, Tbq>
          defaultWidth={60}
          filterType={FilterHeaderType.Select}
          label={t('Type')}
          minWidth={60}
          propertyId="tbq"
          subPropertyId="tbqModuleType"
        />
      ),
      key: 'tbq.tbqModuleType',
      minWidth: 60,
      render: (task: Task) => getType(task),
      width: 100,
    },
    {
      getValue: (task: Task) =>
        task.endTime ? getFormattedDate(task.endTime).label : '',
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label={t('Deadline')}
          minWidth={120}
          propertyId="endTime"
        />
      ),
      key: 'endTime',
      render: ({ endTime }) => <Timestamp date={endTime} />,
      width: 200,
    },
    {
      getValue: (task: Task) => {
        const { status } = task;
        return status ? statuses[status].label : '';
      },
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={120}
          filterType={FilterHeaderType.Select}
          label={t('Status')}
          minWidth={120}
          propertyId="status"
          translateOptions
        />
      ),
      key: 'status',
      render: ({ status }) => <StatusChip status={status} />,
      width: 120,
    },
    {
      getValue: (task: Task) =>
        task.completedAt ? getFormattedDate(task.completedAt).label : '',
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label={t('Completed at')}
          minWidth={120}
          propertyId="completedAt"
        />
      ),
      key: 'completedAt',
      render: ({ completedAt }) => <Timestamp date={completedAt} />,
      width: 120,
    },
  ];
  return columns;
};
