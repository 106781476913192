import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  ScheduledTime,
  useUpdateNotificationSettingsMutation,
  useUsersIncludeQuery,
  WeekDay,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { DialogContainer, DialogHeader } from '@pro4all/shared/ui/dialog';
import { Text } from '@pro4all/shared/ui/typography';

import { NotificationsSettingsForm } from './NotificationsSettingsForm';
import {
  defaultValues,
  formValidator,
  NotificationsSettingsFields,
  NotificationsSetttingsModalProps,
} from './NotificationsSettingsFormUtils';

export const NotificationsSettingsModal = ({
  setIsModalOpen,
  isModalOpen,
  dataQuery,
  scope,
}: NotificationsSetttingsModalProps) => {
  const { t } = useTranslation();
  const { params } = useRouting();
  const { userId, userOrganizationId } = useOrganizationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { data: userData } = useUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      includeIsAdmin: true,
      includeRoles: true,
      projectId: params.projectId,
    },
  });

  const userPermissions = Boolean(
    dataQuery?.getNotifications?.hasUpdatePermission
  );

  const [updateNotificationsSettings, { loading }] =
    useUpdateNotificationSettingsMutation();

  const loggedUser = userData?.users?.find((u) => u?.id === userId);

  const loggedUserIsAdmin =
    loggedUser?.roles?.some((r) => r?.name === 'Admin') || loggedUser?.isAdmin;
  const isViewOnly = Boolean(!loggedUserIsAdmin);

  const closeModal = () => setIsModalOpen(false);

  const onSubmitFunc = async (formFields: NotificationsSettingsFields) => {
    // Check if notificationTimes is an empty array
    // TODO: Maybe do this on the formValidator

    const notificationTimes =
      (formFields?.notificationTimes?.length ?? 0) > 0
        ? formFields.notificationTimes.map(
            (time) => `${time.hour}:${time.minute}`
          )
        : ['07:00'];
    try {
      await updateNotificationsSettings({
        variables: {
          notificationDays: formFields.notificationDays,
          notificationSettingsId:
            dataQuery?.getNotifications?.notificationSettingsId,
          notificationTimes,
          organizationId: !params.projectId ? userOrganizationId : null,
          projectId: params.projectId,
          scope,
        },
      });
      setIsModalOpen(false);
      enqueueSnackbar(t('Saved successfully'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('Something went wrong'), { variant: 'error' });
    }
  };

  const form = useForm<NotificationsSettingsFields>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: formValidator.resolver.bind(formValidator),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  React.useEffect(() => {
    form.reset({
      notificationDays: (dataQuery?.getNotifications?.notificationDays ||
        []) as WeekDay[],
      notificationTimes: (dataQuery?.getNotifications?.notificationTimes ||
        []) as ScheduledTime[],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataQuery?.getNotifications, form.reset]);

  return (
    <DialogContainer maxWidth={false} open={isModalOpen}>
      <Box minHeight="160px" minWidth="750px">
        <DialogHeader>
          <Box
            alignItems="center"
            display="flex"
            flex="1"
            height="40px"
            justifyContent="space-between"
          >
            <Text variant="h3">{t('Notification settings')}</Text>
            <IconButton color="default" iconName="close" onClick={closeModal} />
          </Box>
        </DialogHeader>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmitFunc)}>
            <NotificationsSettingsForm
              hasUserPermissions={userPermissions}
              isLoading={loading}
              onCancelClick={closeModal}
              viewOnly={isViewOnly}
            />
          </form>
        </FormProvider>
      </Box>
    </DialogContainer>
  );
};
