import styled from 'styled-components';

import { Loader as UILoader } from '@pro4all/shared/ui/loader';

export const Canvas = styled.div`
  box-shadow: 0 0 0 2px #dddddd;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

export const Figure = styled.figure`
  flex: 1;
  margin: 0;
  min-height: 0;
  position: relative;
`;

export const Img = styled.img`
  display: block;
  height: 100%;
  object-fit: contain;
  user-select: none;
  width: 100%;
`;

export const Loader = styled(UILoader)`
  background-color: rgba(255, 255, 255, 0.88);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;
