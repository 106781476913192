import { useEffect, useRef } from 'react';

import { useLocalStorage } from '@pro4all/shared/hooks';
import { FilterType, FilterTypeLS } from '@pro4all/shared/ui/filtering';
import {
  FilterColumnIdProps,
  FilterColumnProps,
  useFilterContext,
} from '@pro4all/shared/ui/filtering';
import {
  BaseRow,
  useOptimisticResponseContext,
  useTableContext,
} from '@pro4all/shared/ui/table';

import { useOnSet } from './useOnSet';

export function useInitializeFromLocalStorage<Row extends BaseRow, SubProp>({
  filterType,
  isMetaData = false,
  isMultiSelect = false,
  metaDataHeaderId = '',
  propertyId,
  pullFromLocalStorage,
  subPropertyId,
  translateOptions,
}: Pick<
  FilterColumnProps,
  | 'filterType'
  | 'isMetaData'
  | 'isMultiSelect'
  | 'metaDataHeaderId'
  | 'pullFromLocalStorage'
  | 'translateOptions'
> &
  Pick<FilterColumnIdProps<Row, SubProp>, 'propertyId' | 'subPropertyId'>) {
  const { getFilteredItems } = useOnSet<Row, SubProp>({
    filterType,
    isMetaData,
    isMultiSelect,
    metaDataHeaderId,
    propertyId,
    subPropertyId,
    translateOptions,
  });

  const {
    setRecalculateFilters,
    state: { recalculateFilters },
  } = useOptimisticResponseContext<Row>();

  const { updateTableBasedOnCurrentFilters } = useFilterContext<Row>();

  // For the document and version table we want to use the same id regarding resizing and sorting.
  // But a different id regarding column filtering. That's why the prop 'idFilteringSorting' was introduced.
  const { id, idFilteringSorting } = useTableContext<Row>();
  const tableId = idFilteringSorting || id;

  const { localStorageItem } = useLocalStorage({
    key: `prostream-column-filters-${tableId}`,
  }) as { localStorageItem: FilterTypeLS[] };

  const itemsFilteredPopulated = useRef(false); // To prevent an infinitive loop, because the useEffect updates state via 'updateTableBasedOnCurrentFilters'.

  useEffect(() => {
    if (
      pullFromLocalStorage &&
      (!itemsFilteredPopulated.current || recalculateFilters) &&
      Array.isArray(localStorageItem) &&
      localStorageItem.length
    ) {
      const filtersFromLocalStorage: FilterType<Row>[] = localStorageItem.map(
        ({
          filterType,
          filterValues,
          isMultiSelect,
          propertyId,
          subPropertyId,
          translateOptions,
        }) => {
          // The property for a metadata column is a concatenation of the propertyId and the metaDataHeaderId separated by a period (.).
          // So first we have to pull out the property Id.
          const propId = propertyId.split('.')[0] as keyof Row;
          const metaDataHeaderId = propertyId.split('.')[1];
          const subPropId = subPropertyId as keyof SubProp;
          return {
            filterType,
            filterValues,
            isMultiSelect,
            itemsFiltered: getFilteredItems({
              filterType,
              filterValues,
              isMetaDataLocalStorage: Boolean(metaDataHeaderId),
              isMultiSelectLocalStorage: isMultiSelect,
              metaDataHeaderIdLocalStorage: metaDataHeaderId,
              propertyId: propId,
              subPropertyId: subPropId,
              translateOptionsLocalStorage: translateOptions,
            }),
            propertyId,
            subPropertyId,
            translateOptions,
          };
        }
      );
      itemsFilteredPopulated.current = true; // We wanna do this only when the header initializes for the first column.
      updateTableBasedOnCurrentFilters(filtersFromLocalStorage);
      setRecalculateFilters(false);
    } else if (pullFromLocalStorage) {
      updateTableBasedOnCurrentFilters([]);
    }
  }, [
    filterType,
    getFilteredItems,
    localStorageItem,
    propertyId,
    pullFromLocalStorage,
    recalculateFilters,
    setRecalculateFilters,
    updateTableBasedOnCurrentFilters,
  ]);
}
