import styled from 'styled-components';

import { Slider } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { Text } from '@pro4all/shared/ui/typography';
import { hexToRgb } from '@pro4all/shared/utils';

export const BackButton = styled(Button)`
  && {
    width: fit-content;
  }
`;

export const SliderHeaderContainer = styled('div')<{ pageCount: number }>`
  position: absolute;
  bottom: ${({ pageCount }) => (pageCount > 1 ? '70px' : '15px')};
  right: 35px;
  z-index: 99;
`;

export const StyledH5 = styled(Text)`
  && {
    color: ${({ theme }) => theme.palette.text.primary};
    margin-bottom: ${({ theme }) => theme.spacing(1.333)};
  }
`;

export const HeaderTitle = styled(Text)`
  && {
    margin-left: ${({ theme }) => theme.spacing(4)};
    padding-top: ${({ theme }) => theme.spacing(2)};
  }
`;

export const StyledSlider = styled(Slider)`
  && {
    width: 180px;

    & .MuiSlider-rail,
    .MuiSlider-track {
      height: 6px;
      border-radius: 5px;
    }

    & .MuiSlider-track {
      color: ${({ theme }) => theme.palette.grey['700']};
    }

    & .MuiSlider-rail {
      color: ${({ theme }) => theme.palette.grey['700']};
      opacity: 1;
    }

    & .MuiSlider-thumb {
      height: 20px;
      width: 20px;
      color: ${({ theme }) => theme.palette.grey['700']};
      box-shadow: ${({ theme }) =>
        `0px 0px 0px 8px ${hexToRgb(theme.palette.text.primary, '0.16')}`};
      &:hover {
        box-shadow: ${({ theme }) =>
          `0px 0px 0px 8px ${hexToRgb(theme.palette.text.primary, '0.16')}`};
      }
    }
  }
`;
