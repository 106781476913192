import { useEffect } from 'react';

import {
  Document,
  QualityControlInstance,
  Task,
  TaskType,
  useDocumentLazyQuery,
  VisualContext,
} from '@pro4all/graphql';
import { useBatchFetchResultsLazy } from '@pro4all/quality-control/data-access';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  useOptimisticResponseContext,
  useTableCheck,
} from '@pro4all/shared/ui/table';
import { isQualityControlInstance } from '@pro4all/workflow/ui/utils';

import { determineLinkInstances, setFPS } from './utils';

export const useSetDrawings = ({
  setDrawings,
  setLinkInstances,
  task,
  taskType,
}: {
  setDrawings: React.Dispatch<React.SetStateAction<VisualContext[]>>;
  setLinkInstances: React.Dispatch<
    React.SetStateAction<QualityControlInstance[] | Document[]>
  >;
  task?: Task;
  taskType: TaskType;
}) => {
  const { checkedRows } = useTableCheck<QualityControlInstance | Document>();

  const { searchParams } = useRouting();
  const instanceId = searchParams.get('id');
  const routeCreate = searchParams.is('action', 'createTask');
  const documentTask = searchParams.is('taskType', 'Document');
  const resolveTask = searchParams.is('taskType', 'Resolve');
  const isEditMode = Boolean(task);

  const {
    state: { item: copiedTask },
  } = useOptimisticResponseContext<Task>();

  const [getDocumentData, { data: documentToLink }] = useDocumentLazyQuery({
    variables: { id: instanceId ?? '' },
  });

  const [getSnagData, { data: snagToLink }] = useBatchFetchResultsLazy([
    instanceId ?? '',
  ]);

  const filterFormsOutOfCheckedRows = () =>
    checkedRows.filter((row) => {
      if (isQualityControlInstance(row)) {
        return row.type === 'snag';
      }
      return true;
    });

  useEffect(() => {
    if (!routeCreate || !instanceId) return;
    if (documentTask) {
      getDocumentData();
    } else if (resolveTask) {
      getSnagData();
    }
  }, [routeCreate, documentTask, resolveTask, instanceId]);

  useEffect(() => {
    if (!checkedRows && (!documentToLink || !snagToLink)) return;
    const instances = determineLinkInstances({
      checkedRows,
      copiedTask,
      documentToLink,
      filterFormsOutOfCheckedRows,
      isEditMode,
      snagToLink,
      task,
      taskType,
    }) as QualityControlInstance[] | Document[];
    setLinkInstances(instances);
    if (
      (checkedRows.length ||
        snagToLink ||
        task?.linkedSnagInstances?.length ||
        copiedTask?.linkedSnagInstances?.length) &&
      instances?.[0]?.__typename !== 'Document'
    ) {
      setFPS({
        qualityControlInstances: instances as QualityControlInstance[],
        setDrawings,
      });
    }
  }, [checkedRows, documentToLink, snagToLink]);
};
