import React from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import styled from 'styled-components';

import { Task, Template, TemplateType } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import { FormikSearchableMultiSelect } from '@pro4all/shared/ui/formik';
import { Card } from '@pro4all/shared/ui/general';
import { Icon, IconName } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { Text } from '@pro4all/shared/ui/typography';
import { sortBy } from '@pro4all/shared/utils';
import { LinkedInstance } from '@pro4all/workflow/ui/utils';

import { CustomIconComponent } from '../task-properties/CustomIconComponent';

interface Props {
  iconName: IconName;
  id: string;
  label: string;
  loading: boolean;
  noOptionsText: string;
  options: Option[];
  placeholder: string;
  task?: Task;
  titleIconName?: IconName;
  tooltip: string;
}

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(2)} 0
    ${({ theme }) => theme.spacing(1)} 0;

  article:first-of-type {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const Message = styled.div`
  padding: ${({ theme }) => theme.spacing(2)} 0;
`;

export const MultiSelectList: React.FC<Props> = ({
  iconName,
  id,
  label,
  loading,
  noOptionsText,
  options,
  placeholder,
  task,
  tooltip,
  titleIconName,
}) => {
  const { t } = useTranslation();
  const [field, _meta, helpers] = useField({ name: id });
  const snagsFormsOrDrawings = (field.value || []).sort(
    sortBy({ key: 'label' })
  ) as Option[];

  const snagsFormsOrDrawingsWithIconComponent = snagsFormsOrDrawings.map(
    (snagFormOrDrawing) => {
      if (
        snagFormOrDrawing.type === TemplateType.Form ||
        snagFormOrDrawing.type === TemplateType.Snag
      ) {
        return {
          ...snagFormOrDrawing,
          iconComponent: (
            <CustomIconComponent
              currentTask={task}
              instance={snagFormOrDrawing as LinkedInstance | Template}
              type="form"
            />
          ),
        };
      } else {
        return snagFormOrDrawing;
      }
    }
  );

  return (
    <Container>
      <InputWrapper>
        <Box display="flex" justifyContent="space-between">
          <Text variant="h4">
            <Icon iconName={titleIconName as IconName} /> {label}
          </Text>
          <Tooltip aria-label="tooltip" title={tooltip}>
            <Icon iconName="info" />
          </Tooltip>
        </Box>
        {!loading && options.length === 0 ? (
          <Message>{noOptionsText}</Message>
        ) : (
          <FormikSearchableMultiSelect
            disabled={
              options.length === 0 || options.length === field.value.length
            }
            id={id}
            name={id}
            options={options}
            placeholder={loading ? t('Loading') : placeholder}
            renderSelectedTagsInInput={false}
          />
        )}
      </InputWrapper>

      {snagsFormsOrDrawingsWithIconComponent.map(
        (snagFormOrDrawing: Option) => (
          <Card
            iconComponent={snagFormOrDrawing.iconComponent}
            iconName={snagFormOrDrawing.iconName ?? iconName}
            key={snagFormOrDrawing.id}
            menuItems={[
              {
                ariaLabel: `Remove ${snagFormOrDrawing.label}`,
                dataTestId: `remove-option-${snagFormOrDrawing.id}`,
                key: `remove-option-${snagFormOrDrawing.id}`,
                label: `Remove ${snagFormOrDrawing.label}`,
                onClick: async () =>
                  helpers.setValue(
                    field.value.filter(
                      (value: Option) => value.id !== snagFormOrDrawing.id
                    )
                  ),
                startIcon: 'close',
              },
            ]}
            title={snagFormOrDrawing.label}
          />
        )
      )}
    </Container>
  );
};
