import { Resolver } from 'react-hook-form';

import {
  AttachmentInput as GqlAttachment,
  Message,
  ReferenceKind,
  ReferenceType,
} from '@pro4all/graphql';
import { isDefined } from '@pro4all/shared/utils';
import { isValidEmail } from '@pro4all/shared/utils/email';

import { toUserInfo } from '../../utils';
import { MessageFormFields, RecipientField } from '../types';

export const resolver: Resolver<MessageFormFields> = async (values) => {
  const validateEmails = (recipients: RecipientField[]) =>
    recipients.every((recipient) => {
      try {
        if (recipient.type === ReferenceType.Email)
          return Boolean(isValidEmail(recipient.email));
        return true;
      } catch (e) {
        return false; // If there is an issue with regex, better safe than sorry that is not a valid email
      }
    });

  const valuesTo = values.to || [];
  const valuesCc = values.cc || [];
  const valuesBcc = values.bcc || [];
  //
  const toRequiredError =
    !valuesTo || !valuesTo.length
      ? {
          to: { type: 'required' },
        }
      : null;

  const patternToErrors = !validateEmails(valuesTo)
    ? {
        to: { message: 'Not a valid email', type: 'pattern' },
      }
    : null;
  const patternCcErrors = !validateEmails(valuesCc)
    ? {
        cc: { message: 'Not a valid email', type: 'pattern' },
      }
    : null;
  const patternBccErrors = !validateEmails(valuesBcc)
    ? {
        bcc: { message: 'Not a valid email', type: 'pattern' },
      }
    : null;
  let errors = {};
  if (toRequiredError) errors = { ...errors, ...toRequiredError };
  if (patternToErrors) errors = { ...errors, ...patternToErrors };
  if (patternCcErrors) errors = { ...errors, ...patternCcErrors };
  if (patternBccErrors) errors = { ...errors, ...patternBccErrors };

  return {
    errors,
    values,
  };
};

export const createDefaultValues = ({
  message,
  dmsAttachments,
}: {
  dmsAttachments: GqlAttachment[];
  message: Message;
}): MessageFormFields => ({
  attachments: [...dmsAttachments, ...(message?.attachments || [])],
  bcc:
    message.references
      .filter(({ referenceKind }) => referenceKind === ReferenceKind.Bcc)
      .map(toUserInfo)
      .filter(isDefined) || [],
  body: message.body || null,
  cc:
    message.references
      .filter(({ referenceKind }) => referenceKind === ReferenceKind.Cc)
      .map(toUserInfo)
      .filter(isDefined) || [],
  mentions: message.references
    .filter(
      ({ referenceKind, referenceType }) =>
        referenceKind === ReferenceKind.Body &&
        referenceType === ReferenceType.Mention
    )
    .map(toUserInfo)
    .filter(isDefined),
  messageId: message.id,
  newFiles: [],
  subject: message.subject || '',
  to:
    message.references
      .filter(({ referenceKind }) => referenceKind === ReferenceKind.To)
      .map(toUserInfo)
      .filter(isDefined) || [],
});
