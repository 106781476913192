import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ValueTypeName } from '@pro4all/graphql';
import { Condition, SectionOption } from '@pro4all/metadata/ui/utils';
import { Button } from '@pro4all/shared/ui/buttons';
import { Select } from '@pro4all/shared/ui/inputs';
import { Text } from '@pro4all/shared/ui/typography';

import { useBoolOptions } from '../answer/useBoolOptions';

interface Props {
  answers: SectionOption[];
  condition: Condition;
  conditions: Condition[];
  handleRemoveCondition: (index: number) => void;
  handleSelectChange: (
    index: number,
    field: keyof Condition,
    value: string
  ) => void;
  index: number;
  sections: SectionOption[];
  selectItems: SectionOption[];
  valueTypeName: ValueTypeName;
}

export const ConditionForm: React.FC<Props> = ({
  condition,
  index,
  sections,
  selectItems,
  valueTypeName,
  answers,
  handleSelectChange,
  handleRemoveCondition,
  conditions,
}) => {
  const { t } = useTranslation();
  const boolOptions = useBoolOptions();
  const usedOptions =
    valueTypeName === ValueTypeName.Bool ? boolOptions : answers;

  return (
    <React.Fragment key={index}>
      {index !== 0 && <OR>{t('OR')}</OR>}
      <ConditionWrapper>
        <Row>
          <Column>
            <Text variant="h5">{t('When in section')}</Text>
            <Select
              name={`section${index}`}
              onChange={(event) =>
                handleSelectChange(index, 'section', event.id)
              }
              options={sections}
              placeholder={t('Select')}
              value={condition.section}
            />
          </Column>
          <Column>
            <Text variant="h5">{t('The item')}</Text>
            <Select
              name={`item${index}`}
              noOptions={t('No selection, boolean, or status field options')}
              onChange={(event) => handleSelectChange(index, 'field', event.id)}
              options={selectItems}
              placeholder={t('Select')}
              value={condition.field}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Text variant="h5">{t('is answered with')}</Text>
            <Select
              name={`answer${index}`}
              onChange={(event) =>
                handleSelectChange(index, 'answer', event.id)
              }
              options={usedOptions}
              placeholder={t('Select')}
              value={condition.answer}
            />
          </Column>
          {(condition.section || condition.field || condition.answer) && (
            <DangerButton
              color="inherit"
              disabled={
                conditions.length <= 1 &&
                !condition.section &&
                !condition.field &&
                !condition.answer
              }
              onClick={() => handleRemoveCondition(index)}
              startIcon="removeCircle"
              variant="text"
            >
              {t('Remove condition')}
            </DangerButton>
          )}
        </Row>
      </ConditionWrapper>
    </React.Fragment>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 16px;
  gap: 10px;
  * {
    max-width: 268px;
  }
`;
const ConditionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Column = styled.div`
  flex: 1;
  flex-direction: column;
`;

const OR = styled(Text)`
  && {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 500;
    padding: 20px;
  }
`;

export const DangerButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.palette.error.dark};
    top: 5px;
  }
`;
