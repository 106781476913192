import {
  IntegrationWithConnection,
  useDeleteIntegrationConnectionMutation,
} from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';

export const useDeleteConnection = ({ onClose }: { onClose: () => void }) => {
  const { searchParams } = useRouting();
  const [deleteIntegrationConnection] =
    useDeleteIntegrationConnectionMutation();

  const { connections, setConnections } = useProjectContext();
  const {
    editItems,
    state: { items },
  } = useOptimisticResponseContext<IntegrationWithConnection>();

  const deleteConnection = async () => {
    const id = searchParams.get('id') || '';

    const integrationWithConnection = items.find(
      (x) => x.integrationConnection?.id === id
    );
    if (!integrationWithConnection) return;

    await deleteIntegrationConnection({
      variables: {
        id,
        integrationId: integrationWithConnection.id,
        projectId: integrationWithConnection.projectId,
      },
    });

    editItems([
      {
        ...integrationWithConnection,
        connectionStatus: false,
        integrationConnection: null,
      },
    ]);

    setConnections(
      connections.filter(
        (connection) =>
          connection.integrationId !== integrationWithConnection.id
      )
    );

    onClose();
  };

  return deleteConnection;
};
