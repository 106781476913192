import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  Message,
  MessageFragmentFragmentDoc,
  MessageType,
  ProjectsUnreadCountDocument,
  UnreadCountDocument,
  useMarkMessageMutation,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useRouting } from '@pro4all/shared/routing-utils';

export type CountQueryType = {
  all: number;
  draft: number;
  id: string;
  inbox: number;
  sent: number;
};

export const useOptimisticMarkMessage = (shouldSkipCounterUpdate = false) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { params } = useRouting();
  const { projectId } = params;
  const { organizationId } = useOrganizationContext();
  const projectOrOrganizationId = projectId || organizationId;

  return useMarkMessageMutation({
    update: (cache, { data }, { variables }) => {
      const selectedMessageAmount = {
        all: 0,
        draft: 0,
        inbox: 0,
        sent: 0,
      };
      if (data?.markMessage?.success) {
        // mark table lines as read / unread
        data?.markMessage.ids.forEach((messageId) => {
          const fragment = MessageFragmentFragmentDoc;
          const id = `Message:${messageId}`;

          const message: Message | null = cache.readFragment({
            fragment,
            fragmentName: 'MessageFragment',
            id,
          });

          if (message) {
            const optimisticMessage: Message = {
              ...message,
              read: variables?.read,
            };

            cache.writeFragment({
              data: {
                ...optimisticMessage,
              },
              fragment,
              fragmentName: 'MessageFragment',
              id,
            });

            if (message.messageType !== MessageType.Draft)
              selectedMessageAmount.all = selectedMessageAmount.all + 1;
            if (
              message.messageType === MessageType.Inbox ||
              message.messageType === MessageType.SentAndInbox
            )
              selectedMessageAmount.inbox = selectedMessageAmount.inbox + 1;
            if (
              message.messageType === MessageType.Sent ||
              message.messageType === MessageType.SentAndInbox
            )
              selectedMessageAmount.sent = selectedMessageAmount.sent + 1;
          }
        });

        // chnge sidebar counters
        const countQueryVariables = {
          isOrganization: !projectId ? true : false,
        };
        const countQueryArray: {
          unreadCount: CountQueryType[];
        } | null = cache.readQuery({
          optimistic: true,
          query: UnreadCountDocument,
          variables: countQueryVariables,
        });

        const countQuery = countQueryArray?.unreadCount?.find?.(
          (item) => item.id === projectOrOrganizationId
        );

        if (variables?.messages && !shouldSkipCounterUpdate) {
          /* if countQuery exists (current item in apollo cache) we need to update it using a .map
          if doesn't exist, we need to create a new entry using .concat
          
          this happens because the BE just return projects with messages on it
          so in that cases we need to create the projct in the apollo cache */
          const modifiedUnreadCount = countQuery
            ? countQueryArray?.unreadCount?.map?.((item) => {
                if (item.id !== projectOrOrganizationId) return item;
                return {
                  all: calculateCountNumber({
                    currentCount: countQuery.all,
                    numberOfMessages: selectedMessageAmount.all,
                    read: variables.read,
                  }),
                  draft: countQuery.draft,
                  id: projectOrOrganizationId,
                  inbox: calculateCountNumber({
                    currentCount: countQuery.inbox,
                    numberOfMessages: selectedMessageAmount.inbox,
                    read: variables.read,
                  }),
                  sent: calculateCountNumber({
                    currentCount: countQuery.sent,
                    numberOfMessages: selectedMessageAmount.sent,
                    read: variables.read,
                  }),
                };
              })
            : (countQueryArray?.unreadCount || []).concat({
                all: calculateCountNumber({
                  currentCount: 0,
                  numberOfMessages: selectedMessageAmount.all,
                  read: variables.read,
                }),
                draft: calculateCountNumber({
                  currentCount: 0,
                  numberOfMessages: selectedMessageAmount.draft,
                  read: variables.read,
                }),
                id: projectOrOrganizationId,
                inbox: calculateCountNumber({
                  currentCount: 0,
                  numberOfMessages: selectedMessageAmount.inbox,
                  read: variables.read,
                }),
                sent: calculateCountNumber({
                  currentCount: 0,
                  numberOfMessages: selectedMessageAmount.sent,
                  read: variables.read,
                }),
              });

          cache.writeQuery({
            data: {
              unreadCount: modifiedUnreadCount,
            },
            query: UnreadCountDocument,
            variables: countQueryVariables,
          });
        }

        // change my projects table messages counter (/my-overview/my-projects)
        const projectsCountQuery: {
          projectsUnreadCount: { id: string; unreadCount: number | null }[];
        } | null = cache.readQuery({
          optimistic: true,
          query: ProjectsUnreadCountDocument,
        });

        if (projectsCountQuery) {
          const projectsUnreadCount =
            projectsCountQuery.projectsUnreadCount.map((item) => {
              if (item.id !== projectOrOrganizationId) return item;

              return {
                id: item.id,
                unreadCount: calculateCountNumber({
                  currentCount: item.unreadCount || 0,
                  numberOfMessages: selectedMessageAmount.all,
                  read: variables?.read || false,
                }),
              };
            });
          cache.writeQuery({
            data: { projectsUnreadCount },
            query: ProjectsUnreadCountDocument,
          });
        }
        // }
      } else {
        enqueueSnackbar(
          t(data?.markMessage?.errors?.[0] ?? 'Something went wrong')
        );
      }
    },
  });
};

export const calculateCountNumber = ({
  read,
  numberOfMessages,
  currentCount,
}: {
  currentCount: number;
  numberOfMessages: number;
  read: boolean;
}) => {
  if (read === false) {
    return currentCount + numberOfMessages;
  } else return currentCount - numberOfMessages;
};
