import React from 'react';

import { FormControlLabel } from '@pro4all/shared/mui-wrappers';
import { ReportConfigTypes } from '@pro4all/shared/types';
import { Checkbox } from '@pro4all/shared/ui/inputs';

import { BoxSectionFieldInclude } from '../FormStyles';
import { getNestedItemIds } from '../helpers/getNestedItemIds';

type SectionFieldIncludeType = Pick<
  ReportConfigTypes,
  | 'checked'
  | 'flattenedItem'
  | 'items'
  | 'setHiddenSectionsAndFields'
  | 'template'
>;

// We cannot memoize this component because we can select a different template
// and then instances of this component must unmount.
// React.Memo would prevent unmounting.
export const SectionFieldInclude = ({
  checked,
  flattenedItem,
  items,
  setHiddenSectionsAndFields,
  template,
}: SectionFieldIncludeType) => {
  const { displayName, id, level, name, type } = flattenedItem;

  return (
    <BoxSectionFieldInclude level={level} type={type}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={() => {
              const nestedItemIds = getNestedItemIds({ id, items });
              const touchedIds = [id, ...nestedItemIds];
              setHiddenSectionsAndFields({
                add: checked ? true : false,
                ids: touchedIds,
                templateId: template.id,
              });
            }}
          />
        }
        id={id}
        label={displayName || name}
      />
    </BoxSectionFieldInclude>
  );
};
