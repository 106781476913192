import {
  FieldDefinition,
  Group,
  Member,
  MetaDataOption,
  useGroupsAndUsersIncludeQuery,
  User,
  ValueTypeName,
} from '@pro4all/graphql';
import { useTranslateTbq } from '@pro4all/shared/qc-meta';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { IconName } from '@pro4all/shared/ui/icons';

export const useSelection = ({ item }: { item: FieldDefinition }) => {
  const { params, searchParams } = useRouting();
  const { translateTbq } = useTranslateTbq();
  const projectId = params.projectId ?? searchParams.get('projectId');

  const { valueType } = item;
  const isUserSelection = item.type === ValueTypeName.UserSelection;
  const isStatus = item.type === ValueTypeName.Status;

  const { options } = valueType ?? {};

  const { data } = useGroupsAndUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isUserSelection,
    variables: {
      includeEmail: true,
      includeIsAdmin: true,
      includeOrganization: true,
      projectId: projectId,
    },
  });
  const members = (data?.groupsAndUsers as Member[]) || [];

  const membersToMeta = (members: Member[]) => {
    const memberOptions: Option[] = members.map((member) => {
      const isGroup = member.__typename === 'Group';

      if (isGroup) {
        const memberTyped = member as unknown as Group;
        return {
          groupOption: { totalUsers: memberTyped.totalUsers },
          id: memberTyped.id,
          label: translateTbq(memberTyped.displayName),
          type: 'Group',
        };
      } else {
        const memberTyped = member as unknown as User;
        return {
          id: memberTyped.id,
          label: translateTbq(memberTyped.displayName),
          type: 'User',
          userOption: {
            email: memberTyped.email,
            isAdmin: memberTyped.isAdmin,
            organization: memberTyped.organization,
          },
        };
      }
    });
    return memberOptions;
  };

  const toMembers = (
    value: Option | Option[] | { iconName: IconName; id: string; label: string }
  ) => {
    if (!value) return null;
    const ids = Array.isArray(value)
      ? value.map((value) => value.id)
      : [value.id];
    const filteredMembers = membersToMeta(
      members.filter((member: Member) => ids.includes(member.id))
    );
    return filteredMembers;
  };

  const toOption = (option: MetaDataOption): Option | null =>
    option?.name ? { id: option.name, label: translateTbq(option.name) } : null;

  const toOptionStatus = (option: MetaDataOption): Option | null =>
    option?.name
      ? {
          iconColor: option.color,
          iconName: 'tinyCircle',
          id: option.name,
          label: option.name,
        }
      : null;

  const selectOptions: Option[] = isUserSelection
    ? membersToMeta(members) ?? []
    : isStatus
    ? (options ?? []).map(toOptionStatus)
    : (options ?? []).map(toOption);

  return { selectOptions, toMembers };
};
