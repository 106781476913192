import React from 'react';

import { FieldDefinition } from '@pro4all/graphql';
import { HierarchyEditorProvider } from '@pro4all/shared/hierarchy-editor';
import { TextFieldProps } from '@pro4all/shared/ui/inputs';

import { HierarchyListOutput } from './HierarchyListOutput';

export interface HierarchyListProps extends TextFieldProps {
  item: FieldDefinition;
  setValue?: (name: string, value: string) => void;
  value: string;
}

export const HierarchyList = ({
  item,
  name,
  setValue,
  value,
}: HierarchyListProps) => (
  <HierarchyEditorProvider>
    <HierarchyListOutput
      item={item}
      name={name}
      setValue={setValue}
      value={value}
    />
  </HierarchyEditorProvider>
);
