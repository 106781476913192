import React from 'react';
import styled, { keyframes } from 'styled-components';

import type { CircularProgressProps } from '@pro4all/shared/mui-wrappers';

import { CenteredContents } from '@pro4all/shared/ui/layout';
import { Shape, ShapeType } from '@pro4all/shared/ui/shapes';

interface Props extends CircularProgressProps {
  fullHeight?: boolean;
}

const animation = keyframes`
  0% { clip-path: inset(0 100% 0 0 ) }
  50% { clip-path: inset(0) }
  100% { clip-path: inset(0 100% 0 0 ) }
`;

const AnimatedShape = styled(Shape)`
  && {
    font-size: 80px;
    position: absolute;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-expo;
  }
`;

// Shapes that work with the animation
const usableShapes: ShapeType[] = ['build', 'flow1', 'flow2'];

// We use 1 shape for the whole session that is randomized
const sessionShape =
  usableShapes[Math.floor(Math.random() * usableShapes.length)];

export const Loader: React.FC<Props> = ({ className, fullHeight = true }) => (
  <CenteredContents
    $fullHeight={fullHeight}
    className={className}
    data-testid="loader"
  >
    <AnimatedShape type={sessionShape} />
  </CenteredContents>
);
