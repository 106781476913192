import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import {
  FieldDefinition,
  StandardItemKey,
  TemplateType,
  ValueTypeName,
} from '@pro4all/graphql';
import { DndField, DndTypes } from '@pro4all/shared/config';
import { useActionNamingMapping } from '@pro4all/shared/label-config';
import { Box } from '@pro4all/shared/mui-wrappers';
import { ActionProps } from '@pro4all/shared/types';
import { ContextMenuInstance } from '@pro4all/shared/ui/context-menu-instance';
import { Switch } from '@pro4all/shared/ui/inputs';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { useFieldConfig } from '../configs/useFieldConfig';

import { ConditionButton } from './ConditionButton';
import { fieldCardsGlobalInfo } from './fieldCardsGlobalInfo';
import {
  StyledDropZone,
  StyledFieldCustomCard,
  StyledIcon,
  StyledMarginBottomZone,
  StyledName,
  StyledTitleZone,
  StyledToggleZone,
  StyledType,
} from './Styles';
import {
  useFieldContext,
  useFieldSidebarContext,
} from './TemplateMutationContext';
import { useHidePaste } from './useHidePaste';

export const FieldCustomCard = ({
  displayNames = false,
  fieldDefinition,
  filterInvalidConditions,
  parentSection,
  setToggleIndicativeField,
  isMetaDataDms,
}: {
  displayNames?: boolean;
  fieldDefinition: FieldDefinition;
  filterInvalidConditions?: (fieldDefinition: FieldDefinition) => void;
  isMetaDataDms: boolean;
  parentSection?: FieldDefinition;
  setToggleIndicativeField?: (value: string) => void;
}) => {
  const { t } = useTranslation();
  const fieldConfig = useFieldConfig();
  const { templateType } = useMetaDataContext();
  const { editField, insertField, pasteSection, removeField, reusableFields } =
    useFieldContext();
  const { setIsOpenFieldSidebar, setField } = useFieldSidebarContext();
  const { displayName, fieldDefinitionId, name, type } = fieldDefinition;

  const { hidePaste } = useHidePaste({ item: fieldDefinition });

  const isStandardItem = fieldDefinition.type === ValueTypeName.StandardItem;

  const reusableFieldIds = reusableFields.map((field) => field.id);
  const isReusable = Boolean(fieldDefinitionId);
  const isDeleted =
    fieldDefinitionId && !isStandardItem
      ? !reusableFieldIds.includes(fieldDefinitionId)
      : false;

  /* Action naming mapping for filter */
  const getActionNamingMapping = useActionNamingMapping();

  let iconName = fieldConfig[type]?.icon;
  let label = fieldConfig[type]?.label;

  if (isStandardItem) {
    const { name } = fieldDefinition;
    const nameStandardFieldDefinition = name as StandardItemKey;
    const cardData = fieldCardsGlobalInfo[nameStandardFieldDefinition];
    iconName = cardData.icon;
    label = cardData.label;
  }

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
    item: { fieldDefinition, move: true },
    type: DndTypes.INCLUDED_FIELD,
  });

  const [{ hoversOverCurrent }, drop] = useDrop({
    accept: [
      DndTypes.INCLUDED_FIELD,
      DndTypes.INCLUDED_SECTION,
      DndTypes.NOT_INCLUDED_FIELD,
    ],
    collect: (monitor) => ({
      hoversOverCurrent: Boolean(monitor.isOver({ shallow: true })),
    }),
    drop: (field: DndField, monitor) => {
      if (!monitor.didDrop()) {
        // Drop of an field or section
        insertField({
          fieldToInsert: field.fieldDefinition,
          move: field.move,
          nextField: fieldDefinition,
          parentSection,
        });
      }
    },
  });

  if (isDragging) return null;

  const handleRemoveField = (section: FieldDefinition) => {
    filterInvalidConditions(section);
    removeField(section);
  };

  const editAction: ActionProps = {
    ariaLabel: t('Edit'),
    dataTestId: `edit-${fieldDefinition.id}`,
    disabled: false,
    key: `edit-${fieldDefinition.id}`,
    label: t('Edit'),
    onClick: () => {
      setIsOpenFieldSidebar(true);
      setField(fieldDefinition);
    },
    startIcon: 'edit',
  };

  const deleteAction: ActionProps = {
    ariaLabel: t('Delete'),
    dataTestId: `delete-${fieldDefinition.id}`,
    disabled: false,
    key: `delete-${fieldDefinition.id}`,
    label: t('Delete'),
    onClick: () => handleRemoveField(fieldDefinition),
    startIcon: 'delete',
  };

  const pasteAction: ActionProps = {
    ariaLabel: t('Paste above'),
    dataTestId: `paste-${fieldDefinition.id}`,
    disabled: hidePaste(),
    key: `paste-${fieldDefinition.id}`,
    label: t('Paste above'),
    onClick: () =>
      pasteSection({ parentSection, targetSectionOrField: fieldDefinition }),
    startIcon: 'clipboard',
  };

  const reusableFieldActions =
    templateType === TemplateType.Form || templateType === TemplateType.Snag
      ? [pasteAction, deleteAction]
      : [deleteAction];
  const inlineFieldActions =
    TemplateType.Form || templateType === TemplateType.Snag
      ? [pasteAction, editAction, deleteAction]
      : [editAction, deleteAction];
  const menuItems: ActionProps[] = isReusable
    ? reusableFieldActions
    : inlineFieldActions;

  return drag(
    drop(
      <div>
        {hoversOverCurrent && <StyledDropZone />}
        <StyledFieldCustomCard isDeleted={isDeleted} isReusable={isReusable}>
          <StyledTitleZone>
            <Box>
              <StyledIcon iconName={iconName} />
              <StyledName variant="h6">
                {isStandardItem
                  ? t(label, getActionNamingMapping(fieldDefinition.name))
                  : isReusable
                  ? displayNames
                    ? displayName
                    : name
                  : displayName}
              </StyledName>
              {!isStandardItem && <StyledType>{label}</StyledType>}
            </Box>
          </StyledTitleZone>
          {(templateType === TemplateType.Form ||
            templateType === TemplateType.Tbq) &&
            fieldDefinition.type !== ValueTypeName.Description && (
              <StyledToggleZone
                onClick={() =>
                  editField({
                    ...fieldDefinition,
                    linksAllowed: !fieldDefinition.linksAllowed,
                  })
                }
              >
                <Switch checked={fieldDefinition.linksAllowed} />
                {t('Place snags')}
              </StyledToggleZone>
            )}
          {fieldDefinition.type === ValueTypeName.Status && !isMetaDataDms && (
            <StyledToggleZone
              onClick={() => {
                setToggleIndicativeField(fieldDefinition.id);
              }}
            >
              <Switch checked={fieldDefinition.indicative ?? false} />
              {t('Highlight')}
            </StyledToggleZone>
          )}

          {!isStandardItem &&
            fieldDefinition.type !== ValueTypeName.Description && (
              <StyledToggleZone
                onClick={() => {
                  editField({
                    ...fieldDefinition,
                    required: !fieldDefinition.required,
                  });
                }}
              >
                <Switch checked={fieldDefinition.required} />
                {t('Required')}
              </StyledToggleZone>
            )}
          {templateType !== TemplateType.Document && (
            <div onClick={(event) => event.stopPropagation()}>
              <ConditionButton sectionId={fieldDefinition.id} />
            </div>
          )}
          <ContextMenuInstance
            disableBorder
            menuItems={menuItems}
            name={fieldDefinition.name}
          />
        </StyledFieldCustomCard>
        <StyledMarginBottomZone />
      </div>
    )
  );
};
