import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  Document,
  LockType,
  useCheckCommandStatusQuery,
  useDocumentClientDownloadUrlsQuery,
} from '@pro4all/graphql';
import { StorageKeys, TrackingEvent } from '@pro4all/shared/config';
import { useClientRedirectContext } from '@pro4all/shared/contexts';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useEditFile } from '@pro4all/shared/hooks/src/documents';
import {
  getAuthUserDisplayName,
  useContextScopedOrganizationId,
} from '@pro4all/shared/identity';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
} from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { CommandStatus, Position } from '@pro4all/shared/types';
import { useLock, useUnlock } from '@pro4all/shared/ui/actions';
import { ProstreamLogo } from '@pro4all/shared/ui/prostream-logo';
import {
  useOptimisticResponseContext,
  useTableCheck,
} from '@pro4all/shared/ui/table';
import { openInIframe } from '@pro4all/shared/utils';
import { useAnalytics } from '@pro4all/shared/vendor';

import { Paragraph, RedirectLink, Root } from './styled';

type ClientRedirectProps = {
  handleClose: () => void;
  open: boolean;
};

export const ClientRedirect = ({ open, handleClose }: ClientRedirectProps) => {
  const lock = useLock();
  const unlock = useUnlock();
  const { uncheckAllRows } = useTableCheck();
  const { editItems, setItem } = useOptimisticResponseContext<Document>();

  const { clientUrl, position, document, isPublishAction, commandId } =
    useClientRedirectContext();
  const { searchParams } = useRouting();
  const editFile = useEditFile();

  const { userId } = AuthService.getProfile();
  const userName = getAuthUserDisplayName();

  const { t } = useTranslation();

  const { setLocalStorageItem } = useLocalStorage<boolean>({
    key: StorageKeys.PROSTREAM_CLIENT,
  });

  const [timeElapsed, setTimeElapsed] = useState(false);
  const [countdown, setCountdown] = useState(10);

  //Tracking events
  const { track } = useAnalytics();
  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  // Check Status of the client application
  const { data, stopPolling } = useCheckCommandStatusQuery({
    fetchPolicy: 'network-only',
    pollInterval: 1000,
    variables: {
      id: commandId,
    },
  });

  const { data: clientDownloadUrlResponse } =
    useDocumentClientDownloadUrlsQuery({
      fetchPolicy: 'cache-and-network',
    });

  const clientDownloadUrl = navigator.platform.includes('Mac')
    ? clientDownloadUrlResponse?.documentClientDownloadUrls?.downloadUrlMac
    : clientDownloadUrlResponse?.documentClientDownloadUrls?.downloadUrlWindows;

  useEffect(() => {
    if (
      data?.checkCommandStatus?.status === CommandStatus.Acknowledged ||
      data?.checkCommandStatus?.status === CommandStatus.Done
    ) {
      stopPolling();
      setLocalStorageItem(true);
      if (!isPublishAction) {
        lockDocument();
      }
      handleClose();
    }
    if (
      (data && !data.checkCommandStatus) ||
      data?.checkCommandStatus?.status === CommandStatus.Failed
    ) {
      stopPolling();
      setLocalStorageItem(false);
    }
    if (
      (data && !data.checkCommandStatus) ||
      data?.checkCommandStatus?.status === CommandStatus.Pending
    ) {
      setLocalStorageItem(false);
    }
  }, [data, handleClose, stopPolling]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeElapsed(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!timeElapsed && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeElapsed, countdown]);

  const lockDocument = async () => {
    if (document) {
      const response = await lock({ document, showError: true });

      // If locking was successful.
      if (response) {
        // Optimistic response.
        const updatedItem = {
          ...document,
          lockType: LockType.Prostream,
          lockedAt: new Date().toISOString(),
          lockedBy: {
            displayName: userName,
            id: userId,
          },
        };
        setItem(updatedItem);
        editItems([updatedItem]);
      }
    }
  };

  const redirectToApplication = async () => {
    if (document) {
      openInIframe(clientUrl);

      if (!isPublishAction) {
        track(TrackingEvent.VersionOpenedWithClient, {
          folderId: document.folderId,
          organizationId,
          projectId: document.projectId,
          userId,
          versionId: document.versionId,
        });
      } else
        track(TrackingEvent.VersionPublishedFromClient, {
          folderId: document.folderId,
          organizationId,
          projectId: document.projectId,
          userId,
          versionId: document.versionId,
        });

      const position = window.sessionStorage.getItem(
        StorageKeys.ACTION_TRIGGERED_FROM
      );

      if (position !== Position.Contextmenu) uncheckAllRows();
    }
  };

  useEffect(() => {
    //checking if press arrow back
    window.addEventListener('popstate', handleClose);

    if (!document || !position) {
      handleClose();
    } else {
      redirectToApplication().then();
    }
  }, [clientUrl]);

  const downloadFile = async () => {
    if (document && position) {
      // Unlock file first, otherwise the file cannot be downloaded
      const res = await unlock({ document, withNewVersion: false });
      // If locking was successful.
      if (res) {
        // Optimistic response.
        const updatedItem: Document = {
          ...document,
          lockType: LockType.None,
          lockedAt: null,
          lockedBy: null,
        };
        setTimeout(() => {
          setItem(updatedItem);
          editItems([updatedItem]);

          const position = window.sessionStorage.getItem(
            StorageKeys.ACTION_TRIGGERED_FROM
          );

          if (position !== Position.Contextmenu) uncheckAllRows();
        }, 100);

        window.sessionStorage.setItem(
          StorageKeys.ACTION_TRIGGERED_FROM,
          position
        );

        const hideEditDocumentModal = Boolean(
          localStorage.getItem(StorageKeys.HIDE_EDIT_DOCUMENT_MODAL)
        );

        if (hideEditDocumentModal) {
          await editFile(document);
        } else {
          searchParams.set({
            action: 'editFile',
            editDocumentId: document.id,
          });
        }
      }

      handleClose();
    }
  };

  const publishFile = async () => {
    if (document && position) {
      // We store the current document in sessionStorage so that the openFileInputPublish method can pull it out from there.
      window.sessionStorage.setItem(
        'p4a:ps:currentDocument',
        JSON.stringify(document)
      );

      // We store the position from where this action was triggered in sessionStorage so that the selectFileToPublish` method
      // can pull it out from there to deselect the selected row properly.
      window.sessionStorage.setItem(
        StorageKeys.ACTION_TRIGGERED_FROM,
        position
      );

      handleClose();
      searchParams.set({
        action: 'publishVersion',
        publishDocumentId: document.id,
      });
    }
  };

  return (
    <Dialog fullScreen onClose={handleClose} open={open}>
      <DialogContent>
        <Root>
          <Box p={3}>
            <ProstreamLogo type="square" />
          </Box>
          <Typography fontWeight={400} gutterBottom variant="h1">
            {timeElapsed
              ? t('Could not find Prostream Client')
              : t('Redirecting to your Prostream Client...')}
          </Typography>
          <Paragraph paragraph variant="body1">
            {timeElapsed
              ? t(
                  'Are you sure you downloaded Prostream Client? You can also download the document.'
                )
              : t(
                  "With the Prostream Client the file opens directly with your preferred editing software and publish the new version to Prostream when you're done."
                )}
            {!timeElapsed && (
              <Box sx={{ ml: 1 }}>
                <RedirectLink
                  color="inherit"
                  onClick={isPublishAction ? publishFile : downloadFile}
                >
                  {!isPublishAction
                    ? t('You can also download the document for editing.')
                    : t('You can also publish the document.')}
                </RedirectLink>
              </Box>
            )}
          </Paragraph>
          {timeElapsed ? (
            <Box display="flex" gap={2} justifyContent="center">
              <Button
                color="primary"
                onClick={isPublishAction ? publishFile : downloadFile}
                variant="outlined"
              >
                {isPublishAction
                  ? t('Publish Document')
                  : t('Download Document')}
              </Button>
              <Button
                color="primary"
                href={clientDownloadUrl}
                style={{ boxShadow: 'none' }}
                target="_blank"
                variant="contained"
              >
                {t('Install Prostream Client')}
              </Button>
            </Box>
          ) : (
            <Box mt={4} textAlign="center">
              <Typography
                className={`text-gray-600 ${
                  countdown <= 3 ? 'text-red-500' : ''
                }`}
                variant="body2"
              >
                {countdown} {t('seconds')}
              </Typography>
            </Box>
          )}
        </Root>
      </DialogContent>
    </Dialog>
  );
};
