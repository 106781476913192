import styled, { css } from 'styled-components';

import { InputLabel } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Chip } from '@pro4all/shared/ui/general';
import { Section } from '@pro4all/shared/ui/section';

const FullWidthRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const FlexEnd = css`
  display: flex;
  align-items: flex-end;
`;

export const RecipientsWrap = css`
  ${FullWidthRow}
`;

const Column = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledLabel = styled(InputLabel)<{
  $leftPos?: boolean;
  $tight?: boolean;
}>`
  && {
    display: ${({ $leftPos }) => ($leftPos ? 'table-cell' : 'flex')};
    min-width: ${({ $tight }) => ($tight ? '3rem' : '6rem')};
    ${({ theme }) => theme.breakpoints.down('md')} {
      min-width: ${({ $tight }) => ($tight ? '2rem' : '4rem')};
      font-weight: 500;
    }
    color: ${({ $tight }) =>
      $tight ? customColors.grey600 : customColors.grey700};
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1px;
  }
`;

export const StyledToggle = styled(Section.Toggle)`
  align-items: flex-start;
  flex: 0;
`;

export const AddressFields = styled.div<{ $tight?: boolean }>`
  ${Column}
  margin: ${({ $tight }) => ($tight ? '0 0 1rem' : '1rem 0')};
  gap: ${({ $tight }) => ($tight ? '.2rem' : '1rem')};
  color: ${({ $tight }) =>
    $tight ? customColors.grey600 : customColors.grey700};
  max-width: 100%;
`;

export const HeaderWrap = styled.div`
  ${Column}
  align-items: flex-start;
  margin-bottom: 14px;
`;

export const HeaderRow = styled.div`
  ${FullWidthRow}
  gap: .5rem;
  max-width: 100%;
`;

export const InputWrap = styled.div`
  ${RecipientsWrap}
  max-width: 100%;
  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Initials = styled.div<{ $size: number }>`
  display: flex;
  color: white;
  max-height: ${({ $size }) => $size}px;
  max-width: ${({ $size }) => $size}px;
  min-height: ${({ $size }) => $size}px;
  min-width: ${({ $size }) => $size}px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  justify-content: center;
  line-height: 24px;
  font-size: 12px;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const UserDisplayWrap = styled.div<{ $receiverCount: number }>`
  ${FlexEnd}
  flex-flow: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: ${({ $receiverCount }) => $receiverCount > 1 && 'column'};
    align-items: ${({ $receiverCount }) => $receiverCount > 1 && 'flex-start'};
  }
`;

export const TagList = styled.div`
  ${FlexEnd}
  overflow-x: hidden;
  flex-flow: inherit;
`;

export const UserChip = styled(Chip)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const HeaderButtons = styled.div`
  color: ${customColors.grey700};
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
