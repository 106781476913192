import { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { Document } from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useClientRedirectContext } from '@pro4all/shared/contexts';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikCheckbox, FormikForm } from '@pro4all/shared/ui/formik';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';

import { getDownloadName } from '../share/getDownloadName';

import { useEditFile } from './useEditFile';

export const EditFileForm = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const documentId = useRef(searchParams.get('editDocumentId'));
  const {
    state: { items },
  } = useOptimisticResponseContext<Document>();
  const editFile = useEditFile();
  const document = items.find((item) => item.id === documentId.current);

  //Redirect to application
  const { setIsDialogOpen } = useClientRedirectContext();

  const handleEdit = async (values: { hideModal: boolean }) => {
    const { hideModal } = values;

    if (hideModal) {
      localStorage.setItem(StorageKeys.HIDE_EDIT_DOCUMENT_MODAL, 'true');
    }

    await editFile(document);
    onClose();
  };

  return document ? (
    <Formik initialValues={{ hideModal: false }} onSubmit={handleEdit}>
      {() => (
        <FormikForm>
          <Trans
            components={{ strong: <strong /> }}
            defaults="When you download the file, the document will be locked so others cannot edit it while you're editing. When you're done, you can upload the new version to unlock it. Only a document with the name <strong>{{documentName}}</strong> can be uploaded as a new version.
            <br/> <br/> You can also use the Prostream Client to edit. With this tool, the file opens directly with your preferred editing software and automatically uploads the new version to Prostream when you're done."
            values={{
              documentName: getDownloadName(document),
              fileExtension: document?.extension
                ? `.${document?.extension}`
                : '',
              versionId: document?.versionId,
            }}
          />
          <FormikCheckbox
            label={t("Don't show warning again")}
            name="hideModal"
          />
          <FormFooter
            onClose={onClose}
            onSubmitWithoutClose={() => {
              setIsDialogOpen(true);
              onClose();
            }}
            saveLabel={t('Prostream Client')}
            showSubmitWithoutClose
            submitLabel={t('Download')}
          />
        </FormikForm>
      )}
    </Formik>
  ) : null;
};
