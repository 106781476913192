import { useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';

import { NO_VALUE } from '@pro4all/shared/constants';
import { Box } from '@pro4all/shared/mui-wrappers';
import { TagProps } from '@pro4all/shared/ui/tag';

import * as Styled from './FilterSelect.styles';
import { SelectedOptionsProps } from './types';
import { useOptionRendering } from './useOptionRendering';

export const useSelectedOptions = ({
  columnValues,
  getOptionCount,
  getOptions,
  onSet,
  renderOption,
}: SelectedOptionsProps) => {
  const currentOptions = getOptions() as string[];

  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    currentOptions || []
  );

  const [unselectedOptions, setUnselectedOptions] = useState<string[]>(
    columnValues || []
  );

  const currentOptionsRef = useRef(currentOptions);

  const { selectableOptionRendering } = useOptionRendering();

  useEffect(() => {
    if (!isEqual(currentOptionsRef.current, currentOptions)) {
      setSelectedOptions(currentOptions || []);
      if (!currentOptions) {
        setUnselectedOptions(columnValues || []);
      }
    }
    currentOptionsRef.current = currentOptions;
  }, [columnValues, currentOptions]);

  const removeOption = (value: string) => {
    const selectedOptionsUpdated = selectedOptions.filter(
      (option) => option !== value
    );
    setSelectedOptions(selectedOptionsUpdated);
    setUnselectedOptions([...unselectedOptions, value].sort());
    onSet(selectedOptionsUpdated);
  };

  const selectOption = (value: string) => {
    const selectedOptionsUpdated = [...selectedOptions, value].sort();
    setSelectedOptions(selectedOptionsUpdated);
    setUnselectedOptions(
      unselectedOptions.filter((option) => option !== value)
    );
    onSet(selectedOptionsUpdated);
  };

  const toTag = (value: string): TagProps => ({
    color: 'default',
    id: value,
    name: value,
    onClickTag: () => {
      removeOption(value);
    },
    onDelete: () => {
      removeOption(value);
    },
    variant: 'outlined',
  });

  const tags = selectedOptions.map(toTag);

  const renderOptions = () => (
    <Styled.OptionsWrapper>
      {unselectedOptions.map((option) => {
        const count = getOptionCount ? getOptionCount(option) : 0;
        return count > 0 ? (
          <Styled.Option key={option} onClick={() => selectOption(option)}>
            {renderOption && option !== NO_VALUE ? (
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                {selectableOptionRendering({ option, renderOption })}({count})
              </Box>
            ) : (
              `${option} (${count})`
            )}
          </Styled.Option>
        ) : null;
      })}
    </Styled.OptionsWrapper>
  );

  return {
    renderOptions,
    selectedOptions,
    setSelectedOptions,
    setUnselectedOptions,
    tags,
  };
};
