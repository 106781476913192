import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityFolderPermission } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';
import { Footer } from '@pro4all/shared/ui/footer';
import { Backdrop } from '@pro4all/shared/ui/backdrop';
import {
  BigMessageNoOrganizationUsers,
  BigMessageNoProjectUsers,
  DiscardWrapper,
} from '@pro4all/shared/ui/messages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import { Table, TableContextProvider } from '@pro4all/shared/ui/table';

import {
  PermissionsEntityMatrixProvider,
  usePermissionsEntityMatrixContext,
  useSetPermissionsEntityInLocalState,
} from './entity-context/PermissionsEntityMatrixProvider';
import { EntityPermission } from './entity-context/types';
import { useEntityPermissionColumns } from './hooks/useEntityPermissionColumns';
import { useFolderPermissions } from './hooks/useFolderPermissions';
import { useGetFilteredEntities } from './hooks/useGetFilteredEntities';
import { useSubmit } from './hooks/useSubmitEntity';

export const UserFolderPermissionTableComponent: React.FC = () => {
  const { t } = useTranslation();
  const { params } = useRouting();
  const { projectId } = params;

  const { folder, error, loading } = useFolderPermissions();
  const userFolderPermissions = folder?.entityFolderPermissions;

  const entityPermissions = useMemo(() => {
    const permissions: EntityPermission[] =
      folder?.entityFolderPermissions?.map((entityFolderPermission) => ({
        breakInheritance: entityFolderPermission.breakInheritance,
        entityId: entityFolderPermission.memberId,
        folderId: folder?.id,
        folderPermissions: entityFolderPermission.folderPermissions,
        inheritedFrom: entityFolderPermission.inheritedFrom,
        inheritedFromParentFolders:
          entityFolderPermission.inheritedFromParentFolders,
        name: entityFolderPermission.user?.displayName,
        parentFolderId: folder?.parentFolderId,
        subFoldersPermissions: entityFolderPermission.subFoldersPermissions,
      }));
    return permissions;
  }, [folder]);

  useSetPermissionsEntityInLocalState(entityPermissions);

  const columns = useEntityPermissionColumns({
    folder,
  });

  const noContent = Boolean(userFolderPermissions === null && !loading);

  const {
    resetPermissions,
    state: { savePermissions, showBackdrop },
  } = usePermissionsEntityMatrixContext();

  const onSubmit = useSubmit();

  const itemsFiltered = useGetFilteredEntities(folder?.entityFolderPermissions);

  return (
    <ResponseWrapper error={error} isLoading={loading && !folder}>
      {noContent ? (
        <NoUsersMessage projectId={projectId} />
      ) : (
        <>
          {showBackdrop && <Backdrop />}
          <DiscardWrapper
            dirty={savePermissions.length > 0}
            onSubmit={() => onSubmit()}
            values={savePermissions}
          >
            <TableContextProvider
              checkable={false}
              columns={columns}
              id="table-member-folder-permissions"
              items={itemsFiltered}
            >
              <Box flex="auto">
                <Table<EntityFolderPermission> rowHeight={32} />
              </Box>
              <Box>
                <Footer pb={3} pt={2} px={3}>
                  <Button
                    disabled={savePermissions.length === 0}
                    onClick={resetPermissions}
                  >
                    {t('Reset changes')}
                  </Button>
                  <Button
                    disabled={savePermissions.length === 0}
                    onClick={onSubmit}
                    startIcon="save"
                    type="submit"
                    variant="contained"
                  >
                    {t('Save')}
                  </Button>
                </Footer>
              </Box>
            </TableContextProvider>
          </DiscardWrapper>
        </>
      )}
    </ResponseWrapper>
  );
};

const NoUsersMessage = ({ projectId }: { projectId?: string }) =>
  projectId ? <BigMessageNoProjectUsers /> : <BigMessageNoOrganizationUsers />;

export const UserFolderPermissionTable = () => (
  <PermissionsEntityMatrixProvider>
    <UserFolderPermissionTableComponent />
  </PermissionsEntityMatrixProvider>
);
