import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  RieResult,
  RieSection,
  Task,
  TbqAnswer,
  TbqRieResult,
} from '@pro4all/graphql';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { TaskCounter } from '@pro4all/shared/ui/general';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';

export const ResultTypesRieResult = {
  AttentionRequired: 'Attention required',
  ImprovementRequired: 'Improvement required',
  MeasuresRequired: 'Measures required',
  NoPriority: 'No priority',
  NotAnswered: 'Not answered',
  WorkOnHold: 'Work on hold',
};

export const RESULT_BACKGROUND_COLORS_MAP: Record<string, string> = {
  [ResultTypesRieResult.NoPriority]: '#36CCBA',
  [ResultTypesRieResult.AttentionRequired]: '#CDF3ED',
  [ResultTypesRieResult.MeasuresRequired]: '#F2F55B',
  [ResultTypesRieResult.ImprovementRequired]: '#F5B05B',
  [ResultTypesRieResult.WorkOnHold]: '#CF6679',
  [ResultTypesRieResult.NotAnswered]: '#dad8d8',
};

type ExtendedTbqRieResult = TbqRieResult & {
  state: number;
};

export const useTBQRieResultsColumns = (
  relevantTasksForAllQuestions: {
    question: TbqRieResult | TbqAnswer;
    tasks: Task[];
  }[]
) => {
  const { t } = useTranslation();

  type ResultColors = {
    backgroundColor: string;
    textColor: string;
  };

  const renderTaskCounter = ({ result }: { result: ExtendedTbqRieResult }) => {
    if (!result) return null;

    return (
      <TaskCounter
        id={result.id}
        relevantTasksForAllQuestions={relevantTasksForAllQuestions}
      />
    );
  };

  const getResultColors = (result: string): ResultColors => {
    const backgroundColor =
      RESULT_BACKGROUND_COLORS_MAP[result] || 'transparent';
    const textColor =
      result === ResultTypesRieResult.WorkOnHold ? '#FFFFFF' : '#212121';
    return { backgroundColor, textColor };
  };

  //TODO: get Location, Class and Priority data from endpoint
  const columns: ColumnProps<ExtendedTbqRieResult>[] = [
    {
      defaultSort: true,
      filterable: true,
      getValue: ({ section }) => section.name || '-',
      headerComponent: (
        <FilterHeader<TbqRieResult, RieSection>
          customCallbackId="section.name"
          defaultWidth={300}
          filterType={FilterHeaderType.Text}
          label={t('Question')}
          minWidth={100}
          propertyId="section"
          subPropertyId="name"
        />
      ),
      key: 'section.name',
      render: ({ section }) => (
        <MiddleEllipsis endLength={0} text={section.name} />
      ),
      width: 300,
    },
    {
      filterable: true,
      getValue: ({ risc }) => risc || '-',
      headerComponent: (
        <FilterHeader<TbqRieResult, {}>
          defaultWidth={160}
          filterType={FilterHeaderType.Number}
          label={t('Answer')}
          minWidth={100}
          propertyId="risc"
        />
      ),
      key: 'risc',
      width: 160,
    },
    {
      filterable: true,
      getValue: ({ result }) => result?.name || '-',
      headerComponent: (
        <FilterHeader<TbqRieResult, RieResult>
          defaultWidth={160}
          filterType={FilterHeaderType.Select}
          label={t('Result')}
          minWidth={100}
          propertyId="result"
          subPropertyId="name"
          translateOptions
        />
      ),
      key: 'result.name',
      render: ({ result }) => {
        const { backgroundColor, textColor } = getResultColors(
          result?.name || ''
        );
        return (
          <Highlight backgroundColor={backgroundColor} color={textColor}>
            {t((result?.name ?? '-').toString())}
          </Highlight>
        );
      },
      width: 160,
    },
    {
      getValue: () => '-',
      headerComponent: (
        <FilterHeader<TbqRieResult, {}>
          defaultWidth={50}
          filterType={FilterHeaderType.Text}
          label={t('Class')}
          minWidth={50}
          propertyId="class"
        />
      ),
      key: 'class',
      width: 160,
    },
    {
      getValue: () => '-',
      headerComponent: (
        <FilterHeader<TbqRieResult, {}>
          defaultWidth={50}
          filterType={FilterHeaderType.Text}
          label={t('Priority')}
          minWidth={50}
          propertyId="priority"
        />
      ),
      key: 'priority',
      width: 160,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<TbqRieResult, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Select}
          label={t('Category')}
          minWidth={100}
          propertyId="category"
        />
      ),
      key: 'category',
      width: 160,
    },
    {
      getValue: () => '-',
      headerComponent: (
        <FilterHeader<TbqRieResult, {}>
          defaultWidth={50}
          filterType={FilterHeaderType.Text}
          label={t('Location')}
          minWidth={50}
          propertyId="location"
        />
      ),
      key: 'location',
      width: 160,
    },
  ];

  const stateColumn: ColumnProps<ExtendedTbqRieResult> = {
    headerComponent: (
      <FilterHeader<ExtendedTbqRieResult, {}>
        defaultWidth={100}
        filterType={FilterHeaderType.Number}
        label={t('State')}
        minWidth={80}
        propertyId="state"
      />
    ),
    key: 'state',
    render: (result: ExtendedTbqRieResult) => renderTaskCounter({ result }),
    width: 100,
  };

  columns.push(stateColumn);

  return columns;
};

const Highlight = styled.p<{ backgroundColor: string; color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  line-height: 16px;
  padding: 3px 4px;
  border-radius: 4px;
  font-size: 13px;
  gap: 4px;
`;
