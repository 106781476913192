import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikForm, FormikInput } from '@pro4all/shared/ui/formik';
import { Text } from '@pro4all/shared/ui/typography';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useInitialValues } from './useInitialValues';
import { useOrganizationSettingsFormConfig } from './useOrganizationSettingsFormConfig';
import { useSubmit } from './useSubmit';
/* eslint-disable-next-line */
export interface DocumentSettingsFormProps {}

export const DocumentSettingsForm = () => {
  const { t } = useTranslation();
  const { getField, validationSchema } = useOrganizationSettingsFormConfig();
  const onSubmit = useSubmit();

  const initialValues = useInitialValues();

  const approveActionLabel = getField('approve_action_label');
  const rejectActionLabel = getField('reject_action_label');
  const finalizeActionLabel = getField('finalize_action_label');

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting }) => (
        <FormikForm>
          <FormWrapper>
            <Text variant="h4">{t('Documents')}</Text>
            <Text color="inherit" variant="h5">
              {t('Customizing action naming convention')}
            </Text>
            <FormikInput
              label={approveActionLabel?.displayName}
              name={approveActionLabel?.name}
            />
            <FormikInput
              label={rejectActionLabel?.displayName}
              name={rejectActionLabel?.name}
            />
            <FormikInput
              label={finalizeActionLabel?.displayName}
              name={finalizeActionLabel?.name}
            />
          </FormWrapper>

          <FormFooter
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            pb={3}
            pt={2}
            px={3}
            sticky
          />
        </FormikForm>
      )}
    </Formik>
  );
};
