import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useHierarchicalListsIncludeQuery } from '@pro4all/graphql';
import { SchemaExtender } from '@pro4all/shared/forms';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import {
  FormikCheckbox,
  FormikSearchableSelect,
} from '@pro4all/shared/ui/formik';

import { useFieldDefinitionFormConfig } from './useFieldDefinitionFormConfig';

export const CustomHierarchyListForm = () => {
  const { getField } = useFieldDefinitionFormConfig();
  const hierarchyListField = getField('hierarchyList');
  const multipleAnswersField = getField('multipleAnswers');
  const staticBreadcrumbField = getField('staticBreadcrumbs');

  const {
    params: { projectId },
  } = useRouting();

  const { data } = useHierarchicalListsIncludeQuery({
    fetchPolicy: 'cache-first',
    variables: {
      includeOrgItems: true,
      projectId,
    },
  });

  const hierarchyListOptions: Option[] = data
    ? data.hierarchicalLists.map(({ id, name }) => ({ id, label: name }))
    : [];

  return (
    <>
      <FormikSearchableSelect
        id={hierarchyListField.name}
        label={hierarchyListField.label}
        limitTags={20}
        name={hierarchyListField.name}
        options={hierarchyListOptions}
      />
      <FormikCheckbox
        label={multipleAnswersField.label}
        name={multipleAnswersField.name}
      />
      <FormikCheckbox
        label={staticBreadcrumbField.label}
        name={staticBreadcrumbField.name}
      />
    </>
  );
};

export const useCustomHierarchyListValidationSchema = (): Record<
  string,
  SchemaExtender
> => {
  const { t } = useTranslation();

  const hierarchyList = () =>
    Yup.object()
      .nullable()
      .when(['type'], {
        is: (type) => type?.id === 'HierarchyList',
        then: Yup.object().required(
          t("'{{name}}' is required", { name: t('Select hierarchical list') })
        ),
      });

  return {
    hierarchyList,
  };
};
