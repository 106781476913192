import React from 'react';
import { useField } from 'formik';
import { v4 as uuid } from 'uuid';

import { AutocompleteChangeReason } from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import {
  SearchableMultiSelect,
  SearchableMultiSelectType,
} from '@pro4all/shared/ui/inputs';

export const FormikSearchableMultiSelect: React.FC<
  SearchableMultiSelectType<Option, true, false, true> & { name: string }
> = ({
  autoFocus,
  canAddNewOptions,
  name,
  noOptionsText,
  onChange,
  onBlur,
  onClickTag,
  renderCustomInput,
  renderCustomOption,
  ...props
}) => {
  const [field, meta, helpers] = useField({ name });
  const hasError = Boolean(meta.touched) && Boolean(meta.error);

  const handleChange = async (
    event: React.ChangeEvent,
    value: Option[],
    reason: AutocompleteChangeReason
  ) => {
    // setValue is an async function. If it is not awaited setTouched will be called before the value is updated.
    await helpers.setValue(value);
    helpers.setTouched(true);
    onChange && onChange(event, value, reason);
  };

  const handleBlur = async (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // In this MultiSelect component, the user can add new options and press Enter to add it to the value.
    // If the user types a new option and then clicks outside the component, the new option is not added to the value.
    // Many of our customers get confused by this UI, so we need to add the new option to the value automatically.
    const nonConfirmedValue = event.target.value;
    if (nonConfirmedValue && canAddNewOptions) {
      const values = [
        ...field.value,
        { id: uuid(), inputValue: nonConfirmedValue, label: nonConfirmedValue },
      ];
      await helpers.setValue(values);
    }

    helpers.setTouched(true);
    onBlur && onBlur(event);
  };

  return (
    <SearchableMultiSelect
      {...props}
      {...field}
      autoFocus={autoFocus}
      canAddNewOptions={canAddNewOptions}
      error={hasError && Boolean(meta.error)}
      helperText={hasError && meta.error}
      noOptionsText={noOptionsText}
      onBlur={handleBlur}
      onChange={handleChange}
      onClickTag={onClickTag}
      renderCustomInput={renderCustomInput}
      renderCustomOption={renderCustomOption}
    />
  );
};
