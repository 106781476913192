import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FormFooter } from '@pro4all/shared/ui/form';
import {
  FormikForm,
  FormikInput,
  FormikRadioGroup,
  FormikTextarea,
} from '@pro4all/shared/ui/formik';
import { InfoBlock } from '@pro4all/shared/ui/info-block';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useCancelSubscriptionConfig } from './hooks/useCancelSubscriptionConfig';
import { useCancelSubscriptionOptions } from './hooks/useCancelSubscriptionOptions';
import { Reason } from './hooks/useCancelSubscriptionOptions';
import { useSubmitCancelSubscription } from './hooks/useSubmitCancelSubscription';

export interface CancelSubscriptionFields {
  description?: string;
  reason: string;
  reasonDifferent?: string;
}

export const CancelSubscription = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const onSubmit = useSubmitCancelSubscription();
  const options = useCancelSubscriptionOptions();
  const { getField, validationSchema } = useCancelSubscriptionConfig();
  const reasonField = getField('reason');
  const reasonDifferentField = getField('reasonDifferent');
  const descriptionField = getField('description');

  return (
    <Formik
      initialValues={{
        description: '',
        reason: '',
        reasonDifferent: '',
      }}
      onSubmit={(values: CancelSubscriptionFields) => onSubmit(values)}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting, values }) => (
        <FormikForm>
          <Box mt={3} width="640px">
            <FormWrapper>
              <InfoBlock
                paragraph={`${t(
                  'You are about to cancel your Prostream license and we are very sorry. Of course we keep your documents, users and groups safe. This way you can always come back to us, of course without extra costs. Hopefully see you soon!'
                )}.`}
              />
              <FormikRadioGroup
                label={reasonField?.label}
                name={reasonField?.name}
                options={options}
              />
              {values.reason === Reason.Different && (
                <FormikInput
                  name={reasonDifferentField?.name}
                  placeholder={t('Namely')}
                />
              )}

              <FormikTextarea
                label={descriptionField?.label}
                name={descriptionField?.name}
              />
            </FormWrapper>
          </Box>

          <FormFooter
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            onClose={() => searchParams.clear()}
            pb={3}
            pt={2}
            px={3}
            sticky
            submitLabel="Cancel permanently"
          />
        </FormikForm>
      )}
    </Formik>
  );
};
