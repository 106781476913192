import React from 'react';
import { useTranslation } from 'react-i18next';

import { QcPermissionCategory, Task } from '@pro4all/graphql';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import { Action } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { useTableCheck } from '@pro4all/shared/ui/table';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';
import { usePredefinedFilters } from '@pro4all/shared/ui/tasks';
import { useAnalytics } from '@pro4all/shared/vendor';

import { useCreateTask } from './useCreateTask';

export const TasksActionBar: React.FC = () => {
  const { createTaskButtonProps } = useCreateTask();
  const { checkedRows } = useTableCheck<Task>();
  const qcTasksReportFF = useFeatureFlag('quality-task-reports');

  const { t } = useTranslation();
  const { goTo, params } = useRouting();
  const { track } = useAnalytics();

  const predefinedFilters = usePredefinedFilters();

  const { qualityCreateAll, qualityCreateOwn } = useQCPermissions({
    category: QcPermissionCategory.Procedure,
  });

  const altActions = [
    qcTasksReportFF && {
      ariaLabel: t('Create report'),
      dataTestId: 'new-report',
      disabled: false,
      key: 'new-report',
      label: t('Create report'),
      onClick: () => {
        track(Action.CreateTaskReportButtonClicked, {
          itemAmount: checkedRows.length,
          projectId: params.projectId,
        });

        goTo({
          searchParams: { action: 'createReport' },
          state: {
            taskInstanceIds: checkedRows.map((row) => row.id),
          },
        });
      },
      startIcon: 'document',
    },
  ].filter((action) => Boolean(action)) as ActionProps[];

  return (
    <TableActionBar
      altActions={altActions}
      dataTestid="qc-tasks-action-bar"
      dataViewToggle
      mainActions={
        [
          (qualityCreateAll || qualityCreateOwn) && createTaskButtonProps,
        ].filter(Boolean) as ActionProps[]
      }
      predefinedFilters={predefinedFilters}
      search
    />
  );
};
