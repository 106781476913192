import React from 'react';

import { User } from '@pro4all/graphql';

import { IdentityTag } from './IdentityTag';

export const UserTag = ({ user }: { user: User }) => {
  const { displayName, id } = user || {};
  return <IdentityTag id={id} label={displayName} type="User" />;
};
