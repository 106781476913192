import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import {
  TaskType,
  useTaskQuery,
  useVisualContextsQuery,
  VisualContext,
} from '@pro4all/graphql';
import { DrawingRouterState } from '@pro4all/quality-control/data-access';
import { getDrawingRoute } from '@pro4all/quality-control/utils';
import { Routes } from '@pro4all/shared/config';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useAnswerEditContext } from '@pro4all/shared/qc-sources/answer-edit-context';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { Card } from '@pro4all/shared/ui/general';
import { useSearchInput } from '@pro4all/shared/ui/search-input';
import { Timestamp } from '@pro4all/shared/ui/timestamp';

interface Props {
  fieldId: string;
  onClose: () => void;
  projectId: string;
}

export const PlaceSelectSnagDialog = ({
  onClose,
  fieldId,
  projectId,
}: Props) => {
  const { t } = useTranslation();
  const { goTo, searchParams, url, params } = useRouting();
  const { enqueueSnackbar } = useSnackbar();
  const { query, searchInput } = useSearchInput();
  const formInstanceId = searchParams.get('id') ?? undefined;
  const taskId = searchParams.get('taskId') ?? undefined;
  const isMyTasks = useRouteMatch([Routes.projectWorkflows]);
  const isResults = useRouteMatch([Routes.projectQualityControlResults]);
  const isOrgObjects = useRouteMatch([Routes.objectsBase]);
  const editResult = searchParams.is('action', 'editResult');
  const projId = projectId ?? params.projectId;
  const objectProjectId = isOrgObjects ? projId : undefined;

  const { linkedTaskId } = useAnswerEditContext();

  const { data: taskData, error: taskError } = useTaskQuery({
    fetchPolicy: 'cache-and-network',
    skip: !taskId && !linkedTaskId,
    variables: { id: taskId ?? linkedTaskId },
  });

  const isTbqType =
    taskData?.task?.type === TaskType.Tbq ||
    taskData?.task?.type === TaskType.TbqScan;

  const { data: vcData, error: vcError } = useVisualContextsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isTbqType && taskData?.task?.visualContexts?.length > 0,
    variables: { projectId: projId },
  });

  if (taskError || vcError) {
    enqueueSnackbar(t('Could not fetch drawings.'));
    return null;
  }

  if (!taskData && !vcData) {
    return null;
  }

  let visualContexts: VisualContext[] = [];

  if (isTbqType || !linkedTaskId) {
    visualContexts = vcData?.visualContexts ?? [];
  } else if (linkedTaskId) {
    visualContexts = taskData?.task?.visualContexts?.length
      ? taskData.task.visualContexts
      : [];
  }

  const filteredVisualContexts = visualContexts.filter(
    ({ name, deletedAt }) =>
      name.toLocaleLowerCase().includes(query.toLocaleLowerCase()) && !deletedAt
  );
  const navigateToDrawing = (id: string) => {
    const previousPageName =
      (editResult && 'Editing result') ||
      (isMyTasks && 'My tasks') ||
      (isResults && 'Results') ||
      'Tasks';

    goTo(getDrawingRoute(params), {
      params: { objectId: params.objectId, projectId, visualContextId: id },
      searchParams: {
        fieldId,
        formInstanceId,
        id: id,
        placeSnag: true,
        projectId: objectProjectId,
        taskId,
      },
      state: {
        previousPageName,
        previousPageUrl: url,
      } as DrawingRouterState,
    });
  };

  return (
    <FloatingModal
      maxWidth="md"
      onClose={() => onClose()}
      open
      title={t('Select a drawing')}
    >
      <FloatingModal.Header iconName="map">
        {t('Select a drawing')}
      </FloatingModal.Header>
      <ContentWrapper>
        {searchInput}
        {!filteredVisualContexts.length && <p>No drawings found</p>}

        {filteredVisualContexts.map(({ id, name, createdAt, createdBy }) => (
          <Card
            disabledButtonBorder
            iconName="map"
            key={id}
            menuItems={[
              {
                label: `Drawing`,
                onClick: () => navigateToDrawing(id),
                startIcon: 'arrowForward',
              },
            ]}
            meta={[
              `${createdBy?.displayName}`,
              <Timestamp date={createdAt} format="lll" />,
            ]}
            onClick={() => navigateToDrawing(id)}
            title={name}
          />
        ))}
      </ContentWrapper>
      <FloatingModal.Footer>
        <Button color="inherit" onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
      </FloatingModal.Footer>
    </FloatingModal>
  );
};

const ContentWrapper = styled(Box)`
  && {
    height: 500px;
  }
`;
