import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  UnreadCountDocument,
  useDeleteDraftMessageBatchMutation,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useRouting } from '@pro4all/shared/routing-utils';

import { CountQueryType } from './useOptimisticMarkMessage';

export const useOptimisticDeleteDraftBatch = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { params } = useRouting();
  const { projectId } = params;
  const { organizationId } = useOrganizationContext();
  const projectOrOrganizationId = projectId || organizationId;

  return useDeleteDraftMessageBatchMutation({
    update: (cache, { data }) => {
      if (data?.deleteDraftMessageBatch?.success) {
        const amountDeleted = data.deleteDraftMessageBatch.ids.length;
        data?.deleteDraftMessageBatch.ids.forEach((messageId) => {
          const id = `Message:${messageId}`;

          cache.evict({ id });
          cache.gc();
        });

        // chnge sidebar counters
        const countQueryVariables = {
          isOrganization: !projectId ? true : false,
        };
        const countQueryArray: {
          unreadCount: CountQueryType[];
        } | null = cache.readQuery({
          optimistic: true,
          query: UnreadCountDocument,
          variables: countQueryVariables,
        });

        const countQuery = countQueryArray?.unreadCount?.find?.(
          (item) => item.id === projectOrOrganizationId
        );

        const modifiedUnreadCount = countQuery
          ? countQueryArray?.unreadCount?.map?.((item) => {
              if (item.id !== projectOrOrganizationId) return item;
              return {
                all: countQuery.all,
                draft: countQuery.draft - amountDeleted,
                id: projectOrOrganizationId,
                inbox: countQuery.inbox,
                sent: countQuery.sent,
              };
            })
          : (countQueryArray?.unreadCount || []).concat({
              all: 0,
              draft: 0,
              id: projectOrOrganizationId,
              inbox: 0,
              sent: 0,
            });

        cache.writeQuery({
          data: {
            unreadCount: modifiedUnreadCount,
          },
          query: UnreadCountDocument,
          variables: countQueryVariables,
        });
      } else {
        enqueueSnackbar(
          `${t('Something went wrong')}. ${t('Please try again')}.`,
          { variant: 'error' }
        );
      }
    },
  });
};
