import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StandardDateRange } from '@pro4all/graphql';
import { Box, ClickAwayListener } from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import { getFormattedDate } from '@pro4all/shared/ui/timestamp';
import { Icon, IconName } from '@pro4all/shared/ui/icons';
import { DateField } from '@pro4all/shared/ui/inputs';

import { CUSTOM_DATE_ID } from './constants';
import * as Styled from './FilterDate.styles';
import { Footer } from './Footer';
import * as StyledGeneric from './Styles';
import { FilterComponentTypeProps } from './types';
import { CompareType, standardRangeNames } from './types';

export const FilterDate = ({
  getOptions,
  onReset,
  onSet,
}: FilterComponentTypeProps) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  // Get the previously selected option, if there is any.
  const previousSelectedValue = getOptions();
  const previousSelectedId = previousSelectedValue
    ? previousSelectedValue[0]
    : null;
  const iconName = 'checkCircleOutlined' as IconName;
  const initialOption = previousSelectedId
    ? {
        iconName,
        id:
          typeof previousSelectedId === 'string'
            ? previousSelectedId
            : CUSTOM_DATE_ID,
        label: '',
      }
    : null;

  // Get the custom selected date of the previously selected option, if there is any.
  const customDate =
    typeof previousSelectedId === 'object'
      ? previousSelectedId?.valueFirst || ''
      : '';

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    initialOption
  );
  const [expanded, setExpanded] = useState(customDate ? true : false);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const checkedIcon = <Icon color="secondary" iconName="check" />;

  const dateValue = selectedDate || customDate ? customDate : '';

  const resetFilter = () => {
    setSelectedOption(null);
    setExpanded(false);
    setShowDatePicker(false);
    onReset();
  };

  const standardOptions: Option[] = Object.values(StandardDateRange).map(
    (range) => ({
      iconName:
        selectedOption && selectedOption?.id === range
          ? 'checkCircleOutlined'
          : undefined,
      id: range,
      label: standardRangeNames[range],
    })
  );

  const allOptions: Option[] = [
    ...standardOptions,
    {
      iconName:
        selectedOption && selectedOption?.id === CUSTOM_DATE_ID
          ? 'checkCircleOutlined'
          : undefined,
      id: CUSTOM_DATE_ID,
      label: 'Custom',
    },
  ];

  const onSelect = (option: Option) => {
    if (option.id === CUSTOM_DATE_ID) {
      setExpanded(true);
      setSelectedOption(option);
      onSet([]);
    } else if (selectedOption?.id !== option.id) {
      expanded && setExpanded(false);
      setSelectedOption(option);
      onSet([option.id]);
    }
  };

  const handleChange = (date: unknown) => {
    const selectedDate = date ? new Date(date as Date).toString() : '';
    if (selectedDate && selectedDate !== 'Invalid Date') {
      const { dateTime } = getFormattedDate(selectedDate);
      setSelectedDate(dateTime);
      onSet([{ compareType: CompareType.isAfter, valueFirst: dateTime }]);
    } else {
      onReset();
    }
  };

  const renderOptions = () =>
    allOptions.map((option) => (
      <Styled.ListItem
        $active={Boolean(selectedOption?.id === option.id)}
        $disabled={Boolean(selectedOption)}
        key={option.id}
        onClick={() => onSelect(option)}
      >
        {t(option.label)}
        {selectedOption?.id === option.id && checkedIcon}
      </Styled.ListItem>
    ));

  return (
    <>
      <Box display="flex" flexDirection="row" width={expanded ? 641 : 326}>
        <Box
          display="flex"
          flexDirection="column"
          width={expanded ? '50%' : '100%'}
        >
          <StyledGeneric.ContentWrap $maxRows={10}>
            {renderOptions()}
          </StyledGeneric.ContentWrap>
        </Box>
        {expanded && (
          <Styled.CustomDateWrapper>
            <ClickAwayListener onClickAway={() => setShowDatePicker(false)}>
              <div ref={ref}>
                <DateField
                  label={t('From')}
                  maxDate={new Date()}
                  name="customFilterDate"
                  onChange={handleChange}
                  setShowDatePicker={setShowDatePicker}
                  showDatePicker={showDatePicker}
                  value={dateValue}
                />
              </div>
            </ClickAwayListener>
          </Styled.CustomDateWrapper>
        )}
      </Box>
      <Footer
        disableOnReset={
          !selectedOption ||
          (selectedOption.id === CUSTOM_DATE_ID && !dateValue)
        }
        onReset={resetFilter}
      />
    </>
  );
};
