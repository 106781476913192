import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAnswerEditContext } from '@pro4all/shared/qc-sources/answer-edit-context';
import {
  FileUpload,
  useFileRejections,
  useFileUploadContext,
} from '@pro4all/shared/ui/file-upload';
import { TextFieldProps } from '@pro4all/shared/ui/inputs';

import { PhotoSlider } from '../components/PhotoSlider';
import { usePhotoIds } from '../hooks/usePhotoIds';
import { usePhotoUpload } from '../hooks/usePhotoUpload';
import * as Styled from '../styles/Photo.styles';

interface Props extends TextFieldProps {
  setValue: (name: string, value: string) => void;
  value: string;
}

const MAX_SIZE = 20000000;

export const Media = ({ name, setValue, value }: Props) => {
  // prop 'name' is the name of the form input which equals the input id.

  const { t } = useTranslation();

  const { photoIds } = usePhotoIds({ fieldId: name, initialValue: value });

  const [currentValue, setCurrentValue] = useState(photoIds);

  const { handleUploadPhotos } = usePhotoUpload({
    fieldId: name,
  });

  const onDropRejected = useFileRejections(MAX_SIZE);

  const { canSubmit, setCanSubmit, state, startUploading, stopUploading } =
    useAnswerEditContext();

  const {
    filesProcessed,
    filesSelectedToUpload,
    filesUploadedSuccessfully,
    isUploading,
    reset,
  } = useFileUploadContext();
  const { idToUploadFor } = state;

  const statusCurrentField =
    idToUploadFor === name &&
    filesSelectedToUpload.length > 0 &&
    filesProcessed.length !== filesSelectedToUpload.length
      ? t('{{current}} of {{maximum}} documents uploaded', {
          current: filesProcessed.length,
          maximum: filesSelectedToUpload.length,
        })
      : '';

  useEffect(() => {
    if (isUploading && idToUploadFor === name) {
      if (filesSelectedToUpload.length !== filesProcessed.length) {
        setCanSubmit(false);
      } else if (filesSelectedToUpload.length === filesProcessed.length) {
        const fileIds = filesUploadedSuccessfully.map((file) => file.id);
        setValue && setValue(name, [...currentValue, ...fileIds].join(','));
        setCurrentValue([...currentValue, ...fileIds] as string[]);
        stopUploading();
        setCanSubmit(true);
        reset();
      }
    }
  }, [
    currentValue,
    filesProcessed,
    filesSelectedToUpload,
    filesUploadedSuccessfully,
    idToUploadFor,
    isUploading,
    name,
    reset,
    setCanSubmit,
    setValue,
    stopUploading,
    value,
  ]);

  return (
    <>
      <PhotoSlider
        canDelete
        fieldId={name}
        ids={currentValue}
        setCurrentValue={setCurrentValue}
        setValue={setValue}
      />
      <FileUpload
        accept={['.bmp', '.gif', '.jpeg', '.jpg', '.png']}
        onDrop={handleUploadPhotos}
        onDropRejected={onDropRejected}
      >
        {({ openFileInput }) => (
          <Styled.UploadWrapper>
            <Styled.UploadButton
              aria-label={t('Upload')}
              data-testid="close-instance"
              disabled={!canSubmit}
              onClick={() => {
                startUploading(name);
                openFileInput();
              }}
              startIcon="upload"
            >
              {t('Upload')}
            </Styled.UploadButton>
            {statusCurrentField ? statusCurrentField : null}
          </Styled.UploadWrapper>
        )}
      </FileUpload>
    </>
  );
};
