import React from 'react';

import { IdentityTag } from '@pro4all/shared/ui/identity-card';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import { StyledLabel, UserChip, UserDisplayWrap } from './Header.styles';

export const UserDisplay: React.FC<{
  limit?: number;
  name: string;
  values: string[];
}> = ({ limit = 4, name, values }) => {
  if (!values.length) return null;
  return (
    <UserDisplayWrap $receiverCount={values.length}>
      <StyledLabel $tight>{name}</StyledLabel>
      {values?.map((value, index) =>
        index < limit ? (
          <IdentityTag
            hasBorder
            id={index.toString()}
            label={value}
            type="User"
          />
        ) : (
          index === limit && (
            <Tooltip
              placement="bottom"
              title={values.slice(limit, value.length + 1).join(', ')}
            >
              <UserChip
                icon="personOutline"
                key={index}
                label={'+' + (values.length - limit)}
              ></UserChip>
            </Tooltip>
          )
        )
      )}
    </UserDisplayWrap>
  );
};
