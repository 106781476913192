import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar } from '@pro4all/shared/ui/sidebar';

import { SmartFolderForm } from '../smart-folder-form/SmartFolderForm';

export const SmartFolderCreate: React.FC = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(() => searchParams.is('action', 'createSmartFolder'));
  }, [searchParams]);

  const handleClose = () => searchParams.clear();

  return (
    <Sidebar onClose={handleClose} open={isOpen}>
      <Sidebar.Header title={t('New smart folder')} />
      {isOpen && <SmartFolderForm onClose={handleClose} />}
    </Sidebar>
  );
};
