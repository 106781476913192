import React from 'react';
import { Route } from 'react-router-dom';

import { HierarchicalList } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { HierarchicalLists } from './HierarchicalLists';

export const ProjectHierarchicalLists = () => (
  <Route
    exact
    path={[Routes.projectMetaDataHierarchicalLists]}
    render={() => (
      <OptimisticResponseProvider<HierarchicalList>>
        <HierarchicalLists
          isProject
          pathHierarchicalLists={Routes.projectMetaDataHierarchicalLists}
        />
      </OptimisticResponseProvider>
    )}
  />
);
