import { useTranslation } from 'react-i18next';

import { useMyOrganizationProjectsQuery } from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';
import { FormikSearchableMultiSelect } from '@pro4all/shared/ui/formik';

import { useAddGroupsUsersToProjectFormConfig } from './useAddGroupsUsersToProjectFormConfig';

export const FormikProjectSelect = () => {
  const { data } = useMyOrganizationProjectsQuery();
  const { t } = useTranslation();
  const { getField } = useAddGroupsUsersToProjectFormConfig();
  const projectsField = getField('projects');

  const projectOptions: Option[] =
    data?.projects.map((project) => ({
      id: project.id,
      label: project.name,
    })) || [];

  return (
    <FormikSearchableMultiSelect
      label={projectsField.label}
      limitTags={5}
      name={projectsField.name}
      options={projectOptions}
      placeholder={t('Select projects')}
    />
  );
};
