import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar } from '@pro4all/shared/ui/sidebar';

import { useInitializeLocalStorage } from './helpers/useInitializeLocalStorage';
import { ItemForm } from './ItemForm';
import { BasePropsHierarchyItem, CallBackProps } from './types';

export const ItemSidebar = <HierarchyItem extends BasePropsHierarchyItem>({
  callbackAddEdit,
}: Pick<CallBackProps<HierarchyItem>, 'callbackAddEdit'>) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const initializeLocalStorage = useInitializeLocalStorage();

  const isAddItem = searchParams.has('additem');
  const isAddFirstItem = searchParams.has('addfirstitem');
  const isAddLastItem = searchParams.has('addlastitem');
  const isEditItem = searchParams.has('edititem');
  const isOpen = isAddItem || isAddFirstItem || isAddLastItem || isEditItem;

  const handleClose = () => {
    isAddItem && searchParams.delete('additem');
    isAddFirstItem && searchParams.delete('addfirstitem');
    isAddLastItem && searchParams.delete('addlastitem');
    isEditItem && searchParams.delete('edititem');
    initializeLocalStorage();
  };

  return (
    <Sidebar onClose={handleClose} open={isOpen}>
      <Sidebar.Header
        icon="addObject"
        title={isEditItem ? t('Edit item') : t(`Add item`)}
      ></Sidebar.Header>
      {isOpen && (
        <ItemForm<HierarchyItem>
          callbackAddEdit={callbackAddEdit}
          onClose={handleClose}
        />
      )}
    </Sidebar>
  );
};
