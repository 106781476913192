import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { IntegrationWithConnection } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FormFooter } from '@pro4all/shared/ui/form';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';

import { useDeleteConnection } from './useDeleteConnection';

export const DeleteConnectionForm = ({ onClose }: { onClose: () => void }) => {
  const { searchParams } = useRouting();
  const { t } = useTranslation();
  const deleteConnection = useDeleteConnection({ onClose });
  const {
    state: { items },
  } = useOptimisticResponseContext<IntegrationWithConnection>();
  const id = searchParams.get('id') || '';
  const integrationWithConnection = items.find(
    (x) => x.integrationConnection?.id === id
  );
  const integrationName = integrationWithConnection?.integrationName || '';
  return (
    <>
      <Trans
        components={{ strong: <strong /> }}
        defaults="Are you sure you want to break the connection with <strong>{{integrationName}}</strong>?. <strong>Once deleted we cannot restore it</strong>."
        values={{
          integrationName,
        }}
      />
      <FormFooter
        onClose={onClose}
        onSubmit={deleteConnection}
        pt={2}
        sticky
        submitLabel={t('Delete')}
      />
    </>
  );
};
