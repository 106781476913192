import React from 'react';

import { Loader } from '@pro4all/shared/ui/loader';

import * as Messages from '../../Messages';

import { BrandcheckHeaderSection } from './BrandcheckHeaderSection';
import { BrandcheckMultiDashboardDetailedProgress } from './BrandcheckMultiDashboardDetailedProgress';
import { MultiBrandcheckResultTabs } from './MultiBrandcheckResultTabs';
import { ResultsProgressBars } from './Progressbar';
import { MultiDashboardMain } from './Progressbar.styles';
import { Row, StyledButton } from './ResultsUI.styles';
import { useTBQBrandcheckMultiDashboard } from './useTBQBrandcheckMultiDashboard';

export function TBQMultiBrandcheckResults() {
  const {
    t,
    back,
    resultPlus,
    isSchadeTabSelected,
    statusIcon,
    toggleResultPlus,
    categoriesAverage,
    goalsAverage,
    filterShortcomings,
    progressBarSelected,
    progressBarType,
    brandcheckMultiResult,
    selectedGoalOrCategoryId,
    error,
    loading,
    data,
  } = useTBQBrandcheckMultiDashboard();

  if (error) return <Messages.Error error={error} />;
  if (Boolean(!data?.getTBQBrandcheckMultiResult) && loading) return <Loader />;

  return (
    <MultiDashboardMain>
      <StyledButton
        data-testid="close-instance"
        onClick={back}
        startIcon="arrowBack"
        variant="outlined"
      >
        {t('Go back')}
      </StyledButton>
      <MultiBrandcheckResultTabs />
      <BrandcheckHeaderSection
        isSchadeTabSelected={isSchadeTabSelected}
        resultPlus={resultPlus}
        statusIcon={statusIcon()}
        toggleResultPlus={toggleResultPlus}
      />
      <Row gap={12}>
        <ResultsProgressBars
          categories={categoriesAverage}
          onClickProgress={filterShortcomings}
          operationGoals={goalsAverage}
        />
      </Row>

      <Row gap={12}>
        {Boolean(progressBarSelected && progressBarType) && (
          <BrandcheckMultiDashboardDetailedProgress
            brandcheckMultiResult={brandcheckMultiResult}
            id={selectedGoalOrCategoryId}
            progressBarType={progressBarType}
          />
        )}
      </Row>
    </MultiDashboardMain>
  );
}
