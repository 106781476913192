import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Column } from '@pro4all/shared/ui/layout';
import { Text } from '@pro4all/shared/ui/typography';

export const StyledText = styled(Text)`
  && {
    padding: ${({ theme }) => theme.spacing(2)} 0;
  }
`;

export const StyledColumn = styled(Column)`
  && {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      max-height: 300px;
      overflow: auto;
      border-bottom: 1px solid ${customColors.grey400};
    }
    ${({ theme }) => theme.breakpoints.up('md')} {
      max-width: 282px;
    }
  }
`;
