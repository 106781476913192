import styled from 'styled-components';

import { Select } from '@pro4all/shared/ui/inputs';

export const PropertyGroupSelect = styled(Select)`
  && {
    width: 100%;
    margin: ${({ theme }) => theme.spacing(1)};
  }
`;
