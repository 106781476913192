import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, ListSubheader, Tab, Tabs } from '@pro4all/shared/mui-wrappers';
import {
  Option,
  RenderCustomInputProps,
  RenderCustomOptionProps,
  UserGroupTabs,
} from '@pro4all/shared/types';
import { sortBy } from '@pro4all/shared/utils';

import { useIdentityCardsJsx } from './useIdentityCardsJsx';
import { useIdentityTagJsx } from './useIdentityTagJsx';

export const useSelectUsersAndGroupsDropdownSources = ({
  options,
}: {
  options: Option[];
}) => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<UserGroupTabs>(UserGroupTabs.All);
  const { getGroupCardJsx, getUserCardJsx } = useIdentityCardsJsx();
  const { getIdentityTagJsx } = useIdentityTagJsx();

  const handleTabChange = (_: React.SyntheticEvent, newTab: UserGroupTabs) => {
    setActiveTab(newTab);
  };

  const optionsSorted = options.sort(sortBy({ key: 'label' }));
  const optionsGroups = optionsSorted.filter(
    (option) => option.type === 'Group'
  ) as Option[];
  const optionsUsers = optionsSorted.filter(
    (option) => option.type === 'User'
  ) as Option[];

  const noOfUsers = optionsUsers.length;
  const noOfGroups = optionsGroups.length;

  useEffect(() => {
    if (noOfGroups > 0 && noOfUsers > 0) {
      setActiveTab(UserGroupTabs.All);
    } else if (noOfGroups === 0) {
      setActiveTab(UserGroupTabs.Users);
    } else if (noOfUsers === 0) {
      setActiveTab(UserGroupTabs.Groups);
    }
  }, [noOfUsers, noOfGroups]);

  const usersAndGroupsTabs = (
    <Box sx={{ ml: 2, mt: 2 }}>
      <Tabs
        aria-label="Option Tabs"
        onChange={handleTabChange}
        value={activeTab}
      >
        {optionsGroups.length && optionsUsers.length && (
          <Tab label={t(UserGroupTabs.All)} value={UserGroupTabs.All} />
        )}
        {optionsGroups.length && (
          <Tab label={t(UserGroupTabs.Groups)} value={UserGroupTabs.Groups} />
        )}
        {optionsUsers.length && (
          <Tab label={t(UserGroupTabs.Users)} value={UserGroupTabs.Users} />
        )}
      </Tabs>
    </Box>
  );

  const optionsToPassIn =
    activeTab === UserGroupTabs.All
      ? [...optionsGroups, ...optionsUsers]
      : activeTab === UserGroupTabs.Users
      ? optionsUsers
      : activeTab === UserGroupTabs.Groups
      ? optionsGroups
      : [];

  const showListSubheader = activeTab === UserGroupTabs.All;

  const placeholder =
    activeTab === UserGroupTabs.All
      ? 'Select a user or group' // i18n
      : activeTab === UserGroupTabs.Groups
      ? 'Select a group' // i18n
      : activeTab === UserGroupTabs.Users
      ? 'Select a user' // i18n
      : '';

  const noOptionsText =
    activeTab === UserGroupTabs.All
      ? 'No users and groups available' // i18n
      : activeTab === UserGroupTabs.Groups
      ? 'No groups available' // i18n
      : activeTab === UserGroupTabs.Users
      ? 'No users available' // i18n
      : '';

  const renderCustomOption = ({ option, props }: RenderCustomOptionProps) => {
    const { type } = option;
    const groupCardJsx = getGroupCardJsx({ option });
    const userCardJsx = getUserCardJsx({ option });

    const listSubheaderStyles = {
      fontSize: '12px',
      pl: 2,
    };

    if (type === 'Group') {
      return (
        <>
          {optionsGroups.length > 0 &&
            optionsUsers.length > 0 &&
            optionsGroups[0].id === option.id &&
            usersAndGroupsTabs}
          {optionsGroups.length > 0 &&
            optionsGroups[0].id === option.id &&
            showListSubheader && (
              <ListSubheader sx={listSubheaderStyles}>
                {t('Groups')}
              </ListSubheader>
            )}
          <Box component="li" {...props}>
            {groupCardJsx}
          </Box>
        </>
      );
    } else {
      return (
        <>
          {optionsUsers.length > 0 &&
            optionsUsers[0].id === option.id &&
            (!optionsGroups?.length || activeTab === UserGroupTabs.Users) &&
            usersAndGroupsTabs}
          {optionsUsers.length > 0 &&
            optionsUsers[0].id === option.id &&
            showListSubheader && (
              <ListSubheader sx={listSubheaderStyles}>
                {t('Users')}
              </ListSubheader>
            )}
          <Box component="li" {...props}>
            {userCardJsx}
          </Box>
        </>
      );
    }
  };

  // Can be a user of a group, that's why this method is located in `useSelectUsersAndGroupsDropdownSources` instead of `useSelectUsersDropdownSources`.
  // And we don't want to put it in both to avoid a duplication.
  const renderCustomInput = ({
    hasBorder,
    onDelete,
    option,
  }: RenderCustomInputProps) =>
    getIdentityTagJsx({ hasBorder, onDelete, option });

  return {
    noOptionsText,
    optionsToPassIn,
    placeholder: t(placeholder),
    renderCustomInput,
    renderCustomOption,
  };
};
