import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';
import { getFormattedDate } from '@pro4all/shared/ui/timestamp';
import { Text } from '@pro4all/shared/ui/typography';

import { useSubscriptionAnalytics } from './hooks/useSubscriptionAnalytics';
import {
  SubscriptionStatus,
  useSubscriptionHelpers,
} from './hooks/useSubscriptionHelpers';
import * as Styled from './styles/MySubscription.styles';
import * as StyledShared from './styles/Shared.styles';

export const MySubscription = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const {
    getCurrentSubscriptionStatus,
    getExpireDate,
    getNextSubscriptionLevel,
    getSubscriptionEndDate,
    getSubscriptionLevelInactive,
    getSubscriptionLevelTranslates,
    isCancelled,
    isDowngraded,
    isExpired,
    isTrial,
  } = useSubscriptionHelpers();
  const subscriptionLevelTranslates = getSubscriptionLevelTranslates({
    checkExpired: true,
  });
  const { description, subtitle, title } = subscriptionLevelTranslates || {};

  const { trackChooseLicenseClicked } = useSubscriptionAnalytics();

  const subscriptionLevelNext = getNextSubscriptionLevel();
  const subscriptionLevelTranslatesNext = getSubscriptionLevelTranslates({
    subscriptionLevel: subscriptionLevelNext,
  });
  const {
    description: descriptionNext,
    subtitle: subtitleNext,
    title: titleNext,
  } = subscriptionLevelTranslatesNext || {};

  const isValidSubscriptionCancelled =
    getCurrentSubscriptionStatus() ===
    SubscriptionStatus.ValidSubscriptionCancelled;
  const showChooseLicense =
    isCancelled || isTrial() || isValidSubscriptionCancelled;

  const subscriptionLevelInactive = getSubscriptionLevelInactive();
  const subscriptionLevelTranslatesInactive = getSubscriptionLevelTranslates({
    subscriptionLevel: subscriptionLevelInactive,
  });
  const {
    description: descriptionInactive,
    subtitle: subtitleInactive,
    title: titleInactive,
  } = subscriptionLevelTranslatesInactive || {};

  return subscriptionLevelTranslates ? (
    <StyledShared.Wrapper>
      <StyledShared.TitleWrapper>
        <Box display="flex" justifyContent="space-between" width="100%">
          <StyledShared.TitleBox>
            <Text variant="h4">{t('My license')}</Text>
          </StyledShared.TitleBox>

          <StyledShared.TitleBox>
            <Button
              data-testid="change-subscription"
              onClick={() => {
                trackChooseLicenseClicked();
                searchParams.set({ action: 'changeSubscription' });
              }}
              startIcon={showChooseLicense ? 'payment' : 'edit'}
              variant={showChooseLicense ? 'contained' : 'outlined'}
            >
              {t(showChooseLicense ? 'Choose license' : 'Change')}
            </Button>
          </StyledShared.TitleBox>
        </Box>
      </StyledShared.TitleWrapper>
      <Styled.LevelCard>
        <Styled.Title variant="h2">{title}</Styled.Title>
        {subtitle && <Styled.Subtitle variant="h6">{subtitle}</Styled.Subtitle>}
        <Text variant="body2">{description}</Text>
      </Styled.LevelCard>

      {!isTrial() && !isExpired && getSubscriptionEndDate() ? (
        <>
          <Styled.ExpiredDate variant="h6">
            {t('After {{date}}', {
              date: getFormattedDate(getSubscriptionEndDate(), 'll').label,
            })}
          </Styled.ExpiredDate>
          <Styled.LevelCard>
            <Styled.Title variant="h2">{titleInactive}</Styled.Title>
            {subtitle && (
              <Styled.Subtitle variant="h6">{subtitleInactive}</Styled.Subtitle>
            )}
            <Text variant="body2">{descriptionInactive}</Text>
          </Styled.LevelCard>
        </>
      ) : (
        isDowngraded() && (
          <>
            <Styled.ExpiredDate variant="h6">
              {t('After {{date}}', {
                date: getFormattedDate(getExpireDate(), 'll').label,
              })}
            </Styled.ExpiredDate>
            <Styled.LevelCard>
              <Styled.Title variant="h2">{titleNext}</Styled.Title>
              {subtitle && (
                <Styled.Subtitle variant="h6">{subtitleNext}</Styled.Subtitle>
              )}
              <Text variant="body2">{descriptionNext}</Text>
            </Styled.LevelCard>
          </>
        )
      )}

      {
        // TODO: This block is temporary disabled, soon as the Quality control becomes available, it should be re-enabled.
        /* {isTrial ? (
        <Box maxWidth="435px">
          <Text variant="body2">
            {t(
              'Since you are now in your trial period, we have given you Premium. This allows you to experience all the powers of Prostream and then make a well-considered choice that suits you.'
            )}
          </Text>
        </Box>
      ) : */
      }
      {!getSubscriptionEndDate() ? (
        <Box mb={2}>
          <Button
            data-testid="cancel-subscription"
            onClick={() => searchParams.set({ action: 'cancelSubscription' })}
            startIcon="close"
          >
            {t('Cancel license')}
          </Button>
        </Box>
      ) : null}
    </StyledShared.Wrapper>
  ) : null;
};
