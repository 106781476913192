import { gqlType, Member } from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';

interface Props {
  getInputValue?: (member: Member) => string;
  getLabel?: (member: Member) => string;
  includeInactive?: boolean;
  includeNotInvited?: boolean;
  type?: 'User' | 'Group';
}

export const useMemberOptions = (
  members: Member[],
  {
    getInputValue = (member) => member.id,
    getLabel = (member) => member.displayName,
    includeInactive,
    includeNotInvited,
    type,
  }: Props
): Option[] => {
  const toFilteredUsers = (member: Member) =>
    gqlType('User')(member) &&
    ((member.active && !includeInactive) ||
      (member.invited && !includeNotInvited));

  if (type === 'User') {
    const filteredMembers: Member[] = members?.filter(toFilteredUsers);
    return (
      filteredMembers?.map((member) =>
        toMemberOption(member, { getInputValue, getLabel })
      ) || []
    );
  }

  if (type === 'Group') {
    const groups: Member[] = members?.filter(gqlType('Group'));
    return (
      groups?.map((member) =>
        toMemberOption(member, { getInputValue, getLabel })
      ) || []
    );
  }

  const filteredUsers: Member[] = members?.filter(toFilteredUsers);
  const groups: Member[] = members?.filter(gqlType('Group'));

  const combinedMembers = [...groups, ...filteredUsers];

  return (
    combinedMembers?.map((member) =>
      toMemberOption(member, { getInputValue, getLabel })
    ) || []
  );
};

export const toMemberOption = (
  member: Member,
  {
    getInputValue,
    getLabel,
  }: {
    getInputValue?: (member: Member) => string;
    getLabel: (member: Member) => string;
  }
): Option => ({
  groupOption: {
    totalUsers: gqlType('Group')(member) ? member.totalUsers : 0,
  },
  iconName: gqlType('User')(member)
    ? member.isAdmin
      ? 'admin'
      : 'personOutline'
    : 'group',
  id: member.id,
  inputValue: getInputValue(member),
  label: getLabel(member),
  type: gqlType('User')(member) ? 'User' : 'Group',
  userOption: {
    email: gqlType('User')(member) ? member.email : '',
    isAdmin: gqlType('User')(member) ? member.isAdmin : false,
    organization: member.organization,
  },
});
