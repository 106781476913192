import styled from 'styled-components';

import { Select } from '@pro4all/shared/ui/inputs';

export const PermissionSelector = styled(Select)`
  && {
    margin-right: ${({ theme }) => theme.spacing(0.5)};

    .MuiInputBase-root {
      width: ${({ theme }) => theme.spacing(8)};
    }

    .MuiInputBase-input.MuiSelect-select {
      padding: ${({ theme }) => theme.spacing(0.5)};
      font-size: 0px;
    }

    .MuiSvgIcon-root {
      font-size: 1.3rem;
    }
  }
`;

export const SelectorWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing(0.5)};
`;
