import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { PropertyList } from '@pro4all/shared/ui/property-list';
import { Section } from '@pro4all/shared/ui/section';

export const StyledSection = styled(Section)<{ $root: boolean }>`
  /* Design specifies rounded corners on the left side of header
   * for each of the nested sections
   */
  ${/* sc-selector  */ Section.ContentWrapper} ${
    /* sc-selector  */ Section.Header
  } {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
`;

export const Label = styled(PropertyList.Label)`
  align-items: center;
  display: flex;
  padding-right: 1rem;
`;

export const Info = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-style: italic;
  font-weight: 400;
  color: ${customColors.grey600};
`;

export const IconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const FieldWrapper = styled.div<{ padding: boolean }>`
  padding-right: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme, padding }) =>
    padding ? theme.spacing(3) : theme.spacing(0)};
`;
