import { useCallback } from 'react';

import { CommentStatus } from '@pro4all/graphql';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';

type ItemWithComment = {
  commentStatus: CommentStatus | undefined;
  id: string;
};
type Props = {
  status: CommentStatus | undefined;
  targetId: string;
};

export const useCommentOptimisticResponse = () => {
  const {
    state: { items },
    editItems,
  } = useOptimisticResponseContext<ItemWithComment>();

  const updateTableCache = useCallback(
    ({ status, targetId }: Props) => {
      editItems(
        items.map((doc) => ({
          ...doc,
          commentStatus: doc?.id === targetId ? status : doc?.commentStatus,
        }))
      );
    },
    [editItems, items]
  );

  return updateTableCache;
};
