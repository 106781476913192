import { ApolloCache } from '@apollo/client';

import {
  CreateDocumentTaskMutation,
  CreateTaskMutation,
  Document,
  GetLinkedTasksDocument,
  ProjectTasksDocument,
  QualityControlInstance,
  Status,
  Task,
  TaskType,
  User,
} from '@pro4all/graphql';

import { FormFields } from '../../types';

export const docLinkedTasksOptRes = ({
  cache,
  data,
  linkInstances,
}: {
  cache: ApolloCache<Document>;
  data?: CreateDocumentTaskMutation | null;
  linkInstances: (QualityControlInstance | Document)[];
}) => {
  const existingDocLinkedTasks: { getLinkedTasks: Task[] } | null =
    cache.readQuery({
      query: GetLinkedTasksDocument,
      variables: {
        id: linkInstances?.[0].id,
      },
    });

  const optResLinkedTasks = [
    ...(existingDocLinkedTasks?.getLinkedTasks ?? []),
    {
      ...data?.createDocumentTask,
      attachments: [],
      deliverables: [],
      formTemplates: [],
      linkedFormInstances: [],
      linkedSnagInstances: [],
      project: {
        id: '',
        name: '',
        organizationId: '',
        projectNumber: '',
      },
      snagTemplates: [],
      status: Status.ToDo,
      tbqFormInstances: [],
      visualContexts: [],
    } as Task,
  ];
  cache.writeQuery({
    data: {
      getLinkedTasks: optResLinkedTasks,
    },
    query: GetLinkedTasksDocument,
    variables: {
      id: linkInstances?.[0].id,
    },
  });
};

export const QcTaskOptimisticResponse = async ({
  cache,
  data,
  projectId,
  createdBy,
  values,
}: {
  cache: ApolloCache<Task>;
  createdBy: User;
  data?: CreateTaskMutation | null;
  projectId?: string;
  values: FormFields;
}) => {
  const variables = {
    id: projectId,
    typeFilter: [TaskType.Resolve, TaskType.QualityControl],
  };

  const { category, participant } = values;
  const existingProjectTasks: {
    project: { mainProcedure: { tasks: Task[] } };
  } | null = cache.readQuery({
    query: ProjectTasksDocument,
    variables,
  });

  const { userOption } = participant;
  const { email, isAdmin, organization } = userOption || {};

  const newTask = {
    ...data?.createTask,
    assignment: [
      {
        __typename: 'User',
        displayName: participant?.label ?? '',
        email,
        id: participant?.id ?? '',
        isAdmin,
        organization,
      },
    ],
    createdAt: new Date().toISOString(),
    createdByUser: createdBy,
    status: Status.ToDo,
    taskCategoryName: category?.label ?? '',
  };

  const newtasks = [
    ...(existingProjectTasks?.project?.mainProcedure?.tasks ?? []),
    newTask,
  ];

  const newProjectTasks = {
    project: {
      ...existingProjectTasks?.project,
      mainProcedure: {
        ...existingProjectTasks?.project?.mainProcedure,
        tasks: newtasks,
      },
    },
  };

  cache.writeQuery({
    data: newProjectTasks,
    query: ProjectTasksDocument,
    variables,
  });
};
