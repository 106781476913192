import React from 'react';
import { useTranslation } from 'react-i18next';

import { TbqAnswer, TbqRieResult } from '@pro4all/graphql';
import { StyledSandTimerIcon } from '@pro4all/shared/ui/general';
import { Property, PropertyList } from '@pro4all/shared/ui/property-list';
import { isTbqBrandcheck, isTbqRie } from '@pro4all/workflow/ui/utils';

interface QuestionSidebarPropertiesProps {
  allTasksNumber: number;
  numberCompletedTasks: number;
  question?: TbqRieResult | TbqAnswer;
}

export const QuestionSidebarProperties: React.FC<
  QuestionSidebarPropertiesProps
> = ({ question, allTasksNumber, numberCompletedTasks }) => {
  const { t } = useTranslation();

  let questionTitle = '';
  if (question && isTbqRie(question)) {
    questionTitle = question.section.name;
  } else if (question && isTbqBrandcheck(question)) {
    questionTitle = question.sectionText;
  }

  const properties: Property[] = [
    {
      label: t('Name'),
      value: questionTitle || t('None'),
    },
    {
      label: t('State'),
      value: (
        <>
          <StyledSandTimerIcon iconName="sandTimer" />
          {numberCompletedTasks + '/' + allTasksNumber}
        </>
      ),
    },
  ].filter(Boolean);

  return <PropertyList items={properties} orientation="horizontal" px={3} />;
};
