import React from 'react';
import { useTranslation } from 'react-i18next';

import { Member } from '@pro4all/graphql';
import { useMemberOptions } from '@pro4all/identity/ui';
import { Box } from '@pro4all/shared/mui-wrappers';
import { RenderCustomOptionProps } from '@pro4all/shared/types';
import { sortBy } from '@pro4all/shared/utils';

import { useIdentityCardsJsx } from './useIdentityCardsJsx';

export const useSelectUsersDropdownSources = ({
  members,
}: {
  members: Member[];
}) => {
  const { t } = useTranslation();

  const { getUserCardJsx } = useIdentityCardsJsx();

  const options = useMemberOptions(members, {
    includeNotInvited: true,
    type: 'User',
  });

  const optionsSorted = options.sort(sortBy({ key: 'label' }));
  const placeholder = 'Select a user'; // i18n
  const noOptionsText = 'No users available'; // i18n

  const renderCustomOption = ({ option, props }: RenderCustomOptionProps) => {
    const userCardJsx = getUserCardJsx({ option });
    return (
      <Box component="li" {...props}>
        {userCardJsx}
      </Box>
    );
  };

  return {
    noOptionsText,
    optionsToPassIn: optionsSorted,
    placeholder: t(placeholder),
    renderCustomOption,
  };
};
