import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommunicationService } from '@pro4all/communication/data-access';
import { DocumentService } from '@pro4all/documents/data-access';
import { SharedAttachments } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Column, Main } from '@pro4all/shared/ui/layout';
import { Table, TableContextProvider } from '@pro4all/shared/ui/table';
import { Text } from '@pro4all/shared/ui/typography';
import { isDefined } from '@pro4all/shared/utils';

import { AttachmentsActionBar } from '../table/AttachmentsActionBar';
import { usePublicAttachmentColumn } from '../table/usePublicAttachmentColumn';

import { usePubliclySharedMessagesContext } from './PubliclySharedMessagesContext';

enum DownloadActions {
  FILE = 'downloadFile',
  ZIP = 'downloadZip',
}

export const PubliclySharedMessagesTable: React.FC = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { data, loading, linkIsExpiredError } =
    usePubliclySharedMessagesContext();

  const attachments: SharedAttachments[] =
    data?.messageAttachments?.filter(isDefined) ?? [];

  const { attachmentColumn } = usePublicAttachmentColumn();

  const action = searchParams.get('action');
  const fileId = searchParams.get('fileId');

  const downloadZip = action === DownloadActions.ZIP;
  const downloadFile = action === DownloadActions.FILE;

  if (downloadZip) {
    // @TODO: Check where the download shared zip should be located
    const zipDownloadUrl = data?.zipDownloadUrl;
    zipDownloadUrl && DocumentService.downloadSharedZip(zipDownloadUrl);
  }
  if (downloadFile) {
    const attachment = attachments.find(
      (attachment) => attachment?.id === fileId
    );
    attachment &&
      CommunicationService.downloadPublicAttachmentDirect({
        fileName: attachment.name,
        url: attachment.downloadUrl,
      });
  }

  return (
    <Main>
      <Column>
        {linkIsExpiredError && !loading && (
          <Box ml={3} mt={2}>
            <Text align="left" variant="subtitle2">
              {t('This link is expired')}
            </Text>
          </Box>
        )}
        <TableContextProvider
          checkable={false}
          columns={[attachmentColumn]}
          id="table-publicly-shared-attachments"
          items={attachments}
        >
          {!linkIsExpiredError && !loading && (
            <>
              <AttachmentsActionBar />
              <Table<SharedAttachments> headerHeight={40} />
            </>
          )}
        </TableContextProvider>
      </Column>
    </Main>
  );
};
