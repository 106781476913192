import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Formik } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikDate } from '@pro4all/shared/ui/formik';
import { Checkbox } from '@pro4all/shared/ui/inputs';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { StyledForm, StyledLabel } from '../modal/styles';
import { PublicLinkFormValues, ShareModalBaseProps } from '../modal/types';

import { useGeneratePublicLinkFormConfig } from './useGeneratePublicLinkFormConfig';
import { useSubmit } from './useSubmit';

export const GeneratePublicLinkForm: React.FC<ShareModalBaseProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();

  const { submitPublicLink } = useSubmit();

  const initialValues: PublicLinkFormValues = {
    enablePassword: true,
    endTime: dayjs().add(7, 'day').format('YYYY-MM-DD'),
    showFolders: false,
  };

  const { getField, validationSchema } = useGeneratePublicLinkFormConfig();
  const endTimeField = getField('endTime');
  const enablePasswordField = getField('enablePassword');
  const showFoldersField = getField('showFolders');

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitPublicLink}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting, values, setFieldValue, setFieldTouched }) => (
        <StyledForm>
          <FormWrapper noPadding>
            <FormikDate
              label={endTimeField.label}
              maxDate={dayjs().add(20, 'year')}
              minDate={dayjs()}
              name={endTimeField.name}
            />
            <StyledLabel>
              <Checkbox
                checked={values.enablePassword}
                name={enablePasswordField.name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldTouched('enablePassword');
                  setFieldValue('enablePassword', event.target.checked);
                }}
              />
              {t(enablePasswordField.displayName)}
            </StyledLabel>
            <StyledLabel>
              <Checkbox
                checked={values.showFolders}
                name={showFoldersField.name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldTouched('showFolders');
                  setFieldValue('showFolders', event.target.checked);
                }}
              />
              {t(showFoldersField.displayName)}
            </StyledLabel>
          </FormWrapper>
          <FormFooter
            disableSubmit={isSubmitDisabled({
              errors,
              isSubmitting,
            })}
            marginLeft="auto"
            marginTop="auto"
            onClose={onClose}
            pt={2}
            sticky
            submitLabel={t('Next')}
          />
        </StyledForm>
      )}
    </Formik>
  );
};
