import dayjs from 'dayjs';

import { OsidDeviceLog } from '@pro4all/graphql';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';

const formatDate = (dateString: string): string =>
  dayjs(dateString).format('DD-MM-YYYY hh:mm A');

export const useTBQOsidLogsColumns = () => {
  const columns: ColumnProps<OsidDeviceLog>[] = [
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<OsidDeviceLog, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="MeldingTijdstip"
          minWidth={100}
          propertyId="timestamp"
          pullFromLocalStorage
        />
      ),
      key: 'timestamp',
      minWidth: 100,
      render: ({ timestamp }: OsidDeviceLog) => formatDate(timestamp),
      width: 200,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<OsidDeviceLog, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Select}
          label="Meldings type"
          minWidth={120}
          propertyId="eventType"
        />
      ),
      key: 'eventType',
      minWidth: 120,
      width: 200,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<OsidDeviceLog, {}>
          defaultWidth={100}
          filterType={FilterHeaderType.Select}
          label="Melding tekst"
          minWidth={80}
          propertyId="text"
        />
      ),
      key: 'text',
      minWidth: 80,
      width: 200,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<OsidDeviceLog, {}>
          defaultWidth={100}
          filterType={FilterHeaderType.Text}
          label="Adres"
          minWidth={80}
          propertyId="adres"
        />
      ),
      key: 'adres',
      width: 200,
    },
  ];
  return columns;
};
