import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { SelectDays, SelectTime } from '@pro4all/shared/ui/inputs';

import { NotificationsSettingsFields } from './NotificationsSettingsFormUtils';

type Props = {
  hasUserPermissions: boolean;
  isLoading?: boolean;
  onCancelClick: () => void;
  viewOnly?: boolean;
};

export const NotificationsSettingsForm: React.FC<Props> = ({
  viewOnly,
  onCancelClick,
  isLoading,
  hasUserPermissions,
}) => {
  const { t } = useTranslation();
  const { setValue, watch, errors, trigger } =
    useFormContext<NotificationsSettingsFields>();

  return (
    <>
      <Box mb="8px">
        <Box mb="8px">
          {t('Send notifications on the following day(s):', {
            nsSeparator: false,
          })}
        </Box>
        <SelectDays
          enabled={!viewOnly}
          errors={errors}
          setValue={setValue}
          trigger={trigger}
          watch={watch}
        />
      </Box>
      <Box>
        <Box mb="8px">
          {t('Send notifications at the following time(s):', {
            nsSeparator: false,
          })}
        </Box>
        <SelectTime
          enabled={!viewOnly}
          errors={errors}
          setValue={setValue}
          watch={watch}
        />
      </Box>
      {!viewOnly && (
        <Box display="flex" justifyContent="flex-end">
          <Box mr="8px">
            <Button
              color="inherit"
              disabled={isLoading}
              onClick={onCancelClick}
            >
              {t('Cancel')}
            </Button>
          </Box>
          <Box>
            <Button
              disabled={isLoading || !hasUserPermissions}
              startIcon="save"
              type="submit"
              variant="contained"
            >
              {t('Save')}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
