import { useTranslation } from 'react-i18next';

import {
  HierarchyPayload,
  useHierarchyEditorContext,
} from '@pro4all/shared/hierarchy-editor';
import { Box } from '@pro4all/shared/mui-wrappers';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';
import { Icon } from '@pro4all/shared/ui/icons';

import { HierarchyListProps } from '../../edit/HierarchyList';

import { getBreadCrumbHierarchy } from './getBreadCrumbHierarchy';
import { useGetPayloadHierarchy } from './useGetPayloadHierarchy';

export const useSelectedItemsBreadcrumbHierarchy = ({
  fieldDefinitionId,
  setValue,
  staticBreadcrumbs,
  value,
}: { fieldDefinitionId: string; staticBreadcrumbs: boolean } & Pick<
  HierarchyListProps,
  'setValue' | 'value'
>) => {
  const { t } = useTranslation();

  const {
    deselectItemAction,
    forceRender,
    state: { items, itemsNonExisting },
  } = useHierarchyEditorContext();

  const getPayloadHierarchy = useGetPayloadHierarchy();

  const selectedItemsExisting = items.filter((item) => item.selected);
  const selectedItemsExistingMapped = selectedItemsExisting.map((item) => ({
    breadcrumbs: getBreadCrumbHierarchy({ item, items }),
    value: item.id,
  }));
  const currentItems = [...selectedItemsExistingMapped, ...itemsNonExisting];

  const onRemove = (id: string) => {
    const selectedItemsIds = currentItems.map((item) => item.value);
    const index = selectedItemsIds.indexOf(id);
    if (index !== -1) {
      selectedItemsIds.splice(index, 1);
      setValue(
        fieldDefinitionId,
        getPayloadHierarchy({
          selectedItemsIds: selectedItemsIds.filter(
            (nonExistingId) => nonExistingId !== id
          ),
        })
      ); // Set the value to the Formik Form context.
      deselectItemAction(id);
      forceRender();
    }
  };
  const currentItemsValue = value
    ? (JSON.parse(value) as HierarchyPayload[])
    : '';

  const selectedItemsBreadCrumb = currentItems.map((item) => {
    // Find the item in the items coming from the provider, it might be that the item has been deleted in the hierarchy list.
    // If the item is not found, it means that the item has been deleted and the breadcrumb should be displayed as it was when the item was selected.
    const itemFound = items.find((i) => i.id === item.value);

    const currentItemValue =
      currentItemsValue &&
      currentItemsValue.find((value) => value.value === item.value);

    // Display can be static (the breadcrumb as it was at the time the option was selected) or dynamic (the breadcrumb as it is now).
    const nameArray =
      (staticBreadcrumbs || !itemFound) && currentItemValue
        ? currentItemValue.breadcrumbs
        : getBreadCrumbHierarchy({ item: itemFound, items });

    // Calculate JSX for the deepest child.
    const deepestChild = nameArray[nameArray.length - 1];
    const deepestChildJsx = (
      <MiddleEllipsis
        endLength={30}
        text={itemFound ? deepestChild : `${deepestChild} (${t('Removed')})`}
      ></MiddleEllipsis>
    );

    // Calculate JSX for anscestors.
    const nameArrayAncestors = nameArray.slice(0, -1);
    const ancestors = nameArrayAncestors.join(' > ');
    const anscestorsJsx = nameArrayAncestors.length ? (
      <MiddleEllipsis endLength={20} text={`${ancestors} > `}></MiddleEllipsis>
    ) : null;

    return (
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          paddingX: 0,
          paddingY: 0.5,
        }}
      >
        {anscestorsJsx}
        {deepestChildJsx}
        {setValue && (
          <Box sx={{ cursor: 'pointer' }}>
            <Icon iconName="close" onClick={() => onRemove(item.value)} />
          </Box>
        )}
      </Box>
    );
  });

  return selectedItemsBreadCrumb;
};
