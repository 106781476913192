import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  QualityControlInstance,
  Task,
  TaskType,
  TbqAnswer,
  TbqRieResult,
} from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Tab, Tabs } from '@pro4all/shared/ui/general';
import { IconName } from '@pro4all/shared/ui/icons';
import { Loader } from '@pro4all/shared/ui/loader';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import { Sidebar } from '@pro4all/shared/ui/sidebar';
import {
  useOptimisticResponseContext,
  useSetItemInLocalState,
} from '@pro4all/shared/ui/table';
import {
  useSidebarActions,
  useTBQFetchTask,
} from '@pro4all/workflow/data-access';

import { TBQTaskForm } from './task-form/TBQTaskForm';
import { TaskProperties } from './task-properties/TaskProperties';
import { TBQLinkedInstances } from './task-properties/TBQLinkedInstances';
import { useSidebarButtons } from './useSidebarButtons';
import { TabValues, useSidebarTabs } from './useSidebarTabs';
import { useTBQTaskQuestions } from './useTBQTaskQuestions';

interface Props {
  procedureId?: string;
  projectId?: string;
  tasks?: Task[];
  tbqBrandcheckQcInstance?: QualityControlInstance;
  tbqQuestion?: TbqRieResult | TbqRieResult[] | TbqAnswer | TbqAnswer[];
  tbqRieOnClose?: () => void;
}

export const TBQTaskSidebar: React.FC<Props> = ({
  procedureId,
  projectId,
  tbqQuestion,
  tasks,
  tbqBrandcheckQcInstance,
  tbqRieOnClose,
}) => {
  const { params, searchParams } = useRouting();
  const { t } = useTranslation();
  const { mainProcedureId } = useProjectContext();

  projectId = projectId || params.projectId;

  const taskId = searchParams.get('id') ?? '';
  const selectedTask = tasks?.find((task) => task.id === taskId);

  const taskProcedureId =
    selectedTask?.procedureId || procedureId || mainProcedureId;
  const overviewProjectId = selectedTask?.project?.id; // We need this if we're not in a project context.

  const routeCreate = searchParams.is('action', 'createTask');
  const routeEdit = searchParams.is('action', 'editTask');
  const routeView = searchParams.is('action', 'viewTask');
  const taskTypeParam = searchParams.get('taskType') as TaskType;
  const isOpenFromTable = routeCreate || routeView || routeEdit;

  const { data, error, loading: loadingTask } = useTBQFetchTask();
  const currentTask = data?.taskInclude;

  const taskType = currentTask?.type ?? taskTypeParam;
  const isOpenSidebar = isOpenFromTable || loadingTask;

  useSetItemInLocalState<Task>(currentTask);

  const {
    state: { item },
  } = useOptimisticResponseContext<Task>();

  const { loading, scanQuestion } = useTBQTaskQuestions(item);

  const { currentTab, setCurrentTab } = useSidebarTabs();

  if (routeCreate && currentTab !== TabValues.Properties)
    setCurrentTab(TabValues.Properties);

  const sidebarActions = useSidebarButtons({
    currentTask: item,
    taskProcedureId,
  });

  const { onClose, onViewTask } = useSidebarActions({
    currentTask: item,
    taskProcedureId,
  });

  const TabsWrapper: React.FC = ({ children }) => (
    <Tabs onChange={(_, value) => setCurrentTab(value)} value={currentTab}>
      {children}
    </Tabs>
  );

  let icon: IconName = undefined;
  if (taskType === TaskType.TbqResolve) {
    icon = 'tbqResolveTask';
  } else if (taskType === TaskType.Tbq || taskType === TaskType.TbqScan) {
    icon = 'tbqBrandcheck';
  }
  return (
    <Sidebar
      onClose={tbqRieOnClose ? tbqRieOnClose : onClose}
      open={isOpenSidebar}
      wide
    >
      <ResponseWrapper error={error} isLoading={routeCreate ? false : !data}>
        <Sidebar.Header
          icon={icon}
          title={routeCreate ? t('TBQ resolve task') : data && item?.name}
        >
          <Sidebar.SidebarActionBar sidebarActions={sidebarActions} />
          {item &&
            (taskType === TaskType.Tbq || taskType === TaskType.TbqScan) && (
              <TabsWrapper>
                <Tab
                  data-testid="task-sidebar-properties-tab"
                  key="properties"
                  label={t('Properties')}
                  value={TabValues.Properties}
                />
              </TabsWrapper>
            )}

          {item && [TaskType.TbqResolve].includes(taskType) && !routeCreate && (
            <TabsWrapper>
              <Tab
                data-testid="task-sidebar-properties-tab"
                key="properties"
                label={t('Properties')}
                value={TabValues.Properties}
              />
            </TabsWrapper>
          )}
        </Sidebar.Header>

        {currentTab === TabValues.Properties && (
          <>
            {routeCreate && (
              <TBQTaskForm
                onClose={onClose}
                procedureId={taskProcedureId}
                projectId={overviewProjectId || (projectId ?? '')}
                taskType={taskType}
                tbqBrandcheckQcInstance={tbqBrandcheckQcInstance}
                tbqQuestion={tbqQuestion}
              />
            )}

            {routeEdit && item && (
              <TBQTaskForm
                onClose={onViewTask}
                procedureId={taskProcedureId}
                projectId={overviewProjectId || (projectId ?? '')}
                task={item}
                taskType={taskType}
              />
            )}

            {routeView && item && (
              <>
                <TaskProperties task={item} />
                {loading ? (
                  <Loader />
                ) : (
                  <TBQLinkedInstances
                    currentTask={item}
                    filteredRieQuestions={scanQuestion}
                    taskType={taskType}
                  />
                )}
              </>
            )}
          </>
        )}
      </ResponseWrapper>
    </Sidebar>
  );
};
