import React, { useMemo } from 'react';

import { SavedAnswers, useSavedAnswersIncludeQuery } from '@pro4all/graphql';
import { PhotoProvider } from '@pro4all/shared/contexts';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { Column, Main } from '@pro4all/shared/ui/layout';
import { BigMessageNoSavedAnswers } from '@pro4all/shared/ui/messages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/table';

import { includeProps } from './constants';
import { CreateSavedAnswersContextProvider } from './CreateSavedAnswersContext';
import { DialogDeleteSavesAnswersConfirmation } from './DialogDeleteSavesAnswersConfirmation';
import { DialogSelectTemplate } from './DialogSelectTemplate';
import { SavedAnswersActionBar } from './SavedAnswersActionBar';
import { SavedAnswersSidebar } from './SavedAnswersSidebar';
import { useColumns } from './useColumns';
import { useSavedAnswersActions } from './useSavedAnswersActions';

export const SavedAnswersTable = () => {
  const {
    params: { projectId },
    searchParams,
  } = useRouting();

  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<SavedAnswers>();

  const altActions = useSavedAnswersActions({ returnAll: true });

  const { data, loading, error } = useSavedAnswersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      ...includeProps,
      projectId: projectId || '',
    },
  });

  const savedAnswers = useMemo(
    () => data?.savedAnswers || [],
    [data?.savedAnswers]
  ) as SavedAnswers[];
  useSetItemsInLocalState<SavedAnswers>(savedAnswers);

  const columns = useColumns();

  const selectSavedAnswer = (row: SavedAnswers) => {
    searchParams.set({
      action: 'viewSavedAnswer',
      id: row.id,
    });
  };

  return (
    <Main>
      <TableContextProvider columns={columns} id="saved-answers" items={items}>
        <FilterContextProvider<SavedAnswers>>
          <Column>
            <SavedAnswersActionBar />
            <ResponseWrapper error={error} isLoading={!data && loading}>
              {items.length === 0 && itemsInitial.length === 0 ? (
                <BigMessageNoSavedAnswers />
              ) : (
                <Table<SavedAnswers>
                  contextMenuActions={altActions}
                  onRowClick={(row) => selectSavedAnswer(row)}
                />
              )}
            </ResponseWrapper>
          </Column>
        </FilterContextProvider>
      </TableContextProvider>
      <DialogDeleteSavesAnswersConfirmation />
      <CreateSavedAnswersContextProvider>
        <DialogSelectTemplate />
        <PhotoProvider>
          <SavedAnswersSidebar />
        </PhotoProvider>
      </CreateSavedAnswersContextProvider>
    </Main>
  );
};
