import { isEqual } from 'lodash';

import { Option } from '@pro4all/shared/types';
import { BaseRow } from '@pro4all/shared/ui/table';

import { FilterType, PredefinedFilter } from './types';

export const filtersAreEqual = (
  filtersOne: FilterType<BaseRow>[],
  filtersTwo: FilterType<BaseRow>[]
) => {
  let found = true;
  if (!filtersOne || !filtersTwo) return false;
  if (filtersOne.length !== filtersTwo.length) return false;
  if (filtersOne.length === 0 && filtersTwo.length === 0) return true;
  filtersOne.forEach((filterOne) => {
    const filter = filtersTwo.find(
      (filterTwo) =>
        filterTwo.filterType === filterOne.filterType &&
        filterTwo.propertyId === filterOne.propertyId &&
        isEqual(filterOne.filterValues, filterTwo.filterValues)
    );
    if (!filter) found = false;
  });

  return found;
};

export const convertPredefinedFilterToOption = (
  predefinedFilter: PredefinedFilter
) => {
  const option: Option = {
    id: predefinedFilter.title,
    label: predefinedFilter.title,
  };
  return option;
};

export const getPredefinedFilterForOption = (
  predefinedFilters: PredefinedFilter[],
  option: Option
) =>
  option ? predefinedFilters.find((f) => f.title === option.id) : undefined;

export const getPredefinedFilterByFilters = (
  predefinedFilters: PredefinedFilter[],
  filters: FilterType<BaseRow>[]
) =>
  predefinedFilters.find((predefinedFilter) =>
    filtersAreEqual(filters, predefinedFilter?.filters)
  );
