import React from 'react';

import { Popper, PopperProps } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

interface DynamicPopperProps extends PopperProps {
  setMaxHeight: (height: number) => void;
}

export const DynamicPopper: React.FC<DynamicPopperProps> = (props) => {
  const { anchorEl, setMaxHeight } = props;

  // Determine the position and available space around the input
  const inputRect =
    anchorEl instanceof HTMLElement
      ? anchorEl.getBoundingClientRect()
      : { bottom: 0, top: 0 };
  const spaceBelow = window.innerHeight - inputRect.bottom - 16; // Space below the input
  const spaceAbove = inputRect.top - 16; // Space above the input
  const maxHeight = Math.max(spaceBelow, spaceAbove); // Take the most available space

  React.useEffect(() => {
    setMaxHeight(maxHeight);
  }, [maxHeight, setMaxHeight]);

  // Make width of Popper equal to width of input
  const popperWidth =
    anchorEl instanceof HTMLElement ? anchorEl.clientWidth : undefined;

  return (
    <Popper
      {...props}
      modifiers={[
        {
          enabled: true,
          name: 'flip', // Enable automatic position switching
          options: {
            fallbackPlacements: ['top-start', 'bottom-start'], // Try upwards first and then downwards
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altBoundary: true,
            rootBoundary: 'viewport',
          },
        },
        {
          enabled: true,
          fn: ({ state }) => {
            if (state.styles.popper) {
              state.styles.popper.maxHeight = `${maxHeight}px`;
            }
          },
          name: 'maxHeight',
          phase: 'beforeWrite',
        },
      ]}
      placement="bottom-start" // Default positioning
      style={{
        ...props.style,
        backgroundColor: customColors.white,
        border: `1px solid ${customColors.grey400}`,
        maxHeight: `${maxHeight}px`,
        width: popperWidth,
      }}
    />
  );
};
