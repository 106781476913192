import { useTranslation } from 'react-i18next';
import { AlternateEmailRounded } from '@mui/icons-material';
import { Transforms } from 'slate';
import { useSlate } from 'slate-react';

import { EditorButton } from './EditorButton';

export const MentionButton = () => {
  const editor = useSlate();
  const { t } = useTranslation();
  return (
    <EditorButton
      onClick={() => {
        Transforms.insertText(editor, '@');
      }}
      title={t('Mention')}
    >
      <AlternateEmailRounded sx={{ transform: 'translateY(5px)' }} />
    </EditorButton>
  );
};
