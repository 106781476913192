import { Redirect, Route, Switch } from 'react-router';

import { SentOrInbox } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import { Column, Main } from '@pro4all/shared/ui/layout';

import { MessagesTable } from '../table/MessageTable';
import { MessageTabs } from '../tabs/MessageTabs';

export const MessageOverview = () => {
  const { params } = useRouting();
  const { projectId = '' } = params;

  return (
    <Main sx={{ overflow: 'hidden' }}>
      <Column>
        <MessageTabs />
        <Switch>
          <Route path={[Routes.allMessages, Routes.projectAllMessages]}>
            <MessagesTable />
          </Route>
          <Route path={[Routes.inboxMessages, Routes.projectInboxMessages]}>
            <MessagesTable sentOrInbox={SentOrInbox.Inbox} />
          </Route>
          <Route path={[Routes.sentMessages, Routes.projectSentMessages]}>
            <MessagesTable sentOrInbox={SentOrInbox.Sent} />
          </Route>
          <Route path={[Routes.draftMessages, Routes.projectDraftMessages]}>
            <MessagesTable sentOrInbox={SentOrInbox.Draft} />
          </Route>
          <Route
            exact
            path={[Routes.projectMessages, Routes.organizationMessages]}
          >
            <Redirect
              to={
                projectId
                  ? generateRoute('projectInboxMessages', {
                      params: { projectId },
                    })
                  : Routes.inboxMessages
              }
            />
          </Route>
        </Switch>
      </Column>
    </Main>
  );
};
