import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Maybe } from '@pro4all/graphql';
import { CustomActionBarWrapper } from '@pro4all/metadata/ui';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import { Button } from '@pro4all/shared/ui/buttons';
import { SearchableSelect } from '@pro4all/shared/ui/inputs';

import { parseDevicesToOptions } from './devices-utils';
import { useLinkOsidDevice } from './useLinkOsidDevice';
import { useUnlinkOsidDevice } from './useUnlinkOsidDevice';

interface DeviceManagerProps {
  isLinkedDevice: boolean;
  linkedDeviceId?: string;
  noOsidDevices?: boolean;
  objectId: string;
  onDeviceSelect: (id: string) => void;
  onLinkStatusChange: () => void;
  osidData?: Maybe<string>[];
}

export const DeviceManager: React.FC<DeviceManagerProps> = ({
  isLinkedDevice,
  objectId,
  onDeviceSelect,
  onLinkStatusChange,
  linkedDeviceId,
  osidData,
  noOsidDevices,
}) => {
  const { t } = useTranslation();
  const [selectedDevice, setSelectedDevice] = useState<string>('');
  const onLinkDevice = useLinkOsidDevice({
    deviceCodeToLink: selectedDevice,
    objectId,
  });
  const onUnlinkDevice = useUnlinkOsidDevice({ objectId });

  const options = osidData ? parseDevicesToOptions(osidData) : [];

  const initializeSelectedDevice = useCallback(() => {
    if (isLinkedDevice && linkedDeviceId) {
      setSelectedDevice(linkedDeviceId);
    } else {
      setSelectedDevice('');
    }
  }, [isLinkedDevice, linkedDeviceId]);

  useEffect(() => {
    initializeSelectedDevice();
  }, [initializeSelectedDevice]);

  const handleLinkDevice = async () => {
    await onLinkDevice();
    onLinkStatusChange();
  };

  const handleUnlinkDevice = async () => {
    await onUnlinkDevice();
    setSelectedDevice('');
    onLinkStatusChange();
  };

  const handleChange = (device: Option) => {
    const newSelectedDeviceId = device.label;
    setSelectedDevice(newSelectedDeviceId);
    onDeviceSelect(newSelectedDeviceId);
  };

  return (
    <Box mb={2}>
      <CustomActionBarWrapper>
        <Box>
          <StyledSelect
            disableClearable
            disabled={isLinkedDevice}
            label={t('Select a device')}
            name="osid-device-select"
            onChange={handleChange}
            options={options}
            placeholder={
              noOsidDevices
                ? t('No devices available')
                : isLinkedDevice
                ? linkedDeviceId
                : t('Select a device')
            }
            value={
              options.find((option) => option.label === selectedDevice) || null
            }
          />
        </Box>
        <StyledButton
          color={isLinkedDevice ? 'error' : 'primary'}
          data-testid="link-device-button"
          disabled={!selectedDevice}
          onClick={isLinkedDevice ? handleUnlinkDevice : handleLinkDevice}
          variant="contained"
        >
          {isLinkedDevice ? t('Unlink device') : t('Link device')}
        </StyledButton>
      </CustomActionBarWrapper>
    </Box>
  );
};

const StyledSelect = styled(SearchableSelect)`
  && {
    width: 250px;
  }
`;

const StyledButton = styled(Button)`
  left: 20px;
  width: 150px;
`;
