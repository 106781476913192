import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FormFooter } from '@pro4all/shared/ui/form';
import {
  FormikForm,
  FormikInput,
  FormikSearchableSelect,
} from '@pro4all/shared/ui/formik';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { FormObserver } from './FormObserver';
import { languageOptions } from './languageOptions';
import { FormFields } from './types';
import { NameType } from './types';
import { useMyProfileFormConfig } from './useMyProfileFormConfig';
import { useSubmit } from './useSubmit';

export const MyProfileForm = ({
  setValues,
  initialValues,
}: {
  initialValues: FormFields;
  setValues: (value: NameType) => void;
}) => {
  const { t } = useTranslation();
  const hasChangeEmailAddressFeatureFlag = useFeatureFlag(
    'change-emailaddress'
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const { getField, validationSchema } = useMyProfileFormConfig();

  const firstNameField = getField('firstName');
  const lastNameField = getField('lastName');
  const emailField = getField('email');
  const languageField = getField('language');
  const phoneNumberField = getField('phoneNumber');
  const jobTitle = getField('jobTitle');

  const onSubmit = useSubmit(initialValues.language?.id);

  const { searchParams } = useRouting();
  const handleClose = () => searchParams.clear();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onReset={() => inputRef?.current?.focus()}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting }) => (
        <FormikForm>
          <FormObserver setValues={setValues} />
          <FormWrapper noPadding>
            <FormikInput
              autoFocus
              data-testid="profile-firstname"
              inputRef={inputRef}
              label={firstNameField?.label}
              name={firstNameField?.name}
            />
            <FormikInput
              data-testid="profile-lastname"
              inputRef={inputRef}
              label={lastNameField?.label}
              name={lastNameField?.name}
            />
            <FormikInput
              data-testid="profile-phone"
              inputRef={inputRef}
              label={phoneNumberField?.label}
              name={phoneNumberField?.name}
            />
            <FormikInput
              data-testid="profile-email"
              disabled={!hasChangeEmailAddressFeatureFlag}
              inputRef={inputRef}
              label={emailField?.label}
              name={emailField?.name}
            />

            <FormikSearchableSelect
              data-testid="profile-language"
              id={languageField?.name}
              label={languageField?.label}
              name={languageField?.name || ''}
              options={languageOptions.filter((option) => option.id !== 'none')}
              placeholder={t('Select language')}
            />
            <FormikInput
              data-testid="profile-jobtitle"
              inputRef={inputRef}
              label={jobTitle?.label}
              name={jobTitle?.name}
            />
          </FormWrapper>
          <FormFooter
            disableSubmit={isSubmitDisabled({ dirty, errors, isSubmitting })}
            onClose={handleClose}
            pb={3}
            pt={2}
            px={3}
            showCancel={false}
            sticky
          />
        </FormikForm>
      )}
    </Formik>
  );
};
