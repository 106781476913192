import { FacetGroup, MetaDataInstance, SearchDocument } from '@pro4all/graphql';
import { mdFilterColumnTypes, useFilters } from '@pro4all/search/ui';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';
import { isDefined } from '@pro4all/shared/utils';

export const useEmptyFacetColumns = <GenericColumnType,>(
  mdFacetGroups?: FacetGroup[]
): ColumnProps<GenericColumnType>[] => {
  const { currentFilters } = useFilters();

  const includedGroups = mdFacetGroups?.filter((group) =>
    mdFilterColumnTypes.includes(group.type)
  );

  const uniqueFacetIds = Array.from(
    new Set(
      includedGroups?.flatMap((group) =>
        group.items?.map((item) => item?.mdField?.id).filter(isDefined)
      )
    )
  );

  const missingFacets =
    (currentFilters &&
      currentFilters.filter(
        (filter) =>
          filter.metaDataKey && !uniqueFacetIds.includes(filter.metaDataKey)
      )) ||
    [];

  const columns: ColumnProps<GenericColumnType>[] = [...missingFacets]
    .map((facetItem) => {
      if (!facetItem?.name || !facetItem?.metaDataKey) return null;

      return {
        filterable: false,
        headerComponent: (
          <FilterHeader<SearchDocument, MetaDataInstance>
            defaultWidth={200}
            filterType={FilterHeaderType.Text}
            label={facetItem.name}
            minWidth={120}
            propertyId="metaDataInstance"
            showFilterIcon={false}
          />
        ),
        key: facetItem.metaDataKey,
        label: facetItem.name,
        // This values will always be empty
        render: () => null,
      };
    })
    .filter(isDefined);

  return columns;
};
