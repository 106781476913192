import { Option } from '@pro4all/shared/types';

import { splitEmailAddress } from './splitEmailAddress';

export const pasteHandlerEmails = ({
  currentValues,
  event,
  updateValuesCallback,
}: {
  currentValues: Option[];
  event: React.ClipboardEvent<HTMLDivElement>;
  updateValuesCallback: (values: Option[]) => void;
}) => {
  const clipboardText = event.clipboardData.getData('text');
  event?.preventDefault();
  splitEmailAddress(clipboardText, currentValues, updateValuesCallback);
};
