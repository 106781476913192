import React from 'react';
import { AutoResizer, unflatten } from 'react-base-table';

import { TableNode } from '@pro4all/objects/utils';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useToggleProvider } from '@pro4all/shared/ui/toggle';

import { useEditObjectsContext } from './EditObjectsContext';
import { Row } from './Row';
import * as Styled from './Table.styles';
import { useDragAndDropObject } from './useDragAndDropObject';

export const Table = () => {
  const { objects } = useEditObjectsContext();

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const rows = unflatten(objects, null, 'id', 'parentNodeId');
  const defaultExpandedRowKeys = objects.reduce(
    (keys: string[], { parentNodeId: id }) => (id ? [...keys, id] : keys),
    []
  );

  const { toggle, toggles: expandedRowKeys } = useToggleProvider({
    initial: defaultExpandedRowKeys,
  });

  const rootNode: TableNode = {
    id: 'root',
    name: 'root',
    organizationId,
  };

  const { drop, hoversOverCurrent } = useDragAndDropObject(rootNode);

  if (!rows?.length) return null; // @Todo: render empty state

  return drop(
    <div style={{ display: 'flex', flex: 1 }}>
      <AutoResizer>
        {({ width, height }) => (
          <Styled.Table
            $dragOver={hoversOverCurrent}
            data={rows}
            defaultExpandedRowKeys={defaultExpandedRowKeys}
            expandColumnKey="name"
            expandedRowKeys={expandedRowKeys}
            headerHeight={0}
            height={height}
            rowRenderer={(row: { depth: number; rowData: TableNode }) => (
              <Row
                depth={row.depth}
                expanded={expandedRowKeys.includes(row.rowData.id)}
                {...row.rowData}
                toggleRow={toggle}
              />
            )}
            sortKey="name"
            width={width}
          />
        )}
      </AutoResizer>
    </div>
  );
};
