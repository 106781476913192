import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';

import { SendLinkByEmailForm } from '../forms/SendLinkByEmailForm';
import { useSendLinkByEmailFormConfig } from '../forms/useSendLinkByEmailFormConfig';
import { useSubmit } from '../forms/useSubmit';
import { useGenerateLinkContext } from '../GenerateLinkContext';

export interface EmailFormValues {
  to: Option[];
}

export const SendLinkByEmailModal: React.FC = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { resourceIds } = useGenerateLinkContext();
  const { submitEmailForm } = useSubmit();
  const { validationSchema } = useSendLinkByEmailFormConfig();

  const open =
    searchParams.is('action', 'share-by-email') && Boolean(resourceIds?.length);

  const initialValues: EmailFormValues = {
    to: [],
  };

  return (
    <FloatingModal open={open}>
      <FloatingModal.Header iconName="share">
        {t('Email link')}
      </FloatingModal.Header>
      <Formik
        initialValues={initialValues}
        onSubmit={submitEmailForm}
        validationSchema={validationSchema}
      >
        <SendLinkByEmailForm />
      </Formik>
    </FloatingModal>
  );
};
