import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { ImageType } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { ReportService } from '@pro4all/quality-control/data-access';
import { Box } from '@pro4all/shared/mui-wrappers';
import { REPORT_IMAGE_EXTENSIONS } from '@pro4all/shared/qc-report-sources';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import {
  SearchableSelect,
  SearchableSelectProps,
} from '@pro4all/shared/ui/inputs';

import { useReportOptionsContext } from '../ReportOptionsProvider';

import { UploadButton } from './UploadButton';
import { UploadProgress } from './UploadProgress';

type ImageUploadProps = {
  disabled?: boolean;
  imageType: ImageType;
  isFrontPageProp?: boolean;
  label: string;
  name: string;
  onChange: (value: SearchableSelectProps['value']) => void;
  options: Option[];
  selectedId?: string;
};

export const ImageUpload: React.FC<ImageUploadProps> = ({
  disabled,
  imageType,
  isFrontPageProp,
  label,
  name,
  onChange,
  options,
  selectedId,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    params: { projectId },
  } = useRouting();

  const { organizationId } = useOrganizationContext();

  const {
    addBackgroundOption,
    addLogoOption,
    setLogo,
    setBackground,
    setBackgroundFrontPage,
  } = useReportOptionsContext();

  const [progress, setProgress] = useState<number>(0);
  const [inProgress, setInProgress] = useState(false);

  const openFileInput = () => {
    const fileInput = document.getElementById(`${name}_input`);
    fileInput && fileInput.click();
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const file = Array.from(event.target.files)[0];

    const existingFile = options.find((option) => option.label === file.name);

    if (existingFile) {
      enqueueSnackbar(t('Document already exists'));
      if (imageType === ImageType.CompanyLogo) {
        addLogoOption(existingFile);
        setLogo(existingFile.id);
      } else {
        addBackgroundOption(existingFile);
        if (isFrontPageProp) {
          setBackgroundFrontPage(existingFile.id);
        } else {
          setBackground(existingFile.id);
        }
      }
      return;
    }

    if (file.size > 10_000_000) {
      enqueueSnackbar(
        `${t(
          'The maximum document size is 10mb. {{filename}} will not be uploaded',
          { filename: file.name }
        )}`
      );
      return;
    }

    const fileExtension = file.type
      .replace('image/', '.')
      .replace('application/', '.');
    if (!REPORT_IMAGE_EXTENSIONS.includes(fileExtension)) {
      enqueueSnackbar(t('Document not supported'));
      return;
    }
    setInProgress(true);

    try {
      const response = await ReportService.uploadImage({
        file,
        imageType,
        onProgress: setProgress,
        organizationId,
        projectId,
      });
      setInProgress(false);
      if (!response?.data?.files?.length) {
        enqueueSnackbar(t('Something went wrong'));
        return;
      } else {
        const fileName = response.data.files[0].fileName;
        const fileId = response.data.files[0].id;
        const newOption = { id: fileId, label: fileName };
        if (imageType === ImageType.CompanyLogo) {
          addLogoOption(newOption);
          setLogo(newOption.id);
        } else {
          addBackgroundOption(newOption);
          if (isFrontPageProp) {
            setBackgroundFrontPage(newOption.id);
          } else {
            setBackground(newOption.id);
          }
        }
      }
    } catch (error) {
      enqueueSnackbar(t('Something went wrong'));
    }

    setInProgress(false);
  };

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  const showProgress = inProgress;
  const allowedInput = REPORT_IMAGE_EXTENSIONS.map((ext) =>
    ext.replace('.', 'image/')
  ).join(',');

  return (
    <Box>
      {showProgress ? (
        <Box sx={{ mb: 2 }}>
          {/* TODO: I assume the progres indicator should disappear after the upload is done. That is not happening right now, it stays there forever one you upload a file. */}
          <UploadProgress progress={progress} showProgress={showProgress} />
        </Box>
      ) : null}
      <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
        <Box sx={{ flexGrow: 1 }}>
          <SearchableSelect
            disabled={disabled}
            label={t(label)}
            name="logoSelect"
            onChange={onChange}
            options={options}
            value={options.find((option) => option.id === selectedId)}
          />
        </Box>
        <Box display="flex">
          <UploadButton
            disabled={disabled}
            name={name}
            onClick={openFileInput}
            uploading={inProgress}
          />
          <Box sx={{ display: 'none' }}>
            <input
              accept={allowedInput}
              disabled={disabled}
              id={`${name}_input`}
              onChange={handleUpload}
              onClick={onInputClick}
              type="file"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
