import { Integration, useDeleteIntegrationMutation } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';

export const useDeleteIntegration = ({ onClose }: { onClose: () => void }) => {
  const { searchParams } = useRouting();
  const [deleteIntegration] = useDeleteIntegrationMutation();
  const { deleteItems } = useOptimisticResponseContext<Integration>();

  const deleteInt = async () => {
    const id = searchParams.get('id') || '';

    await deleteIntegration({
      variables: { id },
    });

    deleteItems([id]);
    onClose();
  };

  return deleteInt;
};
