import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Typography,
  useTheme,
} from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Shape, shapes, ShapeType } from '@pro4all/shared/ui/shapes';

export const ImagePlaceHolder = ({
  actionButtonLabel,
  actionCallback,
  randomShape,
  shadow = false,
  shape,
  text = true,
}: {
  actionButtonLabel?: string;
  actionCallback?: () => void;
  randomShape?: boolean;
  shadow: boolean;
  shape?: ShapeType;
  text?: boolean;
}) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const [currentShape, setCurrentShape] = useState<ShapeType>(shape || 'build');

  function getRandomShapeType(): ShapeType {
    const shapeKeys = Object.keys(shapes) as ShapeType[];
    const randomIndex = Math.floor(Math.random() * shapeKeys.length);
    return shapeKeys[randomIndex];
  }

  useEffect(() => {
    if (shape) setCurrentShape(shape);
    if (randomShape) setCurrentShape(getRandomShapeType());
  }, [randomShape, shape]);

  return (
    <Box
      sx={{
        alignItems: 'center',
        background: customColors.grey200,
        boxShadow: shadow ? '0 0 5px 0 rgba(0, 0, 0, 0.2)' : 'none',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Shape
        color="silver"
        height="100%"
        sx={{ height: '140%', width: '140%' }}
        type={currentShape}
        width="100%"
      />
      {text && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            position: 'absolute',
          }}
        >
          <Typography color={customColors.grey500} variant="body2">
            {t('No preview available')}
          </Typography>
          {actionButtonLabel && actionCallback && (
            <Button onClick={actionCallback} size="small">
              {actionButtonLabel}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};
