import React from 'react';

import { AppFallback } from '@pro4all/shared/ui/fallback';

import { AppRoutes } from './AppRoutes';
import { GlobalModals } from './GlobalModals';
import { Init } from './Init';
import { Providers } from './Providers';

export const App = () => (
  <Providers>
    <Init />
    <AppFallback>
      <AppRoutes />
      <GlobalModals />
    </AppFallback>
  </Providers>
);

export default App;
