import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  Template,
  TemplateState,
  usePublishTemplatesMutation,
} from '@pro4all/graphql';
import {
  useOptimisticResponseContext,
  useTableCheck,
} from '@pro4all/shared/ui/table';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { featureConfig } from '../configs/featureConfig';

export const usePublishTemplates = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [publishTemplates] = usePublishTemplatesMutation();
  const { checkedRows, uncheckAllRows } = useTableCheck<Template>();
  const { templateType, templateService } = useMetaDataContext();
  const { entity } =
    featureConfig[templateService]?.templateTypes[templateType] ?? {};

  const {
    editItems,
    state: { items },
  } = useOptimisticResponseContext<Template>();

  return async () => {
    const ids = checkedRows.map((row) => row.id);
    try {
      await publishTemplates({
        variables: { ids, templateService },
      });

      editItems(
        items
          .filter((item) => (ids || []).includes(item.id || ''))
          .map((item) => ({
            ...item,
            state: TemplateState.Published,
          }))
      );

      enqueueSnackbar(t(`Selected ${entity}s published`));
      uncheckAllRows();
    } catch (e) {
      enqueueSnackbar(
        `${t('Something went wrong')}. ${t('Please try again')}.`
      );
    }
  };
};
