import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikForm, FormikRadioGroup } from '@pro4all/shared/ui/formik';
import { Text } from '@pro4all/shared/ui/typography';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useInitialValues } from './useInitialValues';
import { useOrganizationSettingsFormConfig } from './useOrganizationSettingsFormConfig';
import { useSubmit } from './useSubmit';

export const OrganizationSettingsForm = ({
  showCancel = true,
}: {
  showCancel?: boolean;
}) => {
  const { t } = useTranslation();
  const { getField, validationSchema } = useOrganizationSettingsFormConfig();
  const loginMethodField = getField('login_method');
  const initialValues = useInitialValues();

  const onSubmit = useSubmit();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting }) => (
        <FormikForm>
          <FormWrapper>
            <Text variant="h4">{t('General')}</Text>
            <FormikRadioGroup
              label={loginMethodField?.displayName}
              name={loginMethodField?.name}
              options={loginMethodField?.options || []}
            />
          </FormWrapper>
          <FormFooter
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            pb={3}
            pt={2}
            px={3}
            showCancel={showCancel}
            sticky
          />
        </FormikForm>
      )}
    </Formik>
  );
};
