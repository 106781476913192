import React from 'react';

import { QualityControlInstance, Task, Template } from '@pro4all/graphql';
import {
  ComposedPin,
  ComposedPinType,
  getFileUrlByTemplateId,
  TMuiIcon,
} from '@pro4all/shared/composed-snag-form-pin';
import { LinkedInstance } from '@pro4all/workflow/ui/utils';

import { useGetInstanceSnagColor } from './helpers';

export const CustomIconComponent: React.FC<{
  currentTask?: Task;
  instance: LinkedInstance | Template;
  type: ComposedPinType;
}> = ({ currentTask, instance, type }) => {
  // Appearantly the prop `instance` can be of type `Document`, `SearchDocument`, `QualityControlInstance` and also (although not typed like so) `Template`.
  // It is of type `Template` if you open a task with linked snags/forms.

  // I didn't figure it out completely but in case of type `Template` we have the icon information wrapped in:
  // icon: {
  //   fileId (id of the custom image, this is not used, because the image dynamically is fetched via getFileUrlByTemplateId)
  //   name (name of the MUI icon)
  //   type (fixed or custom)
  //   __typename: "TemplateIcon"
  // }

  // In case of `QualityControlInstance` it is like so:
  // templateIconProps {
  //   iconName (naam van het MUI5 icon)
  //   iconType (1=MUI of 2=Custom)
  //   templateId
  //   __typename: "TemplateIconProps"
  // }

  // Personally I don't like the differences like:
  // 1. icon vs templateIconProps
  // 2. type vs iconType
  // 3. Value 1/2 vs fixed/custom

  // Also the type should have been an enum.
  // Also I think a prop `template` on type `QualityControlInstance` with exactly the same props as on type `Template` itself would be much more robust.
  // Also it is quite weird to retype `instance` as `QualityControlInstance` because it can be of type `Document` or `SearchDocument` or `Template` as well.
  // TODO: we should make this clean and maintainable because different flavours will cause regression sooner or later.
  // But for now I will try to make fixes with the current data structure.

  const instanceColor = useGetInstanceSnagColor(
    currentTask,
    instance as LinkedInstance
  );

  const isCustomIcon = instance?.icon
    ? instance.icon.type === 'custom'
    : (instance as QualityControlInstance)?.templateIconProps
    ? (instance as QualityControlInstance)?.templateIconProps?.iconType === 2
    : false;

  const templateId =
    (instance as QualityControlInstance)?.templateId ||
    (instance as QualityControlInstance)?.templateIconProps?.templateId ||
    (instance as QualityControlInstance)?.id ||
    '';

  const selectedIconName = instance?.icon
    ? (instance.icon.name as TMuiIcon)
    : (instance as QualityControlInstance)?.templateIconProps
    ? (instance as QualityControlInstance)?.templateIconProps?.iconName
    : null;

  return (
    <ComposedPin
      currentFile={isCustomIcon ? getFileUrlByTemplateId(templateId) : null}
      customColor={instanceColor}
      selectedIconName={selectedIconName as TMuiIcon}
      simpleIcon
      type={type}
      zIndex={0}
    />
  );
};
