import { QualityControlInstance, Task } from '@pro4all/graphql';
import { useTheme } from '@pro4all/shared/mui-wrappers';
import { LinkedInstance } from '@pro4all/workflow/ui/utils';

export const useGetInstanceSnagColor = (
  currentTask?: Task,
  instance?: LinkedInstance
): string | undefined => {
  const theme = useTheme();
  const snagInstance = currentTask?.linkedSnagInstances?.find(
    (snagInstance) => snagInstance?.id === instance?.id
  );

  if (snagInstance?.deletedAt) {
    return theme.palette.error.dark;
  }

  return (
    snagInstance?.indicativeState?.color ||
    (instance as QualityControlInstance)?.indicativeState?.color ||
    undefined
  );
};
