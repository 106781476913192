import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ThreeDConversionStatus } from '@pro4all/documents/ui/3d';
import { ApprovalStatus } from '@pro4all/documents/ui/approval';
import { CommentStatus } from '@pro4all/documents/ui/comments';
import { FinalizeStatus } from '@pro4all/documents/ui/finalize';
import { QRCodeStatus } from '@pro4all/documents/ui/stamper';
import { useGetThumbnail } from '@pro4all/documents/ui/utils';
import { Document } from '@pro4all/graphql';
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Typography,
} from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { DocumentIcon } from '@pro4all/shared/ui/document-icon';
import { ImageLoader } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { ImagePlaceHolder } from '@pro4all/shared/ui/image-placeholder';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';

const THUMBNAIL_HEIGHT = 140;
const SCROLL_DEBOUNCE = 250;

export const DocumentGridCard: React.FC<{
  containerHeight: number;
  document: Document;
  onClick: () => void;
  parentScrollPosition: number;
}> = ({ containerHeight, document, parentScrollPosition, onClick }) => {
  const { t } = useTranslation();

  const BoxRef = useRef<HTMLDivElement>(null);

  const [inScreen, setInScreen] = useState(false);
  const [imgLoadError, setImgLoadError] = useState(false);
  const [timeoutRef, setTimeoutRef] = useState<number | null>(null);

  const { debounce, loading, thumbnailUrl } = useGetThumbnail({
    debounceDuration: SCROLL_DEBOUNCE,
    documentId: document.id,
    load: inScreen,
    versionId: document.versionId,
  });

  // Clears the timeout when the component is not in the screen
  useEffect(() => {
    if (!inScreen) {
      window.clearTimeout(debounce);
    }
  }, [inScreen, debounce]);

  // Checks if the component is in the screen
  useEffect(() => {
    if (timeoutRef) window.clearTimeout(timeoutRef);

    if (BoxRef.current) {
      const top = BoxRef.current.offsetTop;
      const bottom = top + BoxRef.current.clientHeight;

      if (
        (top >= parentScrollPosition &&
          top <= parentScrollPosition + containerHeight) ||
        (bottom >= parentScrollPosition &&
          bottom <= parentScrollPosition + containerHeight &&
          top <= parentScrollPosition)
      ) {
        setTimeoutRef(
          window.setTimeout(() => {
            setInScreen(true);
          }, 500)
        );
      } else {
        setInScreen(false);
      }
    }
    // Dont add timeoutRef to the dependencies array
  }, [parentScrollPosition, containerHeight, BoxRef.current]);

  const getThumbnailComponent = () => {
    if (!inScreen && !thumbnailUrl) return <Icon iconName="snooze"></Icon>;
    if (loading) return <ImageLoader height={THUMBNAIL_HEIGHT} />;
    if (thumbnailUrl && !imgLoadError)
      return (
        <CardMedia
          component="img"
          height={THUMBNAIL_HEIGHT}
          image={thumbnailUrl}
          onError={() => {
            setImgLoadError(true);
          }}
        />
      );
    return <ImagePlaceHolder randomShape shadow={false} />;
  };

  return (
    <Card ref={BoxRef} variant="outlined">
      <CardActionArea onClick={onClick}>
        <CardContent>
          <MiddleEllipsis text={document.name}></MiddleEllipsis>
          <Typography color="text.secondary" variant="body2">
            {`${document.versionNumber} ${t('versions')}`}
          </Typography>
        </CardContent>
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: customColors.grey[500],
            display: 'flex',
            height: `${THUMBNAIL_HEIGHT}px`,
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {getThumbnailComponent()}
        </Box>

        <CardActions>
          <DocumentIcon
            extension={document.extension}
            isExpected={document.isExpected}
          />
          <QRCodeStatus {...document} />
          <CommentStatus commentStatus={document.commentStatus} />
          <FinalizeStatus {...document} />
          <ApprovalStatus {...document} />
          <ThreeDConversionStatus {...document} />
        </CardActions>
      </CardActionArea>
    </Card>
  );
};
