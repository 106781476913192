import React from 'react';

import {
  ModalShowGroup,
  ModalShowUser,
  ModalShowUsersInGroup,
} from '@pro4all/shared/ui/identity-card';
import { BatchInfoModal, DiscardModal } from '@pro4all/shared/ui/messages';

export const GlobalModals = () => (
  <>
    <BatchInfoModal />
    <DiscardModal />
    <ModalShowUsersInGroup />
    <ModalShowGroup />
    <ModalShowUser />
  </>
);
