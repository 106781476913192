import React from 'react';
import { useTranslation } from 'react-i18next';

import { SpreadsheetApiUploadResponse } from '@pro4all/documents/data-access';
import { Alert } from '@pro4all/shared/mui-wrappers';
import { Link } from '@pro4all/shared/ui/link';

export const ImportSpreadsheetMessage: React.FC<{
  uploadFolderResponse: SpreadsheetApiUploadResponse;
}> = ({ uploadFolderResponse }) => {
  const { t } = useTranslation();
  const { errorValue, errorName, success } = uploadFolderResponse;

  let importMessage = t(
    'Pasting folders has started, this could take a few minutes.'
  );
  const supportLink = !success ? (
    <Link color="inherit" href={t('IMPORT_SPREADSHEET_ERROR_LINK')}>
      {t('More info')}
    </Link>
  ) : null;

  if (!success && !errorName)
    importMessage = t(
      'Selected document could not be used. Try a different document.'
    );
  if (!success && errorName)
    importMessage = errorValue
      ? t(errorName, {
          errorValue: errorValue,
          interpolation: { escapeValue: false },
        })
      : t(errorName);
  return <Alert action={supportLink}>{importMessage}</Alert>;
};
