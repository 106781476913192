import React from 'react';
import { useTranslation } from 'react-i18next';

import { ShareType } from '@pro4all/graphql';
import { Button } from '@pro4all/shared/ui/buttons';
import { Footer } from '@pro4all/shared/ui/footer';

import { useGenerateLinkContext } from '../GenerateLinkContext';

import { ComposeMessageContent } from './ComposeMessageContent';
import { SendAndCloseButton } from './SendAndCloseButton';
import { ShareLinkButtons } from './ShareLinkButtons';

export const ComposeMessage: React.FC = () => {
  const { t } = useTranslation();

  const { selectedShareMethod, closeModal } = useGenerateLinkContext();

  return (
    <>
      <ComposeMessageContent />
      <Footer>
        <Button
          color="inherit"
          data-testid="close-composeMessageModal"
          onClick={() => closeModal()}
        >
          {t('Close')}
        </Button>
        {selectedShareMethod === ShareType.Public ? (
          <ShareLinkButtons />
        ) : (
          <SendAndCloseButton />
        )}
      </Footer>
    </>
  );
};
