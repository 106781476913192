import { useMemo } from 'react';

import { Template, useFieldDefinitionsIncludeQuery } from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useTableCheck } from '@pro4all/shared/ui/table';

import { useMetaDataContext } from '../../views/MetaDataContext';

import { useExportTemplate } from './useExportTemplate';

export const useExportTemplates = () => {
  const { checkedRows, uncheckAllRows } = useTableCheck<Template>();
  const { templateService, templateType } = useMetaDataContext();

  const exportTemplate = useExportTemplate();

  const {
    params: { projectId },
  } = useRouting();

  // Get all ids of the checked rows.
  const idsSelectedTemplates: string[] = useMemo(
    () => checkedRows.map((checkedRow) => checkedRow.id),
    [checkedRows]
  );

  // Get data of only the selected templates.
  // Especially the prop `items` that we currently miss in the table data (checkedRows).
  // We removed the prop `items from the table data because it is a very expensive prop timewise and table loading got really slow.
  // So now we have to get it alternatively and this is how we do it.
  // We pass in a prop called `selectedIds` and then we only get data for the selected templates.
  const { templates } = useTemplatesInclude({
    includeItems: true,
    includeOrgItems: true,
    includeScope: true,
    includeType: true,
    selectedIds: idsSelectedTemplates,
    skip: !idsSelectedTemplates || idsSelectedTemplates.length === 0,
    templateService,
    templateType,
  });

  const { data: dataFieldDefinitions } = useFieldDefinitionsIncludeQuery({
    variables: {
      includeOrgItems: true,
      includeScope: true,
      projectId,
      templateService,
    },
  });

  const exportTemplates = () => {
    templates.forEach((row) => {
      exportTemplate({
        fieldDefinitions: dataFieldDefinitions?.fieldDefinitions,
        selectedTemplate: row,
        templateType,
      });
    });
    uncheckAllRows();
  };

  return exportTemplates;
};
