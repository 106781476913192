import BaseTable from 'react-base-table';
import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { StyledTableProps } from '@pro4all/shared/ui/table';

export const StyledObjectsTable = styled(BaseTable)<StyledTableProps>`
  --grey: ${customColors.grey300};

  .BaseTable__row {
    cursor: pointer;
  }

  .BaseTable__row:hover {
    background-color: var(--grey);
  }

  .row-selected,
  .row-selected:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;
