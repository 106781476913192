import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { QcPermissionCategory, VisualContext } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
} from '@pro4all/shared/mui-wrappers';
import { StylingDefaults } from '@pro4all/shared/themes';
import { Button } from '@pro4all/shared/ui/buttons';
import { useTableCheck } from '@pro4all/shared/ui/table';
import { Footer } from '@pro4all/shared/ui/footer';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { Text } from '@pro4all/shared/ui/typography';

import { TDeleteDrawingsConfig } from './DrawingActionBar';
import { DeleteVariant } from './types';

interface Props {
  deleteDrawings: (
    arg0: VisualContext[],
    config: TDeleteDrawingsConfig
  ) => void;
  deleteVariant: DeleteVariant;
  setShowDialog: Dispatch<SetStateAction<boolean>>;
  showDialog: boolean;
}

export const DrawingsDeleteDialog: React.FC<Props> = ({
  deleteDrawings,
  deleteVariant,
  setShowDialog,
  showDialog,
}) => {
  const { t } = useTranslation();
  const { checkedRows } = useTableCheck<VisualContext>();
  const { userId } = useOrganizationContext();

  const { formDeleteAll, snagDeleteAll } = useQCPermissions({
    category: QcPermissionCategory.Qcs,
  });
  const canDeeleteResults = formDeleteAll && snagDeleteAll;

  const handleDelete = ({ includeSnagsAndForms }: TDeleteDrawingsConfig) => {
    deleteDrawings(
      deleteVariant === DeleteVariant.DELETE_ONLY_OWN
        ? checkedRows.filter((row) =>
            row.createdBy ? row.createdBy.id === userId : false
          )
        : checkedRows,
      { includeSnagsAndForms }
    );
  };

  return (
    <DialogContainer
      onClose={() => setShowDialog(false)}
      open={showDialog}
      scroll="paper"
      sx={{
        '&& .MuiPaper-root': {
          padding: 0,
          paddingY: 3,
        },
      }}
    >
      <Box
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          paddingBottom: 4,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
            paddingX: 4,
          }}
        >
          <Icon color="inherit" iconName="delete" sx={{ fontSize: '24px' }} />
          <Text variant="h4">{t('Delete drawings')}</Text>
        </Box>
      </Box>
      <DialogContents style={{ overflowX: 'hidden' }}>
        <Box>
          <>
            {deleteVariant === DeleteVariant.DELETE_NONE && (
              <>
                <DialogSubHeader>{t('No permissions')}</DialogSubHeader>
                <p>
                  {`${t(
                    "You don't have the permission to delete drawings you don't own. The following drawings won't be deleted"
                  )}:`}{' '}
                </p>
                <ul>
                  {checkedRows.map((fp, index) => (
                    <li key={index}>{fp.name}</li>
                  ))}
                </ul>
              </>
            )}

            {deleteVariant === DeleteVariant.DELETE_ONLY_OWN && (
              <>
                <DialogSubHeader>
                  {t('Only your own drawing(s) will be deleted')}
                </DialogSubHeader>
                <p>
                  {t(
                    "You are trying to delete drawings you don't own. You don't have the right permissions to do so."
                  )}
                </p>
                <p>
                  <b>{`${t("The following drawings won't be deleted")}:`}</b>
                </p>
                <ul>
                  {checkedRows
                    .filter((row) =>
                      row.createdBy ? row.createdBy.id !== userId : false
                    )
                    .map((fp, index) => (
                      <li key={index}>{fp.name}</li>
                    ))}
                </ul>
                <p>
                  <b>{`${t(
                    "If you continue, the following drawings will be deleted (this action can't be undone)"
                  )}:`}</b>
                </p>
                <ul>
                  {checkedRows
                    .filter((row) =>
                      row.createdBy ? row.createdBy.id === userId : false
                    )
                    .map((fp, index) => (
                      <li key={index}>{fp.name}</li>
                    ))}
                </ul>
              </>
            )}

            {deleteVariant === DeleteVariant.DELETE_SELECTED && (
              <>
                <DialogSubHeader>
                  <Text fontStyle="italic" variant="h4">
                    {t(
                      'Are you sure you want to delete the drawings and the snags/forms on the drawings?'
                    )}
                  </Text>
                </DialogSubHeader>
                <Box
                  sx={{
                    mb: (theme) => theme.spacing(2),
                    mt: (theme) => theme.spacing(6),
                  }}
                >
                  <Text fontWeight={400} variant="h4">
                    {`${t(
                      'If you continue, the following drawings will be deleted'
                    )}:`}
                  </Text>
                </Box>
                {checkedRows.map((fp, index) => (
                  <li key={index}>{fp.name}</li>
                ))}
              </>
            )}

            {deleteVariant === DeleteVariant.DELETE_ALL && (
              <>
                <DialogSubHeader>{t('Confirm deletion')}</DialogSubHeader>
                <p>
                  {t(
                    "If you proceed, ALL drawings will be deleted (this action can't be undone)."
                  )}
                </p>
              </>
            )}
          </>
        </Box>
      </DialogContents>
      <DialogActions>
        <Footer pb={3} pt={2} px={3}>
          <Box mr="auto">
            <Button
              color="inherit"
              onClick={() => setShowDialog(false)}
              variant="text"
            >
              {t('Cancel')}
            </Button>
          </Box>
          <Tooltip
            open={!canDeeleteResults}
            placement="bottom"
            title={t(
              'You cannot delete the results on this drawing, because you do not have the right view (view all) or delete (Delete all) permissions required to do this.'
            )}
          >
            <Button
              color="error"
              disabled={!canDeeleteResults}
              onClick={() => handleDelete({ includeSnagsAndForms: true })}
            >
              {t('Delete drawing and results')}
            </Button>
          </Tooltip>

          {deleteVariant !== DeleteVariant.DELETE_NONE && (
            <Button
              color="error"
              onClick={() => handleDelete({ includeSnagsAndForms: false })}
              sx={{ backgroundColor: 'rgba(211, 47, 47, 1)' }}
              type="submit"
              variant="contained"
            >
              {t('Delete drawing')}
            </Button>
          )}
        </Footer>
      </DialogActions>
    </DialogContainer>
  );
};

const DialogContents = styled(DialogContent)`
  && {
    overflow-x: hidden;
  }
`;

const DialogContainer = styled(Dialog)`
  && {
    .MuiPaper-root {
      padding: ${({ theme }) => theme.spacing(4)};
      border-radius: ${StylingDefaults.borderRadius};
      box-shadow: ${StylingDefaults.boxShadow};
      min-width: 10vw;
      max-width: 40vw;
      min-height: 10vh;
      max-height: 90vh;
    }

    .MuiBackdrop-root {
      background-color: ${StylingDefaults.backdropColor};
    }
  }
`;

const DialogSubHeader = styled.h3`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;
