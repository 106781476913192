import React from 'react';
import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { PurpleBackground } from '@pro4all/shared/ui/layout';
import { ProstreamLogo } from '@pro4all/shared/ui/prostream-logo';
import { Shape } from '@pro4all/shared/ui/shapes';

const Container = styled(Box)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    --spacing: ${({ theme }) => theme.spacing(3)};
    --spacing-lg: ${({ theme }) => theme.spacing(6)};
  }
`;

const Block = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex: 0 0 auto;
    margin: var(--spacing) 0 var(--spacing) 200px;
  }

  width: 450px;
  min-height: 300px;
  margin: var(--spacing);
  padding: var(--spacing-lg);
  background: white;
  border-radius: 30px;
`;

const BackgroundShape = styled.div`
  display: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    padding: 0 100px;
  }

  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  height: 100%;
  min-height: 100vh;
`;

const BackgroundLogo = styled.div`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
  position: fixed;
  top: var(--spacing-lg);
  right: var(--spacing-lg);
  z-index: 1;
`;

const StyledShape = styled(Shape)`
  && {
    width: 100%;
    max-width: 480px;
    height: auto;
  }
`;

export const RegistrationPage: React.FC = ({ children }) => (
  <>
    <PurpleBackground />
    <Container>
      <Block>{children}</Block>

      <BackgroundShape>
        <StyledShape color="orange" type="collaborate" />
      </BackgroundShape>

      <BackgroundLogo>
        <a
          href="https://prostream.app"
          rel="noopener noreferrer"
          target="_blank"
        >
          <ProstreamLogo color="white" type="stacked" />
        </a>
      </BackgroundLogo>
    </Container>
  </>
);
