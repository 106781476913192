import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const EditMetadata: React.FC<SvgIconProps> = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5 14C4.71667 14 4.47917 13.9042 4.2875 13.7125C4.09583 13.5208 4 13.2833 4 13C4 12.7167 4.09583 12.4792 4.2875 12.2875C4.47917 12.0958 4.71667 12 5 12H10C10.2833 12 10.5208 12.0958 10.7125 12.2875C10.9042 12.4792 11 12.7167 11 13C11 13.2833 10.9042 13.5208 10.7125 13.7125C10.5208 13.9042 10.2833 14 10 14H5ZM5 10C4.71667 10 4.47917 9.90417 4.2875 9.7125C4.09583 9.52083 4 9.28333 4 9C4 8.71667 4.09583 8.47917 4.2875 8.2875C4.47917 8.09583 4.71667 8 5 8H14C14.2833 8 14.5208 8.09583 14.7125 8.2875C14.9042 8.47917 15 8.71667 15 9C15 9.28333 14.9042 9.52083 14.7125 9.7125C14.5208 9.90417 14.2833 10 14 10H5ZM5 6C4.71667 6 4.47917 5.90417 4.2875 5.7125C4.09583 5.52083 4 5.28333 4 5C4 4.71667 4.09583 4.47917 4.2875 4.2875C4.47917 4.09583 4.71667 4 5 4H14C14.2833 4 14.5208 4.09583 14.7125 4.2875C14.9042 4.47917 15 4.71667 15 5C15 5.28333 14.9042 5.52083 14.7125 5.7125C14.5208 5.90417 14.2833 6 14 6H5ZM13 19V17.35C13 17.2167 13.025 17.0875 13.075 16.9625C13.125 16.8375 13.2 16.725 13.3 16.625L18.525 11.425C18.675 11.275 18.8417 11.1667 19.025 11.1C19.2083 11.0333 19.3917 11 19.575 11C19.775 11 19.9667 11.0375 20.15 11.1125C20.3333 11.1875 20.5 11.3 20.65 11.45L21.575 12.375C21.7083 12.525 21.8125 12.6917 21.8875 12.875C21.9625 13.0583 22 13.2417 22 13.425C22 13.6083 21.9667 13.7958 21.9 13.9875C21.8333 14.1792 21.725 14.35 21.575 14.5L16.375 19.7C16.275 19.8 16.1625 19.875 16.0375 19.925C15.9125 19.975 15.7833 20 15.65 20H14C13.7167 20 13.4792 19.9042 13.2875 19.7125C13.0958 19.5208 13 19.2833 13 19ZM19.575 14.4L20.5 13.425L19.575 12.5L18.625 13.45L19.575 14.4ZM14.5 18.5H15.45L18.475 15.45L18.025 14.975L17.55 14.525L14.5 17.55V18.5ZM14.5 18.5V17.55L17.55 14.525L18.475 15.45L15.45 18.5H14.5Z"
      fill="black"
      fillOpacity="0.56"
    />
  </SvgIcon>
);
