import React from 'react';
import styled from 'styled-components';

import { ProstreamLogo } from '@pro4all/shared/ui/prostream-logo';

import { RegistrationForm } from '../components/registration-form/RegistrationForm';
import { RegistrationPage } from '../components/RegistrationPage';

const FormLogo = styled.div`
  align-self: flex-start;
  padding-bottom: var(--spacing);
`;

export const Registration = () => (
  <RegistrationPage>
    <FormLogo>
      <a href="https://prostream.app" rel="noopener noreferrer" target="_blank">
        <ProstreamLogo type="stacked" />
      </a>
    </FormLogo>
    <RegistrationForm />
  </RegistrationPage>
);
