import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import dayjs from 'dayjs';

import { Task } from '@pro4all/graphql';
import {
  BarChart,
  ChartsWrapper,
  DonutChart,
  LoadingOverlay,
  ValueChart,
} from '@pro4all/shared/ui/charts';
import { Loader } from '@pro4all/shared/ui/loader';

import { useDonutOptionsTasks } from './useDonutOptionsTasks';
import { useTimeClusterOptionsTasks } from './useTimeClusterOptionsTasks';

export const TaskCharts: React.FC<{
  loading: boolean;
  tasks: Task[];
}> = ({ loading, tasks }) => {
  const { t } = useTranslation();

  const [avarageCompletionTime, setAvarageCompletionTime] = useState(0);

  const donutOptions = useDonutOptionsTasks(tasks);
  const timeClusterOptions = useTimeClusterOptionsTasks();

  const calculateAverageCompletionTime = (tasks: Task[]): number => {
    if (!tasks || tasks.length < 1) return 0;
    let totalCompletionTime = 0;
    let taskCounted = 0;

    tasks.forEach((task) => {
      if (!task.createdAt || !task.completedAt) return;
      taskCounted++;
      const createdAt = dayjs(task.createdAt);
      const completedAt = dayjs(task.completedAt);

      const completionTime = completedAt.diff(createdAt, 'millisecond');
      totalCompletionTime += completionTime;
    });

    const averageCompletionTime = totalCompletionTime / taskCounted;
    return Math.round(dayjs.duration(averageCompletionTime).asDays());
  };

  useEffect(() => {
    setAvarageCompletionTime(calculateAverageCompletionTime(tasks));
  }, [tasks]);

  return (
    <>
      {loading && (
        <LoadingOverlay>
          <Loader />
        </LoadingOverlay>
      )}
      {tasks && tasks.length > 0 && (
        <Box p={2} sx={{ flexGrow: 'inherit' }}>
          <ChartsWrapper columns={3} rows={2}>
            <ValueChart
              chartKey="document-tasks-count-top-left"
              columnSpan={1}
              rowSpan={1}
              title={`${t('Amount of tasks')}`}
              value={tasks.length.toString()}
            ></ValueChart>
            <DonutChart
              chartKey="document-tasks-donut-top-left"
              columnSpan={1}
              data={tasks}
              initialOptionId="status"
              options={donutOptions}
              rowSpan={1}
            ></DonutChart>
            <DonutChart
              chartKey="document-tasks-donut-top-right"
              columnSpan={1}
              data={tasks}
              initialOptionId="taskCategoryName"
              options={donutOptions}
              rowSpan={1}
            ></DonutChart>
            <ValueChart
              afterValue={` ${t('Days')}`}
              chartKey="document-tasks-count-bottom-left"
              columnSpan={1}
              rowSpan={1}
              title={`${t('Avarage completion time')}`}
              value={avarageCompletionTime.toString()}
            ></ValueChart>
            <BarChart
              chartKey="document-tasks-bar-bottom"
              columnSpan={2}
              data={tasks}
              initialTimeClusterOptionId="completedAt"
              initialTimeOptionId="month"
              rowSpan={1}
              timeClusterOptions={timeClusterOptions}
            />
          </ChartsWrapper>
        </Box>
      )}
    </>
  );
};
