import { AuthenticatedApiService } from '@pro4all/authentication/src/services/authenticated-api-service';
import { getOidcHeaders } from '@pro4all/authentication/src/utils';

export const TileBaseURL = 'https://qualitycontrol.pro4all.io/api/';

class Service {
  api: AuthenticatedApiService;

  constructor() {
    this.api = new AuthenticatedApiService(TileBaseURL);
  }

  getTileUrl = (
    drawingId: string,
    z: number,
    x: number,
    y: number,
    page?: number
  ): Promise<string> =>
    this.api.getImageUrl(
      `visualcontexts/${drawingId}/page/${
        page === 0 ? 1 : page
      }/tile/${z}/${x}/${y}`
    );

  getTile64 = async (
    drawingId: string,
    z: number,
    x: number,
    y: number,
    page: number
  ): Promise<string> => {
    const url = await this.getTileUrl(drawingId, z, x, y, page);
    if (!url) {
      return;
    }

    const result = await fetch(url, {
      headers: {
        ...getOidcHeaders(),
      },
    });
    const blob = await result.blob();
    const src64 = await blobToBase64(blob);
    if (typeof src64 === 'string' && src64.length > 0) return src64;
    else return null;
  };
}

const blobToBase64: (blob: Blob) => Promise<unknown> = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const TileService = new Service();
