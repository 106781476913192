import React from 'react';
import { useField } from 'formik';

import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormHelperText,
} from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import { Text } from '@pro4all/shared/ui/typography';

import { Checkbox } from '@pro4all/shared/ui/inputs';

export const FormikCheckboxGroup: React.FC<
  Omit<FormControlLabelProps, 'control'> & { options: Option[]; row?: boolean }
> = ({ options, name, row, ...props }) => {
  const [field, meta, helpers] = useField<string[]>({ name });
  const hasError = Boolean(meta.touched) && Boolean(meta.error);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setTouched(true);

    if (event.target.checked) {
      field.value = [...field.value, event.target.value];
      helpers.setValue(field.value);
    } else {
      helpers.setValue(
        field.value.filter((item: string) => item !== event.target.value)
      );
    }
  };

  return (
    <FormControl error={hasError}>
      {props.label && (
        <Text color={hasError ? 'error' : 'inherit'} variant="h5">
          {props.label}
        </Text>
      )}
      <FormGroup row={row}>
        {options.map((option: Option) => (
          <FormControlLabel
            {...field}
            {...props}
            control={
              <Checkbox
                checked={Boolean(field.value?.includes(option.id))}
                disabled={option.disabled}
                id={option.id}
                onChange={handleChange}
                value={option.id}
              />
            }
            key={option.id}
            label={option.label}
          />
        ))}
      </FormGroup>
      {hasError && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};
