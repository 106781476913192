import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FieldDefinition } from '@pro4all/graphql';
import { Button } from '@pro4all/shared/ui/buttons';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { DiscardDialog } from '@pro4all/shared/ui/dialog';
import { Text } from '@pro4all/shared/ui/typography';

import {
  formatTimeSpanDuration,
  parseTimeSpanToMilliseconds,
  sortAndSeparateRounds,
} from '../helpers';

import {
  ButtonGroup,
  FixedHeightContainer,
  RoundItem,
  RoundItemContainer,
  RoundLabel,
  RoundTime,
  StopWatchDisplay,
  StyledDialogContent,
} from './StopWatchModalUI.styles';
import { StopWatchRound, useStopWatch } from './useStopWatch';

interface TimeSpanModalProps {
  item: FieldDefinition;
  name: string;
  onClose: () => void;
  open: boolean;
  setValue: (name: string, value: string) => void;
  updatedRounds: StopWatchRound[];
}

export const StopWatchModal = ({
  name,
  setValue,
  updatedRounds,
  item,
  onClose,
  open,
}: TimeSpanModalProps) => {
  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  let initialRounds: StopWatchRound[] = [];
  let initialTime = 0;
  if (item.value && item.value !== '0') {
    const initialData: StopWatchRound[] = JSON.parse(item.value);
    const finalTimeEntry = initialData.find(
      (data) => data.label === 'Final Time'
    );
    initialTime = finalTimeEntry
      ? parseTimeSpanToMilliseconds(finalTimeEntry.time)
      : 0;
    initialRounds = initialData.filter((data) => data.label !== 'Final Time');
  }

  const {
    currentValue,
    isRunning,
    startStopWatch,
    stopStopWatch,
    updateStopWatch,
    resetStopWatch,
    rounds,
    setRounds,
    addRound,
  } = useStopWatch({
    initialRounds: initialRounds,
    initialValue: initialTime,
  });

  useEffect(() => {
    setRounds(updatedRounds.filter((data: any) => data.label !== 'Final Time'));
  }, [updatedRounds]);

  const [preSavedValue, setPreSavedValue] = useState({
    currentValue: initialTime,
    rounds: initialRounds,
  });

  const handleStartStopClick = () => {
    isRunning ? stopStopWatch() : startStopWatch();
  };

  const handleRound = () => {
    addRound(currentValue);
  };

  const handleSave = () => {
    stopStopWatch();
    //Convert round times to an array of appropriate objects
    const roundsData = rounds.map((round) => ({
      id: `round_${round.id}`,
      label: round.label,
      time: round.time,
    }));

    //Create an object for the final time
    const finalTimeData = {
      id: 'final',
      label: 'Final Time',
      time: formatTimeSpanDuration(currentValue), //Convert final time to the correct format
    };

    setPreSavedValue({
      currentValue: parseTimeSpanToMilliseconds(finalTimeData.time),
      rounds: roundsData,
    });
    //Ensure final time is the first element of the array
    const dataToSave = JSON.stringify([finalTimeData, ...roundsData]);
    setValue(name, dataToSave);
    onClose();
  };

  const handleReset = () => {
    resetStopWatch();
  };

  const handleClose = () => {
    if (preSavedValue.currentValue !== currentValue) {
      setShowConfirmModal(true);
    } else {
      onClose();
    }
  };

  const handleDiscardChanges = () => {
    stopStopWatch();
    updateStopWatch(
      preSavedValue.currentValue !== 0
        ? preSavedValue.currentValue
        : initialTime
    );
    setShowConfirmModal(false);
    onClose();
  };

  const sortedRounds = sortAndSeparateRounds(rounds);

  return (
    <>
      <StyledFloatingModal onClose={handleClose} open={open}>
        <FloatingModal.Header>{t('Stopwatch')}</FloatingModal.Header>
        <StyledDialogContent>
          <FixedHeightContainer>
            <StopWatchDisplay>
              {formatTimeSpanDuration(currentValue)}
            </StopWatchDisplay>
          </FixedHeightContainer>
          <ButtonGroup>
            <Button
              color="primary"
              disabled={!isRunning && currentValue === 0}
              onClick={isRunning ? handleRound : handleReset}
            >
              {isRunning || currentValue === 0 ? t('Round') : t('Reset')}
            </Button>
            <Button
              color={isRunning ? 'error' : 'success'}
              onClick={handleStartStopClick}
              variant="contained"
            >
              {isRunning ? t('Stop') : t('Start')}
            </Button>
            <Button
              color="primary"
              disabled={
                isRunning || currentValue === preSavedValue.currentValue
              }
              onClick={handleSave}
              variant="contained"
            >
              {t('Save')}
            </Button>
          </ButtonGroup>
          <RoundItemContainer>
            {sortedRounds.map((round) => (
              <RoundItem key={round.id}>
                <RoundLabel>{round.label}</RoundLabel>
                <RoundTime>{round.time}</RoundTime>
              </RoundItem>
            ))}
          </RoundItemContainer>
        </StyledDialogContent>
      </StyledFloatingModal>
      {showConfirmModal && (
        <DiscardDialog
          confirmLabel={t('Discard')}
          iconName="reportProblemOutlined"
          onClose={() => setShowConfirmModal(false)}
          onConfirm={() => handleDiscardChanges()}
          open={showConfirmModal}
          title={
            t('Unsaved tracked time') +
            ' (' +
            formatTimeSpanDuration(currentValue) +
            ')'
          }
          titleVariant="h3"
        >
          <Text display="inline">
            {t(
              'Are you sure you want to discard? Your tracked time will be lost.'
            )}
          </Text>
        </DiscardDialog>
      )}
    </>
  );
};

const StyledFloatingModal = styled(FloatingModal)`
  &&& {
    .MuiDialog-paper {
      min-width: 700px;
      height: 600px;
    }
  }
`;
