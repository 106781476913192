import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  FieldDefinition,
  InstanceForQcInput,
  Maybe,
  QualityControlInstance,
  useSaveInstanceForQcMutation,
  useUpdateInstanceMutation,
  ValueTypeName,
} from '@pro4all/graphql';
import { isFieldVisible } from '@pro4all/metadata/ui/utils';
import { findIndicative, getColor } from '@pro4all/quality-control/utils';
import { PhotoContext } from '@pro4all/shared/contexts';
import { InstanceValues } from '@pro4all/shared/types';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';

import { useMetaDataAnswerToApiAnswer } from './useMetaDataAnswerToApiAnswer';

type Props = {
  initialValues: InstanceValues;
  items: FieldDefinition[];
  onSuccessfulSubmit: () => void;
  refetchQualityInstance: () => void;
  result: QualityControlInstance;
  updateMarkerObj: {
    instanceId: string;
    page: number;
    visualContextId: string;
    x: number;
    y: number;
  };
};

export const useSubmit = ({
  items,
  initialValues,
  onSuccessfulSubmit,
  result,
  refetchQualityInstance,
  updateMarkerObj,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [saveInstance] = useSaveInstanceForQcMutation();

  const [updateInstance] = useUpdateInstanceMutation();

  const { resetInitialPhotos } = useContext(PhotoContext);

  const { metaDataAnswerToApiAnswer } = useMetaDataAnswerToApiAnswer();

  const {
    editItems,
    state: { items: providerItems },
  } = useOptimisticResponseContext<QualityControlInstance>();

  const updateQCIFields = (
    id: string,
    value: string,
    fieldDefinitions: FieldDefinition[] | Maybe<FieldDefinition[]> | undefined
  ) => {
    fieldDefinitions?.forEach((field: FieldDefinition) => {
      if (field.type === 'Section') {
        updateQCIFields(id, value, field?.valueType?.subFields);
      } else {
        if (field.id === id) field.value = value;
      }
    });
  };

  return async ({
    keepFormOpen = false,
    values,
  }: {
    keepFormOpen?: boolean;
    values: InstanceValues;
  }) => {
    const getAnswers = (
      answers: InstanceForQcInput[],
      item: FieldDefinition
    ): InstanceForQcInput[] => {
      if (item.type === ValueTypeName.Section) {
        return item.valueType.subFields.reduce(getAnswers, answers);
      }

      const params = {
        multiSelect: item?.valueType?.multiSelect ?? false,
        type: item.type,
      };
      const isItemVisible = isFieldVisible({
        field: item,
        formValues: values,
        items,
      });

      const prevValue = metaDataAnswerToApiAnswer({
        ...params,
        value: initialValues[item.id],
      });

      const nextValue = metaDataAnswerToApiAnswer({
        ...params,
        value: isItemVisible
          ? values[item.id]
          : item.type === ValueTypeName.HierarchyList
          ? '[]'
          : null,
      });

      if (prevValue === nextValue && nextValue !== '') return answers;
      return [...answers, { fieldDefinitionId: item.id, value: nextValue }];
    };
    const answers = items.reduce(getAnswers, []);

    try {
      if (providerItems.length > 0) {
        const newFields = items;

        if (newFields && newFields?.length > 0) {
          answers.forEach((answer) => {
            updateQCIFields(answer.fieldDefinitionId, answer.value, newFields);
          });
        }

        const finalInstances = providerItems.map((instance) => {
          const target = {};

          const newInstance = Object.assign(target, instance);

          if (newInstance.id) {
            if (newInstance.id === result.id) {
              if (newInstance.items) newInstance.items = newFields;
              const field: FieldDefinition | Record<string, never> =
                findIndicative(newFields);

              if (field.valueType) {
                const statusColor = getColor(field);

                if (statusColor) {
                  newInstance.indicativeState = {
                    color: statusColor,
                    index: newInstance.indicativeState
                      ? newInstance.indicativeState.index
                      : 0,
                    value: field.value,
                  };
                }
              }
            }
          }
          return newInstance;
        });

        editItems(finalInstances);
      }

      if (updateMarkerObj.instanceId) {
        const finalInstances = providerItems.map((instance) => {
          const target = {};

          const newInstance = Object.assign(target, instance);

          if (newInstance.id) {
            if (newInstance.id === updateMarkerObj.instanceId) {
              newInstance.x = updateMarkerObj.x;
              newInstance.y = updateMarkerObj.y;
            }
          }
          return newInstance;
        });

        editItems(finalInstances);

        const newY = (updateMarkerObj.y + 0.25).toString();

        await updateInstance({
          variables: {
            instanceId: updateMarkerObj.instanceId,
            page: updateMarkerObj.page,
            visualContextId: updateMarkerObj.visualContextId,
            x: updateMarkerObj.x.toString(),
            y: newY,
          },
        });
      }

      await saveInstance({
        variables: {
          answers,
          instanceId: result.id,
          templateVersion: result.templateVersion,
        },
      });

      await refetchQualityInstance();

      const message = (
        <ItemChangedMessage
          description={MessageAction.Update}
          entityName={result.name}
          entityTypeTranslation={EntityTypeTranslation.Result}
        />
      );

      enqueueSnackbar(message);
      resetInitialPhotos();
      if (!keepFormOpen) onSuccessfulSubmit();

      // Return save success
      return true;
    } catch (e) {
      enqueueSnackbar(t('Something went wrong. Please try again.'));

      // Return save failed
      return false;
    }
  };
};
