import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { IntegrationType } from '@pro4all/graphql';
import { SchemaExtender } from '@pro4all/shared/forms';
import { FormikInput } from '@pro4all/shared/ui/formik';
import { InfoBlock } from '@pro4all/shared/ui/info-block';

import { useIntegrationFormConfig } from './useIntegrationFormConfig';

export const CustomSnagstream = () => {
  const { t } = useTranslation();
  const { getField } = useIntegrationFormConfig();
  const environmentUrlField = getField('environmentUrl');

  return (
    <>
      <InfoBlock
        paragraph={`${t(
          'To integrate Snagstream with Prostream we ask you to provide the following information'
        )}:`}
      />
      <FormikInput
        label={`* ${environmentUrlField?.label}`}
        name={environmentUrlField?.name}
        type="text"
      />
    </>
  );
};

export const useCustomSnagstreamValidationSchema = (): Record<
  string,
  SchemaExtender
> => {
  const { t } = useTranslation();

  const environmentUrl = () =>
    Yup.string().when(['application'], {
      is: (application) =>
        application?.id === IntegrationType.IntegrationSnagstream,
      then: Yup.string().required(
        t("'{{environmentUrl}}' is required", {
          environmentUrl: t('Snagstream environment url'),
        })
      ),
    });

  return {
    environmentUrl,
  };
};
