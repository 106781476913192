import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { FolderPermissionType } from '@pro4all/documents/data-access';
import { Document, DocumentVersion } from '@pro4all/graphql';
import { DndTypes } from '@pro4all/shared/config';
import { useTableCheck } from '@pro4all/shared/ui/table';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

export const Draggable: React.FC<{
  document: Document | DocumentVersion;
  hasFolderPermission: (permission: FolderPermissionType) => boolean;
}> = ({ document, hasFolderPermission, children }) => {
  const { t } = useTranslation();
  const { userId } = AuthService.getProfile();
  const { checkedRows } = useTableCheck<Document>();
  const { createdBy, folderId, id, name, versionId, versionNumber } = document;

  const [showTooltip, setShowTooltip] = useState(false);

  const canDeleteContent = hasFolderPermission('DeleteContent');
  const canDeleteOwnContent = hasFolderPermission('DeleteOwnContent');
  const canMoveDocument =
    canDeleteContent ||
    (canDeleteOwnContent && document.createdBy?.id === userId);

  const [, drag, preview] = useDrag({
    item: {
      checkedRows,
      createdBy,
      currentParentFolderId: folderId,
      id,
      name,
      type: DndTypes.DOCUMENT,
      versionId,
      versionNumber,
    },
    type: DndTypes.DOCUMENT,
  });

  const handleDragStart = (e: React.DragEvent) => {
    if (!canMoveDocument) {
      setShowTooltip(true);
      e.preventDefault(); // Prevents the drag action for non-draggable items
    }
  };

  const handleDragEnd = () => {
    setShowTooltip(false);
  };

  return preview(
    <div
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      ref={drag}
      style={{ display: 'flex', flex: '0 0 auto', height: '100%' }}
    >
      {showTooltip ? (
        <Tooltip
          placement="bottom-start"
          title={t(
            'Due to lacking delete permissions you cannot move `{{name}}`',
            { name: document.name }
          )}
        >
          <div style={{ display: 'flex', flex: '0 0 auto', height: '100%' }}>
            {children}
          </div>
        </Tooltip>
      ) : (
        children
      )}
    </div>
  );
};
