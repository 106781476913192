import React from 'react';

import { Box, useMediaQuery, useTheme } from '@pro4all/shared/mui-wrappers';
import { ActionProps } from '@pro4all/shared/types';

import { TableSearch } from '@pro4all/shared/ui/table';

import * as Styled from './ActionBar.styled';
import { Actions } from './Actions';
import { ButtonColor, ButtonOrientation } from './types';

export interface ActionBarProps {
  altActions?: ActionProps[];
  buttonColor?: ButtonColor;
  buttonOrientation?: ButtonOrientation;
  flexEnd?: boolean;
  hideLabels?: boolean;
  mainActions?: ActionProps[];
  search?: boolean;
  showAltBar?: boolean;
  showTableActions?: boolean;
  sidebarActions?: ActionProps[];
}

export const ActionBar = ({
  altActions = [],
  buttonColor = 'primary',
  buttonOrientation = 'horizontal',
  hideLabels = false,
  mainActions = [],
  search = false,
  showAltBar = false,
  showTableActions = true,
  flexEnd = false,
}: ActionBarProps) => {
  const altBar = Boolean(altActions?.length) && showAltBar;
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const actionBar =
    buttonOrientation === 'horizontal' ? (
      <Styled.SlideContainer>
        <Styled.Slide $showAltBar={altBar}>
          <Actions
            actions={mainActions}
            buttonColor={buttonColor}
            flexEnd={flexEnd}
            hideLabels={hideLabels}
          />
          <Actions
            actions={altActions}
            flexEnd={flexEnd}
            hideLabels={hideLabels}
          />
        </Styled.Slide>
      </Styled.SlideContainer>
    ) : (
      //TODO: The three dots probably don't work properly for vertical orientation at the moment
      <Styled.VerticalSlideContainer
        $largeScreen={largeScreen}
        $numberOfActions={mainActions.length}
      >
        <Styled.Slide $showAltBar={altBar}>
          <Actions
            actions={mainActions}
            buttonColor={buttonColor}
            buttonOrientation={buttonOrientation}
            flexEnd={flexEnd}
          />
          <Actions actions={altActions} flexEnd={flexEnd} />
        </Styled.Slide>
      </Styled.VerticalSlideContainer>
    );

  if (!search) {
    return actionBar;
  }
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: '1',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ flexGrow: '1' }}>{showTableActions && actionBar}</Box>
      <Box
        sx={{
          'align-self': 'end',
          display: { sm: 'inherit', xs: 'none' },
          margin: 0.5,
        }}
      >
        {search && <TableSearch />}
      </Box>
    </Box>
  );
};
