import React from 'react';

import { IconButton } from '@pro4all/shared/ui/buttons';
import { FormikInput } from '@pro4all/shared/ui/formik';

import * as Styled from './Keyword.styles';
import { InitialValues, KeywordDisplay } from './types';

type KeywordEditProps = {
  index: number;
  item: KeywordDisplay;
  onDelete: (id: string) => void;
  values: InitialValues;
};

const KeywordEditComponent = ({ index, item, onDelete }: KeywordEditProps) => (
  <>
    <Styled.TextWrapper>
      <FormikInput name={`items[${index}].text`} />
    </Styled.TextWrapper>

    {/* // For now it is decided to visually hide the score. I leave the code commented out in case we want to show it in the future.
       <Styled.ScoreWrapper>
        <FormikInput name={`items[${index}].score`} type="number" />
      </Styled.ScoreWrapper> */}

    <Styled.DeleteIconWrapper>
      <IconButton
        color="default"
        disableBorder
        iconName="close"
        onClick={() => onDelete(item.id)}
        size="small"
      />
    </Styled.DeleteIconWrapper>
  </>
);

const skipUpdate = (prevProps: KeywordEditProps, nextProps: KeywordEditProps) =>
  prevProps.item.text === nextProps.item.text &&
  prevProps.index === nextProps.index;

export const KeywordEdit = React.memo(KeywordEditComponent, skipUpdate);
