import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import type { ApiKey } from '@pro4all/graphql';
import { useRevokeApiKeysMutation } from '@pro4all/graphql';
import { useTableCheck } from '@pro4all/shared/ui/table';

import { useApolloClientCacheHelpers } from './useApolloClientCacheHelpers';

export const useRevokeApiKey = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [revokeApiKeys] = useRevokeApiKeysMutation();
  const { getCurrentlyCachedApiKeys, updateCachedApiKeys } =
    useApolloClientCacheHelpers();

  const { checkedRows: selectedApiKeys } = useTableCheck<ApiKey>();

  return async () => {
    // Max number of API keys to revoke for now is one!
    const ids = selectedApiKeys.map((apiKey) => apiKey.id);

    try {
      const { data } = await revokeApiKeys({
        variables: {
          ids,
        },
      });

      if (data?.revokeApiKeys.length === selectedApiKeys.length) {
        // 1. Inform user.
        enqueueSnackbar(t('API key has been revoked'));

        // 2. Update Apollo client cache.
        const currentlyCachedApiKeys = getCurrentlyCachedApiKeys();
        const otherApiKeys = currentlyCachedApiKeys.filter(
          (key) => key.id !== ids[0]
        );
        updateCachedApiKeys(otherApiKeys);
      } else {
        enqueueSnackbar(
          `${t('Something went wrong')}. ${t('Please try again')}.`
        );
      }
    } catch (e) {
      enqueueSnackbar(
        `${t('Something went wrong')}. ${t('Please try again')}.`
      );
    }
  };
};
