import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { FieldDefinition } from '@pro4all/graphql';
import {
  SectionsAndFields,
  useDisableSubmit,
  useInitialValues,
} from '@pro4all/quality-control/ui/results';
import { Box } from '@pro4all/shared/mui-wrappers';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikForm, FormikInput } from '@pro4all/shared/ui/formik';
import { Loader } from '@pro4all/shared/ui/loader';
import { Text } from '@pro4all/shared/ui/typography';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useSavedAnswersFormConfig } from './useSavedAnswersFormConfig';
import { useSubmit } from './useSubmit';

interface Props {
  edit: boolean;
  items: FieldDefinition[];
  name: string;
  onClose: () => void;
}

export const SavedAnswersForm = ({ edit, items, name, onClose }: Props) => {
  const { getInitialValues } = useInitialValues();
  const initialValues = getInitialValues(items);
  const { t } = useTranslation();

  const { getField, validationSchema } = useSavedAnswersFormConfig();
  const nameField = getField('name');

  const onSubmit = useSubmit({
    items,
    onClose,
  });

  const disableSubmit = useDisableSubmit({ items });

  if (!items) return <Loader />;

  const initialValuesPlusName = { ...initialValues, name: name || '' };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValuesPlusName}
      onSubmit={(values, helpers) =>
        onSubmit({
          initialValues: initialValuesPlusName,
          resetForm: helpers.resetForm,
          values,
        })
      }
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting, resetForm, values }) => (
        <FormikForm>
          <FormWrapper noPadding>
            <Box sx={{ px: 2 }}>
              {edit ? (
                <FormikInput
                  autoFocus
                  label={nameField?.label}
                  name={nameField?.name}
                />
              ) : (
                <Text variant="body1">{name}</Text>
              )}
            </Box>
            <SectionsAndFields
              edit={edit}
              hideExcludedTypes
              items={items}
              onSubmit={() =>
                onSubmit({
                  initialValues: initialValuesPlusName,
                  resetForm,
                  values,
                })
              }
            />
          </FormWrapper>
          {edit && (
            <FormFooter
              cancelLabel={t('Cancel')}
              disableSubmit={disableSubmit({
                dirty,
                errors,
                formValues: values,
                isSubmitting,
              })}
              onClose={onClose}
              pb={3}
              sticky
              submitLabel="Save"
            />
          )}
        </FormikForm>
      )}
    </Formik>
  );
};
