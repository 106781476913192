import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { Message, MessageStatus } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import {
  useTableCheck,
  useTableContextMenuRowContext,
} from '@pro4all/shared/ui/table';

import { useOptimisticDeleteDraftBatch } from '../mutation-utils/apollo-cache/useOptimisticDeleteDraftBatch';
import { useOptimisticMarkMessage } from '../mutation-utils/apollo-cache/useOptimisticMarkMessage';
export function useMessageActions() {
  const { contextMenuRow: ctxMessage } =
    useTableContextMenuRowContext<Message>();
  const { t } = useTranslation();
  const { goTo, params } = useRouting();
  const { checkedRows: selectedMessages, uncheckAllRows } =
    useTableCheck<Message>();

  const [markMessage] = useOptimisticMarkMessage();
  const [deleteDraftMessageBatch] = useOptimisticDeleteDraftBatch();

  const hasDraftFeature = useFeatureFlag('draft-messages');

  const allRead = !selectedMessages.some((message) => !message.read);
  const allUnread = !selectedMessages.some((message) => message.read);
  const [markMessageIsLoading, setMarkMessageIsLoading] = useState(false);
  const anySent = selectedMessages.some(
    (message) => message.status !== MessageStatus.Draft
  );

  const toMarkInput = (message: Message) => ({
    id: message.id,
    threadId: message.threadId,
  });

  const matchDraftMessages = useRouteMatch({
    path: Routes.draftMessages,
  });
  const matchProjectDraftMessages = useRouteMatch({
    path: Routes.projectDraftMessages,
  });

  const isDraftMessage = Boolean(
    matchDraftMessages || matchProjectDraftMessages
  );

  const markAs = async (read: boolean, messages: Message[]) => {
    if (!messages.length) return;

    const isRead = (message: Message) => message.read;
    const isUnread = (message: Message) => !message.read;

    const filteredInput = read
      ? messages.filter(isUnread).map(toMarkInput)
      : messages.filter(isRead).map(toMarkInput);
    // TODO: Change to check message type when that part is merged

    try {
      setMarkMessageIsLoading(true);
      await markMessage({
        variables: {
          messages: filteredInput,
          read,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setMarkMessageIsLoading(false);
    }
    uncheckAllRows();
  };

  const discardDraft = async (messages: Message[]) => {
    if (!messages.length) return;
    try {
      setMarkMessageIsLoading(true);
      deleteDraftMessageBatch({
        variables: {
          messages: messages.map(toMarkInput),
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setMarkMessageIsLoading(false);
    }
  };

  const onCreateMessage = () => {
    goTo(params.projectId ? 'projectMessages' : 'messages', {
      params,
      searchParams: { action: 'createMessage' },
    });
  };

  const contextMenuActions: ActionProps[] = [
    {
      ariaLabel: t('Mark as read'),
      dataTestId: 'mark-as-read-ctx',
      hidden: Boolean(ctxMessage?.read),
      key: 'mark-as-read-ctx',
      label: t('Mark as read'),
      onClick: () => ctxMessage && markAs(true, [ctxMessage]),
      startIcon: 'markUnread' as const,
    },
    {
      ariaLabel: t('Mark as unread'),
      dataTestId: 'mark-as-unread-ctx',
      hidden: Boolean(!ctxMessage?.read),
      key: 'mark-as-unread-ctx',
      label: t('Mark as unread'),
      onClick: () => ctxMessage && markAs(false, [ctxMessage]),
      startIcon: 'markUnread',
    },
  ];

  const defaultTableActions: ActionProps[] = [
    {
      ariaLabel: t('New message'),
      dataTestId: 'new-message-button',
      key: 'new-message-button',
      label: t('New message'),
      onClick: onCreateMessage,
      startIcon: 'prostreamChat',
    },
  ];

  const altTableActions: ActionProps[] = [
    {
      actuallyDisabled: allRead || markMessageIsLoading,
      ariaLabel: t('Mark as read'),
      dataTestId: 'mark-as-read-table',
      key: 'mark-as-read-table',
      label: t('Mark as read'),
      onClick: () => markAs(true, selectedMessages),
      startIcon: 'markUnread',
    },
    {
      actuallyDisabled: allUnread || markMessageIsLoading,
      ariaLabel: t('Mark as unread'),
      dataTestId: 'mark-as-read-table',
      key: 'mark-as-unread-table',
      label: t('Mark as unread'),
      onClick: () => markAs(false, selectedMessages),
      startIcon: 'mailOutline' as const,
    },
  ];

  // TODO: Once draft is released, add the discard draft action to the altTableActions array
  if (hasDraftFeature && isDraftMessage) {
    altTableActions.push({
      actuallyDisabled: anySent || markMessageIsLoading,
      ariaLabel: t('Discard draft'),
      dataTestId: 'discard-draft',
      key: 'discard-draft',
      label: t('Discard draft'),
      onClick: () => discardDraft(selectedMessages),
      startIcon: 'delete' as const,
    });
  }

  const tableActions = selectedMessages.length
    ? altTableActions
    : defaultTableActions;

  return { contextMenuActions, tableActions };
}
