import React from 'react';

import { EmailActions, Message } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { MessageBranch } from '../message-branch/MessageBranch';

import { MessageOverview } from './MessageOverview';

export const Messages: React.FC = () => {
  const { searchParams, params } = useRouting();
  const action = searchParams.get('action');

  const replyId = searchParams.get('replyId') || undefined;
  const resendId = searchParams.get('resendId') || undefined;
  const messageId = params.messageId;

  const viewMode = Boolean(params.messageId);
  const createMode = action === 'createMessage';
  const replyMode = action === EmailActions.Reply;
  const replyAllMode = action === EmailActions.ReplyAll;
  const forwardMode = action === EmailActions.Forward;
  const resendMode = action === EmailActions.Resend;
  const editMode = action === 'editMessage';
  const showBranch =
    createMode ||
    editMode ||
    viewMode ||
    replyMode ||
    replyAllMode ||
    resendMode ||
    forwardMode ||
    resendMode;

  const sendAction: EmailActions =
    EmailActions[action as keyof typeof EmailActions] ?? null;

  return showBranch ? (
    <OptimisticResponseProvider<Message>>
      <MessageBranch
        action={sendAction}
        messageId={messageId}
        replyAll={replyAllMode}
        replyId={replyId}
        resendId={resendId}
      />
    </OptimisticResponseProvider>
  ) : (
    <MessageOverview />
  );
};
