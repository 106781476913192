import React from 'react';
import { useTranslation } from 'react-i18next';

import { Group } from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { Dialog } from '@pro4all/shared/ui/dialog';

import { GroupCard } from './GroupCard';
import { useGetUsersAction } from './useGetUsersAction';

export const ModalShowGroup = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const groupId = searchParams.get('showGroup');

  const { getShowUsersAction } = useGetUsersAction();

  const onClose = () => {
    searchParams.delete('showGroup');
  };

  const { getLocalStorageItem: getGroupInLs } = useLocalStorage<Group>({
    key: StorageKeys.SET_GROUP,
  });

  const group = getGroupInLs() as Group;

  const { displayName, id, totalUsers } = group;

  const cardIcons: ActionProps[] = [
    getShowUsersAction({ id, totalUsers: totalUsers || 0 }),
  ];

  return displayName ? (
    <Dialog
      hideFooter
      iconName="info"
      name="showGroup"
      onClose={onClose}
      open={Boolean(groupId)}
      showCloseIcon
      title={t('Group details')}
    >
      <GroupCard
        // cardIcons={cardIcons} TODO: re-implement this after BE has finished endpoints to fetch all direct and nested users for a group.
        group={{
          displayName,
          id,
          totalUsers,
        }}
      />
    </Dialog>
  ) : null;
};
