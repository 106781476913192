import React from 'react';
import styled from 'styled-components';

import { Integration, User } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { RenderOptionType } from '@pro4all/shared/types';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';
import { getFormattedDate, Timestamp } from '@pro4all/shared/ui/timestamp';

import { IntegrationTypeIcon } from './IntegrationTypeIcon';

const StyledBox = styled(Box)`
  && {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const useColumnsIntegrations = () => {
  const columns: ColumnProps<Integration>[] = [
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<Integration, {}>
          defaultWidth={300}
          filterType={FilterHeaderType.Text}
          label="Application" // i18n
          minWidth={100}
          propertyId="integrationName"
          pullFromLocalStorage
        />
      ),
      key: 'integrationName',
      render: ({ integrationName, integrationType }) => (
        <StyledBox>
          <IntegrationTypeIcon integrationType={integrationType} />
          {integrationName}
        </StyledBox>
      ),
      width: 300,
    },
    {
      filterable: true,
      getValue: (integration: Integration) =>
        integration.createdAt
          ? getFormattedDate(integration.createdAt).label
          : '',
      headerComponent: (
        <FilterHeader<Integration, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="Created on" // i18n
          minWidth={120}
          propertyId="createdAt"
        />
      ),
      key: 'createdAt',
      render: ({ createdAt }) => <Timestamp date={createdAt} />,
      width: 200,
    },
    {
      filterable: true,
      getValue: ({ createdBy }) => createdBy?.displayName,
      headerComponent: (
        <FilterHeader<Integration, User>
          defaultWidth={300}
          filterType={FilterHeaderType.Select}
          label="Created by" // i18n
          minWidth={120}
          propertyId="createdBy"
          renderOption={RenderOptionType.UserCard}
          subPropertyId="displayName"
        />
      ),
      key: 'createdBy.displayName',
      render: ({ createdBy }) => <UserTag user={createdBy} />,
      width: 300,
    },
  ];
  return columns;
};
