import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { Dialog } from '@pro4all/shared/ui/dialog';
import { Text } from '@pro4all/shared/ui/typography';

export const ModalShowUsersInGroup = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const groupId = searchParams.get('showUsersFromGroup');

  const onClose = () => {
    searchParams.delete('showUsersFromGroup');
  };

  return (
    <Dialog
      confirmLabel={t('Ok')}
      iconName="info"
      name="showUsersInGroup"
      onConfirm={onClose}
      open={Boolean(groupId)}
      title={t('Users in this group')}
    >
      <Text>{t('Coming up')}</Text>
    </Dialog>
  );
};
