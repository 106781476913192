import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { Footer } from '@pro4all/shared/ui/footer';
import { Icon, IconName } from '@pro4all/shared/ui/icons';
import { Text, TextProps } from '@pro4all/shared/ui/typography';

import * as Styled from './Dialog.styles';

export interface DiscardDialogProps {
  additionalActions?: JSX.Element;
  buttonVariant?: 'text' | 'outlined' | 'contained';
  closeIcon?: IconName;
  closeLabel?: string;
  confirmIcon?: IconName;
  confirmLabel?: string;
  iconName?: IconName;
  name?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  onSubmit?: () => void;
  open?: boolean;
  submitLabel?: string;
  title: React.ReactNode;
  titleVariant?: TextProps['variant'];
}

//TODO: will be removed-edit when Global Discard dialog
export const DiscardDialog: React.FC<DiscardDialogProps> = ({
  children,
  closeLabel,
  submitLabel,
  confirmLabel,
  iconName,
  name,
  onClose,
  onConfirm,
  onSubmit,
  open = false,
  title,
  titleVariant = 'h6',
}) => {
  const { t } = useTranslation();
  return (
    <Styled.DialogContainer onClose={onClose} open={open}>
      <Styled.DialogHeader>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          width="100%"
        >
          {iconName && (
            <Box mr={1}>
              <Icon iconName={iconName} />
            </Box>
          )}
          <Text variant={titleVariant}>{title}</Text>
        </Box>
      </Styled.DialogHeader>

      <Box paddingTop={1}>{children}</Box>

      <Footer $cancelLeft={onClose !== null} mt={2}>
        {onClose && (
          <Button
            color="inherit"
            data-testid={name ? `close-${name}` : undefined}
            onClick={onClose}
            variant="text"
          >
            {closeLabel || t('Cancel')}
          </Button>
        )}
        {onSubmit && (
          <Button autoFocus data-testid="submit" onClick={onSubmit}>
            {submitLabel || t('Save')}
          </Button>
        )}
        {onConfirm && (
          <Button
            color="error"
            data-testid="confirm"
            onClick={onConfirm}
            variant="contained"
          >
            {confirmLabel || t('Confirm')}
          </Button>
        )}
      </Footer>
    </Styled.DialogContainer>
  );
};
