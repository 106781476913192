import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { Button } from '@pro4all/shared/ui/buttons';
import { ListProps } from '@pro4all/shared/ui/list';
import { ToggleProvider, useToggle } from '@pro4all/shared/ui/toggle';

import * as Styled from './PropertyList.styles';

export interface Property {
  id?: string;
  label: string;
  prefix?: string;
  suffix?: string;
  value: React.ReactNode;
}

export type Props = Omit<ListProps, 'more'> & {
  children?: never;
  items: Property[];
  limit?: number;
  more?: PropertyListMore;
  orientation?: 'horizontal' | 'vertical';
};

export type PropertyListMore = React.ElementType<{
  children?: never;
  toggle: (force?: boolean) => void;
  toggled: boolean;
}>;

const PropertyListComponent: React.FC<Props> = ({
  items,
  limit = Infinity,
  more = More,
  orientation = 'horizontal',
  ...rest
}) => {
  const listRef = useRef<HTMLElement>(null);
  const { toggle, toggled } = useToggle();

  const isMobileScreen = useIsMobileScreen();

  orientation = isMobileScreen ? 'vertical' : orientation;

  const alwaysShownChildren = items.slice(0, limit);
  const collapsibleChildren = items.slice(limit);

  const listFull = wrapChildren(items, orientation, listRef, rest);
  const listOutCollablsible = wrapChildren(
    alwaysShownChildren,
    orientation,
    listRef,
    rest
  );
  const listInCollablsible = wrapChildren(
    collapsibleChildren,
    orientation,
    listRef,
    rest
  );

  // Return early with our list whenever we can display all items
  if (limit >= items.length) return listFull;

  return (
    <>
      {listOutCollablsible}
      <Styled.Collapsible open={toggled}>
        <div style={{ marginTop: '-4px' }}></div>
        {listInCollablsible}
      </Styled.Collapsible>
      <div style={{ marginTop: '-18px' }}></div>
      {more && React.createElement(more, { toggle, toggled })}
    </>
  );
};

const More: Props['more'] = ({ toggle, toggled }) => {
  const { t } = useTranslation();

  return (
    <Box m={3}>
      <Button
        color="inherit"
        onClick={() => toggle()}
        startIcon={toggled ? 'remove' : 'add'}
        variant="outlined"
      >
        {toggled ? t('Show less') : t('Show more')}
      </Button>
    </Box>
  );
};

export const PropertyList: React.FC<Props> = (props) => (
  <ToggleProvider>
    <PropertyListComponent {...props} />
  </ToggleProvider>
);

const FlexContainer = styled(Box)`
  display: flex;
`;

function wrapChildren(
  childrens: Property[],
  orientation: 'horizontal' | 'vertical',
  listRef: React.RefObject<HTMLElement>,
  rest: any
) {
  return (
    <Styled.List
      {...rest}
      item={Styled.Item}
      itemProps={{ $orientation: orientation }}
      ref={listRef}
    >
      {childrens.map(({ id, label, prefix, suffix, value }) => (
        <FlexContainer
          key={id ?? label}
          sx={{
            flexDirection: orientation === 'horizontal' ? 'row' : 'column',
          }}
        >
          <Styled.Label $orientation={orientation}>{label}</Styled.Label>
          {prefix ? `${prefix} ` : ''}
          {value}
          {suffix ? ` ${suffix}` : ''}
        </FlexContainer>
      ))}
    </Styled.List>
  );
}
