import React from 'react';

import { useSideNavContext } from '@pro4all/shared/contexts';
import { useApplication } from '@pro4all/shared/hooks';
import {
  Box,
  Link,
  List,
  useMediaQuery,
  useTheme,
} from '@pro4all/shared/mui-wrappers';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { TbqLogo } from '@pro4all/shared/ui/general';
import { ProstreamLogo } from '@pro4all/shared/ui/prostream-logo';

import * as Styled from './SideNavigation.styles';
import { SubscriptionWarning } from './SubscriptionWarning';
import { Color } from './types';

export interface SideNavigationProps {
  color: Color;
}

export const SideNavigation: React.FC<SideNavigationProps> = ({
  children,
  color,
}) => {
  const {
    sideNavState,
    sideNavState: { isSideNavOpen },
    sideNavVisibleOnMobile,
    setSideNavState,
    setSideNavVisibleOnMobile,
  } = useSideNavContext();
  const application = useApplication();
  const theme = useTheme();
  const showDeviceUi = useMediaQuery(theme.breakpoints.down('md'));

  const styledDrawer = (
    <Styled.StyledDrawer
      $colorVariant={color}
      $open={isSideNavOpen}
      anchor="left"
      className={`${isSideNavOpen ? 'open-side-nav' : 'closed-side-nav'}`}
      disableBackdropTransition
      disableSwipeToOpen
      onClose={() => {
        setSideNavVisibleOnMobile(false);
      }}
      onOpen={() => {
        setSideNavVisibleOnMobile(true);
      }}
      open={showDeviceUi ? sideNavVisibleOnMobile : true}
      role="navigation"
      variant={showDeviceUi ? 'temporary' : 'permanent'}
    >
      <List>{children}</List>
      <Box mt="auto">
        <SubscriptionWarning />
        <Styled.LogoContainer>
          {application === 'tbq' ? (
            <Link href="https://tbq.nl" target="_blank">
              <TbqLogo
                color={color === 'dark' ? 'default' : 'white'}
                type={isSideNavOpen ? 'regular' : 'compact'}
              />
            </Link>
          ) : (
            <Link href="https://www.prostream.app" target="_blank">
              <ProstreamLogo
                color={color === 'dark' ? 'white' : 'grey'}
                type={isSideNavOpen ? 'regular' : 'compact'}
              />
            </Link>
          )}
        </Styled.LogoContainer>
        <Box
          aria-label="toggle-drawer"
          className="toggle-drawer"
          onClick={() =>
            setSideNavState({
              ...sideNavState,
              isSideNavOpen: !isSideNavOpen,
            })
          }
        >
          <IconButton
            className="chevron"
            disableBorder
            iconName={
              isSideNavOpen ? 'chevronLeftRounded' : 'chevronRightRounded'
            }
          />
        </Box>
      </Box>
    </Styled.StyledDrawer>
  );

  return styledDrawer;
};
