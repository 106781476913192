import styled from 'styled-components';

import { InputAdornment } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { TextField } from '@pro4all/shared/ui/inputs';
import { Icon } from '@pro4all/shared/ui/icons';

export const InputWrap = styled.div`
  padding: 0.5rem 0.5rem 0 0.5rem;
`;

export const TagListWrap = styled.div`
  height: 100px;
  overflow-x: hidden;
  padding: 0.5rem 0.5rem 0 0.5rem;
  max-width: 326px;
`;

export const StyledInputAdornment = styled(InputAdornment)`
  && {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    .MuiInput-root {
      &.MuiInput-marginDense {
        input {
          padding: 6px 0 5px;
        }
      }
    }
  }
`;

export const StyledLi = styled.li<{ $active?: boolean; $disabled?: boolean }>`
  display: flex;
  height: 40px;
  padding: 0 0.5rem;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  &:hover {
    background-color: ${({ $active }) => ($active ? '#CDF3ED' : '#eee')};
    color: ${({ $active, $disabled }) =>
      $active ? '#006B5E' : $disabled ? 'inherit' : customColors.grey900};
  }
  background-color: ${({ $active }) => ($active ? '#CDF3ED' : 'inherit')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ $active, $disabled }) =>
    $active ? '#006B5E' : $disabled ? customColors.grey500 : 'inherit'};
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};

  gap: 0.3rem;
`;

export const HierarchyStyledLi = styled(StyledLi)<{
  $active?: boolean;
  $disabled?: boolean;
}>`
  height: auto;
  padding: ${({ theme }) => theme.spacing(2)};
  white-space: normal;
  text-overflow: unset;
  overflow: unset;
`;

export const IconRight = styled(Icon)`
  margin-left: auto;
`;

export const StyledButton = styled.button`
  width: 100%;
  cursor: pointer;
  text-align: left;

  :disabled {
    cursor: default;
  }
`;

export const TagBg = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${customColors.grey200};
  padding: 0 0.5rem;
`;

export const NoAnswersSelected = styled.div`
  width: 100%;
  height: 100%;
  line-height: 93px;
  background-color: ${customColors.grey200};
  margin: auto;
  text-align: center;
`;

export const MenuHeader = styled.span`
  text-align: center;
  font-weight: 500;
  margin: 0.5rem 0;
`;
