import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, DialogContent, DialogTitle } from '@pro4all/shared/mui-wrappers';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { Button } from '@pro4all/shared/ui/buttons';
import { Footer } from '@pro4all/shared/ui/footer';
import { IconName } from '@pro4all/shared/ui/icons';
import { TextProps } from '@pro4all/shared/ui/typography';

import * as Styled from './Dialog.styles';

export interface DialogProps {
  additionalActions?: JSX.Element;
  buttonColor?: string;
  buttonVariant?: 'text' | 'outlined' | 'contained';
  closeLabel?: string;
  confirmIcon?: IconName;
  confirmLabel?: string;
  discard?: boolean;
  hideFooter?: boolean;
  iconName?: IconName;
  name?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  onSubmit?: (() => void) | undefined;
  open?: boolean;
  showCloseIcon?: boolean;
  submitDisabled?: boolean;
  submitLabel?: string;
  title: React.ReactNode;
  titleVariant?: TextProps['variant'];
}

export const Dialog: React.FC<DialogProps> = ({
  additionalActions,
  buttonColor,
  children,
  closeLabel,
  confirmLabel,
  discard = false,
  hideFooter = false,
  iconName, // left in case UX decide they really want icons
  name,
  onClose,
  onConfirm,
  onSubmit,
  open = false,
  showCloseIcon = false,
  submitDisabled = false,
  submitLabel,
  title,
  titleVariant = 'h2',
}) => {
  const { t } = useTranslation();
  return (
    <Styled.DialogContainer onClose={onClose} open={open}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle variant={titleVariant}>{title}</DialogTitle>
        {onClose && showCloseIcon && (
          <IconButton
            color="default"
            disableBorder
            iconName="close"
            onClick={onClose}
          />
        )}
      </Box>
      <DialogContent>{children}</DialogContent>
      {!hideFooter && (
        <Footer $cancelLeft $sticky>
          {onClose && (
            <Button
              color="inherit"
              data-testid={name ? `close-${name}` : undefined}
              onClick={onClose}
              variant="text"
            >
              {closeLabel || t('Cancel')}
            </Button>
          )}
          {additionalActions}
          {onConfirm && (
            <StyledButton
              $color={buttonColor}
              autoFocus
              color={discard ? 'error' : 'primary'}
              data-testid="confirm"
              onClick={onConfirm}
              variant={discard || onSubmit ? 'outlined' : 'contained'}
            >
              {confirmLabel || t('Confirm')}
            </StyledButton>
          )}
          {onSubmit && (
            <StyledButton
              $color={buttonColor}
              autoFocus
              data-testid="submit"
              disabled={submitDisabled}
              onClick={onSubmit}
              variant="contained"
            >
              {submitLabel || t('Save')}
            </StyledButton>
          )}
        </Footer>
      )}
    </Styled.DialogContainer>
  );
};

const StyledButton = styled(Button)<{
  $color?: string;
}>`
  && {
    ${({ $color }) => $color && `background-color: ${$color};`}
  }
`;
