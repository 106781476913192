import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Dialog } from '@pro4all/shared/ui/dialog';
import { Text } from '@pro4all/shared/ui/typography';

import { useApiKeyContext } from './ApiKeyContext';

export const CopyTokenDialog = () => {
  const { t } = useTranslation();
  const { apiToken, setApiToken } = useApiKeyContext();

  const onClose = () => {
    setApiToken('');
  };

  const onCopy = () => {
    navigator.clipboard.writeText(apiToken);
    setApiToken('');
  };

  return (
    <Dialog
      confirmLabel={t('Copy API key to clipboard')}
      iconName="warning"
      onClose={onClose}
      onConfirm={onCopy}
      open={Boolean(apiToken)}
      title={t('API key successfully created')}
    >
      <Box sx={{ mb: 2 }}>
        <Text>
          {t(
            'Copy the API key and keep it in a safe place. The API key is offered only once and cannot be retrieved later. It provides data access via public Prostream APIs.'
          )}
        </Text>
      </Box>
      <Box sx={{ overflowWrap: 'break-word' }}>
        <Text variant="caption">{apiToken}</Text>
      </Box>
    </Dialog>
  );
};
