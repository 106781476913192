import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useField } from 'formik';
import styled from 'styled-components';

import { FormError } from '@pro4all/shared/ui/form';

declare global {
  interface Window {
    recaptchaOptions: { useRecaptchaNet: boolean };
  }
}

interface Props {
  name: string;
  sitekey: string;
}

const CaptchaResizer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  iframe {
    transform: scale(1.17);
    transform-origin: 0 0;
  }
`;

export const FormikCaptcha: React.FC<Props> = ({ name, sitekey }) => {
  const [, meta, helpers] = useField({ name });
  const hasError = Boolean(meta.touched) && Boolean(meta.error);

  window.recaptchaOptions = {
    useRecaptchaNet: true,
  };

  return (
    <>
      <CaptchaResizer>
        <ReCAPTCHA onChange={helpers.setValue} sitekey={sitekey} />
      </CaptchaResizer>
      {hasError && <FormError>{meta.error}</FormError>}
    </>
  );
};
