import { useMemo } from 'react';
import styled from 'styled-components';

import { Task, TaskType } from '@pro4all/graphql';
import { useFetchTasks } from '@pro4all/quality-control/data-access';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { Loader } from '@pro4all/shared/ui/loader';
import { BigMessageNoTasks } from '@pro4all/shared/ui/messages';
import {
  Table as UITable,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/table';
import { TBQTaskSidebar } from '@pro4all/workflow/ui/task-sidebar';

import { useTBQResolveTaskColumns } from '../useTBQResolveTaskColumns';

interface Props {
  procedureId: string;
  projectId: string;
}

export const TBQResolveTasks = ({ procedureId, projectId }: Props) => {
  const { searchParams, params } = useRouting();
  const taskId = searchParams.get('id') ?? undefined;
  const objectId = params.objectId;

  const columns = useTBQResolveTaskColumns();

  const { data: fetchTasksByType, loading } = useFetchTasks({
    projectId,
    typeFilter: [TaskType.TbqResolve],
  });

  const tasks = useMemo(
    () => fetchTasksByType?.project?.mainProcedure?.tasks || [],
    [fetchTasksByType?.project?.mainProcedure?.tasks]
  ) as Task[];

  const objectResolveTasks = useMemo(
    () =>
      tasks?.filter(
        (task) => task?.tbqTbqResolveTaskLinkedObjectId === objectId
      ),
    [objectId, tasks]
  );

  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<Task>();

  useSetItemsInLocalState<Task>(objectResolveTasks);

  if (loading) return <Loader />;

  if (!items.length && !itemsInitial.length) return <BigMessageNoTasks />;

  return (
    <TableContextProvider
      checkable
      checkableSpacing
      columns={columns}
      id="table-tbq-tasks"
      items={items}
    >
      <FilterContextProvider<Task>>
        <Table
          onRowClick={({ id }) => {
            searchParams.set({ action: 'viewTask', id });
          }}
          selectedId={taskId}
        />
        <TBQTaskSidebar
          procedureId={procedureId}
          projectId={projectId}
          tasks={items}
        />
      </FilterContextProvider>
    </TableContextProvider>
  );
};

const Table = styled(UITable)`
  && {
    margin-top: 2rem;
  }
`;
