import React from 'react';
import { Formik } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikForm } from '@pro4all/shared/ui/formik';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useIntegrationConfig } from '../config/useIntegrationConfig';

import { useConnectionFormConfig } from './useConnectionFormConfig';
import { useGetInitialValues } from './useGetInitialValues';
import { useGetIntegrationTypeData } from './useGetIntegrationTypeData';
import { useSubmit } from './useSubmit';

export const ConnectionForm = ({ onClose }: { onClose: () => void }) => {
  const integrationConfig = useIntegrationConfig();
  const { validationSchema } = useConnectionFormConfig();
  const initialValues = useGetInitialValues();
  const onSubmit = useSubmit({ onClose });
  const { integrationType } = useGetIntegrationTypeData();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting, values }) => {
        // Get the properties that are specifically related to the type (Build12, Snagstream etc..)
        const { CustomForm } = integrationType
          ? integrationConfig[integrationType]
          : { CustomForm: null };

        return (
          <FormikForm>
            <FormWrapper noPadding>
              {CustomForm && <CustomForm values={values} />}
            </FormWrapper>

            <FormFooter
              ariaLabelSave="Save connection"
              disableSubmit={isSubmitDisabled({
                dirty,
                errors,
                isSubmitting,
              })}
              onClose={onClose}
              pt={2}
              sticky
            />
          </FormikForm>
        );
      }}
    </Formik>
  );
};
