import React from 'react';

import { TaskType } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar } from '@pro4all/shared/ui/sidebar';

import { ResolveTaskForm } from './ResolveTaskForm';
import { ResultFormWrapper } from './ResultFormWrapper';
import { useGetResult } from './useGetResult';
import { useNavigationActions } from './useNavigationActions';
import { useRedirect } from './useRedirect';

export const ResultSidebar = () => {
  useRedirect();

  const { closeSidebar, goBack } = useNavigationActions();

  // Previously the calculation of `routeEdit`, `routeView` and `createResolveTask` was done in the hook `useNavigationActions`.
  // However somehow (I cannot explain why) the calculation of `routEdit` was not reliable in the hook `useNavigationActions` after intervention of the `Discard` modal.
  // That caused a situation where users were editing a boolean value in the form and the sidebar disappeared because `routeEdit` was calculated to `false`.
  // If we do the calculation in the component itself, it all works solid.
  const { searchParams } = useRouting();
  const routeEdit = searchParams.is('action', 'editResult');
  const routeView = searchParams.is('action', 'viewResult');
  const createResolveTask =
    searchParams.is('action', 'createTask') &&
    searchParams.is('taskType', TaskType.Resolve);
  const isSidebarOpen = routeView || routeEdit || createResolveTask;

  const { refetch, result } = useGetResult({ isSidebarOpen });

  return (
    <Sidebar onClose={closeSidebar} open={isSidebarOpen} wide>
      {createResolveTask ? (
        <ResolveTaskForm onClose={goBack} />
      ) : (
        <ResultFormWrapper goBack={goBack} refetch={refetch} result={result} />
      )}
    </Sidebar>
  );
};
