import styled from 'styled-components';

import { StylingDefaults } from '@pro4all/shared/themes';
import { Select } from '@pro4all/shared/ui/inputs';

export const ChartsWrapper = styled.div<{
  columns: number;
  rows: number;
}>`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template: repeat(${({ rows: rowCount }) => rowCount}, minmax(0, 1fr)) / repeat(
      ${({ columns: columnCount }) => columnCount},
      minmax(0, 1fr)
    );
  gap: ${({ theme }) => theme.spacing(2)};
  position: relative;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template: repeat(1, minmax(0, 1fr)) / repeat(2, minmax(0, 1fr));
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template: repeat(1, minmax(0, 1fr)) / repeat(1, minmax(0, 1fr));
  }
`;

export const LoadingOverlay = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: ${StylingDefaults.borderRadiusSmall};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`;

export const ChartGroupWrapper = styled.div<{
  columnSpan: number;
  rowSpan: number;
}>`
  display: flex;
  flex-direction: column;
  grid-row: span ${({ rowSpan }) => rowSpan};
  grid-column: span ${({ columnSpan }) => columnSpan};
  padding: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${StylingDefaults.borderRadiusSmall};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-row: span 1;
    grid-column: span 1;
  }
`;

export const ChartInputWrapper = styled.div`
  width: 300px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const ChartSelect = styled(Select)``;

export const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  padding: ${({ theme }) => theme.spacing(2)} 0;
`;
