import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Descendant, Editor, Range, Transforms } from 'slate';
import { Editable, ReactEditor, Slate } from 'slate-react';
import styled from 'styled-components';

import {
  gqlType,
  Group,
  Member,
  useGroupsAndUsersIncludeQuery,
  User,
} from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';

import { BlockButton } from '../buttons/BlockButton';
import { MarkButton } from '../buttons/MarkButton';

import {
  CustomElementType,
  CustomText,
  GroupMentionElement,
  MentionElement,
  MentionType,
} from './CustomTypes';
import { EditorToolbar, EditorWrap, ErrorMessage } from './EditorStyles';
import { defaultEditorValues, origin } from './index';
import { toggleMark } from './shortcutsHelpers';
import { Suggestions } from './Suggestions';
import { useRenderElement } from './useElement';
import { useRenderLeaf } from './useLeaf';

interface Props {
  CustomButtons?: React.ReactNode[];
  autofocus?: boolean;
  displayGroups?: boolean;
  editor: Editor;
  editorMaxHeight?: number;
  editorMinHeight?: number;
  errorMessage?: string;
  inputName?: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onMention?: (member: Member) => void;
  placeholder?: string;
  projectId?: string;
  readOnly?: boolean;
  showToolbar?: boolean;
  suggestionsLimit?: number;
  value?: Descendant[];
  withPlaceholder?: boolean;
}

export const SlateEditor: React.FC<Props> = ({
  CustomButtons,
  autofocus,
  editor,
  inputName = 'body',
  onBlur,
  onChange,
  onFocus,
  onMention,
  readOnly,
  showToolbar,
  suggestionsLimit,
  value = defaultEditorValues,
  displayGroups = false,
  editorMinHeight,
  errorMessage,
  editorMaxHeight,
  placeholder,
  withPlaceholder = true,
  projectId,
}) => {
  const { t } = useTranslation();
  const { params } = useRouting();
  const editorRef = useRef<HTMLDivElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const [target, setTarget] = useState<Range | null>(); // Points to an element in the Editor
  const [index, setIndex] = useState(0); // Points to the user highlighted in Suggestions
  const [searchString, setSearchString] = useState('');
  const renderElement = useRenderElement();
  const renderLeaf = useRenderLeaf();

  const { data } = useGroupsAndUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      includeActive: true,
      includeEmail: true,
      includeIsAdmin: true,
      includeMembers: true,
      includeMembersCount: true,
      includeOrganization: true,
      includeState: true,
      includeTotalUsers: true,
      projectId: projectId || params.projectId,
    },
  });
  const members = (data?.groupsAndUsers as Member[]) || [];

  const users: User[] = members.filter(gqlType('User'));
  const groups: Group[] = members.filter(gqlType('Group'));

  const activeUsers = users
    ? users.filter((user) => user?.active === true && Boolean(user))
    : [];

  // We remove groups if display groups is set to false
  // Active users should appear first before groups
  // We used to remove groups that had no active members
  // But since org groups come as having no members, this could cause confusion
  const activeUserGroups = displayGroups
    ? [...activeUsers, ...groups]?.slice(suggestionsLimit)
    : [...activeUsers]?.slice(suggestionsLimit);

  const filteredMembers = searchString
    ? activeUserGroups?.filter((member) =>
        member?.displayName
          .toLowerCase()
          .startsWith(searchString?.toLowerCase())
      )
    : activeUserGroups;

  const toMention = (
    mentioned: Member
  ): MentionElement | GroupMentionElement => {
    const displayName = mentioned.displayName;
    const id = mentioned.id;
    const children = [{ text: `${displayName}` }];
    if (mentioned.__typename === 'Group') {
      return {
        children,
        displayName,
        groupId: id,
        members: mentioned.members || [],
        type: MentionType.group,
      };
    }
    const email = mentioned.__typename === 'User' ? mentioned.email : '';
    return {
      children: [{ text: `${displayName}` }],
      displayName,
      email,
      type: MentionType.user,
      userId: id,
    };
  };

  const handleChange = (value: Descendant[]) => {
    onChange && onChange(JSON.stringify(value));
    setTypingStarted(true);

    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      /* Before means to the left of the input cursor
       *  - trigger: the first character in an entry
       *  - wordBefore: 'foo' (.before the cursor)
       *  - before: '@foo' (extracted by using .before a 2nd time thus including '@')
       * */
      const [start] = Range.edges(selection);

      const triggerPos = Editor.before(editor, start, { unit: 'character' });
      const triggerRange =
        triggerPos && Editor.range(editor, triggerPos, start);
      const trigger = triggerRange && Editor.string(editor, triggerRange);
      const triggerMatch = trigger === '@';

      const wordBefore = Editor.before(editor, start, { unit: 'word' });
      const before = wordBefore && Editor.before(editor, wordBefore);
      const beforeRange = before && Editor.range(editor, before, start);
      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);

      const after = Editor.after(editor, start);
      const afterRange = Editor.range(editor, start, after);
      const afterText = Editor.string(editor, afterRange);
      const afterMatch = afterText.match(/^(\s|$)/);

      if (afterMatch) {
        if (beforeMatch) {
          setTarget(beforeRange); // Show suggestions near word '@foo'
          setSearchString(beforeMatch[1]);
          setIndex(0);
          return;
        } else if (triggerMatch) {
          setTarget(triggerRange); // Show suggestions near character '@'
          setSearchString('');
          setIndex(0);
          return;
        }
      }
    }
  };

  const onSelect = useCallback(() => {
    const insertMention = (editor: Editor, mentioned: Member) => {
      const mention = toMention(mentioned);

      const space: CustomText = {
        text: ' ',
      };
      Transforms.insertNodes(editor, [mention, space]);
    };

    const selectedMember = filteredMembers && filteredMembers[index];
    if (selectedMember && target) {
      Transforms.select(editor, target);
      insertMention(editor, selectedMember);
      setTarget(null);
      setSearchString('');
      onMention && onMention(selectedMember);
      ReactEditor.focus(editor);
    }
  }, [editor, filteredMembers, index, onMention, target]);

  const [typingStarted, setTypingStarted] = useState(false);

  const onKeyDown = useCallback(
    (event) => {
      if (target && filteredMembers?.length) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            event.stopPropagation();
            setIndex(index >= filteredMembers.length - 1 ? 0 : index + 1);
            break;
          case 'ArrowUp':
            event.preventDefault();
            event.stopPropagation();
            setIndex(index <= 0 ? filteredMembers.length - 1 : index - 1);
            break;
          case 'Tab':
          case 'Enter':
            event.preventDefault();
            onSelect();
            break;
          case 'Escape':
            event.preventDefault();
            event.stopPropagation();
            setTarget(null);
            break;
        }
      } else {
        const isMac = /Mac/.test(navigator.userAgent);
        if ((isMac && event.metaKey) || (!isMac && event.ctrlKey)) {
          switch (event.key) {
            case 'b': {
              event.preventDefault();
              toggleMark(editor, 'bold');
              break;
            }
            case 'i': {
              event.preventDefault();
              toggleMark(editor, 'italic');
              break;
            }
            case 'u': {
              event.preventDefault();
              toggleMark(editor, 'underline');
              break;
            }
            default:
              break;
          }
        }

        switch (event.key) {
          case 'ArrowDown':
            event.stopPropagation();
            break;
          case 'ArrowUp':
            event.stopPropagation();
            break;
        }
      }
    },
    [target, filteredMembers.length, index, onSelect, editor]
  );

  useLayoutEffect(() => {
    if (target) {
      const el = suggestionsRef.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();

      if (el) {
        /* Repositioning: prevent suggestions going off-screen
         * By limiting the values 'top' and 'left' */
        const hLimit = window.innerWidth - el.clientWidth - 24;
        const left = rect.left < hLimit ? rect.left : hLimit;
        el.style.left = `${left + window.pageXOffset}px`;

        const vLimit = window.innerHeight - el.clientHeight;
        if (rect.top > vLimit) {
          el.style.maxHeight = `${window.innerHeight - rect.top - 48}px`;
        }
        el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      }
    }
  }, [filteredMembers, editor, index, searchString, target]);

  /* Slate is an uncontrolled component.
   * So the editor value must be set functionally
   * And onChange must be called to force a rerender */
  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(editor.children) && readOnly) {
      editor.children = value;
      editor.onChange();
    }
  }, [editor, readOnly, value]);

  const [hasFocus, setHasFocus] = useState(false);

  const handleBlur = () => {
    onBlur && onBlur();
    setHasFocus(false);
    !typingStarted && setWithPlaceholderState(true);
  };

  const [withPlaceholderState, setWithPlaceholderState] =
    useState(withPlaceholder);

  const handleOnFocus = () => {
    setWithPlaceholderState(false);
    onFocus && onFocus();
    setHasFocus(true);
  };

  const handleHidePlaceholder = () => {
    setWithPlaceholderState(false);
    ReactEditor.focus(editor);
    editor.selection = {
      anchor: origin,
      focus: origin,
    };
  };

  return (
    <Box>
      <EditorWrap ref={editorRef}>
        <Slate editor={editor} onChange={handleChange} value={value}>
          {showToolbar && hasFocus && (
            <EditorToolbar>
              <MarkButton format="bold" icon="formatBold" title={t('Bold')} />
              <MarkButton
                format="italic"
                icon="formatItalic"
                title={t('Italic')}
              />
              <MarkButton
                format="underline"
                icon="formatUnderlined"
                title={t('Underline')}
              />
              <MarkButton format="code" icon="formatCode" title={t('Code')} />
              <BlockButton format="title" icon="looksOne" title={t('Title')} />
              <BlockButton
                format="subtitle"
                icon="looksTwo"
                title={t('Subtitle')}
              />
              <BlockButton
                format="bulleted-list"
                icon="selection"
                title={t('Bulleted-list')}
              />
              <BlockButton
                format="left"
                icon="formatAlignLeft"
                title={t('Left')}
              />
              <BlockButton
                format="center"
                icon="formatAlignCenter"
                title={t('Center')}
              />
              <BlockButton
                format="right"
                icon="formatAlignRight"
                title={t('Right')}
              />
              {CustomButtons?.map((button) => button)}
            </EditorToolbar>
          )}
          <Controller
            name={inputName}
            render={() => (
              <Box position="relative">
                <Editable
                  autoFocus={autofocus}
                  data-testid="slate-editor"
                  onBlur={handleBlur}
                  onFocus={handleOnFocus}
                  onKeyDown={onKeyDown}
                  readOnly={readOnly}
                  renderElement={renderElement}
                  renderLeaf={renderLeaf}
                  style={{
                    maxHeight: editorMaxHeight
                      ? `${editorMaxHeight}px`
                      : 'inherit',
                    minHeight: editorMinHeight
                      ? `${editorMinHeight}px`
                      : 'inherit',
                  }}
                />
                {withPlaceholderState && !readOnly && (
                  <Placeholder onClick={handleHidePlaceholder}>
                    {t(placeholder || 'Your message')}
                  </Placeholder>
                )}
              </Box>
            )}
          />
          <Suggestions
            contentRef={suggestionsRef}
            members={filteredMembers}
            onSelect={onSelect}
            setIndex={(i) => setIndex(i)}
            show={Boolean(target) && filteredMembers.length > 0}
          />
        </Slate>
      </EditorWrap>
      {errorMessage && (
        <Box mt="8px">
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Box>
      )}
    </Box>
  );
};

// To be used even when the editor is not empty (like when it has a signature for example)
const Placeholder = styled(Box)`
  && {
    cursor: text;
    color: #aaa;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const withMentions = (editor: Editor) => {
  const { isInline, isVoid } = editor;
  editor.isInline = (element) =>
    element.type === MentionType.user
      ? true
      : isInline(element) || element.type === MentionType.group
      ? true
      : isInline(element);
  editor.isVoid = (element) =>
    element.type === MentionType.user
      ? true
      : isVoid(element) || element.type === MentionType.group
      ? true
      : isVoid(element);

  return editor;
};

export const withImages = (editor: Editor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) =>
    element.type === CustomElementType.image ? true : isVoid(element);

  editor.insertData = (data) => {
    insertData(data);
  };

  return editor;
};
