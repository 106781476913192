import { GrowthBook } from '@growthbook/growthbook-react';

import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useRouting } from '@pro4all/shared/routing-utils';

let initialized = false;
let growthbook: GrowthBook;

/**
 * Define constants, the strings will be replaced by nginx
 * while serving. Add the reqruied configs in nginx.conf in root
 * */
const GROWTHBOOK_KEY =
  process.env.NX_GROWTHBOOK_KEY ?? 'process.env.GROWTHBOOK_KEY';
const GROWTHBOOK_HOST =
  process.env.NX_GROWTHBOOK_HOST ?? 'process.env.GROWTHBOOK_HOST';

export async function init() {
  if (!initialized) {
    // Cant do direct comparison as that string would be replaced too
    if (GROWTHBOOK_KEY.endsWith('GROWTHBOOK_KEY')) {
      console.error(
        'Growthbook key not configured, all feature flags will be turned off'
      );
      return;
    }

    growthbook = new GrowthBook({
      apiHost: GROWTHBOOK_HOST || 'https://cdn.growthbook.io',
      clientKey: GROWTHBOOK_KEY,
      enableDevMode: ['localhost', 'my.prostream.dev'].includes(
        window.location.hostname
      ),
    });

    // Load all features and flag as initialized
    await growthbook.loadFeatures();
    initialized = true;
  }
}

export function featureIsOn(key: string) {
  return initialized ? growthbook.isOn(key) : false;
}

export function useIdentify() {
  const {
    params: { projectId },
  } = useRouting();
  const {
    meData,
    organizationId,
    organizationName,
    userEmail,
    userId,
    userIsOrganizationAdmin,
    projectsMe,
  } = useOrganizationContext();
  const projectMeData = projectsMe?.find(
    (project) => project?.projectId === projectId
  );

  const { isExplicitProjectAdmin } = useProjectContext();

  const orgName = projectId
    ? projectMeData?.organizationName
    : organizationName;
  const orgId = projectId ? projectMeData?.organizationId : organizationId;

  const isAdmin = projectId
    ? orgId === organizationId
      ? userIsOrganizationAdmin || isExplicitProjectAdmin
      : isExplicitProjectAdmin
    : userIsOrganizationAdmin;

  if (meData && growthbook) {
    growthbook.setAttributes({
      email: userEmail,
      id: userId,
      isAdmin,
      organization: orgName || organizationName,
      organizationId: orgId || organizationId,
      projectId,
      userAgent: window.navigator.userAgent,
    });
  }
}
