import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  NotificationScope,
  NotificationsUser,
  NotificationUpdateUser,
  useToggleBatchDocumentDigestMutation,
  useToggleSingleDocumentDigestMutation,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useRouting } from '@pro4all/shared/routing-utils';
import { BigMessage } from '@pro4all/shared/ui/messages';
import {
  OnToggleAllRowsType,
  Table,
  useOptimisticResponseContext,
  useTableCheck,
} from '@pro4all/shared/ui/table';

import { CustomCheckRow } from './components/CustomCheckRow';

type Row = {
  cells: React.ReactNode[];
  rowData: NotificationsUser;
};

export const NotificationsTable = ({ scope }: { scope: NotificationScope }) => {
  const { t } = useTranslation();
  const routing = useRouting();
  const {
    replaceAllItems,
    state: { items },
  } = useOptimisticResponseContext<NotificationsUser>();

  const { checkRowBatch } = useTableCheck();
  const [toggleSingleDocumentDigest] = useToggleSingleDocumentDigestMutation();
  const [toggleBatchDocumentDigest] = useToggleBatchDocumentDigestMutation();

  React.useEffect(() => {
    checkRowBatch(items.filter((r) => r.checked));
  }, []);

  const { userOrganizationId } = useOrganizationContext();

  const projectId = routing.params.projectId;

  const handleRowClick = async (row: NotificationsUser) => {
    const newItems = items.map((item) => {
      if (item.id === row.id) {
        return {
          ...item,
          checked: !row.checked,
        };
      }
      return item;
    });
    await toggleSingleDocumentDigest({
      variables: {
        checked: !row.checked,
        organizationId: row.organization?.id,
        projectId: projectId,
        scope,
        userId: row.userId,
      },
    });

    replaceAllItems(newItems);
  };

  const rowRenderer = ({ cells }: Row) => cells;

  const handleToggleAllRows: OnToggleAllRowsType = ({ anyRowsAreChecked }) => {
    const isAllRowChecked = anyRowsAreChecked ? false : true;
    const users: NotificationUpdateUser[] = items.map((item) => ({
      organizationId: item.organization?.id || '',
      userId: item.id || '',
    }));
    toggleBatchDocumentDigest({
      variables: {
        checked: isAllRowChecked,
        organizationId: !projectId ? userOrganizationId : null,
        projectId: projectId,
        scope,
        users,
      },
    });
  };

  return (
    <Table<NotificationsUser>
      customCheckboxRenderer={(row, rowIndex) => (
        <CustomCheckRow row={row} rowIndex={rowIndex} />
      )}
      emptyRenderer={
        <BigMessage shapeName="flow1" title={t("There isn't any data")} />
      }
      enableKeyboardControl
      onRowClick={handleRowClick}
      onToggleAllRows={handleToggleAllRows}
      rowRenderer={rowRenderer}
      withSwitchToSelectAll
    />
  );
};
