import { useCallback } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { gqlType, Member, ReferenceType } from '@pro4all/graphql';

import { MessageFormFields } from '../types';

export const useMentionHandlers = (form: UseFormMethods<MessageFormFields>) => {
  const handleMention = useCallback(
    (member: Member) => {
      const values = form.getValues();

      if (gqlType('User')(member)) {
        const withoutMember = values.to?.filter(({ id }) => id !== member.id);

        form.setValue('to', [
          ...(withoutMember || []),
          { email: member.email, id: member.id, type: ReferenceType.User },
        ]);

        const currentMentions = values?.mentions || [];
        form.setValue('mentions', [
          ...currentMentions,
          { email: member.email, id: member.id, type: ReferenceType.User },
        ]);

        const updatedCc = values.cc?.filter((cc) => cc.id !== member.id);
        form.setValue('cc', updatedCc?.length ? updatedCc : null);

        const updatedBcc = values.bcc?.filter((bcc) => bcc.id !== member.id);
        form.setValue('bcc', updatedBcc?.length ? updatedBcc : null);
      } else if (gqlType('Group')(member)) {
        const withoutMember = values.to?.filter(({ id }) => id !== member.id);

        const groupAdded = {
          email: member.id,
          id: member.id,
          type: ReferenceType.Group,
        };

        form.setValue('to', [...(withoutMember || []), groupAdded]);
      }

      form.trigger();
    },
    [form]
  );

  return { handleMention };
};
