import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { gqlType, Group, User } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { IdentityTag } from '@pro4all/shared/ui/identity-card';
import { Property, PropertyList } from '@pro4all/shared/ui/property-list';

interface GroupPropertiesProps {
  group: Group;
}

const StyledBox = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const GroupProperties = ({ group }: GroupPropertiesProps) => {
  const { t } = useTranslation();
  const { members, subgroups } = group || {};

  if (!group) return null;

  const getGroupTag = (group: Group) => {
    const { displayName, id, totalUsers } = group;
    return (
      <StyledBox>
        <IdentityTag
          hasBorder
          id={id}
          label={displayName}
          totalUsers={totalUsers}
          type="Group"
        />
      </StyledBox>
    );
  };

  const getUserTag = (user: User) => {
    const { displayName, id } = user;
    return (
      <StyledBox>
        <IdentityTag hasBorder id={id} label={displayName} type="User" />
      </StyledBox>
    );
  };

  const properties: Property[] = [
    {
      label: t('Members'),
      value: members?.map((member) => {
        if (gqlType('Group')(member)) {
          return getGroupTag(member);
        } else if (gqlType('User')(member)) {
          return getUserTag(member);
        } else return null;
      }),
    },
    {
      label: t('Subgroups'),
      value: subgroups?.map((group) => getGroupTag(group)),
    },
  ];

  return <PropertyList items={properties} orientation="vertical" px={3} />;
};
