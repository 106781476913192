import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const EditDocumentOnComputer: React.FC<SvgIconProps> = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H13.175C13.4417 2 13.6958 2.05 13.9375 2.15C14.1792 2.25 14.3917 2.39167 14.575 2.575L19.425 7.425C19.6083 7.60833 19.75 7.82083 19.85 8.0625C19.95 8.30417 20 8.55833 20 8.825V11C19.6167 11.0833 19.2583 11.2167 18.925 11.4C18.5917 11.5833 18.2833 11.8167 18 12.1L12.6 17.5C12.4167 17.6833 12.2708 17.8958 12.1625 18.1375C12.0542 18.3792 12 18.6333 12 18.9V22H6ZM14 21V19.35C14 19.2167 14.025 19.0875 14.075 18.9625C14.125 18.8375 14.2 18.725 14.3 18.625L19.525 13.425C19.675 13.275 19.8417 13.1667 20.025 13.1C20.2083 13.0333 20.3917 13 20.575 13C20.775 13 20.9667 13.0375 21.15 13.1125C21.3333 13.1875 21.5 13.3 21.65 13.45L22.575 14.375C22.7083 14.525 22.8125 14.6917 22.8875 14.875C22.9625 15.0583 23 15.2417 23 15.425C23 15.6083 22.9667 15.7958 22.9 15.9875C22.8333 16.1792 22.725 16.35 22.575 16.5L17.375 21.7C17.275 21.8 17.1625 21.875 17.0375 21.925C16.9125 21.975 16.7833 22 16.65 22H15C14.7167 22 14.4792 21.9042 14.2875 21.7125C14.0958 21.5208 14 21.2833 14 21ZM20.575 16.4L21.5 15.425L20.575 14.5L19.625 15.45L20.575 16.4ZM14 9H18L13 4V8C13 8.28333 13.0958 8.52083 13.2875 8.7125C13.4792 8.90417 13.7167 9 14 9Z"
      fill="black"
      fillOpacity="0.56"
    />
  </SvgIcon>
);
