import React from 'react';
import { useTranslation } from 'react-i18next';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Column } from '@pro4all/shared/ui/layout';

import ctaImage from './ctaImage.png';
import * as Styled from './ProstreamCta.styles';

export const ProstreamCta: React.FC = () => {
  const userId = AuthService.getProfile()?.userId;
  const { goTo } = useRouting();
  const { t } = useTranslation();

  return (
    <Styled.CtaContainer>
      <Column color="white" width="50%">
        <Styled.Header color="inherit" variant="h1">
          {t('The best projects begin with Prostream')}
        </Styled.Header>
        <Styled.BodyText color="inherit" variant="body1">
          {t(
            'Prostream facilitates cooperation by removing digital hurdles and frustrations, increasing quality and freeing up time.'
          )}
        </Styled.BodyText>
        <Styled.ButtonGroup>
          {userId ? (
            <Styled.Button
              onClick={() => goTo('base')}
              startIcon="chevronRightRounded"
              variant="contained"
            >
              {t('To Prostream')}
            </Styled.Button>
          ) : (
            <>
              <Styled.Button
                onClick={() => goTo('registration')}
                startIcon="chevronRightRounded"
                variant="contained"
              >
                {t('Register now')}
              </Styled.Button>
              <Styled.Button
                onClick={() => goTo('base')}
                startIcon="person"
                variant="contained"
              >
                {t('Login')}
              </Styled.Button>
            </>
          )}
        </Styled.ButtonGroup>
      </Column>
      <Styled.Img alt="prostream" src={ctaImage} />
    </Styled.CtaContainer>
  );
};
