import React from 'react';
import { useTranslation } from 'react-i18next';

import type { SubscriptionLevel } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { Chip } from '@pro4all/shared/ui/general';
import { Radio } from '@pro4all/shared/ui/inputs';
import { Text } from '@pro4all/shared/ui/typography';

import { useSubscriptionHelpers } from './hooks/useSubscriptionHelpers';
import * as Styled from './styles/SubscriptionSelectionCard.styles';

export const SubscriptionSelectionCard = ({
  ignoreIsChecked,
  subscriptionLevel,
  onSelect,
  currentLicense,
}: {
  currentLicense?: string;
  ignoreIsChecked?: boolean;
  onSelect?: (license: string) => void;
  subscriptionLevel: SubscriptionLevel;
}) => {
  const { t } = useTranslation();

  const {
    isCurrentSubscription,
    getSubscriptionLevelTranslates,
    getSubscriptionLevelPrice,
  } = useSubscriptionHelpers();
  const subscriptionLevelTranslates = getSubscriptionLevelTranslates({
    subscriptionLevel,
  });
  const { bullets, description, subtitle, title } =
    subscriptionLevelTranslates || {};
  const subscriptionLevelPrice = getSubscriptionLevelPrice(subscriptionLevel);
  const currentSubscription = isCurrentSubscription(subscriptionLevel);
  const { id, isPopular, isSelectable, name } = subscriptionLevel || {};

  if (
    !useFeatureFlag('qualitycontrol') &&
    (name === 'qualitycontrol' || name === 'premium' || name === 'enterprise')
  )
    return null;

  const isChecked = currentLicense
    ? currentLicense === id
    : currentSubscription;

  return (
    <Styled.LevelCard
      $isChecked={!ignoreIsChecked && isChecked}
      onClick={isSelectable ? () => onSelect && onSelect(id) : undefined}
    >
      <Styled.ZoneWrapper>
        <Styled.Title variant="h2">{title}</Styled.Title>
        {subtitle && <Styled.Subtitle variant="h6">{subtitle}</Styled.Subtitle>}
        <Styled.Description variant="body1">{description}</Styled.Description>

        {bullets &&
          bullets.length &&
          bullets.map((bullet, index) => (
            <Box alignItems="flex-start" display="flex" key={index} mb={1}>
              <Styled.BulletIcon color="secondary" iconName="check" />
              <Text variant="body1">{bullet}</Text>
            </Box>
          ))}
      </Styled.ZoneWrapper>
      <Styled.ZoneWrapper>
        <Box display="flex">
          {currentSubscription && (
            <Styled.Chip>
              <Chip color="primary" label={t('Current license')} />
            </Styled.Chip>
          )}
          {isPopular && (
            <Styled.Chip>
              <Chip color="warning" label={t('Popular')} />
            </Styled.Chip>
          )}
        </Box>
        <Box display="flex" justifyContent="space-between">
          {isSelectable ? (
            <>
              <Box alignItems="flex-end" display="flex">
                <Styled.Price variant="h1">{`€ ${subscriptionLevelPrice?.toLocaleString()}`}</Styled.Price>
                <Text variant="body2">{t('/ user / month')}</Text>
              </Box>
              {!ignoreIsChecked && (
                <Radio checked={isChecked} id="selectSubscriptionLevel" />
              )}
            </>
          ) : (
            <Button
              color="secondary"
              data-testid="request-quote"
              onClick={() => {
                if (navigator.language.toLowerCase().includes('nl')) {
                  window.location.href =
                    'https://www.prostream.app/prijzen/enterprise/';
                } else {
                  window.location.href =
                    'https://www.prostream.app/en/pricing/';
                }
              }}
            >
              {t('Request quote')}
            </Button>
          )}
        </Box>
      </Styled.ZoneWrapper>
    </Styled.LevelCard>
  );
};
