import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TbqGlobalScope } from '@pro4all/graphql';
import { useObjectDetailContext } from '@pro4all/shared/contexts';
import { Alert, Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button as UIButton } from '@pro4all/shared/ui/buttons';
import { Sidebar } from '@pro4all/shared/ui/sidebar';

import { TBQModulesTaskForm } from './form/TBQModulesTaskForm';
import { TBQTaskForm } from './form';
interface Props {
  scopes: TbqGlobalScope[];
}

export const TBQCreateScanSidePanel: React.FC<Props> = ({ scopes }) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const {
    object: { projectStatus },
  } = useObjectDetailContext();

  const isBrandcheckScanTask = searchParams.is(
    'action',
    'createBrandcheckScan'
  );
  const isRIEScanTask = searchParams.is('action', 'createRIEScan');
  const isModulesScanTask = searchParams.is('action', 'createModuleScan');
  const scopeId = searchParams.get('scopeId');

  const sidebarOpen =
    isBrandcheckScanTask || isRIEScanTask || isModulesScanTask;

  let title;
  if (isModulesScanTask) {
    title = scopes.find((s) => s.scopeId === scopeId)?.name;
  } else if (isRIEScanTask) {
    title = t('RI&E scan');
  } else {
    title = t('Brandcheck scan');
  }

  const close = () => searchParams.delete('action');
  const canCreateTask =
    projectStatus && ['LinkedAncestor', 'Linked'].includes(projectStatus);

  const ErrorTask = (
    <>
      <Box p={2}>
        <Alert severity="warning" variant="filled">
          {t(
            'You need to link either this object or a parent object to a Project in order to be able to create TBQ scans.'
          )}
        </Alert>
      </Box>
      <Button color="inherit" onClick={close} variant="outlined">
        {t('Cancel')}
      </Button>
    </>
  );
  const TaskForm = isModulesScanTask ? (
    <TBQModulesTaskForm onClose={close} />
  ) : (
    <TBQTaskForm onClose={close} />
  );

  return (
    <Sidebar onClose={close} open={sidebarOpen} wide>
      <Sidebar.Header icon="tbqBrandcheck" title={title} />
      {canCreateTask ? TaskForm : ErrorTask}
    </Sidebar>
  );
};

const Button = styled(UIButton)`
  && {
    margin: auto 1.5rem 1.5rem auto;
  }
`;
