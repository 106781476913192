import React from 'react';
import styled from 'styled-components';

import { Loader } from '@pro4all/shared/ui/loader';

interface Props {
  loading?: boolean;
  matchString?: string;
  renderOptions: () => React.ReactNode;
}

export const FilterOptions: React.FC<Props> = ({ loading, renderOptions }) => (
  <ListWrap>{loading ? <Loader /> : <ul>{renderOptions()}</ul>}</ListWrap>
);

const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 13rem;
  min-height: 4rem;
  padding: 0.5rem 0;
  background-color: #fff;
  gap: 0.5rem;

  ul {
    height: 100%;
    overflow-x: hidden;
    padding: 0;
  }
`;
