import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TemplateType } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { Card } from '@pro4all/shared/ui/general';
import { Loader } from '@pro4all/shared/ui/loader';
import { useSearchInput } from '@pro4all/shared/ui/search-input';
import { sortBy } from '@pro4all/shared/utils';

import { useCreateSavedAnswersContext } from './CreateSavedAnswersContext';
import { useGetTemplates } from './useGetTemplates';

export const DialogSelectTemplate = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const selectTemplateContext = searchParams.is('action', 'selectTemplate');
  const { query, searchInput } = useSearchInput();
  const { setSelectedTemplate } = useCreateSavedAnswersContext();
  const { formTemplates, showLoader, snagTemplates } = useGetTemplates();

  const filteredTemplates =
    !showLoader && selectTemplateContext
      ? [...snagTemplates, ...formTemplates]
          .sort(sortBy({ key: 'name' }))
          .filter(({ name }) =>
            name
              ? name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
              : true
          )
      : [];

  return (
    <FloatingModal
      data-testid="select-template-modal"
      maxWidth="md"
      onClose={() => searchParams.delete('action')}
      open={selectTemplateContext}
    >
      <FloatingModal.Header>
        {t('Select a template to create saved answers')}
      </FloatingModal.Header>
      <ContentWrapper>
        {showLoader ? (
          <Loader />
        ) : (
          <>
            {searchInput}
            {filteredTemplates.map((template) => {
              const { id, name, type } = template;
              return (
                <Card
                  disabledButtonBorder
                  iconName={type === TemplateType.Snag ? 'snag' : 'form'}
                  key={id}
                  menuItems={[
                    {
                      startIcon: 'arrowForward',
                    },
                  ]}
                  onClick={() => {
                    searchParams.set({
                      action: 'createSavedAnswer',
                    });
                    setSelectedTemplate({
                      id,
                      name: name || '',
                      type: type || TemplateType.Form,
                    });
                  }}
                  title={name || ''}
                />
              );
            })}
          </>
        )}
      </ContentWrapper>
    </FloatingModal>
  );
};

const ContentWrapper = styled(Box)`
  && {
    height: 500px;
  }
`;
