import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Formik } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikDate } from '@pro4all/shared/ui/formik';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { StyledForm } from '../modal/styles';
import { ScopedLinkFormValues, ShareModalBaseProps } from '../modal/types';

import { useGenerateScopedLinkFormConfig } from './useGenerateScopedLinkFormConfig';
import { useSubmit } from './useSubmit';

export const GenerateScopedLinkForm: React.FC<ShareModalBaseProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();
  const { submitScopedLink } = useSubmit();

  const initialValues: ScopedLinkFormValues = {
    endTime: dayjs().add(7, 'day').format('YYYY-MM-DD'),
  };

  const { getField, validationSchema } = useGenerateScopedLinkFormConfig();
  const endTimeField = getField('endTime');

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitScopedLink}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting }) => (
        <StyledForm>
          <FormWrapper noPadding>
            <FormikDate
              label={endTimeField.label}
              maxDate={dayjs().add(20, 'year')}
              minDate={dayjs()}
              name={endTimeField.name}
            />
          </FormWrapper>

          <FormFooter
            disableSubmit={isSubmitDisabled({
              errors,
              isSubmitting,
            })}
            marginTop="auto"
            onClose={onClose}
            pt={2}
            sticky
            submitLabel={t('Next')}
          />
        </StyledForm>
      )}
    </Formik>
  );
};
