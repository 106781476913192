import styled from 'styled-components';

/* Do NOT add margins and padding in here, those are controlled from the outside because they differ per use case */
export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const DetailsWrapper = styled.div`
  flex-grow: 1;
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

export const HeadlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled.div`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const NameAppendix = styled.div`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
`;

export const SublineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SublineLeftPart = styled.div`
  display: flex;
`;

export const SublineRightPart = styled.div`
  font-style: italic;
`;

export const SublinePrefix = styled.div`
  margin-right: ${({ theme }) => theme.spacing(3)};
`;

export const Organization = styled.div`
  margin-right: ${({ theme }) => theme.spacing(3)};
`;

export const Email = styled.div`
  margin-right: ${({ theme }) => theme.spacing(3)};
`;
