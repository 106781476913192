import React from 'react';
import { useField, useFormikContext } from 'formik';

import { TextArea } from '@pro4all/shared/ui/inputs';
import { TextFieldProps } from '@pro4all/shared/ui/inputs';

export const FormikTextarea: React.FC<TextFieldProps> = ({
  fieldsToSync = [],
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField({ name });
  const { setFieldTouched, setFieldValue } = useFormikContext();

  const hasError = Boolean(meta.touched) && Boolean(meta.error);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    helpers.setTouched(true);
    helpers.setValue(event.target.value);
    fieldsToSync.forEach((field) => {
      setFieldValue(field, event.target.value);
      setFieldTouched(field, true, true);
    });
  };

  return (
    <TextArea
      {...field}
      {...props}
      error={hasError}
      helperText={hasError && meta.error}
      margin="normal"
      onChange={handleChange}
      rows={props.rows}
    />
  );
};
