import { useCallback, useEffect, useState } from 'react';

import {
  HierarchicalList,
  useHierarchicalListsIncludeQuery,
} from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { useInitializeLocalStorage } from '@pro4all/shared/hierarchy-editor';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';

export const useHierarchicalLists = ({
  includeOrgItems = true,
}: {
  includeOrgItems?: boolean;
}) => {
  const { params, searchParams } = useRouting();
  const [selectedHierarchicalList, setSelectedHierarchicalList] =
    useState<HierarchicalList | null>(null);

  const initializeLocalStorage = useInitializeLocalStorage();

  const action = searchParams.get('action');
  const {
    state: { items },
    replaceAllItems,
  } = useOptimisticResponseContext<HierarchicalList>();

  const { data, loading } = useHierarchicalListsIncludeQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000 * ApiConfig.pollEnabled,
    variables: {
      includeCreatedAt: true,
      includeCreatedBy: true,
      includeOrgItems,
      includeScope: true,
      projectId: params.projectId,
    },
  });

  const hierarchicalLists = data?.hierarchicalLists as HierarchicalList[];

  if (action === 'editHierarchicalList' && !items.length && hierarchicalLists)
    replaceAllItems(hierarchicalLists);

  const selectHierarchicalList = useCallback(
    (hierarchicalList: HierarchicalList) => {
      setSelectedHierarchicalList(hierarchicalList);
      initializeLocalStorage();
      searchParams.set({
        action: 'editHierarchicalList',
        id: hierarchicalList.id,
      });
    },
    [initializeLocalStorage, searchParams]
  );

  useEffect(() => {
    const setFromUrl = searchParams.get('id');
    const setByUrl = items?.find((set) => set.id === setFromUrl);

    if (setByUrl !== selectedHierarchicalList) {
      setSelectedHierarchicalList(setByUrl);
    }
  }, [
    items,
    searchParams,
    selectedHierarchicalList,
    setSelectedHierarchicalList,
  ]);

  return {
    hierarchicalLists,
    loading,
    selectHierarchicalList,
    selectedHierarchicalList,
  };
};
