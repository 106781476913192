import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  MarkerLabelContent,
  useMapLinkingContext,
} from '@pro4all/quality-control/ui/maps';
import { Checkbox, FormControlLabel } from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import { Select } from '@pro4all/shared/ui/inputs';

const ShowLabels = () => {
  const { t } = useTranslation();

  const labelOptions: Option[] = [
    {
      id: MarkerLabelContent.NameAndRef,
      label: t(`${MarkerLabelContent.NameAndRef}`),
    },
    { id: MarkerLabelContent.Name, label: t(`${MarkerLabelContent.Name}`) },
    { id: MarkerLabelContent.Ref, label: t(`${MarkerLabelContent.Ref}`) },
  ];

  const {
    instanceLabelContent,
    setInstanceLabelContent,
    setShowInstanceLabels,
    showInstanceLabels,
  } = useMapLinkingContext();

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={showInstanceLabels}
            onChange={() => {
              setShowInstanceLabels(!showInstanceLabels);
            }}
          />
        }
        label={t('Show labels')}
      />
      <MarkerLabelContentSelect
        name="markerLabelContent"
        onChange={(e) => {
          setInstanceLabelContent(e.id as MarkerLabelContent);
        }}
        options={labelOptions}
        value={instanceLabelContent}
      />
    </>
  );
};

export default ShowLabels;

const MarkerLabelContentSelect = styled(Select)`
  && {
    min-width: 200px;
  }
`;
