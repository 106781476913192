import { Dispatch, SetStateAction, useState } from 'react';
import { DropzoneState } from 'react-dropzone';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { useFinalize } from '@pro4all/documents/ui/finalize';
import { Document, DocumentVersion, LockType } from '@pro4all/graphql';
import { DmsTrackingLocation } from '@pro4all/shared/config';
import { useDocumentSearchContext } from '@pro4all/shared/documents-search-context';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import {
  useContextScopedOrganizationId,
  useIsAdminInContext,
} from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Position, PositionType } from '@pro4all/shared/types';
import { useTableCheck } from '@pro4all/shared/ui/table';

import { useIsLockedUser } from './useIsLockedUser';

export type DocumentActionsProps = {
  contextMenuRow?: Document;
  contextMenuRowVersion?: DocumentVersion;
  folderId?: string;
  isSpecialFolderProp?: boolean;
  openFileInput?: DropzoneState['open'];
  position?: PositionType;
  setLoading?: Dispatch<SetStateAction<boolean>>;
  sidebarRow?: Document;
  sidebarRowVersion?: DocumentVersion;
};

export function useDocumentActionsProps({
  contextMenuRow,
  contextMenuRowVersion,
  folderId,
  isSpecialFolderProp,
  position,
  setLoading,
  sidebarRow,
  sidebarRowVersion,
}: Pick<
  DocumentActionsProps,
  | 'contextMenuRow'
  | 'contextMenuRowVersion'
  | 'folderId'
  | 'isSpecialFolderProp'
  | 'position'
  | 'setLoading'
  | 'sidebarRow'
  | 'sidebarRowVersion'
>) {
  const { params } = useRouting();
  const projectId = params.projectId || '';
  const hasFeatureFlagQcGroupPermissions = useFeatureFlag(
    'qc-group-permissions'
  );
  const hasFeatureFlagClient = useFeatureFlag('client');

  const [, setLoadingDummy] = useState(false);
  const setActionLoading = setLoading || setLoadingDummy;

  const isSpecialFolder = Boolean(isSpecialFolderProp);

  const { checkedRows, uncheckAllRows } = useTableCheck<Document>();
  const searchContext = useDocumentSearchContext();

  const selection = contextMenuRow
    ? [contextMenuRow]
    : sidebarRow
    ? [sidebarRow]
    : checkedRows;

  const selectionVersion = contextMenuRowVersion
    ? [contextMenuRowVersion]
    : sidebarRowVersion
    ? [sidebarRowVersion]
    : [];

  const selected = selection?.length
    ? selection
    : selectionVersion?.length
    ? selectionVersion
    : [];

  const versionIds = selection.length
    ? selection.map((row) => row.versionId).filter(Boolean)
    : selectionVersion.map((row) => row.id).filter(Boolean);

  const { allDocumentsFinalized, allDocumentsUnfinalized } = useFinalize({
    documents: selection,
    versions: selectionVersion,
  });

  const expectedFileCount = selection.length
    ? selection.filter((doc) => doc.isExpected).length
    : 0;

  const { userId } = AuthService.getProfile();

  const getContextScopedOrganizationId = useContextScopedOrganizationId();

  const organizationId = getContextScopedOrganizationId();
  const isVersion = Boolean(contextMenuRowVersion || sidebarRowVersion);

  const trackingPosition = contextMenuRowVersion
    ? DmsTrackingLocation.VersionContextMenu
    : position === Position.Contextmenu
    ? DmsTrackingLocation.DocumentContextMenu
    : sidebarRowVersion
    ? DmsTrackingLocation.VersionSidebar
    : position === Position.Sidebar
    ? DmsTrackingLocation.DocumentSidebar
    : DmsTrackingLocation.DocumentTable;

  const allDocumentsExpected =
    contextMenuRowVersion || sidebarRowVersion
      ? false
      : contextMenuRow || sidebarRow
      ? Boolean(contextMenuRow?.isExpected) || Boolean(sidebarRow?.isExpected)
      : checkedRows.every((row) => Boolean(row.isExpected));

  const notExpectedDocuments = selection.filter((row) => !row.isExpected);

  const isLocked = Boolean(selection[0]?.lockType === LockType.Prostream);

  const isLockedAll = Boolean(
    selection[0]?.lockType === LockType.OfficeOnline ||
      selection[0]?.lockType === LockType.Prostream
  );

  const versionIsNotCurrentVersion = selection.length
    ? false
    : !contextMenuRowVersion?.state && !sidebarRowVersion?.state;

  const folderIdSelected =
    contextMenuRow?.folderId ||
    contextMenuRowVersion?.folderId ||
    sidebarRow?.folderId ||
    sidebarRowVersion?.folderId ||
    folderId ||
    '';

  const { getEditableDocumentsOrVersions, hasFolderPermission } =
    useUserFolderPermissions({
      folderId: folderIdSelected,
    });

  const editableDocuments = getEditableDocumentsOrVersions({
    documents: selection,
  }) as Document[];

  const editableDocumentsWithFinalized = getEditableDocumentsOrVersions({
    documents: selection,
    excludeFinalized: false,
  }) as Document[];

  const editableVersions = getEditableDocumentsOrVersions({
    versions: selectionVersion,
  }) as DocumentVersion[];

  // Is this user an admin on organization or project context?
  const isAdmin = useIsAdminInContext();

  // Is this user the one who locked the document?
  const isLockedUser = useIsLockedUser({ selection });

  // Selected documents that are owned by the authenticated user.
  const ownedByUserDocuments = selection.filter(
    (doc) => doc.createdBy?.id === userId
  );

  return {
    allDocumentsExpected,
    allDocumentsFinalized,
    allDocumentsUnfinalized,
    editableDocuments,
    editableDocumentsWithFinalized,
    editableVersions,
    expectedFileCount,
    folderIdSelected,
    hasFeatureFlagClient,
    hasFeatureFlagQcGroupPermissions,
    hasFolderPermission,
    isAdmin,
    isLocked,
    isLockedAll,
    isLockedUser,
    isSpecialFolder,
    isVersion,
    notExpectedDocuments,
    organizationId,
    ownedByUserDocuments,
    projectId,
    searchContext,
    selected,
    selection,
    selectionVersion,
    setActionLoading,
    trackingPosition,
    uncheckAllRows,
    userId,
    versionIds,
    versionIsNotCurrentVersion,
  };
}
