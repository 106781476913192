import { Project } from '@pro4all/graphql';
import { CreateProjectMutationVariables } from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';

export type FormFields = Pick<
  CreateProjectMutationVariables,
  'description' | 'name' | 'projectNumber' | 'startDate' | 'deadline'
> & { admins: Option[]; users: Option[] };

export type ProjectType = Pick<
  Project,
  | 'availableRoles'
  | 'deadline'
  | 'description'
  | 'id'
  | 'name'
  | 'projectNumber'
  | 'startDate'
>;

export type SelectTemplateFields = {
  template: Option;
};

export type CoordinatesType = {
  x: number;
  y: number;
};
export interface ContentsProps {
  cluster?: boolean;
  disableControls?: boolean;
  lat: number;
  lng: number;
  mapHeight: number;
  mapWidth: number;
  offsetX?: number;
  onClose?: () => void;
  onContextMenu?: (event: React.MouseEvent) => void;
  onLoad?: () => void;
  placeSnagMode?: boolean;
  visualContextId: string;
  zoomLevels: number;
}
export enum MarkerLabelContent {
  Name = 'Show.LabelContent.Name',
  NameAndRef = 'Show.LabelContent.Ref.Name',
  Ref = 'Show.LabelContent.Ref',
}
