import styled from 'styled-components';

import { customColors, ThemeColor } from '@pro4all/shared/themes';
import { Shape } from '@pro4all/shared/ui/shapes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;

  ${({ theme }) => theme.breakpoints.up('md')} {
    align-items: center;
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 600px;
  min-height: 400px;
  margin: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(3)};
  background: ${customColors.white};
  border-radius: ${({ theme }) => theme.spacing(3)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-left: 140px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Actions = styled.div<{
  $color: ThemeColor;
}>`
  color: ${({ $color }) => customColors[$color]};
  width: 100%;
`;

export const BackgroundShape = styled.div`
  display: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    flex: 1 1 auto;
    padding: 0 100px;
  }
`;

export const StyledShape = styled(Shape)`
  && {
    width: 100%;
    max-width: 480px;
    height: auto;
  }
`;
