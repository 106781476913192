import React from 'react';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';

import {
  Document,
  QualityControlInstance,
  Status,
  Task,
  TaskType,
  useCreateDocumentTaskMutation,
  useCreateTaskMutation,
  useSetLinkMutation,
  useSetTaskTemplateLinksMutation,
  useSetTaskVisualContextLinksMutation,
  useUpdateTaskMutation,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
  useShowMessages,
} from '@pro4all/shared/ui/messages';
import { toApiDate } from '@pro4all/shared/utils';

import { FormFields } from '../types';

import {
  docLinkedTasksOptRes,
  QcTaskOptimisticResponse,
} from './utils/optimisticResponses';
import { setDocumentAndResolveTaskLinks, setQcLinks } from './utils/setLinks';
import { editTask } from './editMode';
import SubmitMessage from './SubmitMessage';

type Props = {
  initialValues: FormFields;
  linkInstances?: (QualityControlInstance | Document)[];
  procedureId: string;
  task: Task;
};

export const useSubmit = ({
  initialValues,
  linkInstances = [],
  procedureId,
  task,
}: Props) => {
  const { userDisplayName, userId } = useOrganizationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [createTask] = useCreateTaskMutation();
  const [createDocumentTask] = useCreateDocumentTaskMutation();
  const [setLinkMutation] = useSetLinkMutation();

  const [updateTask] = useUpdateTaskMutation();
  const [setTaskTemplateLinks] = useSetTaskTemplateLinksMutation();
  const [setTaskVisualContextLinks] = useSetTaskVisualContextLinksMutation();
  const { showSingleError } = useShowMessages();

  const { goTo, params, goBack, searchParams } = useRouting();
  const { projectId } = params;

  const duplicated = Boolean(searchParams.get('duplicate'));
  const duplicatedFrom = duplicated ? searchParams.get('id') : null;

  const commands = task?.availableStatuses?.map((ts) => ({
    command: ts?.command,
    id: ts?.taskStatus,
  }));

  const isEditMode = Boolean(task);

  return async (values: FormFields, helpers: FormikHelpers<FormFields>) => {
    const {
      description,
      endTime,
      name,
      category,
      participant,
      nextStatus,
      type,
      formTemplates = [],
      snagTemplates = [],
      visualContexts = [],
      documentAction,
    } = values;
    let message;

    const {
      formTemplates: formTemplatesInitial,
      snagTemplates: snagTemplatesInitial,
      visualContexts: visualContextsInitial,
    } = initialValues;

    // We only want to show the name and not the email.
    const participantNameDisplay = participant?.label.split('(')[0];

    try {
      const status = nextStatus.id as Status;
      let taskId = task?.id;
      const deadline = endTime ? toApiDate(endTime) : null;

      const edit = () => {
        const payload = {
          command: commands?.find((c) => c.id === status)?.command,
          description,
          documentAction: documentAction?.id,
          endTime: deadline,
          id: task?.id,
          name,
          procedureId,
          status,
          taskCategoryId: category ? category.id : null,
          type,
          userId: participant?.id,
        };
        editTask({
          linkInstances,
          participantNameDisplay,
          payload,
          task,
          updateTask,
          values,
        });
        message = (
          <ItemChangedMessage
            description={MessageAction.Update}
            entityName={name}
            entityTypeTranslation={EntityTypeTranslation.Task}
          />
        );
      };

      if (isEditMode) {
        edit();
      } else {
        let result;
        const variables = {
          description,
          duplicatedFrom,
          endTime: deadline,
          name,
          procedureId,
          taskCategoryId: category ? category.id : null,
          type,
          userId: participant?.id,
        };
        if (type === TaskType.Document) {
          const createdDocTask = await createDocumentTask({
            update: (cache, { data }) => {
              docLinkedTasksOptRes({
                cache,
                data,
                linkInstances,
              });
            },
            variables: { ...variables, documentAction: documentAction?.id },
          });
          result = createdDocTask.data?.createDocumentTask;
          taskId = result?.id ?? '';
        } else if (
          type === TaskType.Resolve ||
          type === TaskType.QualityControl
        ) {
          const createdTask = await createTask({
            update: (cache, { data }) => {
              if (type === TaskType.QualityControl)
                QcTaskOptimisticResponse({
                  cache,
                  createdBy: {
                    displayName: userDisplayName || '',
                    id: userId ?? '',
                  },
                  data,
                  projectId,
                  values,
                });
            },
            variables,
          });
          result = createdTask.data?.createTask;
          taskId = result?.id ?? '';
        }
        message = (
          <SubmitMessage
            goTo={goTo}
            id={taskId}
            name={result?.name ?? ''}
            projectId={projectId}
            type={type}
          />
        );

        helpers.resetForm();
      }
      //Linking
      if (type === TaskType.Document || type === TaskType.Resolve) {
        setDocumentAndResolveTaskLinks({
          linkInstances,
          setLinkMutation,
          taskId,
          type,
        });
      }

      if (type === TaskType.QualityControl) {
        setQcLinks({
          duplicated,
          formTemplates,
          formTemplatesInitial,
          setTaskTemplateLinks,
          setTaskVisualContextLinks,
          snagTemplates,
          snagTemplatesInitial,
          taskId,
          visualContexts,
          visualContextsInitial,
        });
      }

      enqueueSnackbar(message);
      goBack();
    } catch (e) {
      showSingleError(e);
    }
  };
};
