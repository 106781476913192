import React, { useContext, useState } from 'react';

import { Document } from '@pro4all/graphql';
import { PositionType } from '@pro4all/shared/types';

type ClientRedirectContextValue = {
  clearState: () => void;
  clientUrl: string;
  commandId: string;
  document: Document | null;
  isDialogOpen: boolean;
  isPublishAction: boolean;
  position: PositionType | null;
  setClientUrl: (url: string) => void;
  setCommandId: (commandId: string) => void;
  setDocument: (document: Document) => void;
  setIsDialogOpen: (isOpen: boolean) => void;
  setIsPublishAction: (isPublishAction: boolean) => void;
  setPosition: (position: PositionType) => void;
};

const ClientRedirectContext = React.createContext<ClientRedirectContextValue>({
  clearState: () => '',
  clientUrl: '',
  commandId: '',
  document: null,
  isDialogOpen: false,
  isPublishAction: false,
  position: null,
  setClientUrl: () => '',
  setCommandId: () => '',
  setDocument: () => null,
  setIsDialogOpen: () => false,
  setIsPublishAction: () => false,
  setPosition: () => null,
});

export function useClientRedirectContext() {
  return useContext(ClientRedirectContext);
}

export const ClientRedirectContextProvider: React.FC = ({ children }) => {
  const [clientUrl, setClientUrl] = useState('');
  const [position, setPosition] = useState<PositionType | null>(null);
  const [document, setDocument] = useState<Document | null>(null);
  // State to manage dialog visibility
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [isPublishAction, setIsPublishAction] = useState(false);
  const [commandId, setCommandId] = useState('');

  const clearState = () => {
    setClientUrl('');
    setPosition(null);
    setDocument(null);
    setIsDialogOpen(false);
    setIsPublishAction(false);
    setCommandId('');
  };

  const values = {
    clearState,
    clientUrl,
    commandId,
    document,
    isDialogOpen,
    isPublishAction,
    position,
    setClientUrl,
    setCommandId,
    setDocument,
    setIsDialogOpen,
    setIsPublishAction,
    setPosition,
  };

  return (
    <ClientRedirectContext.Provider value={values}>
      {children}
    </ClientRedirectContext.Provider>
  );
};
