import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { _TextField as TextField } from '@pro4all/shared/ui/inputs';

import { MessageFormFields } from '../types';

import { InputWrap, StyledLabel } from './Header.styles';

interface SubjectInputProps {
  handleDebounceDraftSave: () => void;
}

export const SubjectInput: React.FC<SubjectInputProps> = ({
  handleDebounceDraftSave,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<MessageFormFields>();

  return (
    <Box pt={1}>
      <InputWrap>
        <StyledLabel>{`${t('Subject')}:`}</StyledLabel>
        <Controller
          control={control}
          name="subject"
          render={({ onChange, ref, value }) => (
            <TextField
              defaultValue={value || undefined}
              fullWidth
              name="subject"
              onChange={(e) => {
                onChange(e);
                control.trigger();
                handleDebounceDraftSave();
              }}
              ref={ref}
            />
          )}
        />
      </InputWrap>
    </Box>
  );
};
