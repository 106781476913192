import React from 'react';

import { DateField } from '@pro4all/shared/ui/inputs';

import { useHandleValueChangeInputs } from './useHandleValueChangeInputs';

interface Props {
  name: string;
  setValue: (name: string, value: string) => void;
  value: string;
}

export const DateTime = ({ name, setValue, value, ...rest }: Props) => {
  const { currentValue, handleChangeDate } = useHandleValueChangeInputs({
    name,
    setValue,
    value,
  });

  return (
    <DateField
      {...rest}
      name={name}
      onChange={handleChangeDate}
      value={currentValue}
    />
  );
};
