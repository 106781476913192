import React, { useCallback, useEffect, useMemo } from 'react';

import { ImageType, useReportBackgroundsQuery } from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';
import { SearchableSelectProps } from '@pro4all/shared/ui/inputs';
import { sortBy } from '@pro4all/shared/utils';

import { ImageUpload } from '../../components';
import { useReportOptionsContext } from '../../ReportOptionsProvider';

export const BackgroundField = ({
  isFrontPageProp = false,
}: {
  isFrontPageProp?: boolean;
}) => {
  const fieldName = isFrontPageProp ? 'backgroundFrontPage' : 'background';

  const {
    setBackground,
    setBackgroundFrontPage,
    setBackgroundOptions,
    state: {
      backgroundOptions,
      reportOptions: { bgId, bgIdFrontPage },
    },
  } = useReportOptionsContext();

  const { data: bgData } = useReportBackgroundsQuery();

  useEffect(() => {
    if (bgData) {
      const queriedBgOptions = bgData?.reportBackgrounds?.map((background) => ({
        id: background.id,
        label: decodeURI(background.name),
      }));

      const defaultBgOption = {
        id: 'default',
        label: 'Prostream background (default)',
      };

      const bgOptions: Option[] = queriedBgOptions
        ? [defaultBgOption, ...queriedBgOptions.sort(sortBy({ key: 'label' }))]
        : [defaultBgOption];

      setBackgroundOptions(bgOptions);
    }
  }, [bgData, setBackgroundOptions]);

  const selectedId = isFrontPageProp ? bgIdFrontPage : bgId;

  const selectBgOption = useCallback(
    async ({ id }: Option) => {
      if (isFrontPageProp) {
        setBackgroundFrontPage(id);
      } else {
        setBackground(id);
      }
    },
    [isFrontPageProp, setBackground, setBackgroundFrontPage]
  );

  const onChange = (value: SearchableSelectProps['value']) => {
    if (!value || typeof value === 'string') {
      selectBgOption({ id: 'default', label: 'default' });
    } else {
      selectBgOption({ id: value.id, label: value.label });
    }
  };

  const memoizedJSX = useMemo(
    () => (
      <ImageUpload
        imageType={ImageType.Background}
        isFrontPageProp={isFrontPageProp}
        label="Background"
        name={fieldName}
        onChange={onChange}
        options={backgroundOptions}
        selectedId={selectedId}
      />
    ),
    [backgroundOptions, selectedId]
  );

  return memoizedJSX;
};
