import React from 'react';
import { useTranslation } from 'react-i18next';

import { useUserQuery } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { Avatar } from '@pro4all/shared/ui/avatar';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { IconName } from '@pro4all/shared/ui/icons';
import { Loader } from '@pro4all/shared/ui/loader';
import { Tag } from '@pro4all/shared/ui/tag';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { Text } from '@pro4all/shared/ui/typography';

import * as Styled from './Card.styles';
import { UserCardProps } from './Card.types';

export const UserCard = ({
  cardIcons,
  label,
  nameSuffix,
  sublinePrefix,
  sublineSuffix,
  userId,
}: UserCardProps) => {
  const { t } = useTranslation();
  const { projectData } = useProjectContext();
  const { organization } = useOrganizationContext();

  const projectUsers = projectData?.users || [];
  const organizationUsers = organization?.users || [];
  const user = [...projectUsers, ...organizationUsers]?.find(
    (user) => user?.id === userId
  );

  // In case the user is not a member of the project or organization anymore we need to query the user data.
  const { data: userQueryData, loading } = useUserQuery({
    fetchPolicy: 'cache-first',
    skip: Boolean(user),
    variables: {
      id: userId,
    },
  });

  const userDetails = user || userQueryData?.user;

  const isAdmin =
    userDetails && userDetails.isAdmin ? userDetails.isAdmin : false;
  const name =
    userDetails && userDetails.displayName
      ? userDetails.displayName
      : label
      ? label
      : t('Unknown');
  const email = userDetails && userDetails.email ? userDetails.email : '';
  const organizationName =
    userDetails && userDetails.organization && userDetails.organization.name
      ? userDetails.organization.name
      : '';

  if (!userDetails && loading) {
    return <Loader />;
  }

  return (
    <Styled.CardWrapper>
      <Avatar name={name} size="large" />
      <Styled.DetailsWrapper>
        <Styled.HeadlineWrapper>
          <Styled.NameWrapper>
            <Styled.Name>
              <Text variant="h5">{name}</Text>
            </Styled.Name>
            {isAdmin && <Tag name={t('Admin')} />}
            {nameSuffix && (
              <Styled.NameAppendix>{nameSuffix}</Styled.NameAppendix>
            )}
          </Styled.NameWrapper>
        </Styled.HeadlineWrapper>
        <Styled.SublineWrapper>
          <Styled.SublineLeftPart>
            {sublinePrefix && (
              <Styled.SublinePrefix>{sublinePrefix}</Styled.SublinePrefix>
            )}
            {organizationName && (
              <Styled.Organization>{organizationName}</Styled.Organization>
            )}
            {email && <Styled.Email>{email}</Styled.Email>}
          </Styled.SublineLeftPart>
          <Styled.SublineRightPart>{sublineSuffix}</Styled.SublineRightPart>
        </Styled.SublineWrapper>
      </Styled.DetailsWrapper>

      {cardIcons && (
        <Styled.IconsWrapper>
          {cardIcons?.map(
            (icon) =>
              !icon.disabled && (
                <Tooltip placement="bottom" title={icon.label}>
                  <IconButton
                    className="CardActions" // Without this className the icon is not clickable when the card is used in the editor @mentions feature (Suggestions).
                    color="default"
                    dataTestId={icon.dataTestId}
                    disableBorder
                    disabled={icon.disabled}
                    iconName={icon.startIcon as IconName}
                    onClick={icon.onClick}
                  />
                </Tooltip>
              )
          )}
        </Styled.IconsWrapper>
      )}
    </Styled.CardWrapper>
  );
};
