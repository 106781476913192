import React from 'react';
import { useTranslation } from 'react-i18next';

import { Switch } from '@pro4all/shared/ui/inputs';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

interface BreakInheritanceProps {
  checked: boolean;
}

export const BreakInheritanceForEntitySwitchComponent = ({
  checked,
}: BreakInheritanceProps) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      placement="bottom"
      title={t(
        'Breaking or unbreaking inheritance can only be managed from the permission matrix on selected user or group.'
      )}
    >
      <Switch checked={checked} disabled />
    </Tooltip>
  );
};

const skipUpdate = (
  prevProps: BreakInheritanceProps,
  nextProps: BreakInheritanceProps
) => prevProps.checked === nextProps.checked;

export const BreakInheritanceForEntitySwitch = React.memo(
  BreakInheritanceForEntitySwitchComponent,
  skipUpdate
);
