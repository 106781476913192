import React from 'react';

import { Checkbox } from '@pro4all/shared/ui/inputs';

import { UploaderEditorTypes } from '../types';

type Props = {
  checked: boolean;
  onChangeCallback: () => void;
} & Pick<UploaderEditorTypes, 'tabIndex'>;

const EditorCheckboxComponent = ({
  checked,
  onChangeCallback,
  tabIndex,
}: Props) => (
  <Checkbox checked={checked} onChange={onChangeCallback} tabIndex={tabIndex} />
);

const skipUpdate = (prevProps: Props, nextProps: Props) =>
  prevProps.checked === nextProps.checked &&
  prevProps.tabIndex === nextProps.tabIndex;

export const EditorCheckbox = React.memo(EditorCheckboxComponent, skipUpdate);
