import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { Dialog } from '@pro4all/shared/ui/dialog';
import { Text } from '@pro4all/shared/ui/typography';

import { useGenerateLinkContext } from '../GenerateLinkContext';

export const FilesCannotBeSharedDialog: React.FC = () => {
  const { t } = useTranslation();
  const { openShareModal, setResources, resourceIds } =
    useGenerateLinkContext();

  const { searchParams } = useRouting();

  const open =
    searchParams.is('action', 'file-cant-be-shared') &&
    Boolean(resourceIds.length);
  const handleClose = () => {
    setResources([]);
    searchParams.delete('action');
  };

  return (
    <Dialog
      iconName="warning"
      name="filesCannotBeSharedDialog"
      onClose={handleClose}
      onConfirm={openShareModal}
      open={open}
      title={t('Expected documents selected')}
    >
      <Text display="inline">
        {t(
          'You have included expected documents in you selection. These cannot be shared.'
        )}
        &nbsp;
        {t('Continue with the remaining documents?')}
      </Text>
    </Dialog>
  );
};
