import type { ReactNode } from 'react';
import React from 'react';

import { Footer as UIFooter } from '@pro4all/shared/ui/footer';

export function Footer({ children }: { children?: ReactNode }) {
  return (
    <UIFooter $sticky pt={2} px={3}>
      {children}
    </UIFooter>
  );
}
