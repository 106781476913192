import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { createGlobalStyle } from 'styled-components';

import {
  Box,
  BoxProps,
  useMediaQuery,
  useTheme,
} from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

import { Link } from '@pro4all/shared/ui/link';

export const Column: React.FC<Omit<BoxProps, 'display' | 'flexDirection'>> = (
  props
) => (
  <Box
    flex="auto"
    minHeight="0"
    {...props}
    display="flex"
    flexDirection="column"
  />
);

export const Row: React.FC<Omit<BoxProps, 'display'>> = (props) => (
  <Box display="flex" flex="auto" flexDirection="row" {...props} />
);

export const ResponsiveRowOrColumn: React.FC<Omit<BoxProps, 'display'>> = (
  props
) => {
  const theme = useTheme();
  const tooSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return tooSmallScreen ? <Column {...props} /> : <Row {...props} />;
};

export const MainLayout: React.FC<
  Omit<BoxProps, 'display' | 'flexDirection' | 'height'>
> = (props) => <Column {...props} height="100vh" overflow="auto" />;

export const Main = styled(Row).withConfig({
  shouldForwardProp: (prop) => !['transparent'].includes(prop),
})<{ transparent?: boolean }>`
  && {
    background-color: ${({ transparent }) =>
      transparent ? 'transparent' : customColors.white};

    ${({ theme }) => theme.breakpoints.up('md')} {
      overflow: hidden;
    }
  }
`;

export const ContentNavWrap = styled(Column)<{
  height?: string;
  maxHeight?: string;
}>`
  && {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : '200px')};
      height: ${({ height }) => (height ? height : 'inherit')};
      overflow: auto;
      overflow-y: hidden;
      border-bottom: 1px solid ${customColors.grey300};
    }

    ${({ theme }) => theme.breakpoints.up('sm')} {
      height: 100%;
      border-right: 1px solid ${customColors.grey300};
    }
  }
`;

export const ContentLayout: React.FC<BoxProps> = styled(Column)`
  && {
    @media screen {
      height: 100%;
    }
  }
`;

export const CenteredContents = styled.div<{ $fullHeight?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: ${({ $fullHeight }) => ($fullHeight ? '100%' : 'auto')};
`;

// TODO: Implement theme, its not injected in the function
// TODO: After MUI5 migration it requires this !important. Can't find another way to make it work.
export const PurpleBackground = createGlobalStyle`
  body {
    background: ${customColors.purplePrimary} !important;
  }
`;
export const ErrorBackground = createGlobalStyle`
  body {
    background: ${customColors.errorBg} !important;
  }
`;
export const SuccessBackground = createGlobalStyle`
  body {
    background: ${customColors.successBg} !important;
  }
`;
export const WarningBackground = createGlobalStyle`
  body {
    background: ${customColors.warningBg} !important;
  }
`;

export const FooterLayout = () => {
  const { t } = useTranslation();
  return (
    <StyledBottom>
      <StyledText>{t('This project is powered by')}</StyledText>
      <StyledLink href="https://prostream.app">Prostream</StyledLink>
    </StyledBottom>
  );
};

const StyledBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${customColors.white};
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

const StyledText = styled.div`
  margin-right: ${({ theme }) => theme.spacing(0.5)};
`;

const StyledLink = styled(Link)`
  && {
    color: ${customColors.greenPrimary};
    text-decoration: underline;
  }
`;

export const ContentRow = styled(Row)`
  && {
    flex-direction: column;
    ${({ theme }) => theme.breakpoints.up('sm')} {
      flex-direction: row;
    }
  }
`;
