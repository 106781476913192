import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  OrganizationUsersIncludeDocument,
  useMyOrganizationQuery,
  User,
  useSetOrganizationRoleForUsersMutation,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar } from '@pro4all/shared/ui/sidebar';
import { useShowMessages } from '@pro4all/shared/ui/messages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import {
  useOptimisticResponseContext,
  useTableCheck,
} from '@pro4all/shared/ui/table';

import {
  EditRolesForm,
  FormValues,
} from '../components/EditRolesForm/EditRolesForm';

import { organizationUsersIncludeProps } from './getIncludeProps';

interface Props {
  onClose: () => void;
  userToEdit?: User;
}

export const EditRolesSidebar: React.FC<Props> = ({ onClose, userToEdit }) => {
  const { checkedRows, uncheckAllRows } = useTableCheck<User>();
  const { showBatchErrors } = useShowMessages();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [setOrganizationRoleForUsers] =
    useSetOrganizationRoleForUsersMutation();

  const { searchParams } = useRouting();
  const isOpen = searchParams.is('action', 'editRoles');

  const { userId } = AuthService.getProfile();
  const { data, loading, error } = useMyOrganizationQuery({
    skip: !isOpen,
  });

  const {
    state: { items },
  } = useOptimisticResponseContext<User>();

  const options = data?.myOrganization?.roles?.map((role) => ({
    id: role.id,
    info: t(`Api.organization.roles.${role.name}`),
    label: t(role.name),
  }));

  const includesMe =
    userToEdit?.id === userId ||
    Boolean(checkedRows.find((user) => user.id === userId));

  const onSubmit = async (values: FormValues) => {
    try {
      await setOrganizationRoleForUsers({
        variables: {
          roleId: values.role,
          userIds: userToEdit
            ? [userToEdit.id]
            : checkedRows.map((user) => user.id),
        },
      });

      const usersToUpdate = userToEdit ? [userToEdit] : checkedRows;
      const roletoAdd =
        data?.myOrganization?.roles?.find((role) => role.id === values.role) ??
        null;

      const usersUpdated = usersToUpdate
        .filter((user) => user.id !== userId)
        .map((user) => ({
          ...user,
          roles: roletoAdd
            ? [{ ...roletoAdd, permissions: user.roles[0]?.permissions }]
            : user.roles,
        }));

      const usersToUpdateIds = usersUpdated.map((user) => user.id);
      const itemsRest = items.filter(
        (item) => !usersToUpdateIds.includes(item.id)
      );

      client.writeQuery({
        data: {
          organizationUsers: [...itemsRest, ...usersUpdated],
        },
        query: OrganizationUsersIncludeDocument,
        variables: {
          ...organizationUsersIncludeProps,
        },
      });

      enqueueSnackbar(t('User roles updated'));
      onClose();
      uncheckAllRows();
    } catch (e) {
      showBatchErrors({
        apolloError: e,
        identifierData: checkedRows.map((row) => ({
          displayName: row.displayName,
          id: row.id,
        })),
      });
    }
  };

  return (
    <Sidebar onClose={onClose} open={isOpen}>
      <ResponseWrapper error={error} isLoading={loading}>
        <Sidebar.Header title={t('Edit roles')} />

        {data && (
          <EditRolesForm
            includesMe={includesMe}
            onCancel={onClose}
            onSubmit={onSubmit}
            options={options}
          />
        )}
      </ResponseWrapper>
    </Sidebar>
  );
};
