import styled from 'styled-components';

import { PropertyList } from '@pro4all/shared/ui/property-list';

import { ViewerPropertyGroup } from '../viewers/Viewer.types';

const PropertyContainer = styled('div')`
  padding: ${({ theme }) => theme.spacing(1)};
  height: 100%;
  overflow: scroll;
`;

export const PropertyGroup: React.FC<{
  propertyGroup: ViewerPropertyGroup;
}> = ({ propertyGroup }) => (
  <PropertyContainer>
    <PropertyList items={propertyGroup.properties}></PropertyList>
  </PropertyContainer>
);
