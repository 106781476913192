import React from 'react';

import { Menu } from '@pro4all/shared/ui/context-menu';

import * as Styled from './Filter.styles';
import { ComponentByType } from './FilterComponent';
import { FilterProps } from './types';

export function Filter({
  columnValues,
  filterType,
  getOptionCount,
  getOptions,
  onReset,
  onSet,
  position,
  renderOption,
  setShowFilter,
  showFilter,
}: FilterProps) {
  const CustomComponent = ComponentByType[filterType];

  return (
    <Menu
      anchorPosition={position}
      anchorReference="anchorPosition"
      disableAutoFocusItem
      disableEnforceFocus // This line prevents 'Maximum call stack size exceeded - Unstable_TrapFocus'-issue when picking a date in the datePicker.
      onClose={() => setShowFilter(false)}
      open={showFilter}
    >
      <Styled.ContentWrap>
        <CustomComponent
          columnValues={columnValues}
          getOptionCount={getOptionCount}
          getOptions={getOptions}
          onReset={onReset}
          onSet={onSet}
          renderOption={renderOption}
        />
      </Styled.ContentWrap>
    </Menu>
  );
}
