import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';
import { Column } from '@pro4all/shared/ui/layout';

import * as Styled from './Stamper.styles';
import type { StamperDocument } from './store';
import { Status } from './store';
import { useStamper } from './useStamper';

export interface Props {
  children?: never;
  queue: StamperDocument[];
}

export const Stamper: React.FC<Props> = ({ queue = [] }) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const {
    canvas,
    count,
    current,
    hasQRCode,
    next,
    revokeAll,
    revokeOne,
    stampOne,
    status,
    total,
  } = useStamper(queue);

  const goBack = useCallback(
    () => searchParams.delete('action'),
    [searchParams]
  );

  const hasMore = total > 1 && count < total;

  useEffect(() => {
    if (status === Status.Done) goBack();
  }, [goBack, status]);

  return (
    <>
      <Styled.Header>
        <Button
          color="inherit"
          onClick={goBack}
          startIcon="arrowBack"
          variant="outlined"
        >
          {t('Back')}
        </Button>

        <Styled.Title>{current?.name}</Styled.Title>

        {total > 1 && (
          <Button
            color="inherit"
            disabled={status !== Status.Idle || !hasMore || !hasQRCode}
            onClick={revokeAll}
            startIcon="qrRemove"
            variant="outlined"
          >
            {t('Revoke remaining QR codes')}
          </Button>
        )}
      </Styled.Header>

      <Column>{canvas}</Column>

      <Styled.Footer>
        {total > 1 && (
          <Styled.Progress>
            {t('Stamping document {{count}} of {{total}}', {
              count,
              total,
            })}
          </Styled.Progress>
        )}

        {total > 1 && count < total && (
          <Button color="inherit" onClick={next} variant="outlined">
            {t('Skip')}
          </Button>
        )}

        {(total > 1 || hasQRCode) && (
          <Button
            color="inherit"
            disabled={status !== Status.Idle || !hasQRCode}
            onClick={revokeOne}
            startIcon="qrRemove"
            variant="outlined"
          >
            {t('Revoke QR code')}
          </Button>
        )}

        <Button
          disabled={status !== Status.Idle}
          onClick={stampOne}
          startIcon="stamp"
          variant="contained"
        >
          {t(count >= total ? 'Save and close' : 'Save and next')}
        </Button>
      </Styled.Footer>
    </>
  );
};
