import React from 'react';

import { Loader } from '@pro4all/shared/ui/loader';

import * as Styled from './Backdrop.styles';

export const Backdrop = () => (
  <Styled.Backdrop>
    <Loader />
  </Styled.Backdrop>
);
