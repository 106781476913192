import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, FormControlLabel } from '@pro4all/shared/mui-wrappers';
import { ReportConfigTypes } from '@pro4all/shared/types';
import { Checkbox } from '@pro4all/shared/ui/inputs';

import { useReportOptionsContext } from '../../ReportOptionsProvider';

export const ConfigureShowLinkedSnags = ({
  elementIdentifier,
  template,
}: Pick<ReportConfigTypes, 'elementIdentifier' | 'template'>) => {
  const { t } = useTranslation();
  const {
    setTemplateOptions,
    state: { reportOptions },
  } = useReportOptionsContext();

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={
              template
                ? reportOptions?.templates?.[template.id]?.showLinkedSnags ??
                  true
                : true
            }
            onChange={() => {
              setTemplateOptions({
                id: template.id,
                options: {
                  ...reportOptions?.templates?.[template.id],
                  showLinkedSnags: reportOptions?.templates?.[template.id]
                    ? !reportOptions?.templates?.[template.id]?.showLinkedSnags
                    : false,
                },
              });
            }}
          />
        }
        id={elementIdentifier}
        label={t('Show linked snags')}
      />
    </Box>
  );
};
