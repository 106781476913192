import { Property } from '@pro4all/shared/ui/property-list';

import {
  NodeSelection,
  ViewerNode,
  ViewerPropertyGroup,
} from '../Viewer.types';

import { ForgeViewerExtension, ForgeViewerOptions } from './ForgeViewer.types';

export class ForgeViewer {
  public initalized = false;

  private endpoint: string;
  private container: HTMLElement;
  private options: ForgeViewerOptions;
  private viewer: Autodesk.Viewing.GuiViewer3D;
  private extensions: ForgeViewerExtension[] = [
    { name: 'Autodesk.AEC.Minimap3DExtension', options: {} },
    { name: 'Autodesk.DocumentBrowser', options: {} },
  ];

  constructor(
    options: ForgeViewerOptions,
    container: HTMLElement,
    endpoint: string
  ) {
    this.options = options;
    this.container = container;
    this.endpoint = endpoint;
    this.viewer = new Autodesk.Viewing.GuiViewer3D(this.container, {
      loaderExtensions: { svf: 'Autodesk.MemoryLimited' },
    });
    this.viewer.setTheme('light-theme');

    window.addEventListener('resize', () => {
      this.viewer.resize();
    });
  }

  registerSelectionEventHandler(callback: (nodes: NodeSelection[]) => void) {
    this.viewer.addEventListener(
      Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
      (onSelectProps: any) => {
        const nodeSelection: NodeSelection[] = [];
        for (const selection of onSelectProps.selections) {
          nodeSelection.push({
            ids: selection.nodeArray,
            model: selection.model,
          });
        }
        callback(nodeSelection);
      }
    );
  }

  init() {
    return new Promise((resolve, reject) => {
      Autodesk.Viewing.Initializer(this.options, () => {
        Autodesk.Viewing.endpoint.setEndpointAndApi(this.endpoint, '');

        this.extensions.forEach((extension) => {
          this.viewer.loadExtension(extension.name, extension.options);
        });

        const startedCode = this.viewer.start();
        if (startedCode > 0) {
          reject(`Initializing ForgeViewer failed: ${startedCode}`);
        } else {
          this.initalized = true;
          resolve(`Forge viewer initialized`);
        }
      });
    });
  }

  destroy() {
    Autodesk.Viewing.shutdown();
  }

  async loadModel(urn: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const onDocumentLoadSuccess = (
        viewerDocument: Autodesk.Viewing.Document
      ) => {
        const viewerGeometry = viewerDocument.getRoot().getDefaultGeometry();
        viewerDocument.downloadAecModelData();

        this.viewer
          .loadDocumentNode(viewerDocument, viewerGeometry, {
            globalOffset: { x: 0, y: 0, z: 0 },
            keepCurrentModels: true,
          })
          .then(() => {
            // We have to call these every time we load a model since
            // the viewer overrides the WebGL material color per model
            // which is only present when the model has loaded
            this.viewer.setBackgroundColor(242, 247, 249, 242, 247, 249);
            this.viewer.setEnvMapBackground(false);
            this.viewer.setSelectionColor(new THREE.Color(0x533be2), 1);

            resolve('Model loaded');
          })
          .catch((error) => {
            console.error(error);
          });
      };

      const onDocumentLoadFailure = (
        errCode: Autodesk.Viewing.ErrorCodes,
        errMsg: string
      ) => {
        console.error('Failed to load manifest [' + errCode + '] ' + errMsg);
        reject('Failed fetching Forge manifest');
      };

      Autodesk.Viewing.Document.load(
        `${urn}`,
        onDocumentLoadSuccess,
        onDocumentLoadFailure
      );
    });
  }

  resize() {
    this.viewer.resize();
  }

  getState() {
    return this.viewer.getState({
      guid: false,
      objectSet: true,
      overrides: false,
      renderOptions: false,
      seedURN: false,
      viewport: true,
    });
  }

  restoreState(state: string) {
    this.viewer.restoreState(state);
  }

  select(nodes: ViewerNode[]) {
    const nodeSelection = this.groupNodesByModel(nodes);
    for (const selection of nodeSelection) {
      this.viewer.select(selection.ids, selection.model);
    }
  }

  hide(nodes: ViewerNode[]) {
    const nodeSelection = this.groupNodesByModel(nodes);
    for (const selection of nodeSelection) {
      this.viewer.hide(selection.ids, selection.model);
    }
  }

  isolate(nodes: ViewerNode[]) {
    const nodeSelection = this.groupNodesByModel(nodes, true);
    for (const selection of nodeSelection) {
      selection.ids.length > 0
        ? this.viewer.isolate(selection.ids, selection.model)
        : this.viewer.hide(
            selection.model.getInstanceTree().getRootId(),
            selection.model
          );
    }
  }

  focus(nodes: ViewerNode[]) {
    const nodeSelection = this.groupNodesByModel(nodes);
    for (const selection of nodeSelection) {
      this.viewer.fitToView(selection.ids, selection.model);
    }
  }

  showAll() {
    this.viewer.showAll();
  }

  // Searches all visible models for a specific term in the name. We convert
  // the result to the generic ViewerNode interface
  async search(term: string, skip = 0, take = 0): Promise<ViewerNode[]> {
    const models = this.viewer.getVisibleModels();
    const searchNodes: ViewerNode[] = [];
    const promises = models.map(
      (model) =>
        new Promise<ViewerNode[]>((resolve, reject) => {
          model.search(
            term,
            (results) => {
              for (const id of results.slice(skip, take)) {
                searchNodes.push({
                  children: [],
                  id: id,
                  model: model,
                  name: model.getInstanceTree().getNodeName(id),
                  parentId: model.getInstanceTree().getNodeParentId(id),
                });
              }
              resolve(searchNodes);
            },
            reject
          );
        })
    );

    return new Promise((resolve) => {
      Promise.all(promises).then(() => {
        resolve(searchNodes);
      });
    });
  }

  async getNodeTrees(): Promise<ViewerNode[]> {
    return Promise.all(
      this.viewer.getVisibleModels().map((model: Autodesk.Viewing.Model) => {
        const node = this.getModelTreeNode(model);
        return node;
      })
    );
  }

  async getNode(id: any, model: Autodesk.Viewing.Model): Promise<any> {
    return new Promise((resolve) => {
      resolve({
        children: [],
        id: id,
        model: model,
        name: model.getInstanceTree().getNodeName(id),
        parentId: model.getInstanceTree().getNodeParentId(id),
      });
    });
  }

  async getNodeProperties(node: ViewerNode): Promise<ViewerPropertyGroup[]> {
    return new Promise((resolve) => {
      node.model.getProperties(
        node.id,
        (propertyResult: Autodesk.Viewing.PropertyResult) => {
          const propertyGroups =
            this.propertiesToPropertyGroups(propertyResult);
          resolve(propertyGroups);
        }
      );
    });
  }

  private propertiesToPropertyGroups(
    propertyResult: Autodesk.Viewing.PropertyResult,
    filterNonStandardTypes = true
  ): ViewerPropertyGroup[] {
    if (filterNonStandardTypes) {
      propertyResult.properties = propertyResult.properties.filter(
        (prop) =>
          !prop.displayCategory.startsWith('__') &&
          !prop.displayCategory.endsWith('__')
      );
    }

    const propertyGroups: ViewerPropertyGroup[] = [];

    propertyResult.properties.forEach((prop) => {
      const groupName = prop.displayCategory || 'General';
      const property: Property = {
        label: prop.displayName,
        value: prop.displayValue.toString(),
      };

      prop.units && (property.suffix = prop.units);

      const group = propertyGroups.find((group) => group.label === groupName);

      if (group) {
        group.properties.push(property);
      } else {
        propertyGroups.push({
          label: groupName,
          properties: [property],
        });
      }
    });

    return propertyGroups;
  }

  private groupNodesByModel(
    nodes: ViewerNode[],
    includeNonSelectedModels = false
  ): NodeSelection[] {
    const grouped: NodeSelection[] = [];

    // Group by model
    for (const node of nodes) {
      const group = grouped.find((group) => group.model.id === node.model.id);
      if (!group) {
        grouped.push({
          ids: [node.id],
          model: node.model,
        });
      } else {
        group.ids?.push(node.id);
      }
    }

    // Add models that are not represented, to do actions on them
    if (includeNonSelectedModels) {
      const models = this.viewer.getVisibleModels();
      for (const model of models) {
        const group = grouped.find((group) => group.model.id === model.id);
        if (!group) {
          grouped.push({
            ids: [],
            model: model,
          });
        }
      }
    }

    return grouped;
  }

  private async getModelTreeNode(model: Autodesk.Viewing.Model) {
    return new Promise<ViewerNode>((resolve, reject) => {
      const processChildNodes = (
        currentNode: ViewerNode,
        tree: Autodesk.Viewing.InstanceTree
      ) =>
        new Promise<void>((resolve) => {
          if (tree.getChildCount(currentNode.id) > 0) {
            const childIds: number[] = [];

            tree.enumNodeChildren(
              currentNode.id,
              (childId) => {
                childIds.push(childId);
              },
              false
            );

            for (const childId of childIds) {
              const childNode: ViewerNode = {
                children: [],
                id: childId,
                model: model,
                name: model.getInstanceTree().getNodeName(childId),
                parentId: currentNode.id,
              };

              currentNode.children.push(childNode);
              processChildNodes(childNode, tree).then(resolve);
            }
          }

          resolve();
        });

      // Get tree and rootNode id

      model.getObjectTree((tree) => {
        let rootId = tree.getRootId();

        // If IFC, skipt the root node since its a weird guid node
        // without any sensible info for the user
        if (model.getInstanceTree().getNodeName(rootId).endsWith('.ifc')) {
          rootId += 1;
        }

        // Create rootnode
        const rootNode: ViewerNode = {
          children: [],
          id: rootId,
          model: model,
          name: model.getInstanceTree().getNodeName(rootId),
          parentId: 0,
        };

        // Recursively loop over children
        processChildNodes(rootNode, tree).then(() => {
          resolve(rootNode);
        });
      }, reject);
    });
  }
}
