import { useTranslation } from 'react-i18next';

import { FieldDefinition, Scope, Template } from '@pro4all/graphql';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';

export const useColumnsAdditional = () => {
  const { t } = useTranslation();

  const columnsAdditional: ColumnProps<FieldDefinition | Template>[] = [
    {
      filterable: true,
      getValue: ({ scope }) => t<string>(`ScopeType.${scope?.type}`),
      headerComponent: (
        <FilterHeader<FieldDefinition, Scope>
          defaultWidth={100}
          filterType={FilterHeaderType.Select}
          label="Scope"
          minWidth={100}
          propertyId="scope"
          subPropertyId="type"
          translateOptions
        />
      ),
      key: 'scope.type',
      width: 100,
    },
  ];
  return columnsAdditional;
};
