import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useGetTbqConfigQuery } from '@pro4all/graphql';
import { Loader } from '@pro4all/shared/ui/loader';

import * as Messages from '../../../Messages';
import type { Fields } from '../useTBQSettings';
import { unflattenFields } from '../utils';

import { TBQField } from './TBQField';

export const TBQEntitySettings = () => {
  const { watch } = useFormContext<Fields>();
  const tbqTypeId = watch('tbqType')?.id ?? '';

  const { data, error, loading } = useGetTbqConfigQuery({
    skip: !tbqTypeId,
    variables: { tbqTypeId },
  });

  if (!data?.getTbqConfig && loading) return <Loader />;
  if (error) return <Messages.Error error={error} />;
  if (!data?.getTbqConfig?.length) return null;

  const fields = unflattenFields(data.getTbqConfig);

  return (
    <>
      {fields.map((field) => (
        <TBQField key={field.name} {...field} />
      ))}
    </>
  );
};
