import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { client } from '@pro4all/authentication/src/graph-ql';
import { ErrorCode } from '@pro4all/authentication/src/services/authenticated-api-service';
import {
  VisualContext,
  VisualContextsDocument,
  VisualContextStatus,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useQualityControlContext } from '@pro4all/quality-control/context';
import { DroppedDrawing } from '@pro4all/quality-control/data-access';
import { useObjectDetailContext } from '@pro4all/shared/contexts';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { StylingDefaults } from '@pro4all/shared/themes';
import { Button } from '@pro4all/shared/ui/buttons';
import { useFileUploadContext } from '@pro4all/shared/ui/file-upload';
import { Footer } from '@pro4all/shared/ui/footer';
import { IconName } from '@pro4all/shared/ui/icons';
import { Table, TableContextProvider } from '@pro4all/shared/ui/table';
import { TextProps } from '@pro4all/shared/ui/typography';

import { useDrawingUpload } from './useDrawingUpload';
import { useUploadModalColumns } from './useUploadModalColumns';

interface DrawingUploadModal {
  additionalActions?: JSX.Element;
  buttonColor?: string;
  buttonVariant?: 'text' | 'outlined' | 'contained';
  closeIcon?: IconName;
  closeLabel?: string;
  confirmIcon?: IconName;
  confirmLabel?: string;
  iconName?: IconName;
  name?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  open?: boolean;
  title: React.ReactNode;
  titleVariant?: TextProps['variant'];
  visualContexts: VisualContext[];
}

export const DrawingUploadModal: React.FC<DrawingUploadModal> = ({
  onClose,
  open = false,
  title,
  titleVariant = 'h2',
  visualContexts,
}) => {
  const { userDisplayName, userId } = useOrganizationContext();
  const { projectId: objectProjectId } = useObjectDetailContext();

  const { t } = useTranslation();

  const { cantUpload, cantUpdate, canUpdate, beCheck, canUpload } =
    useQualityControlContext();

  const customDataCallback = (fileDataResponse: any) => {
    if (
      fileDataResponse.data.errorCode ===
      ErrorCode.API_ERROR_FLOORPLAN_NAME_EXISTS
    ) {
      return {
        error: ErrorCode.API_ERROR_FLOORPLAN_NAME_EXISTS,
        fileName: fileDataResponse.file,
      };
    } else {
      const fileData = fileDataResponse.data.files
        ? fileDataResponse.data.files[0]
        : '';
      const { fileName, visualContextId } = fileData;
      return { fileName, visualContextId };
    }
  };

  const {
    filesUploadedUnsuccessfully,
    isUploading,
    filesProcessed,
    filesUploadedSuccessfully,
  } = useFileUploadContext(customDataCallback);

  const { params } = useRouting();
  const projectId = params.projectId ?? objectProjectId;

  const modalColumns = useUploadModalColumns();

  const uploadFiles = useDrawingUpload(projectId);

  const uploadOrUpdateDrawings = () => {
    if (beCheck.length > 0 || canUpdate.length > 0 || canUpload.length > 0) {
      const allDrawings = beCheck.concat(canUpdate).concat(canUpload);

      uploadFiles(allDrawings);
    }
  };

  const addNewOptimisticDrawings = useCallback(() => {
    const newVisualContexts: VisualContext[] = [];
    const visualContextIds = visualContexts.map(
      (visualContext) => visualContext.id
    );

    filesUploadedSuccessfully.forEach((file) => {
      const {
        customData: { fileName, visualContextId },
      } = file;

      if (!visualContextIds.includes(visualContextId)) {
        newVisualContexts.push({
          __typename: 'VisualContext',
          createdAt: Date.now(),
          createdBy: {
            __typename: 'User',
            displayName: userDisplayName,
            id: userId,
          },
          formCount: 0,
          freeSnagCount: 0,
          height: 0,
          id: visualContextId,
          name: fileName,
          pageCount: 0,
          snagCount: 0,
          status: VisualContextStatus.Processing,
          width: 0,
          zoomLevels: 3,
        });
      }
    });

    return newVisualContexts;
  }, [filesUploadedSuccessfully, userDisplayName, userId, visualContexts]);

  useEffect(() => {
    if (
      filesUploadedSuccessfully.length &&
      (canUpload.length || beCheck.length)
    ) {
      const newVisualContexts = addNewOptimisticDrawings();

      if (newVisualContexts.length > 0) {
        client.writeQuery({
          data: {
            visualContexts: [...newVisualContexts, ...visualContexts],
          },
          query: VisualContextsDocument,
          variables: { projectId },
        });
      }
    }
  }, [
    addNewOptimisticDrawings,
    beCheck.length,
    canUpload.length,
    filesUploadedSuccessfully,
    projectId,
    userDisplayName,
    userId,
    visualContexts,
  ]);

  const assignKeys = (fps: DroppedDrawing[], startFrom: number) => {
    fps.map((fp, index) => {
      fp.id = (index + startFrom).toString();
      return fp;
    });
    return fps;
  };

  const returnDrawingTable = (tableId: string, items: DroppedDrawing[]) => (
    <TableWrapper>
      <TableContextProvider
        checkable={false}
        columns={modalColumns}
        id={tableId}
        items={items}
      >
        <Table<DroppedDrawing> />
      </TableContextProvider>
    </TableWrapper>
  );

  return (
    <DialogContainer onClose={onClose} open={open} scroll="paper">
      <DialogTitle>{title}</DialogTitle>
      <DialogContents style={{ overflowX: 'hidden' }}>
        <Box paddingTop={1}>
          <>
            {cantUpload.length > 0 && (
              <>
                <DialogSubHeader>{t("Can't upload")}</DialogSubHeader>
                <p>
                  {t(
                    "You don't have the permissions to upload the following drawings:"
                  )}
                </p>
                <ul>
                  {cantUpload.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </>
            )}
            {cantUpdate.length > 0 && (
              <>
                <DialogSubHeader>{t("Can't update")}</DialogSubHeader>
                <p>
                  {t(
                    "You don't have the permissions to update the following drawings:"
                  )}
                </p>
                <ul>
                  {cantUpdate.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </>
            )}

            {beCheck.length > 0 && (
              <>
                <DialogSubHeader>{t('Validation required')}</DialogSubHeader>
                <p>
                  {t(
                    'You have the permissions to upload new drawings, but you can only view the ones you created or were assigned to you through a task. So we need to validate that the names of the drawings you want to upload are not being used already.'
                  )}
                </p>
                {returnDrawingTable(
                  'update-drawings-table',
                  assignKeys(beCheck, 0)
                )}
              </>
            )}

            {canUpdate.length > 0 && (
              <>
                <DialogSubHeader>{t('Update')}</DialogSubHeader>
                <p>{t('The following drawings will be updated')}</p>
                {returnDrawingTable(
                  'update-drawings-table',
                  assignKeys(canUpdate, beCheck.length)
                )}
              </>
            )}

            {canUpload.length > 0 && (
              <>
                <DialogSubHeader>{t('Upload')}</DialogSubHeader>
                <p>{t('The following drawings will be uploaded')}</p>
                {returnDrawingTable(
                  'upload-drawings-table',
                  assignKeys(canUpload, beCheck.length + canUpdate.length)
                )}
              </>
            )}
          </>
        </Box>
      </DialogContents>
      <DialogActions>
        <Footer pb={3} pt={2} px={3}>
          <Button color="inherit" onClick={onClose}>
            {t('Close')}
          </Button>

          {(canUpdate.length > 0 ||
            canUpload.length > 0 ||
            beCheck.length > 0) &&
            !filesProcessed.length && (
              <Button
                disabled={isUploading || filesUploadedUnsuccessfully.length > 0}
                onClick={uploadOrUpdateDrawings}
                startIcon="save"
                type="submit"
                variant="contained"
              >
                {t(
                  ` ${
                    canUpload.length === 0 && beCheck.length === 0
                      ? 'Update'
                      : 'Upload'
                  } drawing(s)`
                )}
              </Button>
            )}
        </Footer>
      </DialogActions>
    </DialogContainer>
  );
};

const DialogContents = styled(DialogContent)`
  && {
    overflow-x: hidden;
  }
`;

const DialogContainer = styled(Dialog)`
  && {
    .MuiPaper-root {
      padding: ${({ theme }) => theme.spacing(4)};
      border-radius: ${StylingDefaults.borderRadius};
      box-shadow: ${StylingDefaults.boxShadow};
      min-width: 10vw;
      max-width: 40vw;
      min-height: 10vh;
      max-height: 90vh;
    }

    .MuiBackdrop-root {
      background-color: ${StylingDefaults.backdropColor};
    }
  }
`;

const DialogSubHeader = styled.h3`
  margin-top: ${({ theme }) => theme.spacing(6)};
`;

const TableWrapper = styled(Box)`
  && {
    min-height: 100px;
    min-width: 200px;
    height: 140px;
    width: 500px;
  }
`;
