import React from 'react';

import {
  Box,
  StandardTextFieldProps,
  TextField as MuiTextField,
} from '@pro4all/shared/mui-wrappers';

interface TextFieldProps extends Omit<StandardTextFieldProps, 'variant'> {
  fieldsToSync?: string[];
  name: string | undefined;
}

export const _TextField: React.FC<TextFieldProps> = React.forwardRef(
  ({ InputProps, InputLabelProps, ...rest }, ref) => (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', width: '100%' }}>
        <MuiTextField
          {...rest}
          InputLabelProps={{ ...InputLabelProps, shrink: true }}
          InputProps={InputProps}
          autoComplete="off"
          fullWidth
          id={rest.name}
          ref={ref}
          variant="outlined"
        />
      </Box>
    </Box>
  )
);
