import { useAutoFocus } from '@pro4all/shared/hooks';
import { TextField } from '@pro4all/shared/ui/inputs';
import { TagList } from '@pro4all/shared/ui/tag';

import * as Styled from './FilterSelect.styles';
import { Footer } from './Footer';
import * as StyledGeneric from './Styles';
import { FilterComponentTypeProps } from './types';
import { useOptionRendering } from './useOptionRendering';
import { useSearchValue } from './useSearchValue';
import { useSelectedOptions } from './useSelectedOptions';

export const FilterSelect = ({
  columnValues,
  getOptionCount,
  getOptions,
  onReset,
  onSet,
  renderOption,
}: FilterComponentTypeProps) => {
  const { renderOptions, selectedOptions, setUnselectedOptions, tags } =
    useSelectedOptions({
      columnValues,
      getOptionCount,
      getOptions,
      onSet,
      renderOption,
    });

  const {
    endAdornment,
    onSearchValueChange,
    onSearchValueKeyDown,
    searchValue,
    startAdornment,
  } = useSearchValue({
    columnValues,
    selectedOptions,
    setUnselectedOptions,
  });

  const { selectedOptionsRendering } = useOptionRendering();

  const inputRef = useAutoFocus();

  return (
    <>
      <Styled.InputWrap>
        <TextField
          InputProps={
            searchValue
              ? {
                  endAdornment,
                  startAdornment,
                }
              : {
                  startAdornment,
                }
          }
          inputRef={inputRef}
          name="search-input"
          onChange={onSearchValueChange}
          onKeyDown={onSearchValueKeyDown}
          value={searchValue}
        />
      </Styled.InputWrap>
      <Styled.TagListWrap>
        {renderOption ? (
          selectedOptionsRendering({ renderOption, tags })
        ) : (
          <TagList enableTooltip maxNameLength={24} tags={tags} />
        )}
      </Styled.TagListWrap>
      <StyledGeneric.ContentWrap $maxRows={10}>
        {renderOptions()}
      </StyledGeneric.ContentWrap>
      <Footer disableOnReset={Boolean(!tags.length)} onReset={onReset} />
    </>
  );
};
