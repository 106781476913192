import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  DomainStatusDocument,
  DomainToClaim,
  useAddDomainClaimMutation,
  ValueTypeName,
} from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikForm, FormikInput } from '@pro4all/shared/ui/formik';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

type OrganizationDomainsFormProps = {
  domainsToClaim: DomainToClaim[];
  existingDomains: string[];
  organizationId: string;
};

const useSubmit = (domainsToClaim: DomainToClaim[], organizationId: string) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [addDomainClaim] = useAddDomainClaimMutation();

  return async (
    values: { domain: string },
    { resetForm }: { resetForm: () => void }
  ) => {
    try {
      const { domain } = values;

      const response = await addDomainClaim({
        variables: {
          domain,
        },
      });

      enqueueSnackbar(t('Domain has been successfully added'), {
        variant: 'success',
      });

      client.writeQuery({
        data: {
          domainStatus: [
            ...domainsToClaim,
            {
              __typeName: 'DomainToClaim',
              dnsValue: response?.data?.addDomainClaim?.dnsValue ?? '',
              domain,
              domainClaimId: '',
              organizationId,
              status: 0,
              token: '',
            },
          ],
        },
        query: DomainStatusDocument,
        variables: { organizationId },
      });

      resetForm();
    } catch (e) {
      enqueueSnackbar(t('Something went wrong'));
    }
  };
};

export const OrganizationDomainsForm = ({
  domainsToClaim,
  existingDomains,
  organizationId,
}: OrganizationDomainsFormProps) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Domain',
      matches: /^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/,
      max: 63,
      min: 5,
      name: 'domain',
      required: true,
      type: ValueTypeName.Text,
    },
  ];

  const { getField } = useFormConfig({ formFields });

  const validationSchema = Yup.object().shape({
    domain: Yup.string()
      .matches(
        /^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/,
        'Invalid domain format'
      )
      .min(5, 'Domain must be at least 5 characters')
      .max(63, 'Domain must be at most 63 characters')
      .required('Domain is required')
      .test(
        'unique-domain',
        'This domain already exists',
        (value) => !existingDomains.includes(value ?? '')
      ),
  });

  const domainField = getField('domain');

  const onSubmit = useSubmit(domainsToClaim, organizationId);

  return (
    <Formik
      enableReinitialize
      initialValues={{ domain: '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting, resetForm, setFieldValue }) => (
        <FormikForm>
          <FormWrapper noPadding>
            <FormikInput
              data-testid="domain"
              inputRef={inputRef}
              label={domainField?.label}
              name={domainField?.name}
            />
          </FormWrapper>
          <FormFooter
            disableSubmit={isSubmitDisabled({ dirty, errors, isSubmitting })}
            onClose={() => {
              resetForm();
              setFieldValue('domain', '');
            }}
            pb={3}
            pt={2}
            px={3}
            showCancel
            sticky
            submitLabel={t('Add domain')}
          />
        </FormikForm>
      )}
    </Formik>
  );
};
