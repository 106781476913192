import React from 'react';
import { useTranslation } from 'react-i18next';

import { DroppedDrawing } from '@pro4all/quality-control/data-access';
import { ColumnProps } from '@pro4all/shared/ui/table';

import { DrawingRemoveAndUploadStatusCell } from './DrawingRemoveAndUploadStatusCell';
import { DrawingUploadStatusTextCell } from './DrawingUploadStatusTextCell';

export const useUploadModalColumns = () => {
  const { t } = useTranslation();

  const modalColumns: ColumnProps<DroppedDrawing>[] = [
    {
      disableSort: true,
      filterable: true,
      flexGrow: 0,
      key: 'drawing-remove-and-upload-status',
      render: ({ id }) => (
        <DrawingRemoveAndUploadStatusCell droppedDrawingId={id} />
      ),
      width: 55,
    },
    {
      disableSort: true,
      filterable: false,
      flexGrow: 0,
      key: 'name',
      label: t('Name'),
      minWidth: 125,
      render: ({ name }) => <p>{name}</p>,
    },
    {
      disableSort: true,
      filterable: false,
      flexGrow: 0,
      key: 'status',
      label: t('Status'),
      minWidth: 275,
      render: ({ id }) => <DrawingUploadStatusTextCell droppedDrawingId={id} />,
    },
  ];

  return modalColumns;
};
