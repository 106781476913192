import React from 'react';
import { useTranslation } from 'react-i18next';

import { stringToPlainText } from '@pro4all/communication/ui/general';
import { Attachment, Message, ReferenceKind, User } from '@pro4all/graphql';
import { RenderOptionType } from '@pro4all/shared/types';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { Ellipsis, MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';
import { ColumnProps, StyledCellContent } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';
import { getFormattedDate, Timestamp } from '@pro4all/shared/ui/timestamp';

import { ReferenceTag } from '../tags/ReferenceTag';
import { AttachmentsTooltip } from '../tags/TagWithCount';

const getSubject = ({
  noSubjectText,
  subject,
}: {
  noSubjectText: string;
  subject: string;
}) => subject || `(${noSubjectText.toLowerCase()})`;

const getMessage = (body: string) => stringToPlainText(body || '');

const getEmailAddresses = ({
  referenceKind,
  references,
}: {
  referenceKind: ReferenceKind;
  references: Message['references'];
}) => {
  const referencesKind = references.filter(
    (reference) => reference.referenceKind === referenceKind
  );
  return referencesKind.map((reference) => reference.referenceData).join(', ');
};

const renderEmailAddresses = ({
  referenceKind,
  references,
}: {
  referenceKind: ReferenceKind;
  references: Message['references'];
}) => (
  <StyledCellContent active>
    <ReferenceTag referenceKind={referenceKind} references={references} />
  </StyledCellContent>
);

export const useColumns = (): ColumnProps<Message>[] => {
  const { t } = useTranslation();

  const getReadValue = (read: boolean): string =>
    read ? t('Read') : t('Unread');
  return [
    {
      filterable: true,
      getValue: ({ subject }) =>
        getSubject({
          noSubjectText: t('No subject'),
          subject: subject || '',
        }),
      headerComponent: (
        <FilterHeader<Message, {}>
          defaultWidth={100}
          filterType={FilterHeaderType.Text}
          label="Subject" // i18n
          minWidth={100}
          propertyId="subject"
        />
      ),
      key: 'subject',
      render: ({ subject }: Message) => (
        <StyledCellContent active>
          <MiddleEllipsis
            endLength={9}
            text={getSubject({
              noSubjectText: t('No subject'),
              subject: subject || '',
            })}
          />
        </StyledCellContent>
      ),
      width: 100,
    },
    {
      filterable: true,
      getValue: ({ body }) => getMessage(body || ''),
      headerComponent: (
        <FilterHeader<Message, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Text}
          getCustomValueCallback={(body) => getMessage(body || '')}
          label="Message" // i18n
          minWidth={100}
          propertyId="body"
          pullFromLocalStorage
        />
      ),
      key: 'body',
      render: ({ body }: Message) => {
        const text = getMessage(body || '');
        return (
          <StyledCellContent active>
            <Ellipsis width="800px">{text}</Ellipsis>
          </StyledCellContent>
        );
      },
      width: 200,
    },
    {
      filterable: true,
      getValue: ({ read }) => getReadValue(read as boolean),
      headerComponent: (
        <FilterHeader<Message, {}>
          defaultWidth={100}
          filterType={FilterHeaderType.Select}
          getCustomValueCallback={(read) => getReadValue(read)}
          label="Read/unread" // i18n
          minWidth={100}
          propertyId="read"
          translateOptions
        />
      ),
      key: 'read',
      render: ({ read }: Message) => (
        <StyledCellContent active>
          {getReadValue(read as boolean)}
        </StyledCellContent>
      ),
      width: 100,
    },
    {
      filterable: true,
      getValue: ({ createdBy }) => createdBy.displayName,
      headerComponent: (
        <FilterHeader<Message, User>
          defaultWidth={100}
          filterType={FilterHeaderType.Select}
          label="Sent by" // i18n
          minWidth={100}
          propertyId="createdBy"
          renderOption={RenderOptionType.UserCard}
          subPropertyId="displayName"
        />
      ),
      key: 'createdBy.displayName',
      render: ({ createdBy }: Message) => (
        <StyledCellContent active>
          <UserTag user={createdBy} />
        </StyledCellContent>
      ),
      width: 100,
    },
    {
      filterable: true,
      getValue: ({ createdAt }) =>
        createdAt ? getFormattedDate(createdAt, 'L HH:mm').label : '',
      headerComponent: (
        <FilterHeader<Message, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="Sent on" // i18n
          minWidth={100}
          propertyId="createdAt"
        />
      ),
      key: 'createdAt',
      render: ({ createdAt }: Message) => (
        <StyledCellContent active>
          <Timestamp date={createdAt} format="L HH:mm" />
        </StyledCellContent>
      ),
      width: 200,
    },
    {
      filterable: true,
      getValue: ({ attachments }) =>
        attachments?.length ? attachments.length : '',
      headerComponent: (
        <FilterHeader<Message, Attachment>
          defaultWidth={100}
          filterType={FilterHeaderType.Select}
          getCustomValueCallback={(attachments: Attachment[]) =>
            t(attachments?.length ? 'Attachments' : 'No attachments')
          }
          label="Attachments" // i18n
          minWidth={100}
          propertyId="attachments"
          translateOptions
        />
      ),
      key: 'attachments',
      render: ({ attachments }: Message) => (
        <StyledCellContent active>
          <AttachmentsTooltip attachments={attachments} />
        </StyledCellContent>
      ),
      width: 100,
    },
    {
      filterable: true,
      getValue: ({ references }) =>
        getEmailAddresses({ referenceKind: ReferenceKind.To, references }),
      headerComponent: (
        <FilterHeader<Message, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Select}
          flattenOptions
          isMultiSelect
          label="Messages.to" // i18n
          minWidth={100}
          propertyId="to"
          renderOption={RenderOptionType.UserCard}
        />
      ),
      key: 'to',
      render: ({ references }: Message) =>
        renderEmailAddresses({
          referenceKind: ReferenceKind.To,
          references,
        }),
      width: 200,
    },
    {
      filterable: true,
      getValue: ({ references }) =>
        getEmailAddresses({ referenceKind: ReferenceKind.Cc, references }),
      headerComponent: (
        <FilterHeader<Message, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Select}
          flattenOptions
          label="CC"
          minWidth={100}
          propertyId="cc"
          renderOption={RenderOptionType.UserCard}
        />
      ),
      key: 'cc',
      render: ({ references }: Message) =>
        renderEmailAddresses({ referenceKind: ReferenceKind.Cc, references }),
      width: 200,
    },
    {
      filterable: true,
      getValue: ({ references }) =>
        getEmailAddresses({ referenceKind: ReferenceKind.Bcc, references }),
      headerComponent: (
        <FilterHeader<Message, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Select}
          flattenOptions
          label="BCC"
          minWidth={100}
          propertyId="bcc"
          renderOption={RenderOptionType.UserCard}
        />
      ),
      key: 'bcc',
      render: ({ references }: Message) =>
        renderEmailAddresses({ referenceKind: ReferenceKind.Bcc, references }),
      width: 200,
    },
  ];
};
