import { useTranslation } from 'react-i18next';
import Styled from 'styled-components';

import { DocumentTable } from '@pro4all/documents/ui/table';
import { Document } from '@pro4all/graphql';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { SearchableSelectProps } from '@pro4all/shared/ui/inputs';

const StyledDiv = Styled.div`
    height: 100%;
`;

export type DocumentSelectProps = Pick<SearchableSelectProps, 'onChange'> & {
  closeModalCallback: () => void;
  documentsToExclude: string[];
  handleSelectedDocumentCallback: (document: Document) => void;
};

export const DocumentSelect = ({
  closeModalCallback,
  documentsToExclude,
  handleSelectedDocumentCallback,
}: DocumentSelectProps) => {
  const { t } = useTranslation();

  return (
    <FloatingModal maxWidth="xl" open>
      <FloatingModal.Header
        closeModalCallback={closeModalCallback}
        iconName="document"
      >
        {t('Select a document')}
      </FloatingModal.Header>
      <StyledDiv>
        <DocumentTable
          canDropFiles={false}
          disableDoubleRowClick
          documentsToExclude={documentsToExclude}
          handleSelectedDocumentCallback={handleSelectedDocumentCallback}
          showApolloCacheData
          showContextMenu={false}
          showDocumentSelectCheckboxes={false}
          showTableActions={false}
        />
      </StyledDiv>
    </FloatingModal>
  );
};
