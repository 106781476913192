import {
  EntityType,
  QcPermissionCategory,
  useGetProjectPermissionsQuery,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import {
  ProjectAccessType,
  useProjectContext,
} from '@pro4all/projects/ui/context';
import { useObjectDetailContext } from '@pro4all/shared/contexts';
import { getHighestPermissions } from '@pro4all/shared/permissions';
import { useRouting } from '@pro4all/shared/routing-utils';

import { QcAndTaskPermissionsType } from '../types';
import {
  convertPermissions,
  setPermissionsToAll,
} from '../utils/convertPermissions';
interface Props {
  category: QcPermissionCategory;
  returnDirectPermissions?: boolean;
  taskProjectId?: string;
}

//Use this hook to check a users permissions, except when you want to check 'assigned' or 'ownAssigned' permissions.
// In that case use the useFetchResourcePermissions.tsx hook
export const useQCPermissions = ({
  category,
  taskProjectId,
  returnDirectPermissions = false,
}: Props): QcAndTaskPermissionsType => {
  const { userId } = useOrganizationContext();
  const { projectAccessLoadStatus } = useProjectContext();

  const permissionStatus = projectAccessLoadStatus?.status;

  const isOrgOrProjAdmin = permissionStatus === ProjectAccessType.ProjectUpdate;

  const {
    params: { projectId: routingProjectId },
  } = useRouting();
  const { projectId: objectProjectId } = useObjectDetailContext();
  const projectId = routingProjectId || objectProjectId || taskProjectId;

  const { data } = useGetProjectPermissionsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !projectId || !userId,
    variables: {
      category,
      memberId: userId ?? '',
      memberType: EntityType.User,
      projectId: projectId ?? '',
    },
  });

  const highestPermissions = getHighestPermissions(data?.getProjectPermissions);

  const permissionStrings = returnDirectPermissions
    ? data?.getProjectPermissions?.directPermissions
    : highestPermissions;

  let permissionsObj = convertPermissions(permissionStrings);

  //Admin overwrite
  if (isOrgOrProjAdmin) {
    permissionsObj = setPermissionsToAll(permissionsObj);
  }

  return permissionsObj;
};
