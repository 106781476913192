import { useFormikContext } from 'formik';

export function useDelayedFormikUpdate<Values, Name extends string = string>(
  name: Name
) {
  const { setFieldTouched, setFieldValue, getFieldMeta } =
    useFormikContext<Values>();
  const currentValue = getFieldMeta<Extract<Values, Name>>(name).value;
  const setNextValue = (value: Extract<Values, Name>) => {
    setTimeout(() => {
      setFieldValue(name, value);
      setFieldTouched(name);
    }, 0);
  };

  return { currentValue, setNextValue };
}
