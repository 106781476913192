import React from 'react';

export const TBQLinks = ({ name }: { name: string }) => {
  const articles: string[] = [
    '/hfd1/afd1.1/art1.1',
    '/hfd1/afd1.1/art1.1a',
    '/hfd1/afd1.2/art1.2',
    '/hfd2/afd2.1/art2.1',
    '/hfd2/afd2.1/art2.2',
    '/hfd2/afd2.1/art2.3',
    '/hfd2/afd2.1/art2.4',
    '/hfd2/afd2.1/art2.5',
    '/hfd2/afd2.1/art2.6',
    '/hfd2/afd2.1/art2.7',
    '/hfd2/afd2.1/art2.8',
    '/hfd2/afd2.1/art2.9',
    '/hfd2/afd2.1/art2.10',
    '/hfd2/afd2.1/art2.10a',
    '/hfd2/afd2.1/art2.11',
    '/hfd2/afd2.1/art2.12',
    '/hfd2/afd2.2/art2.13',
    '/hfd2/afd2.2/art2.14',
    '/hfd2/afd2.2/art2.15',
    '/hfd2/afd2.2/art2.15a',
    '/hfd2/afd2.2a/art2.16',
    '/hfd2/afd2.2a/art2.17',
    '/hfd2/afd2.2a/art2.18',
    '/hfd2/afd2.2a/art2.19',
    '/hfd2/afd2.2a/art2.20',
    '/hfd2/afd2.2a/art2.21',
    '/hfd2/afd2.3/par2.3.1/art2.22',
    '/hfd2/afd2.3/par2.3.1/art2.23',
    '/hfd2/afd2.3/par2.3.1/art2.24',
    '/hfd2/afd2.3/par2.3.2/art2.25',
    '/hfd2/afd2.3/par2.3.2/art2.26',
    '/hfd2/afd2.3/par2.3.2/art2.27',
    '/hfd2/afd2.3/par2.3.2/art2.28',
    '/hfd2/afd2.3/par2.3.2/art2.29',
    '/hfd2/afd2.3/par2.3.2/art2.30',
    '/hfd2/afd2.4/art2.31',
    '/hfd3/afd3.1/art3.1',
    '/hfd3/afd3.1/art3.2',
    '/hfd3/afd3.1/art3.3',
    '/hfd3/afd3.1/art3.4',
    '/hfd3/afd3.1/art3.5',
    '/hfd3/afd3.1/art3.6',
    '/hfd3/afd3.1/art3.6a',
    '/hfd3/afd3.1/art3.7',
    '/hfd3/afd3.2/par3.2.1/art3.8',
    '/hfd3/afd3.2/par3.2.1/art3.9',
    '/hfd3/afd3.2/par3.2.1/art3.10',
    '/hfd3/afd3.2/par3.2.2/art3.11',
    '/hfd3/afd3.2/par3.2.2/art3.12',
    '/hfd3/afd3.2/par3.2.2/art3.13',
    '/hfd3/afd3.2/par3.2.3/art3.14',
    '/hfd3/afd3.2/par3.2.3/art3.15',
    '/hfd3/afd3.2/par3.2.3/art3.16',
    '/hfd3/afd3.2/par3.2.3/art3.17',
    '/hfd3/afd3.2/par3.2.4/art3.18',
    '/hfd3/afd3.2/par3.2.4/art3.19',
    '/hfd3/afd3.2/par3.2.4/art3.20',
    '/hfd3/afd3.2/par3.2.4/art3.21',
    '/hfd3/afd3.2/par3.2.4/art3.22',
    '/hfd3/afd3.2/par3.2.4/art3.23',
    '/hfd3/afd3.2/par3.2.4/art3.24',
    '/hfd3/afd3.2/par3.2.5/art3.25',
    '/hfd3/afd3.2/par3.2.5/art3.26',
    '/hfd3/afd3.2/par3.2.6/art3.27',
    '/hfd3/afd3.2/par3.2.6/art3.28',
    '/hfd3/afd3.2/par3.2.6/art3.29',
    '/hfd3/afd3.2/par3.2.7/art3.30',
    '/hfd3/afd3.2/par3.2.7/art3.31',
    '/hfd3/afd3.2/par3.2.7/art3.32',
    '/hfd3/afd3.2/par3.2.7/art3.33',
    '/hfd3/afd3.2/par3.2.7/art3.34',
    '/hfd3/afd3.2/par3.2.7/art3.35',
    '/hfd3/afd3.2/par3.2.8/art3.36',
    '/hfd3/afd3.2/par3.2.8/art3.37',
    '/hfd3/afd3.2/par3.2.8/art3.38',
    '/hfd3/afd3.2/par3.2.8/art3.39',
    '/hfd3/afd3.2/par3.2.8/art3.40',
    '/hfd3/afd3.2/par3.2.8/art3.41',
    '/hfd3/afd3.2/par3.2.9/art3.42',
    '/hfd3/afd3.2/par3.2.9/art3.43',
    '/hfd3/afd3.2/par3.2.9/art3.44',
    '/hfd3/afd3.2/par3.2.9/art3.45',
    '/hfd3/afd3.2/par3.2.9/art3.46',
    '/hfd3/afd3.2/par3.2.9/art3.47',
    '/hfd3/afd3.2/par3.2.10/art3.48',
    '/hfd3/afd3.2/par3.2.10/art3.49',
    '/hfd3/afd3.2/par3.2.10/art3.50',
    '/hfd3/afd3.2/par3.2.10/art3.51',
    '/hfd3/afd3.2/par3.2.10/art3.52',
    '/hfd3/afd3.2/par3.2.10/art3.53',
    '/hfd3/afd3.2/par3.2.10/art3.54',
    '/hfd3/afd3.2/par3.2.11/art3.55',
    '/hfd3/afd3.2/par3.2.11/art3.56',
    '/hfd3/afd3.2/par3.2.11/art3.57',
    '/hfd3/afd3.2/par3.2.11/art3.58',
    '/hfd3/afd3.2/par3.2.11/art3.59',
    '/hfd3/afd3.2/par3.2.11/art3.60',
    '/hfd3/afd3.2/par3.2.12/art3.61',
    '/hfd3/afd3.2/par3.2.12/art3.62',
    '/hfd3/afd3.3/par3.3.1/art3.63',
    '/hfd3/afd3.3/par3.3.1/art3.64',
    '/hfd3/afd3.3/par3.3.1/art3.65',
    '/hfd3/afd3.3/par3.3.2/art3.66',
    '/hfd3/afd3.3/par3.3.2/art3.67',
    '/hfd3/afd3.3/par3.3.2/art3.68',
    '/hfd3/afd3.3/par3.3.2/art3.69',
    '/hfd3/afd3.3/par3.3.2/art3.70',
    '/hfd3/afd3.3/par3.3.2/art3.71',
    '/hfd3/afd3.3/par3.3.3/art3.72',
    '/hfd3/afd3.3/par3.3.3/art3.73',
    '/hfd3/afd3.3/par3.3.4/art3.74',
    '/hfd3/afd3.3/par3.3.4/art3.75',
    '/hfd3/afd3.3/par3.3.4/art3.76',
    '/hfd3/afd3.3/par3.3.4/art3.77',
    '/hfd3/afd3.3/par3.3.4/art3.78',
    '/hfd3/afd3.3/par3.3.5/art3.79',
    '/hfd3/afd3.3/par3.3.5/art3.80',
    '/hfd3/afd3.3/par3.3.6/art3.81',
    '/hfd3/afd3.3/par3.3.6/art3.82',
    '/hfd3/afd3.4/par-3.4.1/art3.83',
    '/hfd3/afd3.4/par-3.4.1/art3.84',
    '/hfd3/afd3.4/par-3.4.1/art3.84a',
    '/hfd3/afd3.4/par-3.4.1/art3.84b',
    '/hfd3/afd3.4/par-3.4.1/art3.85',
    '/hfd3/afd3.4/par-3.4.1/art3.86',
    '/hfd3/afd3.4/par-3.4.1/art3.87',
    '/hfd3/afd3.4/par-3.4.1/art3.87a',
    '/hfd3/afd3.4/par-3.4.2/art3.87aa',
    '/hfd3/afd3.4/par-3.4.2/art3.87b',
    '/hfd3/afd3.4/par-3.4.2/art3.87c',
    '/hfd3/afd3.5/par3.5.1/art3.88',
    '/hfd3/afd3.5/par3.5.1/art3.89',
    '/hfd3/afd3.5/par3.5.1/art3.90',
    '/hfd3/afd3.5/par3.5.2/art3.91',
    '/hfd3/afd3.5/par3.5.2/art3.92',
    '/hfd3/afd3.5/par3.5.2/art3.93',
    '/hfd3/afd3.5/par3.5.3/art3.94',
    '/hfd3/afd3.5/par3.5.3/art3.95',
    '/hfd3/afd3.5/par3.5.3/art3.96',
    '/hfd3/afd3.6/art3.97',
    '/hfd3/afd3.6/art3.98',
    '/hfd3/afd3.6/art3.98a',
    '/hfd3/afd3.7/par3.7.1/art3.99',
    '/hfd3/afd3.7/par3.7.1/art3.100',
    '/hfd3/afd3.7/par3.7.1/art3.101',
    '/hfd3/afd3.7/par3.7.1/art3.102',
    '/hfd3/afd3.7/par3.7.1/art3.103',
    '/hfd3/afd3.7/par3.7.1/art3.104',
    '/hfd3/afd3.7/par3.7.2/art3.105',
    '/hfd3/afd3.7/par3.7.2/art3.106',
    '/hfd3/afd3.7/par3.7.2/art3.107',
    '/hfd3/afd3.7/par3.7.3/art3.108',
    '/hfd3/afd3.7/par3.7.3/art3.109',
    '/hfd3/afd3.7/par3.7.3/artikel-3.109a',
    '/hfd3/afd3.7/par3.7.3/art3.110',
    '/hfd3/afd3.7/par3.7.4/art3.111',
    '/hfd3/afd3.7/par3.7.4/art3.112',
    '/hfd3/afd3.7/par3.7.4/art3.113',
    '/hfd3/afd3.7/par3.7.5/art3.114',
    '/hfd3/afd3.7/par3.7.5/art3.115',
    '/hfd3/afd3.7/par3.7.5/art3.116',
    '/hfd3/afd3.7/par3.7.5/art3.117',
    '/hfd3/afd3.7/par3.7.5/art3.117a',
    '/hfd3/afd3.7/par3.7.6/art3.118',
    '/hfd3/afd3.7/par3.7.6/art3.119',
    '/hfd3/afd3.7/par3.7.6/art3.120',
    '/hfd3/afd3.7/par3.7.6/art3.121',
    '/hfd3/afd3.7/par3.7.6/art3.122',
    '/hfd3/afd3.7/par3.7.6/art3.123',
    '/hfd3/afd3.7/par3.7.7/art3.124',
    '/hfd3/afd3.7/par3.7.7/art3.125',
    '/hfd3/afd3.7/par3.7.7/art3.126',
    '/hfd3/afd3.7/par3.7.7/art3.127',
    '/hfd3/afd3.7/par3.7.8/art3.128',
    '/hfd3/afd3.7/par3.7.8/art3.129',
    '/hfd3/afd3.7/par3.7.8/art3.130',
    '/hfd3/afd3.7/par3.7.8/art3.131',
    '/hfd3/afd3.7/par3.7.8/art3.132',
    '/hfd3/afd3.7/par3.7.9/art3.133',
    '/hfd3/afd3.7/par3.7.9/art3.134',
    '/hfd3/afd3.7/par3.7.9/art3.135',
    '/hfd3/afd3.7/par3.7.9/art3.136',
    '/hfd3/afd3.7/par3.7.9/art3.137',
    '/hfd3/afd3.7/par3.7.9/art3.138',
    '/hfd3/afd3.7/par3.7.9/art3.139',
    '/hfd3/afd3.7/par3.7.10/art3.140',
    '/hfd3/afd3.7/par3.7.10/art3.141',
    '/hfd3/afd3.7/par3.7.11/art3.142',
    '/hfd3/afd3.7/par3.7.11/art3.143',
    '/hfd3/afd3.7/par3.7.11/art3.144',
    '/hfd3/afd3.7/par3.7.12/art3.145',
    '/hfd3/afd3.7/par3.7.12/art3.146',
    '/hfd3/afd3.7/par3.7.12/art3.147',
    '/hfd4/afd4.1/art4.1',
    '/hfd4/afd4.1/art4.2',
    '/hfd4/afd4.1/art4.3',
    '/hfd4/afd4.1/art4.4',
    '/hfd4/afd4.1/art4.5',
    '/hfd4/afd4.1/art4.6',
    '/hfd4/afd4.1/art4.7',
    '/hfd4/afd4.1/art4.8',
    '/hfd4/afd4.1/art4.9',
    '/hfd4/afd4.1/art4.10',
    '/hfd4/afd4.2/par4.2.1/art4.11',
    '/hfd4/afd4.2/par4.2.1/art4.12',
    '/hfd4/afd4.2/par4.2.1/art4.13',
    '/hfd4/afd4.2/par4.2.1/art4.14',
    '/hfd4/afd4.2/par4.2.1/art4.15',
    '/hfd4/afd4.2/par4.2.1a/art4.15a',
    '/hfd4/afd4.2/par4.2.1a/art4.15b',
    '/hfd4/afd4.2/par4.2.1a/art4.15c',
    '/hfd4/afd4.2/par4.2.1a/art4.15d',
    '/hfd4/afd4.2/par4.2.1a/art4.15e',
    '/hfd4/afd4.2/par4.2.2/art4.16',
    '/hfd4/afd4.2/par4.2.2/art4.17',
    '/hfd4/afd4.2/par4.2.2/art4.18',
    '/hfd4/afd4.2/par4.2.3/art4.19',
    '/hfd4/afd4.2/par4.2.3/art4.20',
    '/hfd4/afd4.2/par4.2.3/art4.21',
    '/hfd4/afd4.2/par4.2.3/art4.22',
    '/hfd4/afd4.2/par4.2.3/art4.23',
    '/hfd4/afd4.2/par4.2.4/art4.24',
    '/hfd4/afd4.2/par4.2.4/art4.25',
    '/hfd4/afd4.2/par4.2.4/art4.26a',
    '/hfd4/afd4.2/par4.2.4/art4.27',
    '/hfd4/afd4.2/par4.2.4/art4.28',
    '/hfd4/afd4.2/par4.2.4/art4.29',
    '/hfd4/afd4.2/par4.2.4/art4.30',
    '/hfd4/afd4.2/par4.2.4/art4.31',
    '/hfd4/afd4.2/par4.2.4/art4.32',
    '/hfd4/afd4.2/par4.2.4/art4.33',
    '/hfd4/afd4.2/par4.2.5/art4.34',
    '/hfd4/afd4.2/par4.2.5/art4.35',
    '/hfd4/afd4.2/par4.2.5/art4.36',
    '/hfd4/afd4.2/par4.2.6/art4.37',
    '/hfd4/afd4.2/par4.2.6/art4.38',
    '/hfd4/afd4.2/par4.2.6/art4.39',
    '/hfd4/afd4.2/par4.2.6/art4.40',
    '/hfd4/afd4.2/par4.2.6/art4.41',
    '/hfd4/afd4.2/par4.2.7/art4.42',
    '/hfd4/afd4.2/par4.2.7/art4.43',
    '/hfd4/afd4.2/par4.2.7/art4.44',
    '/hfd4/afd4.2/par4.2.7/artikel-4.44a',
    '/hfd4/afd4.2/par4.2.7/art4.45',
    '/hfd4/afd4.2/par4.2.7/art4.45a',
    '/hfd4/afd4.2/par4.2.7/art4.46',
    '/hfd4/afd4.2/par4.2.7/art4.47',
    '/hfd4/afd4.2/par4.2.7/art4.48',
    '/hfd4/afd4.2/par4.2.8/art4.49',
    '/hfd4/afd4.2/par4.2.8/art4.50',
    '/hfd4/afd4.2/par4.2.8/art4.51',
    '/hfd4/afd4.2/par4.2.8/art4.52',
    '/hfd4/afd4.2/par4.2.8/art4.53',
    '/hfd4/afd4.2/par4.2.8/artikel-4.53a',
    '/hfd4/afd4.2/par4.2.8/art4.54',
    '/hfd4/afd4.2/par4.2.8/artikel-4.55',
    '/hfd4/afd4.2/par4.2.9/art4.56',
    '/hfd4/afd4.2/par4.2.9/art4.57',
    '/hfd4/afd4.2/par4.2.9/art4.58',
    '/hfd4/afd4.2/par4.2.9/art4.59',
    '/hfd4/afd4.2/par4.2.9/art4.60',
    '/hfd4/afd4.2/par4.2.9/art4.61',
    '/hfd4/afd4.2/par4.2.9/art4.62',
    '/hfd4/afd4.2/par4.2.9/art4.63',
    '/hfd4/afd4.2/par4.2.10/art4.64',
    '/hfd4/afd4.2/par4.2.10/art4.65',
    '/hfd4/afd4.2/par4.2.10/art4.66',
    '/hfd4/afd4.2/par4.2.10/art4.67',
    '/hfd4/afd4.2/par4.2.10/art4.68',
    '/hfd4/afd4.2/par4.2.10/art4.69',
    '/hfd4/afd4.2/par4.2.10/art4.70',
    '/hfd4/afd4.2/par4.2.10/art4.71',
    '/hfd4/afd4.2/par4.2.10/art4.72',
    '/hfd4/afd4.2/par4.2.11/art4.73',
    '/hfd4/afd4.2/par4.2.11/art4.74',
    '/hfd4/afd4.2/par4.2.11/art4.75',
    '/hfd4/afd4.2/par4.2.11/art4.76',
    '/hfd4/afd4.2/par4.2.11/art4.77',
    '/hfd4/afd4.2/par4.2.11/art4.77a',
    '/hfd4/afd4.2/par4.2.11/art4.78',
    '/hfd4/afd4.2/par4.2.11/art4.79',
    '/hfd4/afd4.2/par4.2.11/art4.80',
    '/hfd4/afd4.2/par4.2.11/art4.81',
    '/hfd4/afd4.2/par4.2.11/art4.82',
    '/hfd4/afd4.2/par4.2.12/art4.83',
    '/hfd4/afd4.2/par4.2.12/art4.84',
    '/hfd4/afd4.2/par4.2.12/art4.85',
    '/hfd4/afd4.2/par4.2.12/art4.86',
    '/hfd4/afd4.2/par4.2.12/art4.87',
    '/hfd4/afd4.2/par4.2.13/art4.88',
    '/hfd4/afd4.2/par4.2.13/art4.89',
    '/hfd4/afd4.2/par4.2.14/art4.90',
    '/hfd4/afd4.2/par4.2.14/art4.91',
    '/hfd4/afd4.2/par4.2.14/art4.92',
    '/hfd4/afd4.2/par4.2.14/art4.93',
    '/hfd4/afd4.2/par4.2.14/art4.94',
    '/hfd4/afd4.2/par4.2.14/art4.95',
    '/hfd4/afd4.2/par4.2.14/art4.96',
    '/hfd4/afd4.2/par4.2.15/art4.97',
    '/hfd4/afd4.2/par4.2.15/art4.98',
    '/hfd4/afd4.2/par4.2.16/art4.99',
    '/hfd4/afd4.2/par4.2.16/art4.100',
    '/hfd4/afd4.3/par4.3.1/art4.101',
    '/hfd4/afd4.3/par4.3.1/art4.102',
    '/hfd4/afd4.3/par4.3.1/art4.103',
    '/hfd4/afd4.3/par4.3.1/art4.103a',
    '/hfd4/afd4.3/par4.3.1/art4.103b',
    '/hfd4/afd4.3/par4.3.1/art4.103c',
    '/hfd4/afd4.3/par4.3.1/art4.104',
    '/hfd4/afd4.3/par4.3.1/art4.105',
    '/hfd4/afd4.3/par4.3.2/art4.106',
    '/hfd4/afd4.3/par4.3.2/art4.107',
    '/hfd4/afd4.3/par4.3.2/art4.108',
    '/hfd4/afd4.3/par4.3.2/art4.109',
    '/hfd4/afd4.3/par4.3.3/art4.110',
    '/hfd4/afd4.3/par4.3.3/art4.111',
    '/hfd4/afd4.3/par4.3.4/art4.112',
    '/hfd4/afd4.3/par4.3.4/art4.113',
    '/hfd4/afd4.3/par4.3.4/art4.114',
    '/hfd4/afd4.3/par4.3.4/art4.115',
    '/hfd4/afd4.3/par4.3.4/art4.116',
    '/hfd4/afd4.3/par4.3.5/art4.117',
    '/hfd4/afd4.3/par4.3.5/art4.118',
    '/hfd4/afd4.3/par4.3.5/art4.119',
    '/hfd4/afd4.3/par4.3.5/art4.120',
    '/hfd4/afd4.3/par4.3.6/art4.121',
    '/hfd4/afd4.3/par4.3.6/art4.122',
    '/hfd4/afd4.3/par4.3.6/art4.123',
    '/hfd4/afd4.3/par4.3.6/art4.124',
    '/hfd4/afd4.3/par4.3.6/art4.125',
    '/hfd4/afd4.3/par4.3.6/art4.126',
    '/hfd4/afd4.3/par4.3.6/art4.127',
    '/hfd4/afd4.3/par4.3.6/art4.128',
    '/hfd4/afd4.3/par4.3.6/art4.129',
    '/hfd4/afd4.3/par4.3.7/art4.130',
    '/hfd4/afd4.3/par4.3.7/art4.131',
    '/hfd4/afd4.3/par4.3.7/art4.132',
    '/hfd4/afd4.3/par4.3.7/art4.133',
    '/hfd4/afd4.3/par4.3.8/art4.134',
    '/hfd4/afd4.3/par4.3.8/art4.135',
    '/hfd4/afd4.3/par4.3.8/art4.136',
    '/hfd4/afd4.3/par4.3.8/art4.137',
    '/hfd4/afd4.3/par4.3.8/art4.138',
    '/hfd4/afd4.3/par4.3.8/art4.139',
    '/hfd4/afd4.3/par4.3.8/art4.140',
    '/hfd4/afd4.3/par4.3.8/art4.141',
    '/hfd4/afd4.3/par4.3.8/art4.142',
    '/hfd4/afd4.3/par4.3.9/art4.143',
    '/hfd4/afd4.3/par4.3.9/art4.144',
    '/hfd4/afd4.3/par4.3.9/art4.145',
    '/hfd4/afd4.3/par4.3.10/art4.146',
    '/hfd4/afd4.3/par4.3.10/art4.147',
    '/hfd4/afd4.4/par4.4.1/art4.148',
    '/hfd4/afd4.4/par4.4.1/art4.149',
    '/hfd4/afd4.4/par4.4.1/art4.149a',
    '/hfd4/afd4.4/par4.4.1/art4.149b',
    '/hfd4/afd4.4/par4.4.1/art4.150',
    '/hfd4/afd4.4/par4.4.1/art4.151',
    '/hfd4/afd4.4/par4.4.1/art4.152',
    '/hfd4/afd4.4/par4.4.1/art4.153',
    '/hfd4/afd4.4/par4.4.1/art4.154',
    '/hfd4/afd4.4/par4.4.1/art4.155',
    '/hfd4/afd4.4/par4.4.1/art4.156',
    '/hfd4/afd4.4/par4.4.1/art4.157',
    '/hfd4/afd4.4/par4.4.2/art4.158',
    '/hfd4/afd4.4/par4.4.2/art4.159',
    '/hfd4/afd4.4/par4.4.2/art4.160',
    '/hfd4/afd4.4/par4.4.3/art4.160a',
    '/hfd4/afd4.4/par4.4.3/art4.160b',
    '/hfd4/afd4.4/par4.4.4/art4.160c',
    '/hfd4/afd4.4/par4.4.4/art4.160d',
    '/hfd4/afd4.4/par4.4.4/art4.160e',
    '/hfd4/afd4.5/par4.5.1/art4.161',
    '/hfd4/afd4.5/par4.5.2/art4.162',
    '/hfd4/afd4.5/par4.5.2/art4.163',
    '/hfd4/afd4.5/par4.5.2/art4.164',
    '/hfd4/afd4.5/par4.5.3/art4.165',
    '/hfd4/afd4.5/par4.5.3/art4.166',
    '/hfd4/afd4.5/par4.5.3/art4.167',
    '/hfd4/afd4.5/par4.5.4/art4.168',
    '/hfd4/afd4.5/par4.5.4/art4.169',
    '/hfd4/afd4.5/par4.5.4/art4.170',
    '/hfd4/afd4.5/par4.5.5/art4.171',
    '/hfd4/afd4.5/par4.5.5/art4.172',
    '/hfd4/afd4.5/par4.5.5/art4.173',
    '/hfd4/afd4.5/par4.5.6/art4.174',
    '/hfd4/afd4.5/par4.5.6/art4.175',
    '/hfd4/afd4.5/par4.5.7/art4.176',
    '/hfd4/afd4.5/par4.5.7/art4.177',
    '/hfd4/afd4.5/par4.5.7/art4.178',
    '/hfd4/afd4.6/par4.6.1/art4.179',
    '/hfd4/afd4.6/par4.6.1/art4.180',
    '/hfd4/afd4.6/par4.6.1/art4.181',
    '/hfd4/afd4.6/par4.6.1/art4.182',
    '/hfd4/afd4.6/par4.6.2/art4.183',
    '/hfd4/afd4.6/par4.6.2/art4.184',
    '/hfd4/afd4.6/par4.6.2/art4.185',
    '/hfd4/afd4.6/par4.6.2/art4.186',
    '/hfd4/afd4.6/par4.6.2/art4.187',
    '/hfd4/afd4.6/par4.6.2/art4.188',
    '/hfd4/afd4.6/par4.6.2/art4.189',
    '/hfd4/afd4.6/par4.6.2/art4.190',
    '/hfd4/afd4.6/par4.6.3/art4.191',
    '/hfd4/afd4.6/par4.6.3/art4.192',
    '/hfd4/afd4.7/par4.7.1/art4.193',
    '/hfd4/afd4.7/par4.7.1/art4.194',
    '/hfd4/afd4.7/par4.7.1/art4.195',
    '/hfd4/afd4.7/par4.7.1/art4.196',
    '/hfd4/afd4.7/par4.7.1/art4.197',
    '/hfd4/afd4.7/par4.7.2/art4.198',
    '/hfd4/afd4.7/par4.7.2/art4.199',
    '/hfd4/afd4.7/par4.7.2/art4.200',
    '/hfd4/afd4.7/par4.7.3/art4.201',
    '/hfd4/afd4.7/par4.7.3/art4.202',
    '/hfd4/afd4.7/par4.7.3/art4.203',
    '/hfd4/afd4.7/par4.7.4/art4.204',
    '/hfd4/afd4.7/par4.7.4/art4.205',
    '/hfd4/afd4.7/par4.7.4/art4.206',
    '/hfd4/afd4.7/par4.7.5/art4.207',
    '/hfd4/afd4.7/par4.7.5/art4.208',
    '/hfd4/afd4.7/par4.7.5/art4.209',
    '/hfd4/afd4.7/par4.7.5/art4.210',
    '/hfd4/afd4.7/par4.7.5/art4.211',
    '/hfd4/afd4.7/par4.7.6/art4.212',
    '/hfd4/afd4.7/par4.7.6/art4.213',
    '/hfd4/afd4.7/par4.7.6/art4.214',
    '/hfd4/afd4.7/par4.7.6/art4.215',
    '/hfd4/afd4.7/par4.7.6/art4.216',
    '/hfd4/afd4.7/par4.7.6/art4.217',
    '/hfd4/afd4.7/par4.7.6/art4.218',
    '/hfd4/afd4.7/par4.7.6/art4.218a',
    '/hfd4/afd4.7/par4.7.7/art4.219',
    '/hfd4/afd4.7/par4.7.7/art4.220',
    '/hfd4/afd4.7/par4.7.7/art4.221',
    '/hfd4/afd4.7/par4.7.7/art4.222',
    '/hfd4/afd4.7/par4.7.7/artikel-4.223',
    '/hfd4/afd4.7/par4.7.7/art4.223a',
    '/hfd4/afd4.7/par4.7.7/art4.224',
    '/hfd4/afd4.7/par4.7.8/art4.225',
    '/hfd4/afd4.7/par4.7.8/art4.226',
    '/hfd4/afd4.7/par4.7.8/art4.227',
    '/hfd4/afd4.7/par4.7.8/artikel-4.228',
    '/hfd4/afd4.7/par4.7.8/art4.229',
    '/hfd4/afd4.7/par4.7.8/art4.230',
    '/hfd4/afd4.7/par4.7.8/art4.230a',
    '/hfd4/afd4.7/par4.7.9/art4.231',
    '/hfd4/afd4.7/par4.7.9/art4.232',
    '/hfd4/afd4.7/par4.7.9/art4.233',
    '/hfd4/afd4.7/par4.7.9/art4.234',
    '/hfd4/afd4.7/par4.7.9/art4.235',
    '/hfd4/afd4.7/par4.7.9/art4.236',
    '/hfd4/afd4.7/par4.7.9/art4.237',
    '/hfd4/afd4.7/par4.7.10/art4.238',
    '/hfd4/afd4.7/par4.7.10/art4.239',
    '/hfd4/afd4.7/par4.7.11/artikel-4.240',
    '/hfd4/afd4.7/par4.7.11/art4.241',
    '/hfd4/afd4.7/par4.7.12/art4.242',
    '/hfd4/afd4.7/par4.7.12/art4.243',
    '/hfd4/afd4.7/par4.7.13/art4.244',
    '/hfd4/afd4.7/par4.7.13/art4.245',
    '/hfd4/afd4.7/par4.7.13/art4.246',
    '/hfd4/afd4.7/par4.7.14/art4.247',
    '/hfd4/afd4.7/par4.7.14/art4.248',
    '/hfd4/afd4.7/par4.7.14/art4.249',
    '/hfd4/afd4.7/par4.7.14/art4.250',
    '/hfd5/afd5.1/art5.1',
    '/hfd5/afd5.1/art5.2',
    '/hfd5/afd5.1/art5.3',
    '/hfd5/afd5.2/art5.3a',
    '/hfd5/afd5.2/art5.4',
    '/hfd5/afd5.2/art5.5',
    '/hfd5/afd5.2/art5.6',
    '/hfd5/afd5.2/art5.7',
    '/hfd5/afd5.3/art5.8',
    '/hfd5/afd5.3/art5.9',
    '/hfd5/afd5.3/art5.10',
    '/hfd5/afd5.3/art5.10a',
    '/hfd5/afd5.3/art5.11',
    '/hfd5/afd5.3/art5.12',
    '/hfd5/afd5.3/art5.13',
    '/hfd5/afd5.3/art5.13a',
    '/hfd5/afd5.3/art5.14',
    '/hfd5/afd5.3/art5.15',
    '/hfd5/afd5.3/art5.16',
    '/hfd5/afd5.3/art5.17',
    '/hfd5/afd5.3/art5.18',
    '/hfd5/afd5.3/art5.19',
    '/hfd5/afd5.3/art5.20',
    '/hfd5/afd5.3/art5.20a',
    '/hfd5/afd5.3/art5.21',
    '/hfd5/afd5.3/art5.21a',
    '/hfd5/afd5.3/art5.21b',
    '/hfd5/afd5.3/art5.21c',
    '/hfd5/afd5.3/art5.21d',
    '/hfd5/afd5.4/art5.22',
    '/hfd5/afd5.4/art5.22a',
    '/hfd5/afd5.4/art5.23',
    '/hfd5/afd5.4/art5.23a',
    '/hfd5/afd5.4/art5.24',
    '/hfd5/afd5.4/art5.24a',
    '/hfd6/afd6.1/par6.1.1/art6.1',
    '/hfd6/afd6.1/par6.1.1/art6.2',
    '/hfd6/afd6.1/par6.1.1/art6.3',
    '/hfd6/afd6.1/par6.1.1/art6.4',
    '/hfd6/afd6.1/par6.1.1/art6.5',
    '/hfd6/afd6.1/par6.1.1/art6.5a',
    '/hfd6/afd6.1/par6.1.2/art6.6',
    '/hfd6/afd6.1/par6.1.2/art6.7',
    '/hfd6/afd6.1/par6.1.2/art6.8',
    '/hfd6/afd6.1/par6.1.2/art6.9',
    '/hfd6/afd6.1/par6.1.2/art6.10',
    '/hfd6/afd6.2/par6.2.1/art6.11',
    '/hfd6/afd6.2/par6.2.1/art6.12',
    '/hfd6/afd6.2/par6.2.1/art6.13',
    '/hfd6/afd6.2/par6.2.1/art6.14',
    '/hfd6/afd6.2/par6.2.1/art6.15',
    '/hfd6/afd6.2/par6.2.1/artikel-6.15a',
    '/hfd6/afd6.2/par6.2.1/art6.16',
    '/hfd6/afd6.2/par6.2.1/art6.17',
    '/hfd6/afd6.2/par6.2.1/art6.18',
    '/hfd6/afd6.2/par6.2.2/art6.19',
    '/hfd6/afd6.2/par6.2.2/art6.20',
    '/hfd6/afd6.2/par6.2.2/art6.21',
    '/hfd6/afd6.2/par6.2.2/art6.22',
    '/hfd6/afd6.2/par6.2.2/art6.23',
    '/hfd6/afd6.2/par6.2.2/artikel-6.23a',
    '/hfd6/afd6.2/par6.2.2/art6.24',
    '/hfd6/afd6.3/art6.25',
    '/hfd6/afd6.3/art6.26',
    '/hfd6/afd6.4/art6.27',
    '/hfd6/afd6.4/art6.28',
    '/hfd6/afd6.4/art6.29',
    '/hfd6/afd6.4/art6.30',
    '/hfd6/afd6.4/art6.31',
    '/hfd6/afd6.5/par6.5.1/art6.32',
    '/hfd6/afd6.5/par6.5.1/art6.33',
    '/hfd6/afd6.5/par6.5.1/art6.34',
    '/hfd6/afd6.5/par6.5.1/art6.35',
    '/hfd6/afd6.5/par6.5.1/art6.36',
    '/hfd6/afd6.5/par6.5.2/artikel-6.37',
    '/hfd6/afd6.5/par6.5.3/art6.37a',
    '/hfd6/afd6.5/par6.5.3/art6.38',
    '/hfd6/afd6.5/par6.5.3/art6.39',
    '/hfd6/afd6.5/par6.5.3/art6.40',
    '/hfd6/afd6.5/par6.5.3/art6.41',
    '/hfd6/afd6.5/par6.5.4/art6.42',
    '/hfd6/afd6.5/par6.5.4/art6.43',
    '/hfd6/afd6.5/par6.5.5/art6.44',
    '/hfd6/afd6.5/par6.5.5/art6.45',
    '/hfd6/afd6.5/par6.5.5/art6.46',
    '/hfd6/afd6.5/par6.5.5/art6.47',
    '/hfd6/afd6.5/par6.5.5/art6.48',
    '/hfd7/afd7.1/par7.1.1/art7.1',
    '/hfd7/afd7.1/par7.1.1/art7.2',
    '/hfd7/afd7.1/par7.1.1/art7.3',
    '/hfd7/afd7.1/par7.1.1/art7.4',
    '/hfd7/afd7.1/par7.1.1/art7.5',
    '/hfd7/afd7.1/par7.1.1/art7.5a',
    '/hfd7/afd7.1/par7.1.1/art7.5b',
    '/hfd7/afd7.1/par7.1.1/art7.5c',
    '/hfd7/afd7.1/par7.1.1/art7.6',
    '/hfd7/afd7.1/par7.1.2/art7.7',
    '/hfd7/afd7.1/par7.1.2/art7.8',
    '/hfd7/afd7.1/par7.1.3/art7.9',
    '/hfd7/afd7.1/par7.1.3/art7.10',
    '/hfd7/afd7.1/par7.1.3/art7.11',
    '/hfd7/afd7.1/par7.1.3/art7.12',
    '/hfd7/afd7.1/par7.1.3/art7.13',
    '/hfd7/afd7.1/par7.1.3/art7.14',
    '/hfd7/afd7.1/par7.1.4/art7.15',
    '/hfd7/afd7.1/par7.1.4/art7.16',
    '/hfd7/afd7.1/par7.1.4/art7.17',
    '/hfd7/afd7.1/par7.1.4/art7.18',
    '/hfd7/afd7.1/par7.1.4/art7.19',
    '/hfd7/afd7.1/par7.1.4/art7.19a',
    '/hfd7/afd7.1/par7.1.4/art7.20',
    '/hfd7/afd7.1/par7.1.4/art7.21',
    '/hfd7/afd7.1/par7.1.4/art7.22',
    '/hfd7/afd7.1/par7.1.4/art7.22a',
    '/hfd7/afd7.1/par7.1.4/art7.23',
    '/hfd7/afd7.1/par7.1.5/art7.24',
    '/hfd7/afd7.1/par7.1.5/art7.25',
    '/hfd7/afd7.1/par7.1.5/art7.26',
    '/hfd7/afd7.2/par7.2.1/art7.27',
    '/hfd7/afd7.2/par7.2.1/art7.28',
    '/hfd7/afd7.2/par7.2.1/art7.29',
    '/hfd7/afd7.2/par7.2.1/art7.30',
    '/hfd7/afd7.2/par7.2.1/art7.31',
    '/hfd7/afd7.2/par7.2.1/art7.32',
    '/hfd7/afd7.2/par7.2.2/art7.33',
    '/hfd7/afd7.2/par7.2.2/art7.34',
    '/hfd7/afd7.2/par7.2.2/art7.35',
    '/hfd7/afd7.2/par7.2.2/art7.36',
    '/hfd7/afd7.2/par7.2.2/art7.37',
    '/hfd7/afd7.2/par7.2.3/art7.38',
    '/hfd7/afd7.2/par7.2.3/art7.39',
    '/hfd7/afd7.2/par7.2.3/art7.40',
    '/hfd8/art8.1',
    '/hfd8/art8.2',
    '/hfd8/art8.3',
    '/hfd9/art9.1',
    '/hfd9/art9.2',
  ];

  // This component filters tbq questions/descriptions, and replaces the article numbers with links to briswarenhuis website.
  // Example tbq question:
  // Dit valt onder andre op te maken uit onderhoudsrapportages of onderhoudscontracten. [BB art. 6.3 & 1.16 -1]

  const processInputWithLinks = (input: string, links: string[]) => {
    // Split the input string into parts based on references
    const stringParts = input.split(/(\[BB.*art.*?\])/g);

    const processedParts = stringParts.map((part, index) => {
      if (part.match(/(\[BB.*art.*?\])/g)) {
        const matches = part.match(/\d+\.\d+/g); // Extract article numbers

        if (matches) {
          return matches.map((article, matchIndex) => {
            const artNumberString = `art${article}`;
            const link = links.find((x) => x.includes(artNumberString));

            return link ? (
              <a
                href={`https://www.briswarenhuis.nl/docs/wet/bbl${link}`}
                key={`${index}-${matchIndex}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                [BBL {article}]
              </a>
            ) : (
              <button
                key={`${index}-${matchIndex}`}
                onClick={(e) => e.preventDefault()}
                style={{
                  color: 'grey',
                  cursor: 'default',
                  pointerEvents: 'none',
                }}
              >
                [BBL {article}]
              </button>
            );
          });
        }
      }

      return part;
    });

    return processedParts.flat() as React.ReactNode[];
  };

  return <strong>{processInputWithLinks(name, articles)}</strong>;
};
