import React from 'react';

import { Project } from '@pro4all/graphql';
import {
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@pro4all/shared/mui-wrappers';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';
import { Icon } from '@pro4all/shared/ui/icons';

import { ProjectWithMessage } from './types';

interface ProjectListMobileProps {
  itemClick: (project: Project) => void;
  projects: ProjectWithMessage[];
  showMessageBadge: boolean;
}

const ProjectListMobile: React.FC<ProjectListMobileProps> = ({
  itemClick,
  projects,
  showMessageBadge,
}) => (
  <List sx={{ width: '100%' }}>
    {projects &&
      projects.map((project) => (
        <ListItemButton
          dense
          divider
          key={project.id}
          onClick={() => itemClick(project)}
        >
          <ListItemIcon>
            <Icon iconName="project"></Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography color="text.primary">{project.name}</Typography>
            }
            secondary={
              <>
                <Typography
                  color="text.secondary"
                  sx={{ fontWeight: 'inherit' }}
                  variant="body2"
                >
                  <MiddleEllipsis
                    text={project.projectNumber ? project.projectNumber : '-'}
                  ></MiddleEllipsis>
                </Typography>
                <Typography
                  color="text.secondary"
                  sx={{ fontWeight: 'inherit' }}
                  variant="body2"
                >
                  <MiddleEllipsis
                    text={project.organization ? project.organization.name : ''}
                  ></MiddleEllipsis>
                </Typography>
              </>
            }
          />
          {showMessageBadge && (
            <IconButton edge="end">
              <Icon
                iconName={
                  project.projectMessages.unreadCount > 0
                    ? 'prostreamChatUnread'
                    : 'prostreamChat'
                }
              />
            </IconButton>
          )}
        </ListItemButton>
      ))}
  </List>
);

export default ProjectListMobile;
