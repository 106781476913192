import { NotificationScope, NotificationsUser } from '@pro4all/graphql';
import { NotificationsPage } from '@pro4all/notifications';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { Tabs } from './Tabs';

export const DocumentNotifications = () => (
  <OptimisticResponseProvider<NotificationsUser>>
    <>
      <Tabs />
      <NotificationsPage scope={NotificationScope.Document} />
    </>
  </OptimisticResponseProvider>
);
