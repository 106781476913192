import { StandardDateRange } from '@pro4all/graphql';
import { RenderOptionType } from '@pro4all/shared/types';
import { Position } from '@pro4all/shared/ui/context-menu';
import { IconName } from '@pro4all/shared/ui/icons';
import { BaseRow, ColumnSizes } from '@pro4all/shared/ui/table';

export enum CompareType {
  is = 'is',
  isAfter = 'isAfter',
  isBefore = 'isBefore',
  isBetween = 'isBetween',
  isNot = 'isNot',
}

export enum ColumnWidthChangeType {
  Decrease = 'Decrease',
  Increase = 'Increase',
}

export type AdvancedFilterValue = {
  compareType: CompareType;
  valueFirst: string;
  valueSecond?: string;
};

// This is a bucket (central place) with all types used for column filtering. We Pick<> from this bucket.
export type FilterColumnProps = {
  columnValues: string[];
  columnWidthChange:
    | ColumnWidthChangeType.Decrease
    | ColumnWidthChangeType.Increase;
  customCallbackId?: string;
  defaultWidth: number;
  filterType: FilterHeaderType;
  filterValues: string[] | AdvancedFilterValue[];
  flattenOptions?: boolean;
  getOptionCount: (option: string) => number;
  getOptions: () => string[] | AdvancedFilterValue[];
  iconName?: IconName;
  isDate?: boolean;
  isMetaData?: boolean;
  isMetaDataLocalStorage?: boolean;
  isMultiSelect?: boolean;
  isMultiSelectLocalStorage?: boolean;
  label?: string;
  metaDataHeaderId?: string;
  metaDataHeaderIdLocalStorage?: string;
  minWidth: number;
  onColumnResizeCallback?: (value: ColumnSizes | FilterTypeLS[]) => void;
  onReset: () => void;
  onSet: (selectedOptions: string[] | AdvancedFilterValue[]) => void;
  position: Position;
  propertyId: string;
  pullFromLocalStorage?: boolean;
  renderOption?: RenderOptionType;
  selectedOptions: string[];
  setSelectedOptions: (options: string[]) => void;
  setShowFilter: (value: boolean) => void;
  setUnselectedOptions: (value: string[]) => void;
  showFilter: boolean;
  showFilterIcon?: boolean;
  subPropertyId: string;
  translateOptions?: boolean;
  translateOptionsLocalStorage?: boolean;
};

export type FilterColumnIdProps<Row extends BaseRow, SubProp> = {
  getCustomValueCallback?: (row: Row[keyof Row]) => string[] | string;
  propertyId: keyof Row;
  subPropertyId?: keyof SubProp;
};

export type FilterTypeLS = Pick<
  FilterColumnProps,
  | 'filterValues'
  | 'filterType'
  | 'isMultiSelect'
  | 'propertyId'
  | 'subPropertyId'
  | 'translateOptions'
>;

export type FilterType<Row extends BaseRow> = Pick<
  FilterColumnProps,
  | 'filterType'
  | 'filterValues'
  | 'isMultiSelect'
  | 'onColumnResizeCallback'
  | 'propertyId'
  | 'subPropertyId'
  | 'translateOptions'
> & {
  itemsFiltered: Row[];
};

export type PredefinedFilter = {
  filters: FilterType<BaseRow>[];
  title: string;
};

export type FilterContextValue<Row extends BaseRow> = {
  applyFilters: (filters: FilterType<Row>[]) => void;
  filters: FilterType<Row>[];
  getFilterValuesFromContext: ({
    flattenOptions,
    propertyId,
    subPropertyId,
  }: Pick<
    FilterColumnProps,
    'flattenOptions' | 'propertyId' | 'subPropertyId'
  >) => string[];
  getItemsThatAreIncludedInAllFilters: ({
    filters,
    flattenOptions,
  }: {
    filters: FilterType<Row>[];
    flattenOptions?: boolean;
  }) => Row[];
  removeAllFilters: () => void;
  removeFilter: ({
    onColumnResizeCallback,
    propertyId,
    subPropertyId,
  }: Pick<
    FilterColumnProps,
    'onColumnResizeCallback' | 'propertyId' | 'subPropertyId'
  >) => void;
  setFilters: React.Dispatch<React.SetStateAction<FilterType<Row>[]>>;
  setSelectedOptions: ({
    filterType,
    filterValues,
    isMultiSelect,
    itemsFiltered,
    propertyId,
    subPropertyId,
    translateOptions,
  }: FilterType<Row>) => void;
  updateTableBasedOnCurrentFilters: (filters: FilterType<Row>[]) => void;
};

export enum FilterHeaderType {
  Date = 'Date',
  Number = 'Number',
  Select = 'Select',
  SelectNumber = 'SelectNumber',
  Text = 'Text',
}

export type FilterProps = Pick<
  FilterColumnProps,
  | 'columnValues'
  | 'filterType'
  | 'getOptionCount'
  | 'getOptions'
  | 'onReset'
  | 'onSet'
  | 'position'
  | 'renderOption'
  | 'setShowFilter'
  | 'showFilter'
  | 'isMetaData'
>;

export type AdjustColumnWidthType = Pick<
  FilterColumnProps,
  'columnWidthChange' | 'propertyId' | 'subPropertyId'
>;

export type SelectedOptionsProps = Partial<
  Pick<FilterColumnProps, 'columnValues' | 'getOptionCount'>
> &
  Pick<FilterColumnProps, 'getOptions' | 'onSet' | 'renderOption'>;

export type FilterComponentTypeProps = Pick<
  FilterColumnProps,
  | 'columnValues'
  | 'getOptionCount'
  | 'getOptions'
  | 'onReset'
  | 'onSet'
  | 'renderOption'
>;

export type FooterProps = { disableOnReset: boolean } & Pick<
  FilterColumnProps,
  'onReset'
>;

export type FilterComponentType = {
  [key in FilterHeaderType]: React.ElementType<FilterComponentTypeProps>;
};

export const standardRangeNames: { [key in StandardDateRange]: string } = {
  [StandardDateRange.Today]: 'Today',
  [StandardDateRange.Yesterday]: 'Yesterday',
  [StandardDateRange.LastThreeDays]: 'Last 3 days',
  [StandardDateRange.LastSevenDays]: 'Last 7 days',
  [StandardDateRange.LastFourteenDays]: 'Last 14 days',
  [StandardDateRange.LastThirtyDays]: 'Last 30 days',
  [StandardDateRange.LastNinetyDays]: 'Last 90 days',
  [StandardDateRange.LastYear]: 'Last 365 days',
};
