import { useTranslation } from 'react-i18next';

import { Task, TbqAnswer, TbqRieResult } from '@pro4all/graphql';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { TaskCounter } from '@pro4all/shared/ui/general';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';

type ExtendedTbqBrandcheckResult = TbqAnswer & {
  state: number;
};

export const useTBQResultsColumns = (
  relevantTasksForAllQuestions: {
    question: TbqRieResult | TbqAnswer;
    tasks: Task[];
  }[]
) => {
  const { t } = useTranslation();

  const renderTaskCounter = ({
    result,
  }: {
    result: ExtendedTbqBrandcheckResult;
  }) => {
    if (!result) return null;

    return (
      <TaskCounter
        id={result.id}
        relevantTasksForAllQuestions={relevantTasksForAllQuestions}
      />
    );
  };

  const columns: ColumnProps<ExtendedTbqBrandcheckResult>[] = [
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<TbqAnswer, {}>
          defaultWidth={90}
          filterType={FilterHeaderType.Text}
          label="Nmbr"
          minWidth={90}
          propertyId="reference"
          pullFromLocalStorage
        />
      ),
      key: 'reference',
      width: 90,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<TbqAnswer, {}>
          defaultWidth={300}
          filterType={FilterHeaderType.Select}
          label={t('Question')}
          minWidth={100}
          propertyId="sectionText"
        />
      ),
      key: 'sectionText',
      render: ({ sectionText }) => (
        <MiddleEllipsis endLength={0} text={sectionText} />
      ),
      width: 300,
    },
    {
      filterable: true,
      getValue: (answer: TbqAnswer) => answer.answerOption ?? '',
      headerComponent: (
        <FilterHeader<TbqAnswer, {}>
          defaultWidth={80}
          filterType={FilterHeaderType.Select}
          label={t('Answer')}
          minWidth={80}
          propertyId="answerOption"
          translateOptions
        />
      ),
      key: 'answerOption',
      render: ({ answerOption }) => <>{t(answerOption ?? '-')}</>,
      width: 80,
    },
    {
      filterable: true,
      getValue: (answer: TbqAnswer) => answer.answerClassString ?? '',
      headerComponent: (
        <FilterHeader<TbqAnswer, {}>
          defaultWidth={80}
          filterType={FilterHeaderType.Select}
          label={t('Class')}
          minWidth={80}
          propertyId="answerClassString"
        />
      ),
      key: 'answerClassString',
      width: 50,
    },
    {
      filterable: true,
      getValue: (answer: TbqAnswer) => answer.categoryName ?? '',
      headerComponent: (
        <FilterHeader<TbqAnswer, {}>
          defaultWidth={160}
          filterType={FilterHeaderType.Select}
          label={t('Category')}
          minWidth={100}
          propertyId="categoryName"
        />
      ),
      key: 'categoryName',
      width: 160,
    },
  ];

  const stateColumn: ColumnProps<ExtendedTbqBrandcheckResult> = {
    headerComponent: (
      <FilterHeader<ExtendedTbqBrandcheckResult, {}>
        defaultWidth={100}
        filterType={FilterHeaderType.Number}
        label={t('State')}
        minWidth={80}
        propertyId="state"
      />
    ),
    key: 'state',
    render: (result: ExtendedTbqBrandcheckResult) =>
      renderTaskCounter({ result }),
    width: 100,
  };

  columns.push(stateColumn);

  return columns;
};
