import React from 'react';
import { useTranslation } from 'react-i18next';

import { Sidebar } from '@pro4all/shared/ui/sidebar';

import { SectionForm } from './sectionForm/SectionForm';
import { useSectionSidebarContext } from './TemplateMutationContext';

export const SectionSidebar = ({ isOpen }: { isOpen: boolean }) => {
  const { t } = useTranslation();
  const {
    section,
    setIsOpenSectionSidebar,
    setParentSectionSidebar,
    setSection,
    sectionSidebarActions,
  } = useSectionSidebarContext();
  const handleClose = () => {
    setIsOpenSectionSidebar(false);
    setParentSectionSidebar(null);
    setSection(null);
  };

  return (
    <Sidebar onClose={handleClose} open={isOpen}>
      <Sidebar.Header
        icon="section"
        title={section ? t(`Edit section`) : t(`New section`)}
      >
        {section && (
          <Sidebar.SidebarActionBar sidebarActions={sectionSidebarActions} />
        )}
      </Sidebar.Header>
      {isOpen && <SectionForm onClose={handleClose} />}
    </Sidebar>
  );
};
