import React from 'react';
import { Formik } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikForm, FormikInput } from '@pro4all/shared/ui/formik';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { BasePropsHierarchyItem, CallBackProps, OnClose } from './types';
import { useInitialValuesItem } from './useInitialValuesItem';
import { useItemFormConfig } from './useItemFormConfig';
import { useSubmitItem } from './useSubmitItem';

export const ItemForm = <HierarchyItem extends BasePropsHierarchyItem>({
  callbackAddEdit,
  onClose,
}: Pick<CallBackProps<HierarchyItem>, 'callbackAddEdit'> & OnClose) => {
  const { getField, validationSchema } = useItemFormConfig();
  const nameField = getField('name');

  const onSubmit = useSubmitItem<HierarchyItem>({ callbackAddEdit, onClose });
  const initialValues = useInitialValuesItem();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting, dirty }) => (
        <FormikForm>
          <FormWrapper>
            <FormikInput
              autoFocus
              label={nameField?.displayName}
              name={nameField?.name}
            />
          </FormWrapper>
          <FormFooter
            ariaLabelSave="Save item"
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            onClose={onClose}
            pb={3}
            pt={2}
            px={3}
            sticky
          />
        </FormikForm>
      )}
    </Formik>
  );
};
