import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ThreeDConversionStatus } from '@pro4all/documents/ui/3d';
import { ApprovalStatus } from '@pro4all/documents/ui/approval';
import { CommentStatus } from '@pro4all/documents/ui/comments';
import { FinalizeStatus } from '@pro4all/documents/ui/finalize';
import { ShareBuild12Status } from '@pro4all/documents/ui/share-build12';
import { ShareSnagstreamStatus } from '@pro4all/documents/ui/share-sns';
import { QRCodeStatus } from '@pro4all/documents/ui/stamper';
import {
  CommentStatus as CommentStatusType,
  Document,
  DocumentVersion,
  FinalizationState,
  Folder,
  LockType,
  StandardItemKey,
  StateBuild12,
  StateSnagstream,
  Tag,
  ThreeDConversionStatus as ThreeDConversionStatusType,
  User,
} from '@pro4all/graphql';
import { useActionNamingMapping } from '@pro4all/shared/label-config';
import { RenderOptionType } from '@pro4all/shared/types';
import {
  ColumnSortOrder,
  DocTablePropertyKeys,
  DocTableSubPropertyKeys,
} from '@pro4all/shared/types';
import { DocumentIcon } from '@pro4all/shared/ui/document-icon';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { DocumentVersionNumber } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { ColumnSizes } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';
import { getFormattedDate, Timestamp } from '@pro4all/shared/ui/timestamp';
import { Text } from '@pro4all/shared/ui/typography';
import { firstCharToUpperCase } from '@pro4all/shared/utils';

import { DocumentName } from '../DocumentName';

const StyledIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export type DocumentType = Document | DocumentVersion;

export const useStandardColumns = ({
  folder,
  onColumnResizeCallback,
  isForVersionTable,
}: {
  folder?: Folder;
  isForVersionTable?: boolean;
  onColumnResizeCallback?: (value: ColumnSizes) => void;
}) => {
  const { t } = useTranslation();
  const getActionNamingMapping = useActionNamingMapping();

  const getColumn = ({
    name,
    pullFromLocalStorage = false,
  }: {
    name: StandardItemKey;
    pullFromLocalStorage?: boolean;
  }) => {
    const standardDocTableColumns = {
      [StandardItemKey.Approved]: {
        getValue: (document: Document) =>
          document.versionState && document.versionState !== 'none'
            ? t(firstCharToUpperCase(document.versionState))
            : '',
        headerComponent: (
          <FilterHeader<Document, {}>
            defaultWidth={40}
            filterType={FilterHeaderType.Select}
            getCustomValueCallback={(item) =>
              getActionNamingMapping(item) as string
            }
            iconName="approveFile"
            label={t(
              '{{Approved}}/{{Rejected}}',
              getActionNamingMapping('Approved/Rejected')
            )} // i18n
            minWidth={40}
            onColumnResizeCallback={onColumnResizeCallback}
            propertyId={DocTablePropertyKeys.ApprovalState}
            pullFromLocalStorage={pullFromLocalStorage}
            translateOptions
          />
        ),
        key: DocTablePropertyKeys.ApprovalState,
        render: (document: Document) => <ApprovalStatus {...document} />,
        width: 40,
      },
      [StandardItemKey.Build12]: {
        getValue: (document: Document) => (document?.stateBuild12 ? 'x' : ''),
        headerComponent: (
          <FilterHeader<Document, StateBuild12>
            defaultWidth={40}
            filterType={FilterHeaderType.Select}
            iconName="build12"
            label="12Build" // i18n
            minWidth={40}
            onColumnResizeCallback={onColumnResizeCallback}
            propertyId={DocTablePropertyKeys.Build12}
            pullFromLocalStorage={pullFromLocalStorage}
            subPropertyId={DocTableSubPropertyKeys.Build12State}
            translateOptions
          />
        ),
        key: `${DocTablePropertyKeys.Build12}.${DocTableSubPropertyKeys.Build12State}`,
        render: (document: Document) => <ShareBuild12Status {...document} />,
        width: 40,
      },
      [StandardItemKey.Comments]: {
        getValue: (document: Document) =>
          document?.commentStatus === CommentStatusType.AllCommentsRead
            ? 'x'
            : '',
        headerComponent: (
          <FilterHeader<Document, {}>
            defaultWidth={40}
            filterType={FilterHeaderType.Select}
            iconName="chatBubble"
            label="Comments" // i18n
            minWidth={40}
            onColumnResizeCallback={onColumnResizeCallback}
            propertyId={DocTablePropertyKeys.CommentStatus}
            pullFromLocalStorage={pullFromLocalStorage}
            translateOptions
          />
        ),
        key: DocTablePropertyKeys.CommentStatus,
        render: (document: Document) => (
          <CommentStatus commentStatus={document.commentStatus} />
        ),
        width: 40,
      },
      [StandardItemKey.Extension]: {
        filterable: true,
        getValue: (document: Document) =>
          document?.extension?.replace('.', '') || '',
        headerComponent: (
          <FilterHeader<Document, {}>
            defaultWidth={50}
            filterType={FilterHeaderType.Select}
            label="Type" // i18n
            minWidth={50}
            onColumnResizeCallback={onColumnResizeCallback}
            propertyId={DocTablePropertyKeys.Extension}
            pullFromLocalStorage={pullFromLocalStorage}
          />
        ),
        key: DocTablePropertyKeys.Extension,
        render: ({ extension, isExpected }: Document) => (
          <DocumentIcon extension={extension} isExpected={isExpected} />
        ),
        width: 50,
      },
      [StandardItemKey.Finalized]: {
        getValue: (document: Document) => {
          const { lockedBy, lockType, state } = document;
          if (state === FinalizationState.Finalized)
            return t(firstCharToUpperCase(state));
          if (lockType === LockType.Prostream)
            return `${lockedBy?.displayName || t('Unknown')} ${t(
              "is editing this document's content."
            )}`;
          if (lockType === LockType.OfficeOnline)
            return t('Document being edited in Office.');
          return '';
        },
        headerComponent: (
          <FilterHeader<Document, {}>
            defaultWidth={40}
            filterType={FilterHeaderType.Select}
            getCustomValueCallback={(item) => {
              // Checking not finalized items
              if (item === FinalizationState.NotFinalized)
                return t('{{name}} not set', {
                  name: getActionNamingMapping(item),
                });
              return getActionNamingMapping(item) as string;
            }}
            iconName="finalize"
            label={getActionNamingMapping('Finalized') as string} // i18n
            minWidth={40}
            onColumnResizeCallback={onColumnResizeCallback}
            propertyId={DocTablePropertyKeys.FinalizeState}
            pullFromLocalStorage={pullFromLocalStorage}
            translateOptions
          />
        ),
        key: DocTablePropertyKeys.FinalizeState,
        render: (document: Document) => <FinalizeStatus {...document} />,
        width: 40,
      },
      [StandardItemKey.Name]: {
        defaultSort: isForVersionTable ? false : true,
        filterable: true,
        headerComponent: (
          <FilterHeader<Document, {}>
            defaultWidth={400}
            filterType={FilterHeaderType.Text}
            label="Name" // i18n
            minWidth={96}
            onColumnResizeCallback={onColumnResizeCallback}
            propertyId={DocTablePropertyKeys.Name}
            pullFromLocalStorage={pullFromLocalStorage}
          />
        ),
        key: DocTablePropertyKeys.Name,
        render: (document: Document) => (
          <DocumentName document={document} folder={folder} />
        ),
        width: 400,
      },
      [StandardItemKey.Qr]: {
        getValue: (document: Document) =>
          document?.qrCodeState === 'done'
            ? 'x'
            : document?.hasPreviousVersionQr
            ? t('Previous version')
            : '',
        headerComponent: (
          <FilterHeader<Document, {}>
            defaultWidth={40}
            filterType={FilterHeaderType.Select}
            iconName="qr"
            label="QR code" // i18n
            minWidth={40}
            onColumnResizeCallback={onColumnResizeCallback}
            propertyId={DocTablePropertyKeys.QrCodeState}
            pullFromLocalStorage={pullFromLocalStorage}
            translateOptions
          />
        ),
        key: DocTablePropertyKeys.QrCodeState,
        render: (document: Document) => (
          <QRCodeStatus
            {...document}
            hasPreviousVersionQr={document.hasPreviousVersionQr}
            isForVersionTable={isForVersionTable}
          />
        ),
        width: 40,
      },
      [StandardItemKey.Snagstream]: {
        getValue: (document: Document) =>
          document?.stateSnagstream ? 'x' : '',
        headerComponent: (
          <FilterHeader<Document, StateSnagstream>
            defaultWidth={40}
            filterType={FilterHeaderType.Select}
            iconName="snagstream"
            label="Snagstream" // i18n
            minWidth={40}
            onColumnResizeCallback={onColumnResizeCallback}
            propertyId={DocTablePropertyKeys.Snagstream}
            pullFromLocalStorage={pullFromLocalStorage}
            subPropertyId={DocTableSubPropertyKeys.SnagstreamState}
            translateOptions
          />
        ),
        key: `${DocTablePropertyKeys.Snagstream}.${DocTableSubPropertyKeys.SnagstreamState}`,
        render: (document: Document) => <ShareSnagstreamStatus {...document} />,
        width: 40,
      },
      [StandardItemKey.Tags]: {
        getValue: (document: Document) =>
          document?.tags?.length ? document.tags.length.toString() : '',
        headerComponent: (
          <FilterHeader<Document, Tag>
            defaultWidth={40}
            filterType={FilterHeaderType.Number}
            getCustomValueCallback={(tags: Tag[]) =>
              tags?.length ? `${tags.length}` : 'none'
            }
            iconName="tag"
            label="Tags" // i18n
            minWidth={40}
            onColumnResizeCallback={onColumnResizeCallback}
            propertyId={DocTablePropertyKeys.Tags}
            pullFromLocalStorage={pullFromLocalStorage}
            translateOptions
          />
        ),
        key: `${DocTablePropertyKeys.Tags}`,
        render: ({ tags }: Document) =>
          tags?.length ? (
            <>
              <StyledIcon fontSize="inherit" iconName="tag" />
              <Text display="inline">{tags.length}</Text>
            </>
          ) : (
            ''
          ),
        width: 40,
      },
      [StandardItemKey.ThreeD]: {
        getValue: (document: Document) => {
          if (!document.threeDConversionState) return '';
          const { status } = document.threeDConversionState;
          switch (status) {
            case 'notStarted':
              return t('Not started');
            case 'pending':
              return t('Pending');
            case 'inprogress':
              return t('In progress');
            case 'success':
              return t('3D Converted');
            case 'failed':
              return t('Failed');
            case 'uploading':
              return t('Uploading');
            default:
              return '';
          }
        },
        headerComponent: (
          <FilterHeader<Document, ThreeDConversionStatusType>
            defaultWidth={40}
            filterType={FilterHeaderType.Select}
            iconName="cube"
            label="3D" // i18n
            minWidth={40}
            onColumnResizeCallback={onColumnResizeCallback}
            propertyId={DocTablePropertyKeys.ThreeDConversionState}
            pullFromLocalStorage={pullFromLocalStorage}
            subPropertyId={DocTableSubPropertyKeys.ThreeDConversionStatus}
            translateOptions
          />
        ),
        key: `${DocTablePropertyKeys.ThreeDConversionState}.${DocTableSubPropertyKeys.ThreeDConversionStatus}`,
        render: ThreeDConversionStatus,
        width: 40,
      },
      [StandardItemKey.UpdatedBy]: {
        filterable: true,
        getValue: (document: Document) => document.updatedBy?.displayName || '',
        headerComponent: (
          <FilterHeader<Document, User>
            defaultWidth={200}
            filterType={FilterHeaderType.Select}
            label="Updated by" // i18n
            minWidth={120}
            onColumnResizeCallback={onColumnResizeCallback}
            propertyId={DocTablePropertyKeys.UpdatedBy}
            pullFromLocalStorage={pullFromLocalStorage}
            renderOption={RenderOptionType.UserCard}
            subPropertyId={DocTableSubPropertyKeys.UpdatedByName}
          />
        ),
        key: `${DocTablePropertyKeys.UpdatedBy}.${DocTableSubPropertyKeys.UpdatedByName}`,
        render: ({ updatedBy }: Document) => <UserTag user={updatedBy} />,
        width: 200,
      },
      [StandardItemKey.UpdatedOn]: {
        filterable: true,
        getValue: (document: Document) =>
          document.updatedAt
            ? getFormattedDate(document.updatedAt, 'lll').label
            : '',
        headerComponent: (
          <FilterHeader<Document, {}>
            defaultWidth={200}
            filterType={FilterHeaderType.Date}
            label="Updated on" // i18n
            minWidth={120}
            onColumnResizeCallback={onColumnResizeCallback}
            propertyId={DocTablePropertyKeys.UpdatedAt}
            pullFromLocalStorage={pullFromLocalStorage}
          />
        ),
        key: DocTablePropertyKeys.UpdatedAt,
        render: ({ updatedAt }: Document) => (
          <Timestamp date={updatedAt} format="lll" />
        ),
        width: 200,
      },
      [StandardItemKey.Version]: {
        defaultSort: isForVersionTable ? true : false,
        defaultSortOrder: ColumnSortOrder.DESC,
        filterable: true,
        headerComponent: (
          <FilterHeader<Document, {}>
            defaultWidth={44}
            filterType={FilterHeaderType.Number}
            label="#"
            minWidth={44}
            onColumnResizeCallback={onColumnResizeCallback}
            propertyId={DocTablePropertyKeys.VersionNumber}
            pullFromLocalStorage={pullFromLocalStorage}
          />
        ),
        key: DocTablePropertyKeys.VersionNumber,
        render: ({ isExpected, versionNumber }: Document) => (
          <DocumentVersionNumber isExpected={isExpected} variant="body1">
            {versionNumber}
          </DocumentVersionNumber>
        ),
        width: 44,
      },
    };

    return standardDocTableColumns[name];
  };

  return getColumn;
};
