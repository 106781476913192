import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { QcPermissionCategory, Task, TaskType } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import {
  QcAndTaskPermissionsType,
  useQCPermissions,
} from '@pro4all/quality-control/data-access';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Tab, Tabs } from '@pro4all/shared/ui/general';
import { BigMessageNoAccess } from '@pro4all/shared/ui/messages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import { Sidebar } from '@pro4all/shared/ui/sidebar';
import {
  useOptimisticResponseContext,
  useSetItemInLocalState,
} from '@pro4all/shared/ui/table';
import { useFetchTask } from '@pro4all/workflow/data-access';
import { useSidebarActions } from '@pro4all/workflow/data-access';
import { getPermissionPrefix } from '@pro4all/workflow/ui/utils';

import { TaskComments } from './comments/TaskComments';
import { TaskForm } from './task-form/TaskForm';
import { LinkedInstances } from './task-properties/LinkedInstances';
import { TaskProperties } from './task-properties/TaskProperties';
import { useSidebarButtons } from './useSidebarButtons';
import { TabValues, useSidebarTabs } from './useSidebarTabs';

interface Props {
  procedureId?: string;
  tasks?: Task[];
}

interface TabsWrapperProps {
  onChange: (value: TabValues.Properties) => void;
}

export const TaskSidebar: React.FC<Props> = ({ procedureId, tasks }) => {
  const { params, searchParams } = useRouting();
  const { t } = useTranslation();
  const { mainProcedureId } = useProjectContext();

  const taskId = searchParams.get('id') ?? '';
  const selectedTask = tasks?.find((task) => task.id === taskId);

  const taskProcedureId =
    selectedTask?.procedureId || procedureId || mainProcedureId;

  const overviewProjectId = selectedTask?.project?.id; // We need this if we're not in a project context.

  const routeCreate = searchParams.is('action', 'createTask');
  const routeEdit = searchParams.is('action', 'editTask');
  const routeView = searchParams.is('action', 'viewTask');

  const documentTask = searchParams.is('taskType', 'Document');
  const taskTypeParam = searchParams.get('taskType') as TaskType;
  const isOpenFromTable = routeCreate || routeView || routeEdit;
  const initialTab: TabValues =
    TabValues[searchParams.get('tab') as keyof typeof TabValues] ||
    TabValues.Properties;

  const { data, error, loading } = useFetchTask({
    includeDeliverables: taskTypeParam === TaskType.Document,
    includeDocumentAction: documentTask,
    includeFormTemplates: taskTypeParam === TaskType.QualityControl,
    includeLinkedSnagInstances: true,
    includeSnagTemplates: taskTypeParam === TaskType.QualityControl,
    includeVisualContexts: true,
  });
  const currentTask = data?.taskInclude;

  const projectId = currentTask?.project?.id || params.projectId;
  const taskType = currentTask?.type ?? taskTypeParam;

  const permissions = useQCPermissions({
    category: QcPermissionCategory.Procedure,
    taskProjectId: overviewProjectId,
  });

  const { userId } = useOrganizationContext();

  const convertParamToPermission = () => {
    const permissionMiddles = {
      createTask: 'Create',
      editTask: 'Update',
      viewTask: 'Read',
    };
    const actionParam = searchParams.get('action');
    return permissionMiddles[actionParam as keyof typeof permissionMiddles] as
      | 'Update'
      | 'Create'
      | 'Read';
  };

  const hasPermissionToDoAction = () => {
    if (!routeCreate && !routeEdit && !routeView) return;
    const typeActionPrefix = getPermissionPrefix(
      convertParamToPermission(),
      taskType
    );
    const all =
      permissions[`${typeActionPrefix}All` as keyof QcAndTaskPermissionsType];
    const ownAssigned =
      permissions[
        `${typeActionPrefix}OwnAssigned` as keyof QcAndTaskPermissionsType
      ];
    const own =
      permissions[`${typeActionPrefix}Own` as keyof QcAndTaskPermissionsType];
    const createdByUser = currentTask?.createdByUser?.id === userId;
    const createdByOrAssignedToUser =
      currentTask?.assignment?.[0]?.id === userId || createdByUser;
    return (
      all ||
      (ownAssigned && createdByOrAssignedToUser) ||
      (own &&
        (createdByUser || ['Create'].includes(convertParamToPermission())))
    );
  };

  const isSidebarOpen = loading || isOpenFromTable;

  const isFocusComment = Boolean(searchParams.get('focusComment'));

  const changeTabs = (value: TabValues.Properties) => {
    setCurrentTab(value);
    searchParams.set({ tab: value });
    if (isFocusComment) searchParams.delete('focusComment');
  };

  useSetItemInLocalState<Task>(currentTask);

  const {
    state: { item },
  } = useOptimisticResponseContext<Task>();

  const { currentTab, setCurrentTab } = useSidebarTabs(initialTab);

  if (routeCreate && currentTab !== TabValues.Properties)
    setCurrentTab(TabValues.Properties);

  const sidebarActions = useSidebarButtons({
    currentTask: item,
    taskProcedureId,
  });

  const { onClose, onViewTask, goBackToDocumentSideBar } = useSidebarActions({
    currentTask: item,
    taskProcedureId,
  });

  const TabsWrapper = ({
    onChange,
    children,
  }: PropsWithChildren<TabsWrapperProps>) => (
    <Tabs
      onChange={(_, value) =>
        onChange ? onChange(value) : setCurrentTab(value)
      }
      value={currentTab}
    >
      {children}
    </Tabs>
  );

  return (
    <Sidebar onClose={onClose} open={isSidebarOpen} wide>
      {loading || hasPermissionToDoAction() ? (
        <ResponseWrapper error={error} isLoading={loading}>
          <Sidebar.Header
            icon={taskType === TaskType.Resolve ? 'taskSucceeded' : 'qcTask'}
            title={routeCreate ? t('New task') : data && item?.name}
          >
            <Sidebar.SidebarActionBar sidebarActions={sidebarActions} />

            {item &&
              [
                TaskType.Document,
                TaskType.QualityControl,
                TaskType.Resolve,
              ].includes(taskType) &&
              !routeCreate && (
                <TabsWrapper onChange={changeTabs}>
                  <Tab
                    data-testid="task-sidebar-properties-tab"
                    key="properties"
                    label={t('Properties')}
                    value={TabValues.Properties}
                  />
                  <Tab
                    data-testid="task-sidebar-properties-tab"
                    key="properties"
                    label={t('Comments')}
                    value={TabValues.Comments}
                  />
                </TabsWrapper>
              )}
          </Sidebar.Header>

          {currentTab === TabValues.Properties && (
            <>
              {routeCreate && (
                <TaskForm
                  onClose={documentTask ? goBackToDocumentSideBar : onClose}
                  procedureId={taskProcedureId}
                  projectId={overviewProjectId || (projectId ?? '')}
                  taskType={taskType}
                />
              )}

              {routeEdit && item && (
                <TaskForm
                  onClose={onViewTask}
                  procedureId={taskProcedureId}
                  projectId={overviewProjectId || (projectId ?? '')}
                  task={item}
                  taskType={taskType}
                />
              )}

              {routeView && item && (
                <>
                  <TaskProperties task={item} />
                  <LinkedInstances currentTask={item} taskType={taskType} />
                </>
              )}
            </>
          )}
          {currentTab === TabValues.Comments && item && (
            <TaskComments
              projectId={overviewProjectId || (projectId ?? '')}
              taskId={item.id}
            />
          )}
        </ResponseWrapper>
      ) : (
        <BigMessageNoAccess />
      )}
    </Sidebar>
  );
};
