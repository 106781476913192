import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import fetch from 'cross-fetch';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { getOidcHeaders } from '@pro4all/authentication/src/utils';
import { getToken } from '@pro4all/authentication/src/utils';
import { ApiConfig, StorageKeys } from '@pro4all/shared/config';
import { ERROR_NOT_LOGGED_IN_VIA_SSO } from '@pro4all/shared/config';
import {
  NewBackground,
  ProstreamLogo,
  WhiteBackground,
} from '@pro4all/shared/qc-report-assets';

const httpLink = createHttpLink({
  fetch: (uri, options) => {
    const { operationName } = JSON.parse(options?.body as string);
    return fetch(`${uri}?operation=${operationName}`, options);
  },
  uri: ApiConfig.graphql,
});
const authLink = setContext(async (_, { headers }) => {
  const token = await getToken();
  return {
    //IMPORTANT: if you add headers to this object, ask the security officer to add the new headers to the white list to prevent CORS errors.
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'x-background': NewBackground,
      'x-logo': ProstreamLogo,
      'x-project-id': sessionStorage.getItem(StorageKeys.PROJECT_ID) ?? '',
      'x-project-organization-id':
        sessionStorage.getItem(StorageKeys.PROJECT_ORGANIZATION_ID) ?? '',
      ...getOidcHeaders(),
      'x-white-background': WhiteBackground,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions?.['code'] === ERROR_NOT_LOGGED_IN_VIA_SSO) {
        AuthService.signinRedirect();
      }
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      CommentThread: {
        fields: {
          messages: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Message: {
        fields: {
          attachments: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          references: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      MessageBranch: {
        fields: {
          previous: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Query: {
        fields: {
          messageInbox: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      TBQCategory: { keyFields: false },
      TBQGoal: { keyFields: false },
      UnreadCount: {
        fields: {
          unreadCount: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      User: {
        fields: {
          firstName: {
            merge(existing, incoming) {
              return existing || incoming;
            },
          },
          lastName: {
            merge(existing, incoming) {
              return existing || incoming;
            },
          },
        },
      },
      ValueType: { keyFields: false },
    },
  }),
  link: from([errorLink, authLink.concat(httpLink)]),
});
