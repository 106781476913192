import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client/errors';
import { useSnackbar } from 'notistack';

import {
  Connection,
  IntegrationWithConnection,
  useConnectProjectMutation,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';
import { useShowMessages } from '@pro4all/shared/ui/messages';

import { getConnectionCustomTypeInput } from './getConnectionCustomTypeInput';
import { FormFields } from './types';
import { useGetIntegrationTypeData } from './useGetIntegrationTypeData';

export const useSubmit = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [connectProject] = useConnectProjectMutation();

  const { connections, setConnections } = useProjectContext();
  const { showSingleError } = useShowMessages();
  const {
    editItems,
    state: { items },
  } = useOptimisticResponseContext<IntegrationWithConnection>();
  const { integrationType } = useGetIntegrationTypeData();
  const { userDisplayName, userId } = useOrganizationContext();

  const { params, searchParams } = useRouting();
  const integrationId = searchParams.get('id');
  const { projectId } = params;
  return async (values: FormFields) => {
    const integrationCustomTypeInput = getConnectionCustomTypeInput({
      integrationType,
      valueType: values,
    });

    const isConnectionRemoved = integrationCustomTypeInput.id === null;

    const projectName = values?.project?.label;

    try {
      const response = await connectProject({
        variables: {
          customConnectionProps: integrationCustomTypeInput,
          integrationId: integrationId || '',
          projectId: projectId || '',
        },
      });

      const selectedIntegration = items.find(
        (item) => item.id === integrationId
      ) as IntegrationWithConnection;

      const newConnection: Connection = {
        connectionName: response?.data?.connectProject?.connectionName ?? '',
        createdAt: new Date().toISOString(),
        createdBy: {
          __typename: 'User',
          displayName: userDisplayName,
          id: userId ?? '',
        },
        id: response?.data?.connectProject?.id ?? '',
        integrationId: selectedIntegration.id,
        integrationType: selectedIntegration.integrationType,
        projectId: response?.data?.connectProject?.projectId ?? '',
      };

      editItems([
        {
          ...selectedIntegration,
          connectionStatus: !isConnectionRemoved ? true : false,
          integrationConnection: newConnection,
          projectId: projectId || '',
        },
      ]);

      connections.push(newConnection);

      setConnections(connections);
      onClose();

      const integration = selectedIntegration?.integrationName;
      const message = isConnectionRemoved
        ? t(
            'Succesfully removed the connection with the {{integration}} project.',
            { integration }
          )
        : t(
            "Succesfully connected with the {{integration}} project '{{projectName}}'.",
            { integration, projectName }
          );

      enqueueSnackbar(message);
    } catch (e) {
      showSingleError(e as ApolloError);
    }
  };
};
