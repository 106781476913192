import React from 'react';
import { useField } from 'formik';

import { AutocompleteChangeReason } from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import {
  SearchableSelect,
  SearchableSelectProps,
} from '@pro4all/shared/ui/inputs';

export const FormikSearchableSelect: React.FC<
  SearchableSelectProps & { name: string }
> = ({ name, onChange, onBlur, ...props }) => {
  const [field, meta, helpers] = useField({ name });
  const hasError = Boolean(meta.touched) && Boolean(meta.error);

  const handleChange = (value: Option, reason: AutocompleteChangeReason) => {
    helpers.setValue(value);
    onChange && onChange(value, reason);
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    helpers.setTouched(true);
    onBlur && onBlur(event);
  };

  return (
    <SearchableSelect
      {...props}
      {...field}
      error={hasError && Boolean(meta.error)}
      helperText={hasError && meta.error}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};
