import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TrackingEvent } from '@pro4all/shared/config';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { Option } from '@pro4all/shared/types';
import { Select } from '@pro4all/shared/ui/inputs';
import {
  useOptimisticResponseContext,
  useTableContext,
} from '@pro4all/shared/ui/table';
import { useAnalytics } from '@pro4all/shared/vendor';

import { useFilterContext } from './FilterContext';
import {
  convertPredefinedFilterToOption,
  filtersAreEqual,
  getPredefinedFilterByFilters,
  getPredefinedFilterForOption,
} from './PredefinedFilterUtils';
import { PredefinedFilter } from './types';

export const PredefinedFilterSelect = ({
  dataTestId,
  predefinedFilters,
}: {
  dataTestId?: string;
  predefinedFilters: PredefinedFilter[];
}) => {
  /**  BEFORE YOU VENTURE FORTH!
   *
   *  This component is a simple select but its logic is complex.
   *
   *  We have a few objects that we have to match since we want to see if there is a Predefined
   *  Filter for the filters that the user manually has set, we have to do a lot of matching and
   *  finding between Options, Predefined Filters and Filters coming from the context.
   *
   *  - Select Option
   *  - Predefined Filter (which is a named set of filters that are used in the useFilterContext)
   *  - Filters (which are the actual columns filters)
   */
  const { setRenderToggle } = useTableContext();
  const { track } = useAnalytics();
  const { t } = useTranslation();

  const isMobile = useIsMobileScreen();

  const [options, setOptions] = useState<Option[]>([]);
  const [activeOption, setActiveOption] = useState<Option>();
  const { filters, applyFilters } = useFilterContext();

  const { setRecalculateFilters } = useOptimisticResponseContext();

  useEffect(() => {
    const activeFilter = activeOption
      ? getPredefinedFilterForOption(predefinedFilters, activeOption)
      : undefined;

    if (!activeFilter) {
      // If no active filter is set, try to find one based on the current filters
      const predefinedFilter = getPredefinedFilterByFilters(
        predefinedFilters,
        filters
      );
      if (predefinedFilter) {
        const option = convertPredefinedFilterToOption(predefinedFilter);
        setActiveOption(option);
        return;
      }
    } else if (
      activeFilter &&
      !filtersAreEqual(filters, activeFilter?.filters)
    ) {
      // There is a filter set, but the user changed the filters in the table
      // since they do not match with the current activeFilter
      const predefinedFilter = getPredefinedFilterByFilters(
        predefinedFilters,
        filters
      );
      if (predefinedFilter) {
        setActiveOption(convertPredefinedFilterToOption(predefinedFilter));
        return;
      }
    } else if (
      activeFilter &&
      filtersAreEqual(filters, activeFilter?.filters)
    ) {
      // Do nothing
      return;
    }
    setActiveOption(options[0]);
  }, [filters, options]);

  useEffect(() => {
    const newOptions: Option[] = [
      ...[
        {
          disabled: true,
          id: '',
          label: t('Select a filter'),
        },
      ],
      ...predefinedFilters.map((filter) =>
        convertPredefinedFilterToOption(filter)
      ),
    ];

    setOptions(newOptions);
  }, [predefinedFilters]);

  const onChange = (option: Option) => {
    const filter = getPredefinedFilterForOption(predefinedFilters, option);

    track(TrackingEvent.PredefinedFilterSelect, {
      filter: filter?.title,
      location: dataTestId,
    });

    if (filter) {
      applyFilters(filter.filters);
      setRecalculateFilters(true);
      setRenderToggle && setRenderToggle(true);
    }
  };

  return !isMobile ? (
    <StyledSelect
      data-testid={dataTestId}
      name="predefined-filter-select"
      onChange={onChange}
      options={options}
      updateValue
      value={activeOption ? activeOption.id : ''}
    />
  ) : null;
};

const StyledSelect = styled(Select)`
  min-width: 150px;
`;
