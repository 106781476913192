import React from 'react';

import { Folder, FolderPermission } from '@pro4all/graphql';
import { Checkbox } from '@pro4all/shared/ui/inputs';

import { EntityPermissionToggle } from './entity-context/types';
import { InheritedPermission } from './InheritedPermissionsIcon';
import { PermissionCellContainer } from './PermissionCellContainer';

export interface PermissionEntityCellProps {
  checked: boolean;
  disabled: boolean;
  entityId: string;
  folder: Folder;
  inheritedFromGroups: string[];
  permission: FolderPermission;
  showFinalPermissions: boolean;
  togglePermission: ({ entityId, permission }: EntityPermissionToggle) => void;
  tooltip: string;
}

const PermissionEntityCellComponent = ({
  checked,
  disabled,
  entityId,
  folder,
  inheritedFromGroups,
  tooltip,
  permission,
  showFinalPermissions,
  togglePermission,
}: PermissionEntityCellProps) => {
  const checkedFinal = checked || inheritedFromGroups.length > 0;
  return (
    <>
      <PermissionCellContainer placement="bottom" title={tooltip}>
        {showFinalPermissions ? (
          <Checkbox checked={checkedFinal || false} disabled />
        ) : (
          <Checkbox
            checked={checked || false}
            disabled={disabled}
            onChange={async () => {
              togglePermission({ entityId, permission });
            }}
          />
        )}
      </PermissionCellContainer>
      {showFinalPermissions ? null : (
        <InheritedPermission
          folder={folder}
          groups={inheritedFromGroups}
          permission={permission}
        />
      )}
    </>
  );
};

const skipUpdate = (
  prevProps: PermissionEntityCellProps,
  nextProps: PermissionEntityCellProps
) =>
  prevProps.checked === nextProps.checked &&
  prevProps.disabled === nextProps.disabled &&
  prevProps.showFinalPermissions === nextProps.showFinalPermissions &&
  prevProps.tooltip === nextProps.tooltip;

export const PermissionEntityCell = React.memo(
  PermissionEntityCellComponent,
  skipUpdate
);
