import { useEffect } from 'react';
import { Formik } from 'formik';

import {
  EntityType,
  QcPermissionCategory,
  useGetProjectPermissionsLazyQuery,
} from '@pro4all/graphql';
import { getHighestPermissions } from '@pro4all/shared/permissions';
import { useGenericContext } from '@pro4all/shared/providers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { PermissionUserOption } from '@pro4all/shared/types';
import { FormikForm } from '@pro4all/shared/ui/formik';
import { DiscardWrapper } from '@pro4all/shared/ui/messages';
import { BigMessageSelectPermissionsTarget } from '@pro4all/shared/ui/messages';

import MatrixFooter from './components/MatrixFooter';
import QCPermissionMatrixTable from './components/QCPermissionMatrixTable';
import { FormContainer, OverflowContainer } from './PermissionsMatrix.styled';
import { useSubmit } from './useSubmit';
import { PermissionCategory } from './utils';

interface Props {
  category: QcPermissionCategory;
  isAdmin: boolean;
  permissionCategories: PermissionCategory[];
  target?: PermissionUserOption;
}

export interface QCPermissionsFormValue {
  [key: string]: string;
}

function PermissionsMatrix({
  target,
  isAdmin,
  permissionCategories,
  category,
}: Props) {
  const { projectId } = useRouting().params;
  const {
    state: { showHighestQCPermission },
  } = useGenericContext();
  const [getPermissions, permissionData] = useGetProjectPermissionsLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      category,
      isMemberGroupFromOrg: Boolean(
        target?.type === EntityType[1] && target?.organizationId
      ),
      memberId: target?.id,
      memberType: EntityType[target?.type as keyof typeof EntityType],
      projectId,
    },
  });
  const projectPermissions = permissionData?.data?.getProjectPermissions;

  useEffect(() => {
    if (!target || !projectId || !getPermissions) return;
    getPermissions();
  }, [target, projectId, getPermissions]);

  const getInitialFormValues = () => {
    const permissions = showHighestQCPermission
      ? getHighestPermissions(projectPermissions)
      : projectPermissions?.directPermissions;

    const currentPermissions = permissions?.map((permission: string) =>
      permission.replace(/\./g, '-')
    );

    const findValueOfPermissionBase = (base: string) =>
      currentPermissions?.find((perm: string) => perm.includes(base));
    const formPermissions = permissionCategories
      .flatMap((category) => category.permissions)
      .reduce((acc: QCPermissionsFormValue, permission) => {
        const permissionBaseStr = permission.permissionBase as string;
        if (permission.values) {
          acc[permissionBaseStr] =
            findValueOfPermissionBase(permission.permissionBase) ?? '';
        } else if (currentPermissions?.includes(permission.permissionBase)) {
          acc[permissionBaseStr] = 'report-create';
        } else {
          acc[permissionBaseStr] = '';
        }
        return acc;
      }, {});

    return formPermissions;
  };

  const onSubmit = useSubmit({
    category,
    inheritedPermissions: projectPermissions?.inheritedPermissions,
    isMemberGroupFromOrg: Boolean(
      target?.type === EntityType[1] && target?.organizationId
    ),
    member: {
      id: target?.id,
      type: EntityType[target?.type as keyof typeof EntityType],
    },
    projectId,
  });

  return target?.id ? (
    <FormContainer>
      <Formik
        enableReinitialize
        initialValues={getInitialFormValues()}
        onSubmit={onSubmit}
      >
        {({ values, dirty, resetForm, setFieldValue }) => (
          <DiscardWrapper dirty={dirty}>
            <FormikForm>
              <OverflowContainer>
                <QCPermissionMatrixTable
                  handleChange={(e: { name: string; value: string }) => {
                    setFieldValue(e.name, e.value);
                  }}
                  inheritedPermissions={
                    projectPermissions?.inheritedPermissions
                  }
                  isAdmin={isAdmin}
                  permissionCategories={permissionCategories}
                  targetIsExplicitProjectMember={target?.explicitProjectMember}
                  values={values}
                />
              </OverflowContainer>
              <MatrixFooter
                dirty={dirty}
                onSubmit={() => onSubmit(values)}
                resetForm={resetForm}
              />
            </FormikForm>
          </DiscardWrapper>
        )}
      </Formik>
    </FormContainer>
  ) : (
    <BigMessageSelectPermissionsTarget />
  );
}

export default PermissionsMatrix;
