import React from 'react';

import { gqlType, Member } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { PermissionUserOption } from '@pro4all/shared/types';
import { useSelectUsersAndGroupsDropdownSources } from '@pro4all/shared/ui/identity-card';
import { SearchableSelect } from '@pro4all/shared/ui/inputs';

interface Props {
  disabled?: boolean;
  members: Member[];
  onChange: (selectedOption: PermissionUserOption) => void;
  value?: PermissionUserOption;
}

export const PersonOrGroupSelect = ({
  disabled = false,
  members = [],
  onChange,
  value,
}: Props) => {
  const options: PermissionUserOption[] = members.map((member) => ({
    explicitProjectMember: member.explicitProjectMember,
    groupOption: {
      totalUsers: gqlType('Group')(member) ? member.totalUsers : 0,
    },
    iconName:
      member.__typename === 'User'
        ? member.isAdmin
          ? 'admin'
          : 'personOutline'
        : 'group',
    id: member.id,
    label:
      member.__typename === 'User'
        ? `${member.displayName} ${member.email && `(${member.email})`}`
        : member.displayName,
    ...(member.organizationId ? { organizationId: member.organizationId } : {}),
    type: member.__typename,
    userOption: {
      email: gqlType('User')(member) ? member.email : '',
      isAdmin: gqlType('User')(member) ? member.isAdmin : false,
      organization: member.organization,
    },
  }));

  const {
    noOptionsText,
    optionsToPassIn,
    placeholder,
    renderCustomInput,
    renderCustomOption,
  } = useSelectUsersAndGroupsDropdownSources({
    options,
  });

  return (
    <Box>
      <SearchableSelect
        data-testid="selectEntity"
        disabled={disabled}
        name="selectEntity"
        noOptionsText={noOptionsText}
        onChange={onChange}
        options={optionsToPassIn}
        placeholder={placeholder}
        renderCustomInput={renderCustomInput}
        renderCustomOption={renderCustomOption}
        value={value && value.id ? value : null}
      />
    </Box>
  );
};
