import { STORAGE_ID } from '@pro4all/authentication/src/config';

const getOidcStorage = () => {
  const storageString = localStorage.getItem(STORAGE_ID);

  if (storageString) {
    return JSON.parse(storageString);
  }

  return {};
};

export const getOidcHeaders = () => {
  const oidcStorage = getOidcStorage();
  return {
    'x-user-id': oidcStorage?.profile?.userId ?? '',
    'x-user-organization-id': oidcStorage?.profile?.tenantId ?? '',
  };
};

export const getToken = () => {
  const oidcStorage = getOidcStorage();
  return oidcStorage.access_token;
};

export const getIdToken = () => {
  const oidcStorage = getOidcStorage();
  return oidcStorage.id_token;
};
