import React, { useCallback, useContext, useReducer } from 'react';

import { SentOrInbox } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

import {
  ActionType,
  BatchInfo,
  initialState,
  messageReducer,
  State,
} from './messageReducer';

type ContextType = {
  clearBatchInfo: () => void;
  setBatchInfo: (batchInfo: BatchInfo[]) => void;
  setCurrentTab: (currentTab: SentOrInbox) => void;
  state: State;
};

const MessageContext = React.createContext<ContextType>({} as ContextType);

export function useMessageContext() {
  return useContext<ContextType>(MessageContext);
}

export function MessageProvider({ children }: { children: JSX.Element }) {
  const [state, dispatch] = useReducer(messageReducer, initialState);
  const { url } = useRouting();

  // Define all actions
  const clearBatchInfo = useCallback(() => {
    dispatch({ type: ActionType.CLEAR_BATCH_INFO });
  }, []);
  const setBatchInfo = useCallback((batchInfo: BatchInfo[]) => {
    dispatch({
      payload: batchInfo,
      type: ActionType.SET_BATCH_INFO,
    });
  }, []);
  const setCurrentTab = useCallback((currentTab: SentOrInbox) => {
    dispatch({
      payload: currentTab,
      type: ActionType.SET_SELECTED_TAB,
    });
  }, []);

  React.useEffect(() => {
    const match = url.match(/inbox|sent|all|draft/);
    const result = match ? match[0] : (null as SentOrInbox | null);
    if (result) {
      const currentTab = {
        all: SentOrInbox.All,
        draft: SentOrInbox.Draft,
        inbox: SentOrInbox.Inbox,
        sent: SentOrInbox.Sent,
      }[result as SentOrInbox];
      setCurrentTab(currentTab);
    }
  }, [url, setCurrentTab]);

  return (
    <MessageContext.Provider
      value={{
        clearBatchInfo,
        setBatchInfo,
        setCurrentTab,
        state,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
}
