import React from 'react';
import { useTranslation } from 'react-i18next';

import { StorageKeys } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { MoveDocumentCountData } from '@pro4all/shared/types';
import { Dialog } from '@pro4all/shared/ui/dialog';
import { Text } from '@pro4all/shared/ui/typography';

export const ModalMoveDocuments = () => {
  const { t } = useTranslation();

  const { searchParams } = useRouting();

  const { getLocalStorageItem: getMoveDocumentsCount } =
    useLocalStorage<MoveDocumentCountData>({
      key: StorageKeys.MOVE_DOCUMENTS_COUNT,
    });

  const convertDocumentsToDrawings = searchParams.is(
    'action',
    'moveDocumentsInfo'
  );

  const onClose = () => {
    searchParams.delete('action');
  };

  return (
    <Dialog
      confirmLabel={t('Ok')}
      iconName="info"
      name="moveDocuments"
      onConfirm={onClose}
      open={convertDocumentsToDrawings}
      title={t('Move documents')}
    >
      <Text>
        {t(
          'Due to lacking permissions only {{count}} of {{total}} documents can be cut to move to another folder.',
          getMoveDocumentsCount()
        )}
      </Text>
    </Dialog>
  );
};
