import { useTranslation } from 'react-i18next';

import { Instance } from '@pro4all/graphql';
import {
  FilterColumnIdProps,
  FilterColumnProps,
  useFilterContext,
} from '@pro4all/shared/ui/filtering';
import {
  BaseRow,
  useOptimisticResponseContext,
} from '@pro4all/shared/ui/table';

import { NO_VALUE } from '@pro4all/shared/constants';
import { getColumnKey, isPropFilled } from './helpers';

export function useValues<Row extends BaseRow, SubProp>({
  flattenOptions,
  getCustomValueCallback,
  isMetaData = false,
  isMultiSelect = false,
  metaDataHeaderId = '',
  propertyId,
  subPropertyId,
  translateOptions,
}: Pick<
  FilterColumnProps,
  | 'flattenOptions'
  | 'isMetaData'
  | 'isMultiSelect'
  | 'metaDataHeaderId'
  | 'translateOptions'
> &
  Pick<
    FilterColumnIdProps<Row, SubProp>,
    'propertyId' | 'subPropertyId' | 'getCustomValueCallback'
  >) {
  const { t } = useTranslation();

  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<Row>();

  // In case column A has an active filter, column B can only offer the options that are in scope of the column A filter.
  // So the logic below calculates itemsToMap, that will be used to provide the column values.

  const { filters, getItemsThatAreIncludedInAllFilters } =
    useFilterContext<Row>();
  const { keyPropertyId, keySubPropertyId } = getColumnKey<Row, SubProp>({
    metaDataHeaderId,
    propertyId,
    subPropertyId,
  });
  // const propIncluded = filtersArray.includes(keyPropertyId);
  const propIncluded = filters
    ? Boolean(
        filters.find((filter) =>
          subPropertyId
            ? filter.propertyId === keyPropertyId &&
              filter.subPropertyId === keySubPropertyId
            : filter.propertyId === keyPropertyId
        )
      )
    : false;

  let itemsToMap: Row[] = [];

  const filtersOtherColumns = filters
    ? subPropertyId
      ? filters.filter(
          (filter) =>
            !(
              filter.propertyId === keyPropertyId &&
              filter.subPropertyId === keySubPropertyId
            )
        )
      : filters.filter((filter) => filter.propertyId !== keyPropertyId)
    : [];

  if (filtersOtherColumns.length) {
    // There are other columns with an active filter. Take the items from these other columns.
    filtersOtherColumns.forEach((filter) => {
      filter.itemsFiltered.forEach((item) => {
        if (!itemsToMap.map((itemToMap) => itemToMap.id).includes(item.id)) {
          // Only if the item has not been added yet.
          itemsToMap.push(item);
        }
      });
    });
  } else {
    // No other columns with an active filter. Take the items from the OptimisticResponseProvider.
    const takeItemsProp = filters
      ? propIncluded
        ? Boolean(filters.length > 1)
        : Boolean(filters.length)
      : false;

    itemsToMap = takeItemsProp
      ? items
      : itemsInitial.length
      ? itemsInitial
      : items;
  }

  //Function that returns the values of the subProperty without duplicates items.
  const getValuesSubProperty = () => {
    if (!subPropertyId) return undefined;

    //Array to check previously added properties.
    const addedProperties = new Set();
    const values = [];

    for (const item of itemsToMap) {
      const value = item[propertyId];
      //Check if the value has not been added yet.
      if (!addedProperties.has(value)) {
        const valueTyped = value as unknown as SubProp;
        const propValue = valueTyped ? valueTyped[subPropertyId] : [NO_VALUE];

        values.push(propValue);
        addedProperties.add(value);
      }
    }
    return values;
  };

  let response = [];
  if (subPropertyId) {
    const subValues = getValuesSubProperty();
    response = [...new Set(subValues)];
    if (isMetaData) {
      // Meta data column, special treatment to check values.
      const metaDatavalues = response.map((doc) => {
        const documentValues = doc as unknown as Instance[];
        const docValues =
          typeof documentValues === 'string'
            ? [documentValues]
            : documentValues;
        return (
          docValues?.find(
            (answer) => answer.fieldDefinitionId === metaDataHeaderId
          )?.value || NO_VALUE
        );
      });
      if (isMultiSelect) {
        // In case this column contains a multiselect value, the values are stored in a comma delimited string (f.i. 'value1,value2,value3').
        // So we have to split them and add all values to the options list.
        const valuesSplitted: string[] = [];
        metaDatavalues.forEach((value) => {
          if (value.includes(',')) {
            value
              .split(',')
              .forEach((singleValue) => valuesSplitted.push(singleValue));
          } else {
            valuesSplitted.push(value);
          }
        });
        response = [...new Set(valuesSplitted)];
      } else {
        response = [...new Set(metaDatavalues)];
      }
    }
  } else {
    // Normal column.
    const values = [
      ...new Set(
        itemsToMap.map((item) =>
          getCustomValueCallback
            ? getCustomValueCallback(item[propertyId])
            : isPropFilled<Row, SubProp>({ item, propertyId })
            ? item[propertyId]
            : NO_VALUE
        )
      ),
    ];
    response = values;
  }

  const getOptionCount = (option: string) => {
    // This method calculates the number of rows contain this option in the column.
    let itemsForCounting = itemsToMap;

    if (filters && filters.length > 1) {
      const filtersMinusCurrent = filters.filter(
        (filter) =>
          !(
            filter.propertyId === propertyId &&
            filter.subPropertyId === subPropertyId
          )
      );

      itemsForCounting = getItemsThatAreIncludedInAllFilters({
        filters: filtersMinusCurrent,
        flattenOptions,
      });
    }

    let count = 0;
    if (subPropertyId) {
      const values = itemsForCounting.map((item) => item[propertyId]);
      if (isMetaData) {
        // Meta data column, special treatment to get value.
        const subValues = getValuesSubProperty();
        const documentsValues = [...new Set(subValues)];

        const docsWithOptionIncluded = documentsValues.filter((doc) => {
          const documentValues = doc as unknown as Instance[];
          if (documentValues.length) {
            const allFieldDefinitionIds = documentValues.map(
              (answer) => answer.fieldDefinitionId
            );
            if (allFieldDefinitionIds.includes(metaDataHeaderId)) {
              // This column is included as meta data value.
              return documentValues.find((answer) => {
                if (isMultiSelect) {
                  // In case this column contains a multiselect value, the values are stored in a comma delimited string (f.i. 'value1,value2,value3').
                  // So we have to split them before we check the passed in option.
                  if (answer.fieldDefinitionId === metaDataHeaderId) {
                    const multiValues = answer.value
                      ? answer.value.split(',')
                      : [];
                    if (multiValues.includes(option)) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                } else {
                  // Meta data column with single value.
                  if (option !== NO_VALUE) {
                    return (
                      answer.fieldDefinitionId === metaDataHeaderId &&
                      answer.value === option
                    );
                  } else {
                    // Return rows that have no value for this column.
                    return (
                      answer.fieldDefinitionId === metaDataHeaderId &&
                      !answer.value
                    );
                  }
                }
              });
            } else {
              // There are meta data values for this document but this column is NOT included as a meta data value, so no value. Check on no value.
              if (option === NO_VALUE) {
                return true;
              } else {
                return false;
              }
            }
          } else {
            // No meta data values at all for this document, so no value. Check on no value.
            if (option === NO_VALUE) {
              return true;
            } else {
              return false;
            }
          }
        });
        count = docsWithOptionIncluded.length;
      } else {
        // Normal column. We have to take the value from the subProperty.
        count = values.filter((value) => {
          const valueTyped = value as unknown as SubProp;
          const subValue = valueTyped
            ? (valueTyped[subPropertyId] as unknown as string)
            : NO_VALUE;

          return translateOptions
            ? t(subValue.toString()) === option.toString()
            : subValue.toString() === option.toString();
        }).length;
      }
    } else {
      // Normal column. We have to take the value from the property.
      count = itemsForCounting.filter((item) => {
        const itemValue = getCustomValueCallback
          ? getCustomValueCallback(item[propertyId])
          : isPropFilled<Row, SubProp>({ item, propertyId })
          ? (item[propertyId] as unknown as string)
          : NO_VALUE;

        if (flattenOptions && typeof itemValue !== 'string') {
          const values = translateOptions ? itemValue.map(t) : itemValue;
          return values.includes(option.toString());
        } else
          return translateOptions
            ? t(itemValue.toString()) === option.toString()
            : itemValue.toString() === option.toString();
      }).length;
    }
    return count;
  };

  const _columnValues = response as string[];
  const translatedValues = translateOptions
    ? _columnValues.map((columnValue) => t(columnValue))
    : _columnValues;
  const translatedAndSorted = translatedValues.sort();
  const columnValues = flattenOptions
    ? Array.from(new Set(translatedAndSorted.flatMap((value) => value)))
    : translatedAndSorted.map((columnValue) =>
        columnValue ? columnValue.toString() : ''
      );

  return {
    columnValues,
    getOptionCount,
  };
}
