import React from 'react';

import {
  Document,
  DocumentVersion,
  useDocumentHistoryQuery,
} from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { EventLog } from '@pro4all/shared/history';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Loader } from '@pro4all/shared/ui/loader';

export const DocumentHistoryTab: React.FC<{
  documentOrVersion: Document | DocumentVersion;
}> = ({ documentOrVersion }) => {
  const { params } = useRouting();
  const projectId = params.projectId;
  const { data, loading } = useDocumentHistoryQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000 * ApiConfig.pollEnabled,
    variables: {
      documentId:
        documentOrVersion.__typename === 'DocumentVersion'
          ? documentOrVersion.documentId
          : documentOrVersion.id,
      fetchForVersion: Boolean(
        documentOrVersion.__typename === 'DocumentVersion'
      ),
      metadataInstanceId:
        documentOrVersion.__typename === 'DocumentVersion'
          ? documentOrVersion.metadataInstanceId
          : null,
      projectId,
      versionId:
        documentOrVersion.__typename === 'DocumentVersion'
          ? documentOrVersion.id
          : null,
    },
  });

  return loading ? (
    <Loader />
  ) : (
    <>
      <EventLog events={data?.documentHistory?.today} groupName="Today" />
      <EventLog
        events={data?.documentHistory?.thisWeek}
        groupName="This week"
      />
      <EventLog
        events={data?.documentHistory?.thisMonth}
        groupName="This month"
      />
      <EventLog events={data?.documentHistory?.older} groupName="Older" />
    </>
  );
};
