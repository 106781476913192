import { DropzoneState } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { useClientRedirectContext } from '@pro4all/shared/contexts';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';

import { PublishVersionForm } from './PublishVersionForm';

export const PublishVersionModal = ({
  openFileInputPublish,
}: {
  openFileInputPublish: DropzoneState['open'];
}) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const showModal = searchParams.is('action', 'publishVersion');
  const { clearState } = useClientRedirectContext();

  const onClose = () => {
    searchParams.delete('action');
    searchParams.delete('publishDocumentId');
    clearState();
  };

  return (
    <FloatingModal
      data-testid="publish-version"
      onClose={onClose}
      open={showModal}
    >
      <FloatingModal.Header iconName="download">
        {t('Publish new version')}
      </FloatingModal.Header>
      <PublishVersionForm
        onClose={onClose}
        openFileInputPublish={openFileInputPublish}
      />
    </FloatingModal>
  );
};
