import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { AccessTokenInterceptor } from './interceptors/AccessTokenInterceptor';
import { ProjectIdInterceptor } from './interceptors/ProjectIdInterceptor';
import { ErrorCode } from './FileDataTypes';

interface UploadFileProps {
  chunkSize?: number;
  file: File;
  metadataInstanceId?: string;
  onProgress?: (progress: number) => void;
  originalFilename?: string;
  overwriteFilename?: boolean;
  publishDocumentVersionId?: string;
  url: string;
  withChunks?: boolean;
}

interface UploadFileWithExceptionHandlingProps {
  file: File;
  url: string;
}

type UploadFileHeaders = {
  'Content-Range': string;
  'Content-Type': string;
  'X-P4-FileId': string;
  'X-P4-Filename': string;
  'X-P4-MetadataInstanceId'?: string;
  'X-P4-OriginalFilename'?: string;
  'X-P4-Overwrite': boolean;
};

class AuthenticatedApiService {
  axios: AxiosInstance;

  constructor(baseURL: string) {
    this.axios = axios.create({ baseURL, params: {}, timeout: 10000 });
    this.axios.interceptors.request.use(AccessTokenInterceptor);
    this.axios.interceptors.request.use(ProjectIdInterceptor);
  }

  async get<ResponseType>(url: string, options?: AxiosRequestConfig) {
    return this.axios.request<ResponseType>({ method: 'GET', url, ...options });
  }

  async delete<ResponseType>(url: string, options?: AxiosRequestConfig) {
    return this.axios.request<ResponseType>({
      method: 'DELETE',
      url,
      ...options,
    });
  }

  async patch<ResponseType>(url: string, options?: AxiosRequestConfig) {
    return this.axios.request<ResponseType>({
      method: 'PATCH',
      url,
      ...options,
    });
  }

  async post<ResponseType>(url: string, options?: AxiosRequestConfig) {
    return this.axios.request<ResponseType>({
      method: 'POST',
      url,
      ...options,
    });
  }

  async put<ResponseType>(url: string, options?: AxiosRequestConfig) {
    return this.axios.request<ResponseType>({ method: 'PUT', url, ...options });
  }

  //TODO: remove filename, is overwritten by BE
  download({
    callback,
    fileName,
    options,
    url,
  }: {
    callback?: (status?: 'success' | 'failed') => void;
    fileName: string;
    options?: AxiosRequestConfig;
    url: string;
  }) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);

    this.get<Blob>(url, { responseType: 'blob', ...options })
      .then(({ data, status }) => {
        link.href = window.URL.createObjectURL(data);
        link.click();
        window.URL.revokeObjectURL(link.href);
        callback && callback('success');
        return status;
      })
      .catch((err) => {
        console.error(err);
        link.click();
        callback && callback('failed');
        return null;
      });
  }

  async getDownloadUrl({
    options,
    url,
  }: {
    options?: AxiosRequestConfig;
    url: string;
  }) {
    return this.get<string>(url, {
      responseType: 'text',
      timeout: 0, // disable timeout for downloading files
      ...options,
    });
  }

  async downloadPresigned({
    fileName,
    options,
    url,
  }: {
    fileName: string;
    options?: AxiosRequestConfig;
    url: string;
  }) {
    const response = await this.getDownloadUrl({ options, url });

    this.download({ fileName, url: response.data });
    return response.status;
  }

  async nativeDownloadZip(
    versionIds: string[],
    filename: string,
    includeFolders: boolean,
    options?: AxiosRequestConfig
  ) {
    const response = await this.post<string>(
      `document/version/downloadurl${
        includeFolders ? '?includeFolders=true' : ''
      }`,
      {
        data: { ids: versionIds },
        responseType: 'text',
        timeout: 0, // disable timeout for downloading files
        ...options,
      }
    );
    this.download({ fileName: filename, url: response.data });
    return response.status;
  }

  async getImageUrl(url: string, options?: AxiosRequestConfig) {
    try {
      const response = await this.get<Blob>(url, {
        responseType: 'blob',
        ...options,
      });

      if (response.data.size === 0) return null;

      const imgUrl = window.URL.createObjectURL(new Blob([response.data]));
      return imgUrl;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  // Will upload in chunks if file size exceeds 20MB
  async uploadFile<T>({
    chunkSize = 20971520, //20mb
    file,
    originalFilename = '',
    onProgress,
    metadataInstanceId = null,
    overwriteFilename = false,
    publishDocumentVersionId,
    withChunks = true,
    url,
  }: UploadFileProps): Promise<T> {
    let progress = 0;
    let chunkIndex = 0;
    let fileId: string = null;
    let uploadResult = null;

    // Determine chunk size, total size and amount of chunks.
    const totalSize = file.size;
    const chunkCount = Math.ceil(totalSize / chunkSize);

    if (withChunks) {
      const processChunk = async () => {
        const chunkOffset = chunkIndex * chunkSize;
        const nextChunkOffset = Math.min(chunkOffset + chunkSize, totalSize);

        // Read the current slice.
        const chunkData = file.slice(chunkOffset, nextChunkOffset);

        // Setup the headers.
        const headers: UploadFileHeaders = {
          'Content-Range': `bytes ${chunkOffset}-${
            nextChunkOffset - 1
          }/${totalSize}`,
          'Content-Type': 'application/octet-stream',
          'X-P4-FileId': fileId,
          'X-P4-Filename': encodeURIComponent(
            publishDocumentVersionId
              ? file.name.replace(`-${publishDocumentVersionId}`, '') // In case of publishing the filename MUST include the versionId of the current version, but we DON'T want to include that versionId in the upload filename.
              : file.name
          ),
          'X-P4-OriginalFilename': encodeURIComponent(originalFilename), // encode to support double byte characters
          'X-P4-Overwrite': overwriteFilename,
        };

        if (metadataInstanceId) {
          headers['X-P4-MetadataInstanceId'] = metadataInstanceId;
        }

        try {
          // Upload the chunk.
          const response = await this.post<string>(url, {
            data: chunkData,
            headers,
            timeout: 180000,
            url,
          });
          uploadResult = response.data;

          // Set the fileId returned by the endpoint.
          // By the last chunk, the endpoint does not return a fileId, so skip that one
          fileId = typeof uploadResult === 'string' ? uploadResult : fileId;

          // Up the chunk counter and update progress
          chunkIndex++;
          progress = chunkIndex / chunkCount;
          onProgress && onProgress(progress);

          // More chunks available?
          if (chunkIndex < chunkCount) {
            // Upload the next chunk.
            await processChunk();
          }
        } catch (error) {
          let errorCode: ErrorCode = ErrorCode.API_ERROR_FILE_UPLOAD_FAILED;
          if (error.message.includes('409')) {
            errorCode = ErrorCode.API_ERROR_FILE_NAME_EXISTS;
          }
          if (error.response.data === 'A visualcontext already exists') {
            errorCode = ErrorCode.API_ERROR_FLOORPLAN_NAME_EXISTS;
            uploadResult = { data: { errorCode }, file: file.name };
          } else {
            uploadResult = { data: { errorCode } };
          }
        }
      };
      // Start processing
      await processChunk();
    } else {
      const formData = new FormData();
      formData.append('files', file);

      const response = await this.post<T>(url, {
        data: formData,
        timeout: 180000,
      });
      uploadResult = response.data;
    }

    return uploadResult;
  }

  async getStaticImageUrl(url: string) {
    const response = await this.getImageUrl(url, {
      responseType: 'blob',
      url,
    });

    return response || '';
  }

  async uploadFileWithExceptionHandling<T>({
    file,
    url,
  }: UploadFileWithExceptionHandlingProps): Promise<T> {
    const formData = new FormData();
    formData.append('files', file);

    const response = await this.post<T>(url, {
      data: formData,
      timeout: 180000,
      validateStatus: (status) => status >= 200 && status < 500,
    });

    return response.data;
  }
}

export default AuthenticatedApiService;
