import { useRef } from 'react';

import { useLocalStorage } from '@pro4all/shared/hooks';
import {
  FilterColumnIdProps,
  FilterColumnProps,
} from '@pro4all/shared/ui/filtering';
import {
  BaseRow,
  ColumnSizes,
  useTableContext,
} from '@pro4all/shared/ui/table';

export function useResize<Row extends BaseRow, SubProp>({
  customCallbackId,
  defaultWidth,
  metaDataHeaderId = '',
  minWidth,
  onColumnResizeCallback,
  propertyId,
  subPropertyId,
}: Pick<
  FilterColumnProps,
  | 'customCallbackId'
  | 'defaultWidth'
  | 'metaDataHeaderId'
  | 'minWidth'
  | 'onColumnResizeCallback'
> &
  Pick<FilterColumnIdProps<Row, SubProp>, 'propertyId' | 'subPropertyId'>) {
  const previousDifference = useRef(0);

  const { id, renderToggle, setRenderToggle } = useTableContext<Row>();
  const { localStorageItem: columnSizes, setLocalStorageItem } =
    useLocalStorage<ColumnSizes>({
      key: `prostream-column-sizes-${id}`,
    });

  const onResize = (widthDifference: number) => {
    const columnKey = customCallbackId
      ? customCallbackId
      : subPropertyId
      ? metaDataHeaderId
        ? `${propertyId as string}.${metaDataHeaderId}.${
            subPropertyId as string
          }`
        : `${propertyId as string}.${subPropertyId as string}`
      : (propertyId as string);

    const currentWidth = columnSizes[columnKey];
    const difference = widthDifference - previousDifference.current;
    previousDifference.current = widthDifference;
    const columnWidth = currentWidth
      ? currentWidth + difference
      : defaultWidth + difference;

    const columnMinWidth = minWidth
      ? columnWidth < minWidth
        ? minWidth
        : columnWidth
      : columnWidth < minWidth
      ? minWidth
      : columnWidth;

    const updatedColumnSizes = columnSizes
      ? { ...columnSizes, [columnKey]: columnMinWidth }
      : { [columnKey]: columnMinWidth };

    setLocalStorageItem(updatedColumnSizes);
    onColumnResizeCallback && onColumnResizeCallback(updatedColumnSizes);
    setRenderToggle && setRenderToggle(!renderToggle); // To have the Table component re-rendered.
  };

  const onResizeStop = () => {
    previousDifference.current = 0;
  };

  return { onResize, onResizeStop };
}
