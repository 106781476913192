import {
  QualityControlInstance,
  useBatchQualityControlInstancesIncludeQuery,
} from '@pro4all/graphql';

export const useBatchFetchResults = ({
  fetchPolicy = 'cache-and-network',
  variables,
}: {
  fetchPolicy?:
    | 'cache-first'
    | 'cache-only'
    | 'cache-and-network'
    | 'network-only'
    | 'no-cache'
    | 'standby';
  variables: { [key: string]: boolean | string[]; ids: string[] };
}) => {
  const result = useBatchQualityControlInstancesIncludeQuery({
    fetchPolicy: fetchPolicy,
    skip: variables.ids.length === 0,
    variables,
  });
  // Sometimes result.data is empty during a reload for some reason,
  // use previousData in that case so we don't get strange rerender problems
  const instances = (result.data || result.previousData)
    ?.qualityControlInstances as QualityControlInstance[];

  return {
    instances: instances || [],
  };
};
