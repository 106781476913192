import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar } from '@pro4all/shared/ui/sidebar';

import { GroupForm } from './GroupForm';
import { GroupProperties } from './GroupProperties';
import { useGetGroup } from './useGetGroup';
import { useSidebarActions } from './useSidebarActions';

export const GroupSidebar: React.FC = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isCreating = searchParams.is('action', 'createGroup');
  const isEditing = searchParams.is('action', 'editGroup');
  const isViewing = searchParams.is('action', 'viewGroup');

  const sidebarOpen = Boolean(isCreating || isEditing || isViewing);

  const selectedGroup = useGetGroup();

  useEffect(() => {
    setIsOpen(sidebarOpen);
  }, [sidebarOpen]);

  const { handleClose, sidebarActions } = useSidebarActions();

  return (
    <Sidebar onClose={handleClose} open={isOpen}>
      <Sidebar.Header
        title={t(
          isCreating
            ? 'New group'
            : isEditing
            ? 'Edit group'
            : selectedGroup?.displayName
        )}
      >
        {selectedGroup && (
          <Sidebar.SidebarActionBar sidebarActions={sidebarActions} />
        )}
      </Sidebar.Header>
      {isOpen && isViewing ? (
        <GroupProperties group={selectedGroup} />
      ) : (
        <GroupForm group={selectedGroup} onClose={handleClose} />
      )}
    </Sidebar>
  );
};
