import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';
import { Location } from 'history';

import { Dialog } from '@pro4all/shared/ui/dialog';
import { IconName } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

interface Props {
  bypassRouterPrompt: boolean;
  iconName?: IconName;
  message: string;
  onConfirm?: () => void;
  onSubmit?: () => void;
  submitDisabled?: boolean;
  title: string;
}

export const NavigateAwayPrompt: React.FC<Props> = ({
  bypassRouterPrompt,
  iconName = 'warning',
  message,
  onConfirm,
  onSubmit,
  submitDisabled = false,
  title,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [showPromptModal, setShowPromptModal] = useState(false);
  const [nextLocation, setNextLocation] = useState<Location<unknown> | null>(
    null
  );
  const block = useRef(!bypassRouterPrompt);

  // Effect to add beforeunload event listener
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (block.current) {
        const confirmationMessage = t('Changes you made may not be saved.');
        event.preventDefault();
        event.returnValue = confirmationMessage; // event.returnValue is deprecated. However, it's still necessary to set it for some browsers to display the confirmation dialog.
        return confirmationMessage; // For some browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [t]);

  const handleBlockedNavigation = (location: Location<unknown>) => {
    if (block.current) {
      setShowPromptModal(true); // Show modal only if the route changes.
      setNextLocation(location);
      return false; // Block navigation
    }
    return true; // Allow navigation
  };

  const handleConfirmNavigation = () => {
    if (nextLocation) {
      const { hash, pathname, search, state } = nextLocation;
      block.current = false;
      history.push({ hash, pathname, search, state });
      setShowPromptModal(false);
      onConfirm && onConfirm();
    }
  };

  const handleCloseModal = () => {
    setShowPromptModal(false);
  };

  return (
    <>
      <Prompt message={handleBlockedNavigation} />
      <Dialog
        confirmLabel={t('Leave')}
        discard
        iconName={iconName}
        name="routerPrompt"
        onClose={handleCloseModal}
        onConfirm={handleConfirmNavigation}
        onSubmit={() => {
          onSubmit?.();
          handleConfirmNavigation();
        }}
        open={showPromptModal}
        submitDisabled={submitDisabled}
        submitLabel={t('Save changes')}
        title={t(title)}
      >
        <Text>{t(message)}</Text>
      </Dialog>
    </>
  );
};
