import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  UnreadCountDocument,
  useDeleteDraftMessageMutation,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useRouting } from '@pro4all/shared/routing-utils';

import { CountQueryType } from './useOptimisticMarkMessage';
import { getDefaultCounters } from './utils';

export const useOptimisticDeleteDraft = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { params } = useRouting();
  const { projectId } = params;
  const { organizationId } = useOrganizationContext();
  const projectOrOrganizationId = projectId || organizationId;
  return useDeleteDraftMessageMutation({
    update: (cache, { data }) => {
      if (data?.deleteDraftMessage?.success) {
        const id = `Message:${data.deleteDraftMessage.id}`;

        cache.evict({ id });
        cache.gc();

        // chnge sidebar counters
        const countQueryVariables = {
          isOrganization: !projectId ? true : false,
        };
        const countQueryArray: {
          unreadCount: CountQueryType[];
        } | null = cache.readQuery({
          optimistic: true,
          query: UnreadCountDocument,
          variables: countQueryVariables,
        });

        const countQuery = countQueryArray?.unreadCount?.find?.(
          (item) => item.id === projectOrOrganizationId
        );

        const modifiedUnreadCount = countQuery
          ? countQueryArray?.unreadCount?.map?.((item) => {
              if (item.id !== projectOrOrganizationId) return item;
              return {
                all: countQuery.all,
                draft: countQuery.draft - 1,
                id: projectOrOrganizationId,
                inbox: countQuery.inbox,
                sent: countQuery.sent,
              };
            })
          : (countQueryArray?.unreadCount || []).concat(
              getDefaultCounters(projectOrOrganizationId)
            );

        cache.writeQuery({
          data: {
            unreadCount: modifiedUnreadCount,
          },
          query: UnreadCountDocument,
          variables: countQueryVariables,
        });
      } else {
        enqueueSnackbar(
          `${t('Something went wrong')}. ${t('Please try again')}.`,
          {
            variant: 'error',
          }
        );
      }
    },
  });
};
