import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import {
  TaskType,
  Template,
  TemplateService,
  TemplateState,
  TemplateType,
  useAddSnagToFormLinkMutation,
  useTaskQuery,
} from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import { DrawingRouterState } from '@pro4all/quality-control/data-access';
import {
  ComposedPin,
  getFileUrlByTemplateId,
  TMuiIcon,
} from '@pro4all/shared/composed-snag-form-pin';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { Card } from '@pro4all/shared/ui/general';
import { Loader } from '@pro4all/shared/ui/loader';
import { useShowMessages } from '@pro4all/shared/ui/messages';
import { useSearchInput } from '@pro4all/shared/ui/search-input';
import { Text } from '@pro4all/shared/ui/typography';

import { useMapLinkingContext } from './MapLinkingContext';
import { useSubmit } from './useSubmit';

interface Props {
  visualContextId: string;
}

export const SelectSnagModal = ({ visualContextId }: Props) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { query, searchInput } = useSearchInput();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    setIsSelectTemplateDialogOpen,
    formId: contextFormInstanceId,
    fieldId: contextFieldId,
    setFormInstanceId,
    setFieldId,
    selectedTemplateType,
  } = useMapLinkingContext();

  const isSnag = selectedTemplateType === TemplateType.Snag;
  const { showSingleError } = useShowMessages();

  const onCloseMenu = () => setIsSelectTemplateDialogOpen(false);
  const [addSnagToFormLink] = useAddSnagToFormLinkMutation();

  const onSubmit = useSubmit({
    onCloseMenu,
    visualContextId,
  });

  const location = useLocation<DrawingRouterState>();
  const isSelectIconEnabled = useFeatureFlag('customericons');

  const currentSnagIcon = isSelectIconEnabled ? 'staticSnagIcon' : 'placeSnag';
  const currentFormIcon = isSelectIconEnabled
    ? 'staticFormIcon'
    : 'addFormTemplate';

  const formInstanceId = searchParams.get('formInstanceId');
  const fieldId = searchParams.get('fieldId');
  const taskId = searchParams.get('taskId');

  const { data: taskData, loading: taskLoading } = useTaskQuery({
    fetchPolicy: 'cache-and-network',
    skip: !taskId,
    variables: { id: taskId },
  });
  const isTbqType =
    taskData?.task?.type === TaskType.Tbq ||
    taskData?.task?.type === TaskType.TbqScan;

  // NOTE: If there are no snag templates linked to a task fetch project and organization snags
  const taskSnagTemplates = taskData?.task?.snagTemplates || [];
  const taskHasSnagTemplates = Boolean(taskSnagTemplates?.length);
  const {
    templates: projectAndOrganizationSnagTemplates,
    error: projectAndOrganizationSnagTemplatesError,
    loading: loadingTemplates,
  } = useTemplatesInclude({
    includeOrgItems: true,
    includeType: true,
    skip: taskHasSnagTemplates,
    state: TemplateState.Published,
    templateService: TemplateService.QualityControl,
    templateType: isSnag ? TemplateType.Snag : TemplateType.Form,
  });

  if (projectAndOrganizationSnagTemplatesError) {
    enqueueSnackbar(t('Could not fetch snags.'));
    return null;
  }

  if (!taskData && !projectAndOrganizationSnagTemplates) {
    return null;
  }
  const PlaceSnagAndLink = async (
    templateId: string,
    name: string,
    type: TemplateType
  ) => {
    try {
      const response = onSubmit(
        {
          template: { id: templateId, label: name, type },
        },
        taskId
      );

      const { instanceId } = (await response).data.createQualityControlInstance;
      const { data } = await addSnagToFormLink({
        variables: {
          fieldId: fieldId ? fieldId : contextFieldId,
          formInstanceId: formInstanceId
            ? formInstanceId
            : contextFormInstanceId,
          snagInstanceId: instanceId,
        },
      });
      if (data.addSnagToFormLink?.errors) {
        enqueueSnackbar(t('Something went wrong. Please try again.'));
      }
      //These context setts is done because they are necessary when the placeSnag happens
      //at the organization level in the TBQ form flow
      setFormInstanceId(
        formInstanceId ? formInstanceId : contextFormInstanceId
      );
      setFieldId(fieldId ? fieldId : contextFieldId);
      afterLinking(instanceId);
    } catch (e) {
      showSingleError(e);
    }
  };

  const AddSnagForLinkTbqQuestion = async (
    templateId: string,
    name: string
  ) => {
    try {
      const response = onSubmit(
        {
          template: { id: templateId, label: name },
        },
        taskId
      );

      const { instanceId } = (await response).data.createQualityControlInstance;
      searchParams.set('placeSnag', 'true');
      const locationData = {
        formInstanceId: formInstanceId ? formInstanceId : contextFormInstanceId,
        isLinkQuestion: true,
        previousPageName: location.state?.previousPageName,
        previousPageUrl: location.state?.previousPageUrl,
        snagInstanceId: instanceId,
        taskId,
        taskName: taskData.task?.name,
      };

      location.state = locationData;
      enqueueSnackbar(t('Snag is succesfully added.'));
      afterLinking(instanceId);
    } catch (e) {
      showSingleError(e);
    }
  };

  const afterLinking = (instanceId: string) => {
    onCloseMenu();
    searchParams.set('placeSnag', 'true');
    searchParams.set({ action: 'editResult', id: instanceId });
  };

  const filteredSnagTemplates: Template[] =
    !isTbqType && taskHasSnagTemplates
      ? taskSnagTemplates?.filter(({ name }) =>
          name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
        )
      : projectAndOrganizationSnagTemplates?.filter(({ name }) =>
          name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
        );

  const cardClick = ({
    id,
    name,
    type,
  }: {
    id: string;
    name: string;
    type: TemplateType;
  }) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    fieldId || contextFieldId
      ? PlaceSnagAndLink(id, name, type)
      : isTbqType
      ? AddSnagForLinkTbqQuestion(id, name)
      : onSubmit({ template: { id, label: name, type } }, taskId);
  };

  return (
    <FloatingModal
      maxWidth="md"
      onClose={() => onCloseMenu()}
      open
      title={t('Select a Snag')}
    >
      <FloatingModal.Header
        iconName={isSnag ? currentSnagIcon : currentFormIcon}
      >
        {isSnag ? t('Select a Snag template') : t('Select a Form template')}
      </FloatingModal.Header>
      <ContentWrapper>
        {searchInput}
        {loadingTemplates || (taskLoading && <Loader />)}
        {!filteredSnagTemplates?.length && !loadingTemplates && !taskLoading && (
          <Text variant="body2">
            {t('No snags found for')} <strong>{query}</strong>
          </Text>
        )}

        {isSubmitting ? (
          <Loader />
        ) : (
          filteredSnagTemplates?.map(({ id, name, type, icon }) => (
            <Card
              disabledButtonBorder
              iconComponent={
                isSelectIconEnabled ? (
                  <ComposedPin
                    currentFile={
                      icon?.fileId ? getFileUrlByTemplateId(id) : undefined
                    }
                    selectedIconName={icon?.name as TMuiIcon}
                    simpleIcon
                    top={1.2}
                    type={type === TemplateType.Snag ? 'snag' : 'form'}
                  />
                ) : null
              }
              iconName={type === TemplateType.Snag ? 'snag' : 'form'}
              key={id}
              menuItems={[
                {
                  label: `Select Snag`,
                  startIcon: 'arrowForward',
                },
              ]}
              onClick={() => cardClick({ id, name, type })}
              title={name}
            />
          ))
        )}
      </ContentWrapper>
      <FloatingModal.Footer>
        <Button color="inherit" onClick={() => onCloseMenu()}>
          {t('Cancel')}
        </Button>
      </FloatingModal.Footer>
    </FloatingModal>
  );
};

const ContentWrapper = styled(Box)`
  && {
    height: 500px;
  }
`;
