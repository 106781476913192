import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  QualityControlInstance,
  useObjectIncludeQuery,
  useQualityControlInstancesLazyQuery,
  useTaskQuery,
  VisualContext,
} from '@pro4all/graphql';
import { DrawingRouterState } from '@pro4all/quality-control/data-access';
import {
  Map,
  Marker,
  useMapLinkingContext,
} from '@pro4all/quality-control/ui/maps';
import { Action } from '@pro4all/shared/config';
import { usePersistentState } from '@pro4all/shared/hooks';
import {
  Box,
  FormControlLabel,
  Slider,
  useMediaQuery,
  useTheme,
} from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';
import { Dialog } from '@pro4all/shared/ui/dialog';
import { FullScreen } from '@pro4all/shared/ui/full-screen';
import { Checkbox } from '@pro4all/shared/ui/inputs';
import { useShowMessages } from '@pro4all/shared/ui/messages';
import { Sidebar } from '@pro4all/shared/ui/sidebar';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';
import { Text } from '@pro4all/shared/ui/typography';
import { hexToRgb } from '@pro4all/shared/utils';
import { useAnalytics } from '@pro4all/shared/vendor';

import Paginator from './Paginator/Paginator';

type Props = {
  visualContext: VisualContext;
};

export interface ErrorDialog {
  enteredPage: number;
}

// Set the following values to offset map coordinates and compensate for other on-screen elements
// Assuming sidebar is always open when a marker is selected (48px being the main container padding on the right)
const OFFSET_X = Sidebar.Size.Wide - 48;

export const DrawingResults = ({ visualContext }: Props) => {
  const { iconScale, setIconScale } = useMapLinkingContext();
  const [persistentIconScale, setPersistentIconScale] =
    usePersistentState<number>('iconScale');
  const [showOnlyTaskSnags, setShowOnlyTaskSnags] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!iconScale) return;
    setPersistentIconScale(() => iconScale);
  }, [iconScale, setPersistentIconScale]);

  const { showSingleError } = useShowMessages();

  const {
    searchParams,
    params: { projectId, objectId, visualContextId },
    state,
    goTo,
  } = useRouting<DrawingRouterState>();

  const { t } = useTranslation();
  const [errorDialog, setErrorDialog] = useState<null | ErrorDialog>(null);

  const history = useHistory();

  const errorEnteredPage = errorDialog?.enteredPage;

  const page =
    Number(searchParams.get('page')) !== 0
      ? Number(searchParams.get('page'))
      : 1;

  const resultId = searchParams.get('id');
  const action = searchParams.get('action');

  const { track } = useAnalytics();

  const {
    state: { items },
    replaceAllItems,
  } = useOptimisticResponseContext<QualityControlInstance>();

  const { data: objectData } = useObjectIncludeQuery({
    fetchPolicy: 'cache-and-network',
    skip: !objectId,
    variables: { id: objectId, includeProjectId: true },
  });

  const objectProjectId = objectData?.object?.projectId;

  const [fetch] = useQualityControlInstancesLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { projectId },
  });

  const taskId = searchParams.get('taskId');
  const { data: taskData } = useTaskQuery({
    fetchPolicy: 'cache-and-network',
    skip: !taskId,
    variables: { id: taskId },
  });

  useEffect(() => {
    const fetchSnagsForms = async () => {
      try {
        const response = await fetch({
          variables: {
            pages: [page],
            projectId: objectProjectId ?? projectId,
            visualContextIds: [visualContext.id],
          },
        });
        replaceAllItems(response?.data?.qualityControlInstances);
      } catch (e) {
        showSingleError(e);
      }
    };

    const pageFilteredTaskLinkedSnags =
      taskData?.task?.linkedSnagInstances?.filter(
        (snag) => snag.page === page && snag.visualContextId === visualContextId
      );

    if (taskId && pageFilteredTaskLinkedSnags && showOnlyTaskSnags)
      replaceAllItems(pageFilteredTaskLinkedSnags);
    else fetchSnagsForms();
  }, [
    page,
    fetch,
    projectId,
    objectProjectId,
    replaceAllItems,
    showSingleError,
    showOnlyTaskSnags,
    taskData,
    taskId,
    visualContextId,
    visualContext.id,
  ]);

  useEffect(() => {
    if (page > visualContext.pageCount) {
      setErrorDialog({ enteredPage: page });
      searchParams.set({ page: 1 });
    }
  }, [searchParams, visualContext.pageCount, page]);

  const goToPreviousPage = () => {
    if (state?.previousPageUrl) history.push(state?.previousPageUrl);
    else goTo('projectQualityControlDrawings', { params: { projectId } });
  };

  const selectedResult = items
    ? resultId
      ? items.find((r) => r.id === resultId)
      : { x: 0, y: 0 }
    : { x: 0, y: 0 };

  return items ? (
    <>
      <Box p={2}>
        <BackButton
          color="inherit"
          onClick={goToPreviousPage}
          startIcon="arrowBack"
          variant="text"
        >
          {`${t('Back to')} ${t(
            state?.previousPageName ?? 'Drawings'
          ).toLowerCase()}`}
        </BackButton>
      </Box>
      <FullScreen headerTitle={visualContext.name}>
        <Box display="flex" flexDirection="column" width="100%">
          {taskId && taskData?.task?.linkedSnagInstances?.length > 0 && (
            <Box display="flex" justifyContent="flex-end" width="100%">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showOnlyTaskSnags}
                    onChange={() => setShowOnlyTaskSnags(!showOnlyTaskSnags)}
                  />
                }
                label={t('Show only snags and forms from this task')}
              />
            </Box>
          )}

          <SliderHeaderContainer pageCount={visualContext.pageCount}>
            <StyledH5 variant="h5">Icon Size</StyledH5>
            <StyledSlider
              max={2}
              min={0.3}
              onChange={(e, value: number) => {
                setIconScale(value);
              }}
              step={0.1}
              value={iconScale}
            />
          </SliderHeaderContainer>
          <Map
            lat={selectedResult ? selectedResult.y : 0}
            lng={selectedResult ? selectedResult.x : 0}
            mapHeight={visualContext.height}
            mapWidth={visualContext.width}
            offsetX={resultId && OFFSET_X}
            visualContextId={visualContextId}
            zoomLevels={visualContext.zoomLevels}
          >
            {items.map((instance) => {
              if (instance.x && instance.y) {
                return (
                  <Marker
                    draggable={
                      action &&
                      action === 'editResult' &&
                      resultId === instance.id
                    }
                    id={instance.id}
                    key={instance.id}
                    lat={instance.y ?? 0}
                    lng={instance.x ?? 0}
                    onClick={() => {
                      track(Action.ItemOpened, {
                        id: instance.id,
                        location: 'Drawing',
                        name: instance.name,
                        projectId,
                        type: instance.type,
                      });

                      searchParams.set({
                        action: 'viewResult',
                        id: instance.id,
                      });
                    }}
                    page={instance.page}
                    selectedId={resultId}
                    statusColor={
                      instance.indicativeState
                        ? instance.indicativeState.color
                        : ''
                    }
                    type={instance.type}
                    visualContextId={instance.visualContextId}
                  />
                );
              }
              return '';
            })}
          </Map>
          {visualContext.pageCount > 1 ? (
            <Paginator
              pageCount={visualContext.pageCount}
              setErrorDialog={setErrorDialog}
              visualContextId={visualContextId}
            />
          ) : null}
        </Box>
      </FullScreen>
      {Boolean(errorEnteredPage) && (
        <Dialog
          buttonVariant="outlined"
          confirmLabel={t('Ok')}
          iconName="reportProblemOutlined"
          onConfirm={() => setErrorDialog(null)}
          open
          title="No page number found"
        >
          {errorEnteredPage ? (
            <p>
              {t(
                `There is no page numbered {{errorEnteredPage}} on this drawing`,
                { errorEnteredPage }
              )}
            </p>
          ) : (
            <p>{t('You have not entered a page number')}</p>
          )}
        </Dialog>
      )}
    </>
  ) : null;
};

const BackButton = styled(Button)`
  && {
    width: fit-content;
  }
`;

const SliderHeaderContainer = styled('div')<{ pageCount: number }>`
  position: absolute;
  bottom: ${({ pageCount }) => (pageCount > 1 ? '70px' : '15px')};
  right: 35px;
  z-index: 99;
`;

const StyledH5 = styled(Text)`
  && {
    color: ${({ theme }) => theme.palette.text.primary};
    margin-bottom: ${({ theme }) => theme.spacing(1.333)};
  }
`;

const StyledSlider = styled(Slider)`
  && {
    width: 180px;

    & .MuiSlider-rail,
    .MuiSlider-track {
      height: 6px;
      border-radius: 5px;
    }

    & .MuiSlider-track {
      color: ${({ theme }) => theme.palette.text.primary};
    }

    & .MuiSlider-rail {
      color: ${({ theme }) => theme.palette.grey['500']};
      opacity: 1;
    }

    & .MuiSlider-thumb {
      height: 20px;
      width: 20px;
      color: ${({ theme }) => theme.palette.text.primary};
      box-shadow: ${({ theme }) =>
        `0px 0px 0px 8px ${hexToRgb(theme.palette.text.primary, '0.16')}`};
      &:hover {
        box-shadow: ${({ theme }) =>
          `0px 0px 0px 8px ${hexToRgb(theme.palette.text.primary, '0.16')}`};
      }
    }
  }
`;
