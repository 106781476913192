import React from 'react';

import { Member, useUsersIncludeQuery } from '@pro4all/graphql';
import { FormikSearchableSelect } from '@pro4all/shared/ui/formik';
import {
  useSelectUsersAndGroupsDropdownSources,
  useSelectUsersDropdownSources,
} from '@pro4all/shared/ui/identity-card';

import { useTaskFormConfig } from './useTaskFormConfig';

export const ParticipantSelect = ({
  projectId,
  disabled,
}: {
  disabled?: boolean;
  projectId: string;
}) => {
  const { data } = useUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      includeActive: true,
      includeEmail: true,
      includeInvited: true,
      includeIsAdmin: true,
      includeOrganization: true,
      projectId,
    },
  });
  const members = (data?.users as Member[]) || [];

  const { noOptionsText, optionsToPassIn, placeholder, renderCustomOption } =
    useSelectUsersDropdownSources({
      members,
    });

  const { renderCustomInput } = useSelectUsersAndGroupsDropdownSources({
    options: [],
  });

  const { getField } = useTaskFormConfig();
  const participantField = getField('participant');

  return (
    <FormikSearchableSelect
      disabled={Boolean(disabled) || !optionsToPassIn}
      id={participantField?.name}
      label={participantField?.label}
      name={participantField?.name || ''}
      noOptionsText={noOptionsText}
      options={optionsToPassIn}
      placeholder={placeholder}
      renderCustomInput={renderCustomInput}
      renderCustomOption={renderCustomOption}
    />
  );
};
