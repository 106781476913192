import { useTranslation } from 'react-i18next';

import { StorageKeys } from '@pro4all/shared/config';
import { Switch } from '@pro4all/shared/ui/inputs';
import { Text } from '@pro4all/shared/ui/typography';

import { useConfigureDownloadNameContext } from './ConfigureDownloadNameProvider';
import * as Styled from './Title.styles';

export const Title = () => {
  const { t } = useTranslation();

  const {
    setEnabled,
    state: { enabled },
  } = useConfigureDownloadNameContext();

  const templateName = sessionStorage.getItem(StorageKeys.TEMPLATE_NAME);

  return (
    <Styled.Container>
      <Styled.TitleWrapper>
        <Styled.StyledIcon iconName="formatShapes" />
        <Styled.Header variant="h5">{`${t(
          'Download name'
        )} '${templateName}'`}</Styled.Header>
        <Switch checked={enabled} onChange={() => setEnabled(!enabled)} />
      </Styled.TitleWrapper>
      <Styled.Description>
        <Text variant="body1">
          {t(
            'Setup your preferred filename based on its meta data. When the document with this meta data set is being downloaded, its name will be generated for you to have the information on your local drive.'
          )}
        </Text>
      </Styled.Description>
    </Styled.Container>
  );
};
