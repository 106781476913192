import { ValueTypeName } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import {
  FORBIDDEN_NAMES_FOR_FOLDERS_AND_DOCUMENTS,
  VALID_FOLDER_CHARACTERS_REGEX,
} from '@pro4all/shared/constants';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useFolderFormConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Name', // i18n
      matches: VALID_FOLDER_CHARACTERS_REGEX,
      name: 'name',
      notOneOf: [
        Routes.smartFolderPath,
        ...FORBIDDEN_NAMES_FOR_FOLDERS_AND_DOCUMENTS,
      ],
      required: true,
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({ formFields });
};
