export enum StorageKeys {
  ACTION_TRIGGERED_FROM = 'p4a:ps:actionTriggeredFrom',
  COPY_CUT_PASTE_TEMPLATE_SECTION = 'copy-cut-paste-template-section',
  DOCUMENT_IDS_TO_CONVERT_TO_DRAWINGS = 'documentIdsToConvertToDrawings',
  HIDE_EDIT_DOCUMENT_MODAL = 'p4a:ps:hideEditDocumentModal',
  HORIZONTAL_TABBING_IN_EDITOR = 'horizontal-tabbing-in-editor',
  MESSAGE_ATTACHMENTS = 'p4a:ps:message-attachments',
  MOVE_DOCUMENTS_COUNT = 'move-document-count',
  MOVE_ORG_TOKEN = 'p4a:ps:prostream:move-org-token',
  PROJECT_ID = 'p4a:ps:prostream:project-id',
  PROJECT_ORGANIZATION_ID = 'p4a:ps:prostream:project-organization-id',
  PROSTREAM_CLIENT = 'prostream-client',
  PUBLISH_TEMPLATES_AFTER_FIELD_UPDATE = 'publish-templates-after-field-update',
  SET_GROUP = 'set-group',
  SET_USER = 'set-user',
  TEMPLATE_DISPLAY_NAME = 'p4a:ps:prostream:template-display-name',
  TEMPLATE_NAME = 'p4a:ps:prostream:template-name',
}
