import React from 'react';
import { useTranslation } from 'react-i18next';

import { Template } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import {
  generateRoute,
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';

export const MetadataTabs = ({ base }: { base: string }) => {
  const { t } = useTranslation();
  const { params } = useRouting();
  const { projectId, tbqScopeId } = params;
  const hasQualityControlFeature = useFeatureFlag('qualitycontrol');
  const hasHierarchyFeature = useFeatureFlag('hierarchy-field');
  const hasTBQAdminFeature = useFeatureFlag('tbq-admin');
  const { contextScopedOrganizationSubscriptionLevel } =
    useOrganizationContext();

  const { hasQualityControlLicense, hasDmsLicense } =
    useSubscriptionRestriction(contextScopedOrganizationSubscriptionLevel);

  const { resetInitialItems } = useOptimisticResponseContext<Template>() || {};
  return (
    <SubTabs base={base}>
      {hasDmsLicense && (
        <Tab
          data-testid="templates-tab"
          label={t('Metadata')}
          onClick={() => {
            resetInitialItems(); // To reset itemsInitial taken from other tab.
            updateLsLastVisitedProjectRoute({
              overwriteCurrentValue: true,
              projectId,
              route: 'projectMetaDataDocumentsTemplates',
            });
          }}
          value={
            projectId
              ? generateRoute('projectMetaDataDocumentsTemplates', {
                  params: { projectId },
                })
              : Routes['metaDataDocumentsTemplates']
          }
        />
      )}
      {hasQualityControlLicense && hasQualityControlFeature && (
        <Tab
          data-testid="field-definitions-tab"
          label={t('Quality control')}
          onClick={() => {
            resetInitialItems(); // To reset itemsInitial taken from other tab.
            updateLsLastVisitedProjectRoute({
              overwriteCurrentValue: true,
              projectId,
              route: 'projectMetaDataQualityControlForms',
            });
          }}
          value={
            projectId
              ? generateRoute('projectMetaDataQualityControlForms', {
                  params: { projectId },
                })
              : Routes['metaDataQualityControlForms']
          }
        />
      )}
      {hasQualityControlFeature &&
        hasQualityControlLicense &&
        hasTBQAdminFeature && (
          <Tab
            data-testid="tbq-form-tab"
            label={t('TBQ scans')}
            onClick={() => {
              resetInitialItems(); // To reset itemsInitial taken from other tab.
            }}
            value={
              tbqScopeId
                ? generateRoute('metaDataQualityControlTBQTemplatesScope', {
                    params: { tbqScopeId },
                  })
                : Routes['metaDataQualityControlTBQTemplates']
            }
          />
        )}
      {hasHierarchyFeature &&
        hasQualityControlFeature &&
        hasQualityControlLicense && (
          <Tab
            data-testid="hierarchical-lists-tab"
            label={t('Hierarchical lists')}
            onClick={() => {
              updateLsLastVisitedProjectRoute({
                overwriteCurrentValue: true,
                projectId,
                route: 'projectMetaDataHierarchicalLists',
              });
            }}
            value={
              projectId
                ? generateRoute('projectMetaDataHierarchicalLists', {
                    params: { projectId },
                  })
                : Routes['metaDataHierarchicalLists']
            }
          />
        )}
      <Tab
        data-testid="tasks-tab"
        label={t('Tasks')}
        onClick={() => {
          resetInitialItems(); // To reset itemsInitial taken from other tab.
          updateLsLastVisitedProjectRoute({
            overwriteCurrentValue: true,
            projectId,
            route: 'projectTemplatesTasks',
          });
        }}
        value={
          projectId
            ? generateRoute('projectTemplatesTasks', {
                params: { projectId },
              })
            : Routes['orgTemplatesTasks']
        }
      />
    </SubTabs>
  );
};
