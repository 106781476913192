import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  Project,
  ProjectDocument,
  useUpdateProjectSettingsMutation,
} from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';

import { FormFields } from './types';

export const useSubmit = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { params } = useRouting();
  const { projectId } = params;
  const { projectData, setProjectData, setSettings } = useProjectContext();
  const [updateProjectSettings] = useUpdateProjectSettingsMutation();
  const { editItems } = useOptimisticResponseContext<Project>();

  return async (values: FormFields) => {
    // Change null values to empty string
    const valuesToSend = {
      ...values,
      approve_action_label: values.approve_action_label ?? '',
      finalize_action_label: values.finalize_action_label ?? '',
      reject_action_label: values.reject_action_label ?? '',
    };
    try {
      await updateProjectSettings({
        variables: { id: projectId || '', settings: valuesToSend },
      });

      editItems([
        {
          ...projectData,
          settings: { ...valuesToSend },
        },
      ]);

      setSettings(valuesToSend);
      setProjectData({ ...projectData, settings: valuesToSend });

      // In here you have to update the Apollo client cache.
      client.writeQuery({
        data: {
          project: {
            ...projectData,
            settings: { ...valuesToSend },
          },
        },
        query: ProjectDocument,
        variables: {
          id: projectId,
        },
      });

      enqueueSnackbar('Project settings have been updated.');
    } catch (e) {
      enqueueSnackbar(t('Something went wrong'));
    }
  };
};
