import React from 'react';

import {
  TextField as TextFieldComponent,
  TextFieldProps,
} from '@pro4all/shared/ui/inputs';

import { useHandleValueChangeInputs } from './useHandleValueChangeInputs';

interface Props extends TextFieldProps {
  setValue: (name: string, value: string) => void;
  value: string;
}

export const TextField = ({ name, setValue, value, ...rest }: Props) => {
  const { currentValue, handleChangeText } = useHandleValueChangeInputs({
    name,
    setValue,
    value,
  });

  return (
    <TextFieldComponent
      {...rest}
      name={name}
      onChange={handleChangeText}
      value={currentValue}
    />
  );
};
