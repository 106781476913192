import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  EmailActions,
  Message,
  MessageStatus,
  SentOrInbox,
  useMessageInboxQuery,
} from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { Box, Divider } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { customColors, useIsMobileScreen } from '@pro4all/shared/themes';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { Loader } from '@pro4all/shared/ui/loader';
import { BigMessage, BigMessageNoMessages } from '@pro4all/shared/ui/messages';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/table';

import { MessageActionBar } from './MessageActionBar';
import MessageListMobile from './MessageListMobile';
import { useColumns } from './useColumns';
import { useMessageActions } from './useMessageActions';

type Row = {
  cells: React.ReactNode[];
  rowData: Message;
};

type Props = {
  sentOrInbox?: SentOrInbox;
};

export const MessagesTable: React.FC<Props> = ({ sentOrInbox }) => {
  const { t } = useTranslation();
  const { goTo, params } = useRouting();
  const { projectId } = params;
  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const isMobileScreen = useIsMobileScreen();

  const { data, loading } = useMessageInboxQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000 * ApiConfig.pollEnabled,
    variables: {
      organizationId,
      projectId,
      sentOrInbox,
    },
  });
  const columns = useColumns();

  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<Message>();
  const messages = useMemo(
    () => data?.messageInbox || [],
    [data?.messageInbox]
  ) as Message[];
  useSetItemsInLocalState(messages);

  const handleRowClick = async (row: Message) => {
    goTo(params.projectId ? 'projectMessages' : 'messages', {
      params: { ...params, messageId: row.id },
      searchParams:
        row.status === MessageStatus.Draft
          ? {
              action: EmailActions.DraftEdit,
              id: row.id,
              replyId: row.parentId || '',
            }
          : undefined,
    });
  };

  const { contextMenuActions } = useMessageActions();

  const emptyRenderer = () =>
    loading ? (
      <Loader />
    ) : (
      <BigMessage shapeName="flow1" title={t('Your inbox is empty')} />
    );

  const rowRenderer = ({ cells, rowData }: Row) =>
    rowData.read ? cells : <Bold>{cells}</Bold>;

  return (
    <TableContextProvider
      checkable
      columns={columns}
      id="messages-table"
      items={items}
    >
      <FilterContextProvider<Message>>
        <MessageActionBar />
        {isMobileScreen && <Divider />}
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            height: '100%;',
            overflowY: isMobileScreen ? 'auto' : 'inherit',
          }}
        >
          {!items.length && !itemsInitial.length && !loading ? (
            <BigMessageNoMessages />
          ) : isMobileScreen ? (
            <MessageListMobile
              itemClick={handleRowClick}
              messages={messages}
            ></MessageListMobile>
          ) : (
            <Table<Message>
              contextMenuActions={contextMenuActions}
              emptyRenderer={emptyRenderer}
              enableKeyboardControl
              onRowClick={handleRowClick}
              rowRenderer={rowRenderer}
            />
          )}
        </Box>
      </FilterContextProvider>
    </TableContextProvider>
  );
};

const Bold = styled.span`
  display: inline-flex;
  font-weight: 500;
  height: 100%;
  color: ${customColors.grey900};
`;
