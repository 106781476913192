import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import {
  gqlType,
  SearchDocument,
  SearchResult,
  useSearchLazyQuery,
} from '@pro4all/graphql';
import {
  getFileUrlByTemplateId,
  TMuiIcon,
} from '@pro4all/shared/composed-snag-form-pin';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SearchEntities } from '@pro4all/shared/search-utils-types';

import { SearchOption } from './SearchBar';

interface Props {
  debounceValue?: number;
  predefinedHiddenFilters?: string[];
  type: SearchEntities;
}

enum idTypes {
  loadingResults = 'loading-results',
  noResults = 'no-results',
}

export type TopTen = {
  clearTopTen: () => void;
  fetchTopTen: (query: string) => undefined | Promise<SearchResult[]>;
  loadingTopTen: boolean;
  topTenOptions: SearchOption[];
  topTenResults: SearchResult[];
};

enum SearchTypesIcons {
  document = 'file',
  form = 'form',
  snag = 'snag',
}

export const useTopTen: (props: Props) => TopTen = ({
  debounceValue = 3000,
  type,
  predefinedHiddenFilters,
}: Props) => {
  const { t } = useTranslation();

  const [topTenResults, setTopTenResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(false);

  const { params } = useRouting();
  const { projectId } = params;

  const noResults = [{ id: idTypes.noResults, name: t('No results') }];
  const [queryTopHits] = useSearchLazyQuery({
    onError: () => noResults,
  });

  function isKeyOfSearchTypesIcons(
    key: string
  ): key is keyof typeof SearchTypesIcons {
    return key in SearchTypesIcons;
  }

  const clearTopTen = () => setTopTenResults([]);

  const debounced = useDebouncedCallback(async (query: string) => {
    const { data } = await queryTopHits({
      variables: {
        documentType: SearchEntities[type],
        filters: predefinedHiddenFilters,
        limit: 10,
        projectId,
        query,
      },
    });
    const results = data?.search?.searchResults;
    let topTenResults: SearchResult[] = [];
    if (type === SearchEntities.Document) {
      const resultsDocuments = (results?.filter(gqlType('SearchDocument')) ||
        []) as SearchDocument[];
      const resultsWithPath = resultsDocuments?.filter(
        (result) => result?.path
      ); // Require path (root files shouldn't exist)
      topTenResults = resultsWithPath;
    }
    if (type === SearchEntities.QualityControl) {
      // Ideally, we would use this
      // TODO: Force that the gqlType is SearchQCInstance or figure out a better way to do this
      // const resultsQC = results?.filter(gqlType('SearchQCInstance')) || [];
      // Without any if statements
      const resultsQC = results || [];
      topTenResults = resultsQC as SearchResult[];
    }

    // const resultsDocuments = results?.filter(gqlType('SearchDocument')) || [];
    // const resultsQC = results?.filter(gqlType('SearchQCInstance')) || [];
    // const resultsWithPath = resultsDocuments?.filter((result) => result?.path); // Require path (root files shouldn't exist)

    const limitedResults: SearchResult[] = topTenResults?.length
      ? topTenResults.slice(0, 10)
      : noResults;
    setLoading(false);
    setTopTenResults(limitedResults);
    return limitedResults;
  }, debounceValue);

  const fetchTopTen = (query: string) => {
    if (!query) {
      setLoading(false);
      clearTopTen();
      /*  .cancel() the promise to stop the latest callback while the input is empty.
       *  SCENARIO: When you clear the input one character at a time,
       *  the last character is treated as the tail end of the debounced cb.
       *  We cancel the cb in this scenario, because we are certain we don't want to query anything.
       *  This saves us a network request.
       * */
      debounced.cancel();
      return;
    }
    setLoading(true);
    return debounced(query);
  };

  const topTenOptions: SearchOption[] = loading
    ? [
        {
          id: idTypes.loadingResults,
          label: `${t('Loading top hits')}...`,
          type: 'feedback',
        },
      ]
    : topTenResults.map(
        ({
          id,
          name,
          path,
          projectId,
          reference,
          type,
          visualContext,
          page,
          templateIconProps,
          indicativeState,
        }) => ({
          currentFile:
            templateIconProps?.iconType === 2
              ? getFileUrlByTemplateId(templateIconProps?.templateId || '')
              : null,
          customIcon: (templateIconProps?.iconName as TMuiIcon) || undefined,
          drawing: visualContext?.name || '',
          iconType:
            (type && isKeyOfSearchTypesIcons(type) && SearchTypesIcons[type]) ||
            undefined,
          id,
          indicateStateColor: indicativeState?.color || undefined,
          label: name || '',
          page: typeof page === 'number' ? ` Page ${page + 1}` : undefined, // Should be located on location.page when available
          path: path || '',
          projectId: projectId || '',
          referenceNumber: reference || undefined,
          type: id === idTypes.noResults ? 'feedback' : 'topTen',
        })
      );

  return {
    clearTopTen,
    fetchTopTen,
    loadingTopTen: loading,
    topTenOptions,
    topTenResults,
  };
};
