import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DocumentEventType,
  DocumentHistoryEvent,
  QcHistoryEvent,
  QcInstanceEventType,
} from '@pro4all/graphql';
import { useActionNamingMapping } from '@pro4all/shared/label-config';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Section } from '@pro4all/shared/ui/section';

import { EventLogExpandCollapse } from './EventLogExpandCollapse';

interface EventLogProps {
  events: (DocumentHistoryEvent | QcHistoryEvent)[] | undefined | null;
  groupName: string;
  open?: boolean;
}

export const EventLog: React.FC<EventLogProps> = ({
  open = true,
  events,
  groupName,
}) => {
  const { t } = useTranslation();
  const getActionNamingMapping = useActionNamingMapping();

  if (!events?.length) return null;

  const findShareType = (event: DocumentHistoryEvent, type: string): boolean =>
    event.properties
      ? event.properties.some((element) => element.name === type)
      : false;

  const handleEventTitle = (
    event: DocumentHistoryEvent | QcHistoryEvent | null
  ) => {
    // Safely return if event is null
    if (!event) return '';
    if (event.name === DocumentEventType.HyperlinkCreatedEvent) {
      if (findShareType(event, 'PublicShare')) {
        return t('Shared as Public link');
      } else if (findShareType(event, 'PrivateShare')) {
        return t('Shared as Private link');
      } else if (findShareType(event, 'ProjectScopeShare')) {
        return t('Shared to all Project members');
      }
    }
    if (
      event.name === DocumentEventType.AnswersAddedEvent ||
      event.name === DocumentEventType.AnswerAddedEvent ||
      event.name === DocumentEventType.AnswerUpdatedEvent ||
      event.name === QcInstanceEventType.AnswerAddedEvent ||
      event.name === QcInstanceEventType.AnswerUpdatedEvent
    ) {
      if (event.__typename === 'QcHistoryEvent') {
        return t('Answers updated');
      } else if (event.__typename === 'DocumentHistoryEvent') {
        return t('Metadata updated');
      }
    }

    const eventName = event?.name || '';
    if (validateNaming(eventName)) {
      return t(`Api.eventNames.${eventName}{{name}}`, {
        defaultValue: eventName,
        name: validateNaming(eventName),
      });
    }
    return t(`Api.eventNames.${eventName}`, {
      defaultValue: eventName,
    });
  };

  const validateNaming = (eventName: string) => {
    const value = getActionNamingMapping(eventName);
    return value !== eventName ? value : false;
  };

  return (
    <Section defaultOpen={open} title={t(groupName)}>
      <Box sx={{ mt: 2 }}>
        {events?.map((event, index) => (
          <EventLogExpandCollapse
            event={event}
            key={index}
            title={handleEventTitle(event)}
          />
        ))}
      </Box>
    </Section>
  );
};
