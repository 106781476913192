import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  useDocument,
  useUserFolderPermissions,
} from '@pro4all/documents/data-access';
import { FinalizationState, LockType } from '@pro4all/graphql';
import { useDocumentActionsLabels } from '@pro4all/shared/label-config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FullScreen } from '@pro4all/shared/ui/full-screen';
import { BigMessage } from '@pro4all/shared/ui/messages';

import { PdfTronOpenAction } from './pdf-tron-utils/actions';
import { Loader, LoadingOverlay } from './DocumentsPdfTronViewer.styles';
import { useSetContainerRef } from './useSetContainerRef';

const StyledDiv = styled.div`
  height: 100%;
  width: 100%;
`;

export const DocumentsPdfTronViewer = ({
  action,
  hideGoBack,
  initialVersionId,
}: {
  action: PdfTronOpenAction;
  hideGoBack?: boolean;
  initialVersionId?: string;
}) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { document, version } = useDocument();
  const { finalize_action_label } = useDocumentActionsLabels();

  const { getEditableDocumentsOrVersions } = useUserFolderPermissions({
    folderId: document?.folderId || '',
  });

  const editableDocuments = getEditableDocumentsOrVersions({
    documents: document ? [document] : [],
  });

  const editableVersions = getEditableDocumentsOrVersions({
    versions: version ? [version] : [],
  });

  const documentIsLocked = document && document?.lockType !== LockType.None;
  const documentIsFinalized =
    document?.state === FinalizationState.Finalized ||
    version?.state === FinalizationState.Finalized;
  const userCanEdit =
    editableDocuments?.length !== 0 || editableVersions.length !== 0;

  const readonly = documentIsLocked || documentIsFinalized || !userCanEdit;

  const [loadingProgress, setLoadingProgress] = useState<number>(0);

  const setContainerRef = useSetContainerRef({
    action: action,
    document: document || null,
    initialVersionId,
    onLoadingProgress: setLoadingProgress,
    readonly: readonly,
    version: version || null,
  });

  return (
    <FullScreen
      centralText={`Powered by Apryse ${
        loadingProgress === 100 && readonly
          ? ` - Read only (${[
              documentIsLocked ? t('Locked') : null,
              documentIsFinalized ? finalize_action_label : null,
              // We check finalized cause the getEditableDocumentsOrVersions checks for finalized
              !documentIsFinalized && !userCanEdit
                ? t('No edit permissions')
                : null,
            ]
              .filter((entry) => entry !== null)
              .join(', ')})`
          : '' // Use loadingprogress to avoid flickering
      }`}
      onClose={
        !hideGoBack
          ? () => {
              searchParams.delete('annotate');
              searchParams.delete('fullscreen');
            }
          : undefined
      }
    >
      {loadingProgress < 100 && (
        <LoadingOverlay>
          <Loader value={loadingProgress} variant="determinate"></Loader>
          <BigMessage shapeName="flow1" title={t('Loading document')} />
        </LoadingOverlay>
      )}
      <StyledDiv ref={setContainerRef} />
    </FullScreen>
  );
};
