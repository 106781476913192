import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import styled from 'styled-components';

import { ProjectQuery } from '@pro4all/graphql';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { FormikForm } from '@pro4all/shared/ui/formik';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useAddExternalUsers } from '../../utils/useAddExternalUsers';
import { InviteExternalsFormValues } from '../types';

import { InviteExternalsForm } from './InviteExternalsForm';
import { useInviteExternalsModalConfig } from './useInviteExternalsModalConfig';

interface ModalProps {
  onClose: () => void;
  open: boolean;
  project: ProjectQuery;
  refetchMembers: () => void;
}

export const InviteExternalsModal: React.FC<ModalProps> = ({
  onClose,
  open,
  project,
  refetchMembers,
}) => {
  const { t } = useTranslation();

  const roleOptions =
    project?.project?.availableRoles?.map((role) => ({
      id: role.id,
      label: t(role.name),
      tooltip: t(`Api.project.roles.${role.name}`),
    })) || [];

  const onSubmit = useAddExternalUsers(onClose, refetchMembers, roleOptions);

  const initialValues: InviteExternalsFormValues = {
    externalUsersToAdd: [],
    message: '',
    roleId:
      roleOptions.find((option) => option.label === 'Api.project.roles.User')
        ?.id || '',
  };

  const { validationSchema } = useInviteExternalsModalConfig();

  const handleClose = (resetForm: () => void) => {
    resetForm();
    onClose();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ resetForm }) => (
        <FloatingModal
          data-testid="invite-external-modal"
          maxWidth="md"
          onClose={onClose}
          open={open}
        >
          <FloatingModal.Header iconName="mail">
            {t('Invite external')}
          </FloatingModal.Header>

          <FormikForm>
            <FormWrapper noPadding>
              <TextWrapper>
                {t(
                  'Invite external partners by email. Include commas to separate addresses. This is useful for copying/pasting text.'
                )}
              </TextWrapper>
              <InviteExternalsForm
                onClose={() => handleClose(resetForm)}
                options={roleOptions}
              />
            </FormWrapper>
          </FormikForm>
        </FloatingModal>
      )}
    </Formik>
  );
};

const TextWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;
