import React from 'react';
import { useTranslation } from 'react-i18next';

import { User } from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Dialog } from '@pro4all/shared/ui/dialog';

import { UserCard } from './UserCard';

export const ModalShowUser = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const userId = searchParams.get('showUser');

  const onClose = () => {
    searchParams.delete('showUser');
  };

  const { getLocalStorageItem: getUserInLs } = useLocalStorage<User>({
    key: StorageKeys.SET_USER,
  });

  const user = getUserInLs() as User;

  const { displayName, id } = user;

  return displayName ? (
    <Dialog
      hideFooter
      iconName="info"
      name="showUser"
      onClose={onClose}
      open={Boolean(userId)}
      showCloseIcon
      title={t('User details')}
    >
      <UserCard userId={id} />
    </Dialog>
  ) : null;
};
