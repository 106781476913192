import React from 'react';
import { Route } from 'react-router-dom';

import { ScopeType } from '@pro4all/graphql';
import { useHierarchicalLists } from '@pro4all/metadata/data-access';
import { Routes } from '@pro4all/shared/config';
import { useIsAdminInContext } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Column, Main } from '@pro4all/shared/ui/layout';
import { BigMessageNoAccess } from '@pro4all/shared/ui/messages';

import { HierarchicalListFrame } from '../components/hierarchical-lists/HierarchicalListFrame';
import { RedirectToOrganizationHierarchicalLists } from '../components/hierarchical-lists/RedirectToOrganizationHierarchicalLists';
import { ScreenFieldHierarchicalLists } from '../components/screens/ScreenFieldHierarchicalLists';
import { MetadataTabs } from '../components/tabs/MetadataTabs';

import { useIncludeOrgItems } from './useIncludeOrgItems';

export const HierarchicalLists = ({
  isProject = false,
  pathHierarchicalLists,
}: {
  isProject?: boolean;
  pathHierarchicalLists: string | string[];
}) => {
  const includeOrgItems = useIncludeOrgItems();
  const isAdminInContext = useIsAdminInContext();

  const { searchParams } = useRouting();
  const action = searchParams.get('action');
  const { selectedHierarchicalList } = useHierarchicalLists({
    includeOrgItems,
  });

  const redirectToOrganizationSection = Boolean(
    pathHierarchicalLists === Routes.projectMetaDataHierarchicalLists &&
      selectedHierarchicalList?.scope === ScopeType.Organization
  );

  return action === 'createHierarchicalList' ? (
    <HierarchicalListFrame includeOrgItems />
  ) : action === 'editHierarchicalList' ? (
    redirectToOrganizationSection ? (
      <RedirectToOrganizationHierarchicalLists
        selectedHierarchicalList={selectedHierarchicalList}
      />
    ) : (
      <HierarchicalListFrame
        includeOrgItems
        selectedHierarchicalList={selectedHierarchicalList}
      />
    )
  ) : (
    <>
      <MetadataTabs base={null} />
      <Main>
        <Column>
          <Route
            exact
            path={pathHierarchicalLists}
            render={() =>
              isAdminInContext ? (
                <ScreenFieldHierarchicalLists
                  includeOrgItems={includeOrgItems}
                  isProject={isProject}
                />
              ) : (
                <BigMessageNoAccess />
              )
            }
          />
        </Column>
      </Main>
    </>
  );
};
