export const NO_VALUE = '-empty-';

export const VALID_FOLDER_CHARACTERS_REGEX =
  /^[^\n:/?#@!$&*+,;=<>"|%{}^[\]\\`]*$/;

export const VALID_DOCUMENT_CHARACTERS_REGEX = /^[^\n<>:"/*?\\|]*$/;
export const FORBIDDEN_NAMES_FOR_FOLDERS_AND_DOCUMENTS = [
  'aux',
  'com1',
  'com2',
  'com3',
  'com4',
  'com5',
  'com6',
  'com7',
  'com8',
  'com9',
  'con',
  'lpt1',
  'lpt2',
  'lpt3',
  'lpt4',
  'lpt5',
  'lpt6',
  'lpt7',
  'lpt8',
  'lpt9',
  'nul',
  'prn',
];
