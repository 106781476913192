import React from 'react';
import { Formik } from 'formik';

import { Folder } from '@pro4all/graphql';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikForm, FormikInput } from '@pro4all/shared/ui/formik';
import { DiscardWrapper } from '@pro4all/shared/ui/messages';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useFolderFormConfig } from './useFolderFormConfig';
import { useSubmit } from './useSubmit';

export interface FolderFormProps {
  folder?: Folder;
  onClose: () => void;
  parentFolder?: Folder;
}

export const FolderForm: React.FC<FolderFormProps> = ({
  folder,
  onClose,
  parentFolder,
}) => {
  const onSubmit = useSubmit({ folder, onClose, parentFolder });
  const { getField, validationSchema } = useFolderFormConfig();
  const nameField = getField('name');

  return (
    <Formik
      enableReinitialize
      initialValues={{ name: folder?.name || '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formikHelpers) => {
        const { values, dirty, errors, isSubmitting } = formikHelpers;
        return (
          <DiscardWrapper
            dirty={dirty}
            onSubmit={() => onSubmit(values, formikHelpers)}
            values={values}
          >
            <FormikForm>
              <FormWrapper>
                <FormikInput
                  autoFocus
                  label={nameField.label}
                  name={nameField.name}
                />
              </FormWrapper>
              <FormFooter
                disableSubmit={isSubmitDisabled({
                  dirty,
                  errors,
                  isSubmitting,
                })}
                onClose={onClose}
              />
            </FormikForm>
          </DiscardWrapper>
        );
      }}
    </Formik>
  );
};
