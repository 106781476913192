import styled from 'styled-components';

import { Main } from '@pro4all/shared/ui/layout';

import { AddTaskCategories } from './AddTaskCategories';

export const TemplatesTasksMain = () => (
  <StyledMain>
    <AddTaskCategories />
  </StyledMain>
);

const StyledMain = styled(Main)`
  && {
    flex: 1 1 0;
    padding: ${({ theme }) => theme.spacing(2.66)}
      ${({ theme }) => theme.spacing(4)} 0 ${({ theme }) => theme.spacing(2.66)};
    overflow-y: scroll;
    & .selection-option-container,
    .MuiInput-root {
      width: 335px;
    }
  }
`;
