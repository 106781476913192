import React, { isValidElement, ReactChild, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { IconButton, Popper } from '@pro4all/shared/mui-wrappers';
import { customColors, StylingDefaults } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';
import { useToggle } from '@pro4all/shared/ui/toggle';
import { hexToRgb } from '@pro4all/shared/utils';

export enum DisplayType {
  Counter = 'counter',
  MoreIcon = 'moreIcon',
}

export type ListMore = React.ElementType<{
  children: React.ReactChild[];
  color: string;
  displayType?: DisplayType;
  dividers: string[];
  noOfHiddenItems: number;
  overflowMenuPosition?: 'bottom-end' | 'bottom-start';
}>;

const POPUP_DELAY = 200;

/*This is the default component for the popup menu that shows up when there is not enough room in the browser to show all components in List.tsx
It's called DefaultMore because if you would like different styling/functionality for this popUp menu you can pass
a different component to the 'more' prop of List.tsx and this default component will automatically be replaced with that component
*/

const DefaultMore: ListMore = ({
  children,
  dividers,
  color,
  displayType = DisplayType.MoreIcon,
  noOfHiddenItems,
  overflowMenuPosition = 'bottom-start',
}) => {
  const tagRef = useRef(null);
  const { toggle, toggled } = useToggle({ scope: 'Tooltip' });

  let timer: number | undefined = undefined;

  const hidePopup = () => {
    timer = window.setTimeout(() => toggle(false), POPUP_DELAY);
  };

  const showPopup = () => {
    window.clearTimeout(timer);
    toggle(true);
  };

  useEffect(() => {
    if (!toggled) window.clearTimeout(timer);
    return () => window.clearTimeout(timer);
  }, [toggled, timer]);

  const renderOverflowChildren = () =>
    children.map((child: ReactChild) => {
      if (!isValidElement(child)) return {};
      const ariaLabel = child.props['aria-label'];
      const label = child.props['label'];
      return (
        <div key={child.key}>
          <div
            className={`list-item-container ${
              (ariaLabel || label) && 'with-label'
            }`}
          >
            {child}
            {(ariaLabel || label) && (
              <label className="overflow-item-text">{ariaLabel ?? label}</label>
            )}
          </div>
          {/* dividers is an optional prop(<string[]>) of List.tsx. 
          You can use it to place horizontal lines in the menu that the design sometimes wants.
          To use it, put the arialabel of the button that needs a line underneath it in the dividers array. */}
          {(dividers?.includes(ariaLabel) || dividers?.includes(ariaLabel)) && (
            <Divider />
          )}
        </div>
      );
    });

  return (
    <>
      {displayType === DisplayType.Counter ? (
        <div
          onMouseOut={hidePopup}
          onMouseOver={showPopup}
          ref={tagRef}
        >{`+${noOfHiddenItems}`}</div>
      ) : (
        <ShowMoreIcon
          $color={color}
          onMouseOut={hidePopup}
          onMouseOver={showPopup}
          ref={tagRef}
        >
          <Icon iconName="moreHoriz" />
        </ShowMoreIcon>
      )}
      <Popup
        anchorEl={tagRef.current}
        onMouseOut={hidePopup}
        onMouseOver={showPopup}
        open={Boolean(toggled)}
        placement={overflowMenuPosition}
      >
        <OverflowList $color={color}>{renderOverflowChildren()}</OverflowList>
      </Popup>
    </>
  );
};

const ShowMoreIcon = styled(IconButton)<{ $color: string }>`
  && {
    border: solid 1px
      ${({ $color }) => ($color ? $color : customColors.grey600)};
    color: ${({ $color }) => ($color ? $color : customColors.grey600)};
    padding: 5px;
    &:hover {
      background-color: ${({ $color }) => hexToRgb($color, '0.1')};
    }
  }
`;

const Popup = styled(Popper)`
  && {
    z-index: 1500; /* make sure it's above the sidebar drawer */
  }
`;

const OverflowList = styled('ul')<{ $color: string }>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0;

  .MuiIconButton-root {
    border: none;
    padding: 0;
    &:hover {
      background: none;
    }
  }

  .list-item-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(1)}
      ${({ theme }) => theme.spacing(2)};
    font-size: 1.14rem;
    &:hover {
      background-color: ${({ $color }) => hexToRgb($color, '0.1')};
      border-radius: ${StylingDefaults.borderRadius};
      .MuiIconButton-root {
        background-color: transparent;
      }
      .MuiButton-outlinedPrimary {
        border-width: 1px;
      }
    }
    .MuiButton-outlinedPrimary:hover {
      border-width: 1px;
    }
    &.with-label > *:first-child {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      background: transparent;
    }
  }

  .MuiButtonBase-root:hover {
    background: none;
  }

  .overflow-item-text {
    margin-left: ${({ theme }) => theme.spacing(4)};
  }

  .list-item-container:hover,
  .overflow-item-text:hover {
    cursor: pointer;
  }

  .overflow-item-label {
    display: block;
  }
`;

const Divider = styled('div')`
  border-bottom: solid 1px ${customColors.grey500};
  margin: 0 ${({ theme }) => theme.spacing(1)};
`;

export default DefaultMore;
