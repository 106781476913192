import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import {
  FormLinkedSnag,
  QualityControlInstance,
  ResourceType,
  useQualityControlInstancesQuery,
  useTaskQuery,
} from '@pro4all/graphql';
import {
  getBatchFormLinkedSnagsIncludeVariables,
  useBatchFetchResults,
  useFetchResourcePermissions,
} from '@pro4all/quality-control/data-access';
import { DrawingRouterState } from '@pro4all/quality-control/data-access';
import { getDrawingRoute } from '@pro4all/quality-control/utils';
import { Action } from '@pro4all/shared/config';
import { Box, MenuItem } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { CopyTextButton, IconButton } from '@pro4all/shared/ui/buttons';
import { ListCard } from '@pro4all/shared/ui/general';
import { BigMessageNoLinkedSnags } from '@pro4all/shared/ui/messages';
import { StyledTooltip } from '@pro4all/shared/ui/side-navigation';
import { Timestamp } from '@pro4all/shared/ui/timestamp';
import { useAnalytics } from '@pro4all/shared/vendor';

import { LinkSnagDialog } from './LinkSnagDialog';
import { PlaceSelectSnagDialog } from './PlaceSelectSnagDialog';
import {
  ButtonsWrapper,
  StyledButton,
  StyledMenu,
} from './SnagActionButtons.styles';
import { useUnlinkSnag } from './useUnlinkSnag';

enum State {
  Idle,
  Link,
  Place,
}
interface Props {
  fieldId: string;
  linkedSnags: FormLinkedSnag[];
  refetchQualityInstance: () => void;
}

export const SnagActionButtons = ({
  fieldId,
  linkedSnags,
  refetchQualityInstance,
}: Props) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const [state, setState] = useState<State>(State.Idle);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { dirty } = useFormikContext() || {};

  const { searchParams, params, goTo, url } = useRouting();
  const { projectId: paramsProjectId } = params;
  const isEditmode = searchParams.is('action', 'editResult');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isTBQQuestionView = searchParams.is('view', 'view-question-sidebar');
  const taskId = searchParams.get('taskId');
  const resultId = searchParams.get('id');

  const { data: taskData, error: taskError } = useTaskQuery({
    fetchPolicy: 'cache-and-network',
    skip: !taskId,
    variables: { id: taskId },
  });

  const projectId = taskData?.task?.project?.id ?? paramsProjectId;

  const { snagCreateAssigned, snagCreateAll } = useFetchResourcePermissions({
    resourceId: resultId,
    resourceType: ResourceType.Instance,
    tbqProjectId: projectId,
  });

  const { data: projectSnagFormInstances } = useQualityControlInstancesQuery({
    fetchPolicy: 'cache-and-network',
    variables: { projectId, visualContextIds: [] },
  });

  const projectSnagInstances =
    projectSnagFormInstances?.qualityControlInstances;

  const snagIntancesList = projectSnagInstances?.length
    ? projectSnagInstances
    : [];

  //Here we are filtering the snagInstances that are available for linking
  const linkedSnagsIds = linkedSnags?.map((link) => link.id);
  const snagsAvailableToLink = snagIntancesList.filter(
    (snag) =>
      !linkedSnagsIds?.includes(snag.id) &&
      snag.type !== null &&
      snag.type === 'snag' &&
      snag.visualContext !== null
  );

  const { instances: listOfSnagIntancesToUnlink } = useBatchFetchResults({
    variables: getBatchFormLinkedSnagsIncludeVariables({ ids: linkedSnagsIds }),
  });

  const UnlinkSnag = useUnlinkSnag({
    linkedSnags,
    refetchQualityInstance,
  });

  const unLinkSnag = async ({
    e,
    snag,
  }: {
    e: React.MouseEvent<HTMLElement>;
    snag: QualityControlInstance;
  }) => {
    e.stopPropagation();
    await UnlinkSnag({
      id: snag.id,
    });
  };

  const snagCardClick = (snag: QualityControlInstance) => {
    if (snag?.visualContext?.deletedAt) {
      goTo('projectQualityControlResults', {
        params: { projectId },
        searchParams: {
          action: 'viewResult',
          id: snag.id,
        },
      });
    } else {
      track(Action.LinkedSnagOpened, {
        id: snag.id,
        name: snag.name,
        projectId,
      });
      goTo(getDrawingRoute(params), {
        params: {
          objectId: params.objectId,
          projectId,
          visualContextId: snag?.visualContext?.id,
        },
        searchParams: {
          action: 'viewResult',
          id: snag.id,
          page: snag.page,
        },
        state: {
          previousPageName: 'form',
          previousPageUrl: url,
        } as DrawingRouterState,
      });
    }
  };

  const renderDialog = () => {
    switch (state) {
      case State.Place:
        return (
          <PlaceSelectSnagDialog
            fieldId={fieldId}
            onClose={() => setState(State.Idle)}
            projectId={projectId}
          />
        );
      case State.Link:
        return (
          <LinkSnagDialog
            fieldId={fieldId}
            linkedSnagsIds={linkedSnagsIds}
            onClose={() => setState(State.Idle)}
            refetchQualityInstance={refetchQualityInstance}
            snagIntancesList={snagsAvailableToLink}
            taskError={taskError}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderDialog()}
      <ButtonsWrapper>
        {isEditmode && (
          <>
            {(snagCreateAll || snagCreateAssigned) && (
              <StyledTooltip
                placement="bottom"
                title={
                  dirty
                    ? t('Save your changes to place a Snag')
                    : t('Create a new Snag')
                }
              >
                <IconButton
                  color="default"
                  disableBorder
                  disabled={dirty}
                  iconName="placeSnag"
                  onClick={() => {
                    setState(State.Place);
                  }}
                />
              </StyledTooltip>
            )}
            <StyledTooltip
              placement="bottom"
              title={
                snagsAvailableToLink.length === 0
                  ? t('No Snags existing to link')
                  : t('Link existing Snag')
              }
            >
              <IconButton
                color="default"
                disableBorder
                iconName="link"
                onClick={() => {
                  snagsAvailableToLink.length === 0
                    ? setState(State.Idle)
                    : setState(State.Link);
                }}
              />
            </StyledTooltip>
          </>
        )}
        <StyledTooltip
          placement="bottom"
          title={
            listOfSnagIntancesToUnlink?.length !== 0
              ? t('Linked Snags')
              : t('There are no linked Snags')
          }
        >
          <StyledButton
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            id="demo-customized-button"
            onClick={handleClick}
            startIcon="snag"
          >
            {listOfSnagIntancesToUnlink !== undefined
              ? listOfSnagIntancesToUnlink?.length
              : '0'}
          </StyledButton>
        </StyledTooltip>
        <StyledMenu
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
            style: {
              height: '328px',
              padding: '0',
              width: '350px',
            },
          }}
          anchorEl={anchorEl}
          elevation={1}
          id="demo-customized-menu"
          onClose={() => setAnchorEl(null)}
          open={open}
        >
          {linkedSnags?.length !== 0 ? (
            listOfSnagIntancesToUnlink?.map((snag: QualityControlInstance) => (
              <MenuItem
                disableGutters
                disableRipple
                key={snag.id}
                value={snag.id}
              >
                <ListCard
                  disabledButtonBorder
                  iconName="snag"
                  key={snag.id}
                  menuItems={
                    !isTBQQuestionView && isEditmode
                      ? [
                          {
                            label: `Snag list`,
                            onClick: (e) => unLinkSnag({ e, snag }),
                            startIcon: 'unlink',
                          },
                        ]
                      : []
                  }
                  meta={[
                    `${snag?.createdBy?.displayName}`,
                    <Timestamp date={snag?.createdAt} format="lll" />,
                  ]}
                  onClick={() => snagCardClick(snag)}
                  titleWithElement={
                    <Box display="flex" gap="5px">
                      <Box display="flex">
                        #
                        <CopyTextButton
                          textName={t('Reference')}
                          textToCopy={snag.reference}
                        />
                      </Box>
                      <Box>{snag.name}</Box>
                    </Box>
                  }
                />
              </MenuItem>
            ))
          ) : (
            <BigMessageNoLinkedSnags />
          )}
        </StyledMenu>
      </ButtonsWrapper>
    </>
  );
};
