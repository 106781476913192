import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikConfig } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Alert } from '@pro4all/shared/mui-wrappers';
import { FormFooter } from '@pro4all/shared/ui/form';
import {
  FormikForm,
  FormikRadioGroup,
  RadioOption,
} from '@pro4all/shared/ui/formik';
import { InfoBlock } from '@pro4all/shared/ui/info-block';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useEditRolesFormConfig } from './useEditRolesFormConfig';

export interface FormValues {
  role: string;
}

interface Props extends Pick<FormikConfig<FormValues>, 'onSubmit'> {
  includesMe: boolean;
  onCancel: () => void;
  options: RadioOption[];
}

export const EditRolesForm: React.FC<Props> = ({
  includesMe,
  onSubmit,
  options,
  onCancel,
}) => {
  const { t } = useTranslation();
  const initialValues: FormValues = {
    role: '',
  };

  const { getField, validationSchema } = useEditRolesFormConfig();
  const roleField = getField('role');

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting, dirty }) => (
        <FormikForm aria-label="Edit roles">
          <FormWrapper>
            {includesMe && (
              <Alert severity="info">
                {t(
                  'You selected yourself, please note that role assignments will not apply to yourself.'
                )}
              </Alert>
            )}
            <InfoBlock
              paragraph={t(
                'A role reflects basic system wide permissions, which are different than access rights to the different parts in Prostream.'
              )}
            />
            <FormikRadioGroup
              label={roleField.label}
              name={roleField.name}
              options={options}
            />
          </FormWrapper>

          <FormFooter
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            onClose={onCancel}
            pb={3}
            pt={2}
            px={3}
            sticky
          />
        </FormikForm>
      )}
    </Formik>
  );
};
