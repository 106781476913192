import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { ShareType } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { Radio } from '@pro4all/shared/ui/inputs';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { GeneratePrivateLinkForm } from '../forms/GeneratePrivateLinkForm';
import { GeneratePublicLinkForm } from '../forms/GeneratePublicLinkForm';
import { GenerateScopedLinkForm } from '../forms/GenerateScopedLinkForm';
import { useGenerateLinkContext } from '../GenerateLinkContext';

import { FormWrap, Title } from './styles';

interface ShareOption extends Option {
  CustomForm?: React.ElementType;
  hidden?: boolean;
  name: string;
}

type ShareForm = Record<
  ShareType,
  { CustomForm: React.ElementType; id: string; label: string }
>;

export const GenerateLink: React.FC = () => {
  const { t } = useTranslation();
  const {
    closeModal,
    composeMessage,
    latestVersionOnly,
    selectedShareMethod,
    setSelectedShareMethod,
    toggleLatestVersionOnly,
  } = useGenerateLinkContext();

  const { projectId } = useRouting().params;
  const isProject = Boolean(projectId);

  const selectShareMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) setSelectedShareMethod(event.target.value);
  };

  // @Todo: Share options will come from BE, enable private and scoped when available
  const shareTypeOptions: ShareOption[] = [
    /* Accessible to anyone, requires pw if enabled */
    {
      CustomForm: GeneratePublicLinkForm,
      id: uuid(),
      label: t('Anyone with the link'),
      name: ShareType.Public,
    },
    /* Accessible to invited org/proj members */
    {
      CustomForm: GeneratePrivateLinkForm,
      id: uuid(),
      label: isProject
        ? t('Selected project members')
        : t('Selected organization members'),
      name: ShareType.Private,
    },
    /* Accessible to all org/proj members */
    {
      CustomForm: GenerateScopedLinkForm,
      id: uuid(),
      label: isProject
        ? t('All project members')
        : t('All organization members'),
      name: ShareType.Scoped,
    },
  ];

  const shareTypes = shareTypeOptions.reduce<ShareForm>(
    (acc, { CustomForm, id, label, name }) => ({
      ...acc,
      [name]: { CustomForm, id, label },
    }),
    {
      private: { CustomForm: undefined, id: '', label: '' },
      public: { CustomForm: undefined, id: '', label: '' },
      scoped: { CustomForm: undefined, id: '', label: '' },
    }
  );

  const { CustomForm } = selectedShareMethod
    ? shareTypes[selectedShareMethod]
    : { CustomForm: null };

  return (
    <FormWrap>
      <FormWrapper noPadding>
        <Title variant="h6">{t('Who can access the link')}</Title>
        <Box>
          {shareTypeOptions
            .filter((option) => !option.hidden)
            .map(({ id, disabled, label, name }: ShareOption, key) => (
              <React.Fragment key={key}>
                <Radio
                  checked={selectedShareMethod === name}
                  disabled={disabled}
                  id={id}
                  label={label}
                  onChange={selectShareMethod}
                  value={name}
                />
              </React.Fragment>
            ))}
        </Box>
        <Title>{t('Versions')}</Title>
        <Box>
          <Radio
            checked={!latestVersionOnly}
            id={uuid()}
            label={t('Latest versions (updates with each new version)')}
            onChange={toggleLatestVersionOnly}
          />
          <Radio
            checked={latestVersionOnly}
            id={uuid()}
            label={t('Current versions (at time of link creation)')}
            onChange={toggleLatestVersionOnly}
          />
        </Box>

        {/* We swap forms (entire Formik context) so we can use validationSchemas independently */}
        {CustomForm && (
          <CustomForm onClose={closeModal} onProceed={composeMessage} />
        )}
      </FormWrapper>
    </FormWrap>
  );
};
