import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Button } from '@pro4all/shared/ui/buttons';
import { Dialog } from '@pro4all/shared/ui/dialog';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikForm, FormikSearchableSelect } from '@pro4all/shared/ui/formik';
import { InfoBlock } from '@pro4all/shared/ui/info-block';
import { Text } from '@pro4all/shared/ui/typography';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { usePreSubmit } from './preSubmit';
import { useShareSnagstreamContext } from './ShareSnagstreamContext';
import { useGetConnectionOptions } from './useGetConnectionOptions';
import { useGetInitialValues } from './useGetInitialValues';
import { useSelectConnection } from './useSelectConnection';
import { useShareSnagstreamFormConfig } from './useShareSnagstreamFormConfig';
import { useSubmit } from './useSubmit';

export const ShareSnagstreamForm = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { getField, validationSchema } = useShareSnagstreamFormConfig();
  const initialValues = useGetInitialValues();
  const onSubmit = useSubmit({ onClose });
  const onPreSubmit = usePreSubmit();
  const selectConnection = useSelectConnection();
  const connectionField = getField('connection');
  const { connectionOptions } = useGetConnectionOptions();
  const { validationScreen, setValidationScreen } = useShareSnagstreamContext();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, helpers) => {
        const isValid = await onPreSubmit(values);
        if (!isValid) {
          helpers.setSubmitting(false);
          return;
        }
        onSubmit({ values });
      }}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting, values, setSubmitting }) => (
        <FormikForm>
          <FormWrapper noPadding>
            <InfoBlock
              paragraph={t('Select a project you want to share it to.')}
            />
            <FormikSearchableSelect
              id={connectionField?.name}
              label={connectionField?.displayName}
              name={connectionField?.name || ''}
              onChange={(option) => selectConnection({ option, values })}
              options={connectionOptions}
              placeholder={t('Select connection')}
            />
          </FormWrapper>

          <FormFooter
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            onClose={onClose}
            pt={2}
            sticky
          />
          <Dialog
            additionalActions={
              <Button
                data-testid="overwrite"
                onClick={() => onSubmit({ overwrite: true, values })}
                type="button"
                variant="contained"
              >
                {t('Overwrite')}
              </Button>
            }
            closeLabel={t('Skip')}
            confirmLabel={t('Mark as sent')}
            iconName="warning"
            name="routerPrompt"
            onClose={() => {
              setSubmitting(true);
              setValidationScreen(false);
              onSubmit({ values });
            }}
            onConfirm={() => {
              setSubmitting(true);
              setValidationScreen(false);
              onSubmit({ markAsSent: true, values });
            }}
            open={validationScreen}
            title={t('Document already exists')}
          >
            <Text>
              {t(
                'One or more documents already exist in the selected Snagstream project. Either chose to overwrite, mark as sent to Snagstream or skip the document(s).'
              )}
            </Text>
          </Dialog>
        </FormikForm>
      )}
    </Formik>
  );
};
