import { HierarchicalList } from '@pro4all/graphql';
import { HierarchyEditorProvider } from '@pro4all/shared/hierarchy-editor';
import { Column, ContentRow, Main } from '@pro4all/shared/ui/layout';

import { HierarchicalListForm } from './HierarchicalListForm';

export const HierarchicalListFrame = ({
  includeOrgItems,
  selectedHierarchicalList,
}: {
  includeOrgItems: boolean;
  selectedHierarchicalList?: HierarchicalList;
}) => (
  <Main>
    <ContentRow>
      <Column flex="1" pt={2} px={1}>
        <HierarchyEditorProvider>
          <HierarchicalListForm
            includeOrgItems={includeOrgItems}
            selectedHierarchicalList={selectedHierarchicalList}
          />
        </HierarchyEditorProvider>
      </Column>
    </ContentRow>
  </Main>
);
