import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { NotificationsUser, User } from '@pro4all/graphql';
import { ColumnSortOrder } from '@pro4all/shared/types';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { ColumnProps } from '@pro4all/shared/ui/table';

const formatDate = (value: string) => {
  if (dayjs(value).isValid()) return dayjs(value).format('lll');
  else return value;
};

export const useColumns = (): ColumnProps<NotificationsUser>[] => {
  const { t } = useTranslation();

  return [
    {
      align: 'left',
      defaultSort: true,
      defaultSortOrder: ColumnSortOrder.ASC,
      key: 'displayName',
      title: t('Name'), // i18n
      width: 176,
    },
    {
      align: 'left',
      defaultSort: true,
      defaultSortOrder: ColumnSortOrder.ASC,
      key: 'email',
      title: t('Email'), // i18n
      width: 176,
    },
    {
      align: 'left',
      defaultSort: true,
      defaultSortOrder: ColumnSortOrder.ASC,
      key: 'organization.name',
      render: ({ organization }) => organization?.name,
      title: t('Organization'), // i18n
      width: 176,
    },
    {
      align: 'left',
      defaultSort: true,
      defaultSortOrder: ColumnSortOrder.ASC,
      key: 'lastUpdatedOn',
      render: ({ lastUpdatedOn }) =>
        lastUpdatedOn ? formatDate(lastUpdatedOn) : '',
      title: t('Last updated on'), // i18n
      width: 160,
    },
    {
      align: 'left',
      defaultSort: true,
      defaultSortOrder: ColumnSortOrder.ASC,
      key: 'lastUpdatedBy',
      render: ({ lastUpdatedBy }) => {
        const { displayName, email, id, isAdmin, organization } =
          lastUpdatedBy || {};
        const user: User = {
          displayName: displayName || '',
          email,
          id: id || '',
          isAdmin,
          organization,
        };
        return <UserTag user={user} />;
      },
      title: t('Last updated by'), // i18n
      width: 216,
    },
  ];
};
