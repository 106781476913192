import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { usePreventSubmitOnEnter } from '@pro4all/shared/ui/form';
import { FormikCheckbox } from '@pro4all/shared/ui/formik';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { naturalCompare } from '@pro4all/shared/utils';

import { SelectionOptions } from './SelectionOptions';
import { CustomType, FormFields } from './Types';
import { useFieldDefinitionFormConfig } from './useFieldDefinitionFormConfig';

export type SelectOption = {
  name: string;
};

export const CustomSelectionForm = ({
  values: { selectionOptions = [] },
}: CustomType) => {
  const { t } = useTranslation();
  const { setFieldTouched, values, setFieldValue } =
    useFormikContext<FormFields>();

  usePreventSubmitOnEnter();

  const sortNaturally = () => {
    const unsortedOptions = [...values.selectionOptions];
    const sortedOptions = unsortedOptions.sort((a, b) =>
      naturalCompare(a.name, b.name)
    );
    setFieldValue('selectionOptions', sortedOptions);
    const throttledSetFieldTouched = debounce((index) => {
      setFieldTouched(`selectionOptions[${index}].name`);
    }, 100);
    selectionOptions.forEach((_, i) => throttledSetFieldTouched(i));
  };

  const { getField } = useFieldDefinitionFormConfig();
  const multiSelectField = getField('multiSelect');
  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        <FormikCheckbox
          label={multiSelectField.label}
          name={multiSelectField.name}
        />
        {selectionOptions.length > 1 ? (
          <Tooltip placement="bottom" title={t('Order options alphabetically')}>
            <SortIcon iconName="sortByAlpha" onClick={sortNaturally}></SortIcon>
          </Tooltip>
        ) : null}
      </Box>
      <SelectionOptions selectionOptions={selectionOptions} />
    </>
  );
};

export const StyledErrorMessage = styled('span')`
  color: ${({ theme }) => theme.palette.error.main};
`;

const SortIcon = styled(Icon)`
  cursor: pointer;
`;
