import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { InputAdornment } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';

import { TextField } from '@pro4all/shared/ui/inputs';

import { useTableFilterContext } from './TableContext';

const Button = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
`;

const StartAdornment = styled(InputAdornment)`
  && {
    margin-right: 0;
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const EndAdornment = styled(InputAdornment)`
  && {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
    margin-left: 0;
  }
`;

export const TableSearch: React.FC = () => {
  const [filter, setFilter] = useTableFilterContext();
  const { t } = useTranslation();

  return (
    <TextField
      InputProps={{
        endAdornment: filter && (
          <EndAdornment position="end">
            <Button onClick={() => setFilter && setFilter('')}>
              <Icon iconName="close" />
            </Button>
          </EndAdornment>
        ),
        startAdornment: (
          <StartAdornment position="start">
            <Icon iconName="search" />
          </StartAdornment>
        ),
      }}
      name="search"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setFilter && setFilter(e.target.value)
      }
      placeholder={t('Search')}
      role="search"
      value={filter}
      variant="standard"
    />
  );
};
