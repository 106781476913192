import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { useReportConfigurations } from '@pro4all/shared/hooks';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ReportOptionsFormProps } from '@pro4all/shared/types';
import { Button } from '@pro4all/shared/ui/buttons';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikForm } from '@pro4all/shared/ui/formik';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useReportOptionsContext } from '../ReportOptionsProvider';
import { defaultTemplate } from '../useGetInitialReportOptions';

import { ReportConfigForm } from './report-config-modal/ReportConfigForm';
import { CustomOptions } from './CustomOptions';
import { StylePresetSelect } from './StylePresetSelect';

export const ReportOptionsForm = ({
  formTemplateOptions,
  formTemplateOptionsAll,
  reportConfigurations: reportConfigurationsTable,
  snagTemplateOptions,
  snagTemplateOptionsAll,
}: ReportOptionsFormProps) => {
  const { t } = useTranslation();
  const {
    params: { projectId },
    searchParams,
  } = useRouting();

  const { hasAppPermission } = useAppPermissions();
  const userCannotUpdateOrg = !hasAppPermission('OrganizationUpdate');
  const userCannotUpdateProject = !hasAppPermission('ProjectUpdate');
  const hideFooter = projectId
    ? userCannotUpdateOrg && userCannotUpdateProject
    : userCannotUpdateOrg;

  const {
    setReportConfigurations,
    setTemplateOptions,
    state: { activeReportConfiguration, customMode, reportOptions },
  } = useReportOptionsContext();

  const { reportConfigurations } = useReportConfigurations({
    reportConfigurations: reportConfigurationsTable,
  });
  useEffect(() => {
    setReportConfigurations(reportConfigurations || reportConfigurationsTable);
  }, [
    reportConfigurations,
    reportConfigurationsTable,
    setReportConfigurations,
  ]);

  // Ensure the report options always at least have a set of default options for each template
  useEffect(() => {
    [...snagTemplateOptions, ...formTemplateOptions].forEach((template) => {
      if (
        !Object.keys(reportOptions.templates ?? {}).some(
          (templateId) => templateId === template.id
        )
      ) {
        setTemplateOptions({ id: template.id, options: defaultTemplate });
      }
    });
  }, [
    snagTemplateOptions,
    formTemplateOptions,
    setTemplateOptions,
    reportOptions.templates,
  ]);

  const labelPrimary = !activeReportConfiguration
    ? t('New configuration')
    : t('Update configuration');
  const labelSecundary = !activeReportConfiguration
    ? t('Update configuration')
    : t('New configuration');
  const onSubmitPrimary = !activeReportConfiguration
    ? () => searchParams.set({ createReportConfig: 'true' })
    : () => searchParams.set({ editReportConfig: 'true' });
  const onSubmitSecundary = !activeReportConfiguration
    ? () => searchParams.set({ editReportConfig: 'true' })
    : () => searchParams.set({ createReportConfig: 'true' });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={reportOptions}
        onSubmit={onSubmitPrimary}
      >
        {({ errors, isSubmitting }) => (
          <FormikForm>
            <FormWrapper>
              <StylePresetSelect />
              {customMode ? (
                <CustomOptions
                  formTemplateOptions={formTemplateOptionsAll}
                  snagTemplateOptions={snagTemplateOptionsAll}
                />
              ) : null}
            </FormWrapper>
            {!hideFooter && (
              <FormFooter
                disableSubmit={
                  isSubmitDisabled({
                    dirty: true,
                    errors: errors,
                    isSubmitting,
                  }) ||
                  !customMode ||
                  activeReportConfiguration?.id === 'default'
                }
                pt={2}
                showCancel={false}
                sticky
                submitLabel={labelPrimary}
              >
                {activeReportConfiguration && (
                  <Button
                    data-testid="edit-report-configuration"
                    onClick={onSubmitSecundary}
                    type="button"
                    variant="outlined"
                  >
                    {labelSecundary}
                  </Button>
                )}
              </FormFooter>
            )}
          </FormikForm>
        )}
      </Formik>
      <ReportConfigForm />
    </>
  );
};
