import {
  ApolloHookReturnType,
  User,
  useVisualContextsQuery,
  VisualContext,
} from '@pro4all/graphql';
import { RenderOptionType } from '@pro4all/shared/types';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';

type VCResult = ApolloHookReturnType<
  typeof useVisualContextsQuery,
  'visualContexts'
>;

export const useColumns = () => {
  const columns: ColumnProps<VCResult>[] = [
    {
      defaultSort: true,
      filterable: true,
      headerComponent: (
        <FilterHeader<VisualContext, {}>
          defaultWidth={400}
          filterType={FilterHeaderType.Text}
          label="Name" // i18n∏
          minWidth={100}
          propertyId="name"
          pullFromLocalStorage
        />
      ),
      key: 'name',
      width: 400,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<VisualContext, {}>
          defaultWidth={120}
          filterType={FilterHeaderType.Number}
          label="Pages" // i18n
          minWidth={100}
          propertyId="pageCount"
        />
      ),
      key: 'pageCount',
      width: 120,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<VisualContext, {}>
          defaultWidth={120}
          filterType={FilterHeaderType.Number}
          label="Forms" // i18n
          minWidth={68}
          propertyId="formCount"
        />
      ),
      key: 'formCount',
      width: 120,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<VisualContext, {}>
          defaultWidth={120}
          filterType={FilterHeaderType.Number}
          label="Snags" // i18n
          minWidth={68}
          propertyId="snagCount"
        />
      ),
      key: 'snagCount',
      width: 120,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<VisualContext, {}>
          defaultWidth={120}
          filterType={FilterHeaderType.Number}
          label="Free snags" // i18n
          minWidth={68}
          propertyId="freeSnagCount"
        />
      ),
      key: 'freeSnagCount',
      width: 120,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<VisualContext, {}>
          defaultWidth={150}
          filterType={FilterHeaderType.Select}
          label="Status" // i18n
          minWidth={80}
          propertyId="status"
          translateOptions
        />
      ),
      key: 'status',
      width: 150,
    },
    {
      filterable: true,
      getValue: ({ createdBy }) => createdBy?.displayName,
      headerComponent: (
        <FilterHeader<VisualContext, User>
          defaultWidth={300}
          filterType={FilterHeaderType.Select}
          label="Created by" // i18n
          minWidth={120}
          propertyId="createdBy"
          renderOption={RenderOptionType.UserCard}
          subPropertyId="displayName"
        />
      ),
      key: 'createdBy.displayName',
      render: ({ createdBy }) => <UserTag user={createdBy} />,
      width: 300,
    },
  ];
  return columns;
};
