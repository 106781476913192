import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Button } from '@pro4all/shared/ui/buttons';
import { FormikInput } from '@pro4all/shared/ui/formik';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { Buttons, Form, StepHeader } from './FormComponents';
import { useStep1Config } from './useStep1Config';

export const initialValues = {
  firstName: '',
  lastName: '',
  organizationName: '',
  phoneNumber: '',
};

export type Values = typeof initialValues;

interface Props {
  onSubmit: (values: Values) => void;
  values: Values;
}

export const Step1: React.FC<Props> = ({ onSubmit, values }) => {
  const { t } = useTranslation();

  const { getField, validationSchema } = useStep1Config();
  const firstNameField = getField('firstName');
  const lastNameField = getField('lastName');
  const organizationNameField = getField('organizationName');
  const phoneNumberField = getField('phoneNumber');

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, errors, isSubmitting }) => (
        <Form>
          <StepHeader step={0} />
          <FormWrapper noPadding>
            <FormikInput
              autoFocus
              label={firstNameField?.label}
              name={firstNameField?.name}
            />
            <FormikInput
              label={lastNameField?.label}
              name={lastNameField?.name}
            />
            <FormikInput
              label={phoneNumberField?.label}
              name={phoneNumberField?.name}
            />
            <FormikInput
              label={organizationNameField?.label}
              name={organizationNameField?.name}
            />
          </FormWrapper>
          <Buttons>
            <Button color="inherit" href="/" variant="text">
              {t('Back')}
            </Button>
            <Button
              color="primary"
              disabled={isSubmitDisabled({
                // It's necessary to check only one field because all fields are required.
                dirty: Boolean(values.firstName),
                errors,
                isSubmitting,
              })}
              type="submit"
            >
              {t('Next')}
            </Button>
          </Buttons>
        </Form>
      )}
    </Formik>
  );
};
