import React from 'react';
import { useTranslation } from 'react-i18next';

import { Shape } from '@pro4all/shared/ui/shapes';

import { useCloseChangeSubscription } from './hooks/useCloseChangeSubscription';
import * as Styled from './styles/LandingPage.styles';
import * as StyledShared from './styles/Shared.styles';

export const LandingPage = () => {
  const { t } = useTranslation();
  const onClose = useCloseChangeSubscription();

  return (
    <StyledShared.PaneWrapper>
      <Styled.Wrapper>
        <Styled.CustomText variant="h2">
          {t(
            "Payment successful, it can take a few moments before it's visible."
          )}
        </Styled.CustomText>
        <Styled.ButtonContinue
          color="primary"
          data-testid="continue"
          onClick={onClose}
          startIcon="arrowForward"
          variant="contained"
        >
          Let's go
        </Styled.ButtonContinue>
        <Shape
          aria-hidden={false}
          color="green"
          fontSize="inherit"
          mono
          role="presentation"
          style={{ maxHeight: '140px', maxWidth: '240px' }}
          type="build"
        />
      </Styled.Wrapper>
    </StyledShared.PaneWrapper>
  );
};
